import { useState } from 'react';
import { RecipesContext } from './recipesContext';
import type { IRecipesProp } from './recipes.types';

export const RecipesProvider = ({ children }) => {
  const [recipes, setRecipes] = useState<IRecipesProp[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  return (
    <RecipesContext.Provider value={{ recipes, setRecipes, isLoading, setIsLoading }}>
      {children}
    </RecipesContext.Provider>
  );
};

import { theme } from 'src/assets/theme/theme';
import colors from 'src/assets/theme/colors';
import { styled, Box, IconButton, Dialog } from '@mui/material';

export const DialogModal = styled(Dialog)`
  max-height: 100vh;
  max-width: 100vw;
  overflow: hidden;
`;

export const CloseButton = styled(IconButton)`
  position: absolute;
  right: ${theme.spacing(4)};
  top: ${theme.spacing(4)};
  color: ${colors.darkBlue};
  z-index: 2;
`;
export const ImageContainer = styled(Box)`
  position: relative;
  width: 33vw;
  height: 100vh;
  overflow: hidden;

  ${(props) => props.theme.breakpoints.down('dl')} {
    display: none;
  }
`;
export const StyledImage = styled('img')`
  height: 100%;
  object-fit: cover;
  width: 100%;
`;

export const Container = styled(Box)`
  display: flex;
  height: 100vh;
  align-items: center;
  &::after,
  &::before {
    pointer-events: none;
    content: '';
    position: absolute;
    width: 100%;
    width: 67vw;
    height: ${theme.spacing(4)};

    left: 33vw;
    z-index: 2;
  }
  &:before {
    top: 0;
    background: linear-gradient(white, transparent);
  }
  &::after {
    bottom: 0;
    background: linear-gradient(transparent, white);
  }
`;

export const FormContent = styled(Box)`
  width: 67vw;
  height: auto;
  max-height: 100vh;
  overflow: auto;
  position: relative;
  z-index: 1;

  ${(props) => props.theme.breakpoints.down('dl')} {
    width: 100vw;
  }
  > form {
    margin: ${theme.spacing(4)} auto;
    max-width: ${theme.spacing(137)};
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
`;

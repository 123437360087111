import { styled, Table, TableCell, TableRow, Typography, Box } from '@mui/material';
import colors from 'src/assets/theme/colors';
import { theme } from 'src/assets/theme/theme';

interface IStyledTableRow {
  selectedrow?: string;
  type?: string;
}

interface IStyledTableDataProp {
  type?: string;
}

export const StyledTypography = styled(Typography)`
  min-width: 33%;
  &::first-letter {
    text-transform: uppercase;
  }
  margin-right: ${theme.spacing(2.5)};
`;

export const StyledTableHeader = styled(TableCell)`
  padding: 0 ${theme.spacing(12.5)} ${theme.spacing(8)} 0;
  border-bottom: 1px dashed ${colors.AZT_Light};
  &::first-letter {
    text-transform: uppercase;
  }
  ${(props) => props.theme.breakpoints.down('dl')} {
    padding-right: ${theme.spacing(4)};
  }
`;

export const StyledImage = styled('img')`
  height: 100%;
  object-fit: cover;
  width: 100%;
`;

export const ImageContainer = styled(Box)`
  position: relative;
  width: ${theme.spacing(18.25)};
  height: ${theme.spacing(18.25)};
  border-radius: ${theme.spacing(2)};
  overflow: hidden;
`;

export const StyledTableData = styled(TableCell)<IStyledTableDataProp>`
  width: ${theme.spacing(62)};
  padding: ${theme.spacing(8)} ${theme.spacing(12.5)} ${theme.spacing(8)} 0;
  vertical-align: ${(props) => (props.type === 'items' ? 'middle' : 'top')};

  border-bottom: 1px dashed ${colors.AZT_Dark_Opaque};
  ${(props) => props.theme.breakpoints.down('dl')} {
    padding-right: ${theme.spacing(4)};
  }
`;

export const StyledTableRow = styled(TableRow)<IStyledTableRow>`
  background-color: ${(props) => props.selectedrow === 'true' && colors.AZT_Sky};
  cursor: ${(props) => (props.type === 'items' ? 'pointer' : '')};
  :hover {
    background-color: ${(props) => props.type === 'items' && colors.AZT_Sky};
  }
  & :last-child {
    padding-right: 0;
  }
`;

export const StyledTable = styled(Table)`
  border-collapse: initial;
  margin-top: 50px;
  max-width: 100%;
`;

export const StyledMobileTable = styled(Box)`
  > div:last-child {
    border: none;
  }
`;

export const StyledMobileData = styled(Box)`
  margin: 0 0 0 ${theme.spacing(2)};
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

export const StyledMobileRow = styled(Box)<IStyledTableRow>`
  background-color: ${(props) => props.selectedrow === 'true' && colors.AZT_Sky};
  padding: ${theme.spacing(2)};
  display: flex;
  flex-direction: column;
  border-bottom: 1px dashed ${colors.AZT_Light};
  & :first-of-type {
    margin-bottom: ${theme.spacing(4)};
  }
`;

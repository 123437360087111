import { ReactElement, lazy } from 'react';
import { Typography } from '@mui/material';
import { TypeText } from './MenuItemType.styles';
import NumericalSubtitle from '../NumericalSubtitle';
import RadioButtonGroup from '../RadioButtonGroup';
import { Control } from 'react-hook-form';

export interface IMenuItemTypeProps {
  variant: string;
  itemType: string;
  control: Control<any>;
}

const MenuItemType = ({ variant, itemType, control }: IMenuItemTypeProps): ReactElement => {
  const radioOptionsArray = [
    {
      option: 'STANDARD',
      label: 'Standard',
      LogoComponent: lazy(() => import('src/assets/images/Standard')),
      toolTipText: 'A typical item i.e. Burger',
    },
    {
      option: 'MODIFIER',
      label: 'Modifier',
      LogoComponent: lazy(() => import('src/assets/images/Modifier')),
      toolTipText: 'Added to standard items to modify them i.e. Ketchup',
    },
    {
      option: 'BUNDLE',
      label: 'Bundle',
      LogoComponent: lazy(() => import('src/assets/images/Bundle')),
      toolTipText: 'A group of menu items for a fixed price e.g. Meal deals',
    },
  ];

  return (
    <>
      <NumericalSubtitle number="1" subtitle="Type" />
      {variant === 'create' ? (
        <RadioButtonGroup radioButtonGroupName={'type'} control={control} radioOptions={radioOptionsArray} />
      ) : (
        <Typography variant="mText">
          <TypeText variant="strongText">{itemType && itemType.toLowerCase()}</TypeText>
        </Typography>
      )}
    </>
  );
};

export default MenuItemType;

/**
 * Break string down to one or two Initials
 * @param  {String} input Name input
 * @return {String} One or two Initials
 */
const getInitials = (input: string): string => {
  if (!input) throw new TypeError('missing input');
  const first = input.split(' ').shift()?.[0] || '';
  const last = input.split(' ').length > 1 ? input.split(' ').pop()?.[0] || '' : '';
  return (first + last).toUpperCase();
};
export default getInitials;

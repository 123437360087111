import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { SecureRoute, useOktaAuth } from '@okta/okta-react';
import { error } from 'src/mocks/data';
import NavBar from '../NavBar';
import { Switch } from 'react-router-dom';
import { Recipes } from 'src/pages/Recipes';
import Dashboard from 'src/pages/Dashboard';
import { useMenusContext } from 'src/contexts/menus';
import { IRecipesProp } from 'src/helpers';
import { useNotificationOutlet } from '../Notification';
import SideBar from '../SideBar/SideBar';
import { StyledBox, LayoutBox, StyledMaxWidth } from './Layout.styles';
import { SpinnerContainer } from 'src/pages/Dashboard/Dashboard.styles';
import Menu from 'src/pages/Menu';
import MenuItem from 'src/pages/MenuItem';
import { getRecipes } from 'src/api/getRecipes/getRecipes';
import { useRecipesContext } from 'src/contexts/recipes';
import { useItemsContext } from 'src/contexts/items';
import { MenuItemCreate } from 'src/pages';
import MenuItemEdit from 'src/pages/MenuItemEdit';
import { getBrands } from 'src/api/getBrands/getBrands';
import { getMenus } from 'src/api/getMenus/getMenus';
import { IGetMenusResponseBody } from 'src/api/getMenus/types';
import { IGetBrandsResponseBody } from 'src/api/getBrands/types';
import { getMenusService } from 'src/services/getMenusService/getMenuService';
import { getBrandsService } from 'src/services/getBrandsService/getBrandsService';
import { getMenuItems } from 'src/api/getMenuItems/getMenuItems';
import { formatMenuItems } from 'src/services/getMenuService/getMenuService';
import { Brand } from 'src/types/brand.types';
import { Items } from 'src/pages/Items';
import { GeographiesProvider } from 'src/contexts/geographies';
import { IMenuItemsResponseBody } from 'src/api/getMenuItems/types';

const Layout = () => {
  const { authState } = useOktaAuth();
  const history = useHistory();
  const accessToken = authState?.accessToken?.accessToken;
  const { menus, setIsLoading, addMenus } = useMenusContext();
  const setMessage = useNotificationOutlet();
  const { setRecipes, setIsLoading: setIsRecipesLoading } = useRecipesContext();
  const { addItems, setIsLoading: setIsItemsLoading } = useItemsContext();
  const [isBrandsLoading, setIsBrandsLoading] = useState<boolean>(true);
  const [brands, setBrands] = useState<Brand[]>([]);
  const [selectedBrandId, setSelectedBrandId] = useState<string>();

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const handleDrawerToggle = () => {
    setIsSidebarOpen((prev) => !prev);
  };

  useEffect(() => {
    getBrands(accessToken)
      .then((res) => res.json())
      .then((response: IGetBrandsResponseBody) => {
        const fetchedBrands = getBrandsService(response.data);
        const storedBrandId = localStorage.getItem('brandId');
        const isValidBrandId = fetchedBrands.find((brand) => brand.id === storedBrandId);
        if (isValidBrandId) {
          setSelectedBrandId(storedBrandId);
        } else {
          const defaultBrandId = fetchedBrands[0].id;
          localStorage.setItem('brandId', defaultBrandId);
          setSelectedBrandId(defaultBrandId);
        }
        setBrands(fetchedBrands);
      })
      .catch(() => {
        setMessage(error);
      })
      .finally(() => setIsBrandsLoading(false));
  }, []);

  useEffect(() => {
    if (selectedBrandId) {
      setIsLoading(true);
      getMenus(accessToken, selectedBrandId)
        .then((res) => res.json())
        .then((response: IGetMenusResponseBody) => {
          addMenus(getMenusService(response.data));
        })
        .catch(() => {
          setMessage(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [accessToken, selectedBrandId]);

  useEffect(() => {
    if (selectedBrandId) {
      getRecipes(accessToken, selectedBrandId)
        .then((res) => res.json())
        .then(({ data }: { data: IRecipesProp[] }) => {
          setRecipes(data);
        })
        .catch(() => {
          setMessage(error);
        })
        .finally(() => {
          setIsRecipesLoading(false);
        });
    }
  }, [accessToken, setRecipes, selectedBrandId]);

  useEffect(() => {
    if (selectedBrandId) {
      setIsItemsLoading(true);
      getMenuItems()
        .then((res) => res.json())
        .then((response: IMenuItemsResponseBody) => {
          addItems(formatMenuItems(response.data));
        })
        .catch(() => {
          setMessage(error);
        })
        .finally(() => {
          setIsItemsLoading(false);
        });
    }
  }, [selectedBrandId]);

  const handleBrandSwitch = (brandId) => {
    setSelectedBrandId(brandId);
    window.localStorage.setItem('brandId', brandId);
    history.push('/');
  };

  return (
    <LayoutBox>
      {!brands.length || isBrandsLoading ? (
        <SpinnerContainer data-testid="loading-spinner">
          <CircularProgress color="secondary" />
        </SpinnerContainer>
      ) : (
        <GeographiesProvider>
          <SideBar
            menus={menus}
            brands={brands}
            selectedBrandId={selectedBrandId}
            open={isSidebarOpen}
            handleClick={handleDrawerToggle}
            onBrandSwitch={handleBrandSwitch}
          />
          <StyledBox>
            <NavBar handleDrawerToggle={handleDrawerToggle} />
            <StyledMaxWidth>
              <Switch>
                <SecureRoute exact path="/" component={Dashboard} />
                <SecureRoute exact path="/menu/:menuId" component={Menu} />
                <SecureRoute exact path="/menu/:menuId/item/new" component={MenuItemCreate} />
                <SecureRoute exact path="/menu/:menuId/item/:itemId/edit" component={MenuItemEdit} />
                <SecureRoute exact path="/menu/:menuId/item/:itemId" component={MenuItem} />
                <SecureRoute path="/menu/:menuId" component={Menu} />
                <SecureRoute exact path="/items" component={Items} />
                <SecureRoute exact path="/items/:itemId" component={MenuItem} />
                <SecureRoute exact path="/items/item/new" component={MenuItemCreate} />
                <SecureRoute exact path="/items/:itemId/edit" component={MenuItemEdit} />
                <SecureRoute path="/recipes" component={Recipes} />
              </Switch>
            </StyledMaxWidth>
          </StyledBox>
        </GeographiesProvider>
      )}
    </LayoutBox>
  );
};

export default Layout;

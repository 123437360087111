import { Box, Skeleton, Typography, useMediaQuery } from '@mui/material';
import { ReactElement, useEffect, useMemo, useState } from 'react';
import { Table } from '../../components/Table/Table';
import { StyledAddIcon as AddIcon } from './Recipes.styles';
import RecipesModal from '../../components/RecipesModal';
import { useRecipesContext } from 'src/contexts/recipes';
import EmptyState from '../../components/EmptyState';
import Burger from 'src/assets/images/Burger';
import { mapRecipesToTable } from './mapRecipesToTable/mapRecipesToTable';
import { theme } from 'src/assets/theme/theme';
import Header from 'src/components/Header';
import { useLocation } from 'react-router-dom';

export const Recipes = (): ReactElement => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const { recipes, isLoading } = useRecipesContext();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { hash } = useLocation();

  useEffect(() => {
    document.title = 'Recipes';
  }, []);

  const tableData: Record<string, string>[] = useMemo<Record<string, string>[]>(
    () => mapRecipesToTable(recipes),
    [recipes],
  );

  const showModal = (): void => {
    setIsModalVisible(true);
  };

  const closeModal = (): void => {
    setIsModalVisible(false);
  };

  const headingTitle = () => {
    return (
      <>
        <Typography variant="xxlText">
          <Typography variant="strongText">Recipes</Typography>
        </Typography>
        <AddIcon fontSize="large" onClick={showModal} data-testid="add-recipe-icon" />
      </>
    );
  };

  if (isLoading) {
    return (
      <Box data-testid="skeleton-container" width="100%">
        <Box display="flex" alignItems="center">
          <Skeleton animation="wave" variant="text" height={50} width={145} />
          <Box ml={4}>
            <Skeleton animation="wave" variant="circular" height={35} width={35} />
          </Box>
        </Box>
        {!isMobile && (
          <Box display="flex" alignItems="center" mt={12} mb={8} justifyContent="space-between">
            {[...Array(5)].map((_n, i) => (
              <Box key={`title-${i}`}>
                <Skeleton animation="wave" variant="text" height={32} width={145} />
              </Box>
            ))}
          </Box>
        )}
        {[...Array(10)].map((_n, i) => (
          <Box key={`row-${i}`} mt={2.5}>
            <Skeleton animation="wave" variant="rectangular" height={isMobile ? 400 : 100} />
          </Box>
        ))}
      </Box>
    );
  }
  return (
    <Box>
      <RecipesModal title="Import recipes" closeModal={closeModal} open={isModalVisible} />
      <Header headingTitle={headingTitle()} />
      {tableData?.length > 0 ? (
        <Table data={tableData} selectedRowId={hash.substring(1)} />
      ) : (
        <Box
          sx={{
            mt: '50px',
          }}
        >
          <EmptyState
            emptySectionText={'Add your recipes'}
            onClick={showModal}
            buttonText={'Import'}
            type={'import-recipes-csv'}
            Icon={Burger}
          />
        </Box>
      )}
    </Box>
  );
};

import { INewOptionGroupItem } from 'src/api/createNewMenuItem/types';
import { IMenuItem, IOptionGroupItem } from 'src/types/menu.types';

const isPosIdChanged = (option: IOptionGroupItem | INewOptionGroupItem, itemOptions: IMenuItem[]): boolean => {
  return (
    itemOptions.filter((selectableOption) => {
      const id = option.parentItemId ?? option.optionItemId;
      return selectableOption.itemId === id && selectableOption.posId !== option.posId;
    }).length > 0
  );
};

export default isPosIdChanged;

import { ISelectedDestination } from '../../types';
import { getChannel } from '../getChannel/getChannel';

export const isVenueSelected = (
  channel: string,
  selectedDestinations: ISelectedDestination[],
  venue_id: string,
): boolean => {
  const channels = getChannel(channel, selectedDestinations);

  if (channels.length > 0 && channels[0].selectedVenues.includes(venue_id)) {
    return true;
  }
  return false;
};

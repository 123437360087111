import { ReactElement, useEffect, useState } from 'react';
import { CircularProgress, Typography } from '@mui/material';
import MenuSectionItems from 'src/components/MenuSectionItems';
import PublishModal from '../../components/PublishModal';
import { useMenusContext } from 'src/contexts/menus';
import { SpinnerContainer } from '../Dashboard/Dashboard.styles';
import Header from 'src/components/Header';
import { StyledTypography } from './Menu.styles';
import MainButton from 'src/components/MainButton';
import ItemOptions from 'src/components/ItemOptions';
import { useOktaAuth } from '@okta/okta-react';
import { useNotificationOutlet } from 'src/components/Notification';
import { getMenu } from 'src/api/getMenu/getMenu';
import { IMenuResponse } from 'src/api/getMenu/types';
import { getMenuService } from '../../services/getMenuService/getMenuService';
import { error } from 'src/mocks/data';
import { getPublicationStatus } from 'src/helpers/getPublicationStatus/getPublicationStatus';
import { formatDate } from 'src/helpers/formatDate/formatDate';

const Menu = ({ match }): ReactElement => {
  const [isPublishModalOpen, setIsPublishModalOpen] = useState<boolean>(false);
  const [isMenuLoading, setIsMenuLoading] = useState<boolean>(true);
  const menuId: string = match?.params?.menuId;
  const { isLoading: isMenusLoading, menus, addNewMenu } = useMenusContext();
  const setMessage = useNotificationOutlet();
  const { authState } = useOktaAuth();
  const accessToken = authState?.accessToken?.accessToken;

  const handlePublishModal = () => {
    setIsPublishModalOpen((prev) => !prev);
  };

  const currentMenu = menus.find((menu) => menu.menuId === menuId);

  useEffect(() => {
    document.title = currentMenu?.name || 'Openr';
  }, [currentMenu]);

  const headingTitle = (): JSX.Element => {
    return (
      <StyledTypography variant="xxlText">
        Menu items for <Typography variant="strongText">{currentMenu?.name}</Typography>
      </StyledTypography>
    );
  };

  useEffect(() => {
    if (!isMenusLoading) {
      setIsMenuLoading(true);
      const fetchMenu = async () => {
        try {
          const response = await getMenu(accessToken, menuId);
          const menuData: IMenuResponse = await response.json();
          addNewMenu(getMenuService(menuData.data[0]));
        } catch (e) {
          console.error(e);
          setMessage(error);
        } finally {
          setIsMenuLoading(false);
        }
      };
      fetchMenu();
    }
  }, [accessToken, menuId, setMessage, isMenusLoading]);
  return (
    <>
      {isMenusLoading || isMenuLoading ? (
        <SpinnerContainer data-testid="loading-spinner">
          <CircularProgress color="secondary" />
        </SpinnerContainer>
      ) : (
        <>
          <Header
            headingTitle={headingTitle()}
            tooltipText={currentMenu?.name}
            backBtn
            backBtnPath={'/'}
            rightSideContents={
              <>
                <MainButton text="Publish menu" onClick={() => handlePublishModal()} type="button" size="small" />
                <ItemOptions id={menuId} name={currentMenu?.name} />
              </>
            }
            subTitle={`Created: ${formatDate(currentMenu.created)} | ${getPublicationStatus(
              currentMenu.publication.publicationStatus,
              currentMenu.publication.publicationDate,
            )}`}
          />
          <MenuSectionItems menu={currentMenu} />
          <PublishModal
            isOpen={isPublishModalOpen}
            closeModal={() => handlePublishModal()}
            menuId={menuId}
            menuName={currentMenu?.name}
            publicationData={currentMenu.publication}
          />
        </>
      )}
    </>
  );
};

export default Menu;

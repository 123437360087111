import { useState, MouseEvent, ReactElement } from 'react';
import { Box, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useOktaAuth } from '@okta/okta-react';

import {
  StyledAvatar,
  StyledAvatarWithinModal,
  StyledIconButton,
  StyledMenu,
  StyledMenuItem,
  StyledLogout,
  StyledMenuIconButton,
  StyledBox,
} from './NavBar.styles';

import { getInitials } from 'src/helpers';

const NavBar = ({ handleDrawerToggle }: { handleDrawerToggle: () => void }): ReactElement => {
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  const { authState, oktaAuth } = useOktaAuth();

  const name: string = authState?.idToken?.claims?.name ?? '';

  const initials: string = getInitials(name);

  const logout = async () => {
    await oktaAuth.signOut();
  };

  const handleOpenUserMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <StyledBox data-testid="NavBar">
      <StyledMenuIconButton
        edge="start"
        onClick={handleDrawerToggle}
        data-testid="menu-icon"
        aria-label="sidebar menu button"
      >
        <MenuIcon />
      </StyledMenuIconButton>

      <StyledIconButton onClick={handleOpenUserMenu}>
        <StyledAvatar data-testid="avatar" open={Boolean(anchorElUser)} alt={name}>
          {initials}
        </StyledAvatar>
      </StyledIconButton>
      <StyledMenu
        MenuListProps={{
          disablePadding: true,
        }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        <StyledMenuItem onClick={handleCloseUserMenu} divider={true} disabled={true}>
          <StyledAvatarWithinModal alt={name}>{initials}</StyledAvatarWithinModal>
          <Box>
            <Typography variant="mText" variantMapping={{ mText: 'p' }}>
              <Typography variant="strongText">{name}</Typography>
            </Typography>
            <Typography variant="sText" variantMapping={{ sText: 'small' }}>
              Admin
            </Typography>
          </Box>
        </StyledMenuItem>
        <StyledMenuItem onClick={logout} data-testid="logoutButton">
          <StyledLogout>Logout</StyledLogout>
        </StyledMenuItem>
      </StyledMenu>
    </StyledBox>
  );
};

export default NavBar;

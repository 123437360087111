import { theme } from 'src/assets/theme/theme';
import { Box, styled, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import colors from 'src/assets/theme/colors';

interface IButtonProps {
  customsize: string;
}

export const StyledButton = styled(LoadingButton)<IButtonProps>`
  min-width: ${(props) =>
    props.customsize === 'xs'
      ? theme.spacing(21.5)
      : props.customsize === 'small'
      ? theme.spacing(37)
      : theme.spacing(125)};
  max-height: ${(props) => (props.customsize === 'medium' ? theme.spacing(135) : theme.spacing(9))};
  border-radius: ${theme.spacing(7.5)};
  padding: ${theme.spacing(4)} ${theme.spacing(4)};
  box-shadow: none;
  [role='progressbar'] {
    margin-top: ${theme.spacing(-1)};
    margin-bottom: ${theme.spacing(-1)};
    margin-left: ${theme.spacing(-8)};
    margin-right: ${theme.spacing(2)};
    height: 24px;
    width: 24px;
    color: ${colors.white};
    svg {
      height: 100%;
      width: 100%;
    }
  }
  &:disabled {
    color: ${colors.white};
    background-color: ${colors.AZT_Dark};
  }
  ${(props) => props.theme.breakpoints.down('ms')} {
    min-width: ${(props) => props.customsize === 'medium' && '100%'};
  }
`;

export const StyledIcon = styled(Box)`
  display: flex;
  align-items: center;
  padding: ${theme.spacing(1.5)} 0 0 0;
`;

export const StyledText = styled(Typography)`
  text-transform: none;
`;

import NumericalSubtitle from '../NumericalSubtitle';
import CardButton from '../CardButton';
import { PriceBandContainer, StyledText } from './MenuItemPrice.styles';
import { Typography, Box } from '@mui/material';
import { FormInputText } from '../FormElements/FormInputText';
import { UseFormReturn, useFieldArray } from 'react-hook-form';
import { PriceBand } from '../PriceBand/PriceBand';
import { useGeographiesContext } from 'src/contexts/geographies';
import { IAggregator } from 'src/types/aggregator.types';
import { IFormValues } from '../menuItemForm/types';

interface IMenuItemPriceProps {
  formFields: UseFormReturn<IFormValues>;
}

export const MenuItemPrice = ({ formFields }: IMenuItemPriceProps) => {
  const { register, control, setValue, watch } = formFields;
  const { aggregators, priceBands } = useGeographiesContext();

  const currentPriceBands = watch('price_bands');

  const currentPrice = watch('defaultPrice');

  const { fields: priceFields, append: priceAppend } = useFieldArray({
    control,
    name: 'price_bands',
  });

  const createNewPriceBand = (): void => {
    priceAppend({
      priceBandId: '',
      aggregators: aggregators.map((aggregator: IAggregator) => ({
        aggregatorId: aggregator.aggregatorId,
        aggregatorName: aggregator.name,
        price: '',
      })),
    });
  };

  return (
    <Box>
      <NumericalSubtitle number="3" subtitle="Pricing" />
      <Box mt={5}>
        <input defaultValue="GBP" {...register('currency')} type="hidden" />
        <FormInputText
          name="defaultPrice"
          control={control}
          rules={{ pattern: /^[.0-9]+$/i, required: true }}
          label="Default price (£)"
          testId="menu-item-price"
        />
      </Box>

      {priceFields.length > 0 && (
        <PriceBandContainer pl={6} mb={4} data-testid="price-band-container">
          {priceFields.map((field, index) => {
            return (
              <PriceBand
                control={control}
                aggregators={field.aggregators}
                priceBands={priceBands}
                setValue={setValue}
                selectedPriceBands={currentPriceBands}
                key={index}
                index={index}
                defaultPrice={currentPrice}
              />
            );
          })}
        </PriceBandContainer>
      )}

      {priceFields.length < priceBands.length && (
        <CardButton data-testid="add-price-row" onClick={createNewPriceBand}>
          <StyledText>
            <Typography variant="strongText">+ Add Price</Typography>
          </StyledText>
        </CardButton>
      )}
    </Box>
  );
};

import { createContext, useContext } from 'react';
import type { IRecipesProp } from './recipes.types';

interface IRecipesContext {
  recipes: IRecipesProp[];
  setRecipes: (recipies: IRecipesProp[]) => void;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
}

export const RecipesContext = createContext<IRecipesContext>({
  recipes: [],
  setRecipes: () => {},
  setIsLoading: () => {},
  isLoading: true,
});

export const useRecipesContext = () => useContext(RecipesContext);

import { ReactElement } from 'react';
import { StyledButton, StyledIcon, StyledText } from './MainButton.styles';
import { CircularProgress } from '@mui/material';

interface MainButtonProps {
  icon?: ReactElement;
  text: ReactElement | string;
  onClick?: any;
  className?: string;
  loading?: string | boolean;
  type?: 'button' | 'submit';
  size?: 'xs' | 'small' | 'medium';
  textFormat?: 'buttonText' | 'mText';
}

/**
 * Lozenger Button
 * @param  {String} text text to display in the button
 * @param  {Function} onClick Click Event handler to fire
 * @param  {String} type button type "Button", "submit" etc.
 * @param  {String|Boolean} loading  set loading spinner and disable the button, if a string it will display the string as well
 */

const MainButton = ({
  icon,
  text,
  onClick,
  type = 'button',
  loading = false,
  size = 'medium',
  textFormat = 'mText',
}: MainButtonProps): ReactElement => {
  const isLoading = typeof loading === 'string' || loading;
  const isTextString = typeof text === 'string' ? text : text?.props?.children;

  return (
    <StyledButton
      type={type}
      loading={isLoading}
      loadingIndicator={<CircularProgress color="inherit" size={24} />}
      color="primary"
      variant="contained"
      onClick={onClick}
      role="button"
      data-testid={`${isTextString}_button`}
      customsize={size}
    >
      {icon && <StyledIcon data-testid={`${text}-icon`}>{icon}</StyledIcon>}
      <StyledText variant={textFormat}>{typeof loading === 'string' ? loading : text}</StyledText>
    </StyledButton>
  );
};

export default MainButton;

import Prompt from './Prompt';
import {
  Container,
  StyledDialogAction,
  StyledTitle,
  StyledSubTitle,
  StyledText,
  SmallButton,
  FullLengthButton,
  StyledFormControl,
  StyledMenuItem,
} from './Prompt.styles';
export {
  Container,
  StyledDialogAction,
  StyledTitle,
  StyledSubTitle,
  StyledText,
  SmallButton,
  FullLengthButton,
  StyledFormControl,
  StyledMenuItem,
};
export default Prompt;

import type { ISchedule } from './types';

export const updateMenuSchedule = async ({ token, payload, id }: ISchedule): Promise<any> => {
  const api = process.env.REACT_APP_API_ENDPOINT;
  const uri = `${api}/menus/${id}/schedules`;

  const response = await fetch(uri, {
    method: 'PUT',
    body: JSON.stringify(payload),
    cache: 'reload',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

import { useState, ReactElement } from 'react';
import { useLocation } from 'react-router-dom';
import {
  StyledAccordion,
  StyledAccordionSummary,
  StyledArrowRightRoundedIcon,
  StyledAccordionDetails,
  StyledTypography,
  StyledAccordionBox,
  StyledArrowIconButton,
  StyledName,
  StyledLink,
} from './Category.styles';
import { getTitle } from 'src/helpers';
import { Tooltip } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { StyledAddIcon } from './Category.styles';
import EmptyArrowClipboard from '../../assets/images/EmptyArrowClipboard';
import { IMenu } from '../../types/menu.types';

interface CategoryProps {
  text: string;
  path: string;
  content?: IMenu[];
  addNewItem?: () => void;
}
const Category = ({ text, path, content, addNewItem }: CategoryProps): ReactElement => {
  const [isExpanded, setIsExpanded] = useState<boolean>(true);
  const handleClick = (): void => setIsExpanded((prev) => !prev);
  const menuCharLimit = 25;
  const location = useLocation();
  const basePath = location.pathname.split('/')[1];
  const activePath = `/${basePath}` === path;

  return (
    <>
      <StyledAccordionSummary>
        <StyledArrowIconButton onClick={() => handleClick()}>
          {content ? (
            <StyledArrowRightRoundedIcon fontSize="large" expanded={isExpanded.toString()} />
          ) : (
            <NavLink to={path} role={`${path.split('/')[1]}-empty-arrow`}>
              <EmptyArrowClipboard fontSize="large" />
            </NavLink>
          )}
        </StyledArrowIconButton>
        <StyledAccordionBox onClick={() => handleClick()}>
          <StyledTypography variant="mText">
            <StyledName active={activePath.toString()}>
              {content ? text : <StyledLink to={path}>{text}</StyledLink>}
            </StyledName>
          </StyledTypography>
        </StyledAccordionBox>
        <StyledAddIcon data-testid={`add-new-${text}`} onClick={() => addNewItem()} />
      </StyledAccordionSummary>
      {content && (
        <StyledAccordion disableGutters expanded={content.length > 0 && isExpanded} aria-label={`${text} dropdown`}>
          <div />
          <StyledAccordionDetails>
            <nav role="navigation" aria-label={text}>
              {content.slice(0, 10).map(({ menuId, name }) => (
                <StyledName key={menuId} variant="sText" data-testid={menuId}>
                  <Tooltip placement={'right'} title={name} enterNextDelay={700} arrow>
                    <StyledLink to={`${path}/${menuId}`} activeStyle={{ fontWeight: 'bold' }}>
                      {getTitle(name, menuCharLimit)}
                    </StyledLink>
                  </Tooltip>
                </StyledName>
              ))}
            </nav>
          </StyledAccordionDetails>
        </StyledAccordion>
      )}
    </>
  );
};

export default Category;

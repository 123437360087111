import { IMenu } from 'src/types/menu.types';

export const deleteMenuItem = (menus: IMenu[], menuId: string, itemId: string): IMenu[] => {
  const newMenus = menus.map((menu) => {
    if (menu.menuId === menuId) {
      const sections = menu.sections.map((section) => {
        return {
          ...section,
          items: section.items.filter((menuItem) => menuItem.itemId !== itemId),
        };
      });
      return {
        ...menu,
        sections: sections,
        uncategorised: menu.uncategorised.filter((menuItem) => menuItem.itemId !== itemId),
        modifiers: menu.modifiers.filter((menuItem) => menuItem.itemId !== itemId),
      };
    }
    return menu;
  });
  return newMenus;
};

import { Box, Typography } from '@mui/material';
import { ReactElement } from 'react';
import { StyledDivider, StyledList, StyledListItem, StyledListItemDetails, StyledListItemText } from './List.styles';

export interface IListProps {
  listItemPrimaryText: string;
  listItemSecondaryText: string;
  listItemId: string;
  listItemDetails: {
    primaryText: string;
    id: string;
    name: string;
    value?: string;
  }[];
}

export const List = ({ data }: { data: IListProps[] }): ReactElement => {
  return (
    <StyledList>
      {data.map((items) => {
        return (
          <Box key={items.listItemId}>
            <StyledDivider />
            <StyledListItem>
              <StyledListItemText
                primary={<Typography variant={'strongText'}>{items.listItemPrimaryText}</Typography>}
                secondary={<Typography component={'p'}>{items.listItemSecondaryText}</Typography>}
              />
              {items.listItemDetails?.map((details) => {
                return (
                  <StyledListItemDetails
                    key={details.id}
                    primary={<Typography variant={'strongText'}>{details.primaryText}</Typography>}
                    secondary={<Typography component={'p'}>{details.value}</Typography>}
                  />
                );
              })}
            </StyledListItem>
          </Box>
        );
      })}
    </StyledList>
  );
};

export default List;

import { API_URL } from '../constants';

export const exportCSV = async ({ token, payload, menuId }): Promise<any> => {
  const uri = `${API_URL}/menus/${menuId}/export`;

  const response = await fetch(uri, {
    method: 'POST',
    body: payload ? JSON.stringify(payload) : null,
    cache: 'reload',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'text/csv',
      Authorization: `Bearer ${token}`,
    },
  });
  const blob = await response.blob();
  return blob ?? null;
};

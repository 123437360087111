import { ReactElement, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, CircularProgress } from '@mui/material';
import Header from 'src/components/Header';
import { useOktaAuth } from '@okta/okta-react';
import { useNotificationOutlet } from 'src/components/Notification';
import { error } from 'src/mocks/data';
import { Section, Content, ItemDetailContainer, ItemImage } from './MenuItem.styles';
import MainButton from 'src/components/MainButton';
import colors from 'src/assets/theme/colors';
import { useRecipesContext } from 'src/contexts/recipes';
import { SpinnerContainer } from '../Dashboard/Dashboard.styles';
import { getMenuItem } from 'src/api/getMenuItem/getMenuItem';
import EditIcon from 'src/assets/images/EditIcon';
import HeadingTitle from 'src/components/HeadingTitle';
import FormatItemInformation from 'src/components/FormatItemInformation';
import NumericalSubtitle from 'src/components/NumericalSubtitle';
import { OptionGroup } from 'src/components';
import { useMenusContext } from 'src/contexts/menus';
import { formatMenuItems, getMenuService } from 'src/services/getMenuService/getMenuService';
import { IMenuResponse } from 'src/api/getMenu/types';
import { getMenu } from 'src/api/getMenu/getMenu';
import { IMenuItem } from 'src/types/menu.types';
import { mapPriceBandToList } from './mapPriceBandToList/mapPriceBandToList';
import List from 'src/components/List';
import { useGeographiesContext } from 'src/contexts/geographies';

const MenuItem = ({ match }: { match: Record<string, Record<string, string>> }): ReactElement => {
  const [currentItem, setCurrentItem] = useState<IMenuItem>();
  const [isLoadingItem, setIsLoadingItem] = useState<boolean>(true);
  const history = useHistory();
  const { recipes } = useRecipesContext();
  const { authState } = useOktaAuth();
  const setMessage = useNotificationOutlet();
  const itemId: string = match?.params?.itemId;
  const menuId: string = match?.params?.menuId;
  const accessToken = authState?.accessToken?.accessToken;
  const { menus, addAMenuItem, isLoading, addNewMenu } = useMenusContext();
  const [isMenuLoading, setIsMenuLoading] = useState<boolean>(true);
  const selectedRecipe = useMemo(() => {
    return recipes.find((recipe) => recipe.recipe_id === currentItem?.recipeId);
  }, [recipes, currentItem]);

  const { aggregators } = useGeographiesContext();
  const onEdit = () => {
    history.push(`${location.pathname}/edit`);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const viewItem = await getMenuItem(itemId);
        const menuItem = formatMenuItems([viewItem])[0];
        const currentMenu = menus.find((menu) => menu.menuId === menuId);
        if (!isLoading && currentMenu) {
          if (
            currentMenu.uncategorised.length === 0 &&
            currentMenu.modifiers.length === 0 &&
            currentMenu.sections.length === 0 &&
            isMenuLoading
          ) {
            const response = await getMenu(accessToken, menuId);
            const menuData: IMenuResponse = await response.json();

            addNewMenu(getMenuService(menuData.data[0]));
            setIsMenuLoading(false);
          } else {
            addAMenuItem(menuId, itemId, menuItem);
          }
        }

        if (!isLoading && !isMenuLoading) {
          addAMenuItem(menuId, itemId, menuItem);
        }

        setCurrentItem(menuItem);
        setIsLoadingItem(false);
      } catch (_e) {
        setMessage(error);
      }
    };
    fetchData();
  }, [accessToken, itemId, setCurrentItem, setIsLoadingItem, isLoading, isMenuLoading]);

  useEffect(() => {
    document.title = currentItem?.name || 'Openr';
  }, [currentItem]);

  const RenderHeadingOptions = (): ReactElement => {
    return <MainButton icon={<EditIcon />} text="Edit" onClick={onEdit} type="button" size="xs" />;
  };
  const CapitaliseTitle = (title: string): string => {
    return title.charAt(0).toUpperCase() + title.toLocaleLowerCase().slice(1);
  };

  return isLoadingItem || aggregators.length === 0 || !currentItem ? (
    <SpinnerContainer data-testid="loadingIcon">
      <CircularProgress color="secondary" />
    </SpinnerContainer>
  ) : (
    <>
      <Header
        headingTitle={<HeadingTitle variantType="xxlText" text={currentItem.name} />}
        tooltipText={currentItem.name}
        backBtn
        backBtnPath={`${menuId ? `/menu/${menuId}` : '/items'}`}
        rightSideContents={<RenderHeadingOptions />}
      />
      <Section>
        <NumericalSubtitle number={'1'} subtitle={'Type'} />
        <Content>
          <HeadingTitle variantType="mText" text={CapitaliseTitle(currentItem.type)} color={colors.AZT_Dark} />
        </Content>
      </Section>
      <Section>
        <NumericalSubtitle number={'2'} subtitle={'Details'} />
        <ItemDetailContainer>
          <Box mr={8}>
            {currentItem.type !== 'BUNDLE' && (
              <FormatItemInformation
                heading="Recipe"
                information={selectedRecipe?.name}
                link={selectedRecipe && `/recipes#${selectedRecipe?.recipe_id}`}
              />
            )}
            <FormatItemInformation heading="Name" information={currentItem.name} />
            <FormatItemInformation heading="Description" information={currentItem.description} />
            <FormatItemInformation heading="POS ID" information={currentItem.posId} />
            <FormatItemInformation heading="Tax Rate (%)" information={currentItem.taxRate} />
          </Box>
          {currentItem.image && <ItemImage src={currentItem.image} alt={`Image of ${currentItem.name}`} />}
        </ItemDetailContainer>
      </Section>
      <Section>
        <NumericalSubtitle number="3" subtitle={'Pricing'} />
        <FormatItemInformation heading="Default price (£)" information={currentItem.prices.defaultPrice} />
        {currentItem?.prices.priceBands.length > 0 && (
          <List data={mapPriceBandToList(currentItem.prices, aggregators)} />
        )}
      </Section>
      {currentItem?.optionGroup.length > 0 && (
        <Section>
          <NumericalSubtitle number="4" subtitle={`${currentItem.type === 'BUNDLE' ? 'Bundle' : 'Modifier'} options`} />
          {currentItem?.optionGroup.map((optionGroup) => {
            return (
              <OptionGroup
                key={optionGroup.optionGroupId}
                optionGroup={optionGroup}
                menuId={menuId}
                menuItemType={currentItem.type}
              />
            );
          })}
        </Section>
      )}
    </>
  );
};

export default MenuItem;

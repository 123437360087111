import { theme } from 'src/assets/theme/theme';
import { styled, Typography } from '@mui/material';

export const StyledTypography = styled(Typography)`
  margin-left: ${theme.spacing(3)};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

import { Box, Typography, styled } from '@mui/material';
import colors from 'src/assets/theme/colors';
import { theme } from 'src/assets/theme/theme';
import { Link } from 'react-router-dom';

interface OptionTitleTextProps {
  wrap?: string;
}

export const OptionTitleContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${theme.spacing(4)};
  flex-basis: 100%;
  width: 100%;
  &::after,
  &::before {
    content: '';
    height: 1px;
    width: 100%;
    background-color: ${colors.AZT_Dark};
  }
`;

export const OptionGroupWrapper = styled(Box)`
  margin-bottom: ${theme.spacing(8)};
  display: flex;
  flex-wrap: wrap;
`;

export const OptionGroupDescription = styled(Box)`
  width: 100%;
  flex: 1 0 auto;
  margin-right: ${theme.spacing(12)};
`;

export const OptionGroupCriteria = styled(Box)`
  flex: 1 1 auto;
  max-width: 33%;
`;

export const OptionTitleText = styled(Typography)<OptionTitleTextProps>`
  padding: 0 ${theme.spacing(4)};
  white-space: ${(props) => (props.wrap === 'true' ? 'unset' : 'nowrap')};
  max-width: inherit;
`;

export const OptionSubSectionTitle = styled(Typography)`
  margin: ${theme.spacing(2)} 0;
  display: block;
`;

export const OptionListItem = styled(Typography)`
  display: inline-block;
  &::before {
    content: '|';
    margin: 0 ${theme.spacing(2)};
  }
  &:first-of-type {
    &::before {
      display: none;
    }
  }
`;

export const OptionListItemLink = styled(Link)`
  color: ${colors.AZT_Light};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

import { ReactElement, useState, useEffect, SyntheticEvent } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Box, CircularProgress, FormControlLabel, Radio, TextField, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { createNewMenuItem, ICreateNewMenuItemResponse } from 'src/api';
import { createFormData } from 'src/helpers';
import { useItemsContext } from 'src/contexts/items';
import { useNotificationOutlet } from 'src/components/Notification';
import { useMenusContext } from 'src/contexts/menus';
import { IMenuItem } from 'src/types/menu.types';
import { formatMenuItems } from 'src/services/getMenuService/getMenuService';
import Prompt, { Container, StyledDialogAction, StyledTitle, StyledText, FullLengthButton } from '../../Prompt';
import { StyledAutocomplete, StyledLabelList, StyledRadioGroup } from './CreateNewItemPrompt.styles';
export interface CreateNewItemPromptProps {
  closePrompt: () => void;
  open: boolean;
  sectionId?: string;
}

const CreateNewItemPrompt = ({ closePrompt, open, sectionId }: CreateNewItemPromptProps): ReactElement => {
  const { items, isLoading } = useItemsContext();
  const { addMenuItems } = useMenusContext();
  const [radioGroupValue, setRadioGroupValue] = useState<string>('');
  const [menuItemValue, setMenuItemValue] = useState<IMenuItem>();
  const setNotification = useNotificationOutlet();
  const history = useHistory();
  const pathname = history.location?.pathname;
  const { menuId } = useParams<string>();

  useEffect(() => {
    if (!open) {
      setMenuItemValue(null);
      setRadioGroupValue('');
    }
  }, [open]);

  const redirectToNewItemPage = () => {
    const param = sectionId ? `?sectionId=${sectionId}` : '';
    history.push(`${pathname}/item/new${param}`);
  };

  const continueButtonHandler = () => {
    if (radioGroupValue === 'createNewMenuItem') {
      redirectToNewItemPage();
    } else if (radioGroupValue === 'createFromExistingItem') {
      const payload = {
        item_id: menuItemValue.itemId,
      };
      const requestBody = createFormData(payload);
      const { itemId: parentItemId, name: parentItemName } = menuItemValue;
      createNewMenuItem(requestBody, menuId, sectionId)
        .then((res) => res.json())
        .then((response: ICreateNewMenuItemResponse) => {
          const itemsToAddToMenu: IMenuItem[] = formatMenuItems(response.data);
          addMenuItems(menuId, parentItemId, itemsToAddToMenu, sectionId ?? null);
          setNotification({
            severity: 'success',
            message: (
              <>
                You have successfully added <Typography variant="strongText">&quot;{parentItemName}&quot;</Typography>.
              </>
            ),
          });
        })
        .catch(() => {
          setNotification({
            severity: 'error',
            message: (
              <>
                Something went wrong whilst adding{' '}
                <Typography variant="strongText">&quot;{parentItemName}&quot;</Typography>.
              </>
            ),
          });
        });
    }
    closePrompt();
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRadioGroupValue(event.target.value);
  };

  return (
    <Prompt testId="pos-id-prompt" closePrompt={closePrompt} open={open}>
      <Container>
        <StyledTitle variant="xlText">
          <Typography variant="strongText">Add menu item</Typography>
        </StyledTitle>
        <StyledRadioGroup name="add-menus" value={radioGroupValue} onChange={handleChange}>
          <FormControlLabel
            data-testid="radio-new-menu-item"
            value="createNewMenuItem"
            control={<Radio checkedIcon={<CheckCircleIcon />} />}
            label="Create new menu item"
          />
          <FormControlLabel
            data-testid="radio-existing-menu-item"
            value="createFromExistingItem"
            control={<Radio checkedIcon={<CheckCircleIcon />} />}
            label="Add existing menu item"
          />
        </StyledRadioGroup>
        {radioGroupValue === 'createFromExistingItem' &&
          (isLoading ? (
            <Box display="flex" justifyContent="center">
              <CircularProgress />
            </Box>
          ) : (
            <StyledAutocomplete
              data-testid="exisiting-menu-items-dropdown"
              options={items}
              getOptionLabel={(option: IMenuItem) => (option.name ? option.name : '')}
              renderInput={(params) => <TextField {...params} label="Menu item" />}
              renderOption={(props, option: IMenuItem) => (
                <StyledLabelList {...props} key={option.itemId}>
                  <Typography>{option.name}</Typography>
                </StyledLabelList>
              )}
              value={menuItemValue || null}
              onChange={(_e: SyntheticEvent<Element, Event>, menuItem: IMenuItem) => setMenuItemValue(menuItem)}
            />
          ))}
        <StyledDialogAction>
          <FullLengthButton
            data-testid="save-pos-id-button"
            color="primary"
            variant="contained"
            onClick={continueButtonHandler}
          >
            <StyledText>Continue</StyledText>
          </FullLengthButton>
        </StyledDialogAction>
      </Container>
    </Prompt>
  );
};

export default CreateNewItemPrompt;

import { styled } from '@mui/material';
import { Link } from 'react-router-dom';
import colors from 'src/assets/theme/colors';

export const StyledLink = styled(Link)`
  color: ${colors.AZT_Light};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

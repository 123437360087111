import { ReactElement } from 'react';
import Prompt, { Container, StyledDialogAction, StyledTitle, StyledSubTitle, StyledText, SmallButton } from '../Prompt';
import { Typography } from '@mui/material';

interface IEditChildItemPromptProps {
  menuName: string;
  closePrompt: () => void;
  isOpen: boolean;
  saveButtonHandler: () => void;
}

export const EditChildItemPrompt = ({
  menuName,
  closePrompt,
  isOpen,
  saveButtonHandler,
}: IEditChildItemPromptProps): ReactElement => {
  return (
    <Prompt testId="edit-child-item-prompt" closePrompt={closePrompt} open={isOpen}>
      <Container>
        <StyledTitle variant="xlText" variantMapping={{ xlText: 'h2' }}>
          <Typography variant="strongText">Are you sure you want to save changes for {menuName}?</Typography>
        </StyledTitle>
        <StyledSubTitle variant="mText" variantMapping={{ mText: 'p' }}>
          This will detach from the original menu item details in the library.
        </StyledSubTitle>
        <StyledDialogAction>
          <SmallButton variant="outlined" onClick={closePrompt}>
            <StyledText>Back</StyledText>
          </SmallButton>
          <SmallButton data-testid="remove-item" color="primary" variant="contained" onClick={saveButtonHandler}>
            <StyledText>Save</StyledText>
          </SmallButton>
        </StyledDialogAction>
      </Container>
    </Prompt>
  );
};

import colors from 'src/assets/theme/colors';
import { styled, Typography, Box } from '@mui/material';

export const StyledText = styled(Typography)`
  color: ${colors.AZT_Light};
`;

export const PriceBandContainer = styled(Box)`
  ${(props) => props.theme.breakpoints.up('dl')} {
    border-left: 1px dashed ${colors.AZT_Dark_Opaque};
  }
  ${(props) => props.theme.breakpoints.down('dl')} {
    padding-left: 0;
  }
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
`;

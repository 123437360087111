/* istanbul ignore file */
import { createContext, useState, useMemo, useContext, ReactNode } from 'react';
import { AlertColor } from '@mui/material/Alert';

type NotificationProps = {
  severity?: AlertColor;
  description?: string;
  message?: ReactNode;
  statusCode?: number;
  open?: boolean;
};
interface NotificationContextInterface {
  notification?: NotificationProps;
  setNotification?: any;
}
/* istanbul ignore next */
const NotificationContext = createContext<NotificationContextInterface | undefined>({
  notification: {},
  setNotification: () => {},
});

/* istanbul ignore next */
function NotificationDisplayBoundary({ children }) {
  const [notification, setNotification] = useState(null);
  const ctx = useMemo(() => ({ notification, setNotification }), [notification]);

  return <NotificationContext.Provider value={ctx}>{children}</NotificationContext.Provider>;
}
/* istanbul ignore next */
function useNotificationOutlet() {
  const NotificationCtx = useContext(NotificationContext);
  return NotificationCtx.setNotification;
}

export { NotificationContext, useNotificationOutlet, NotificationDisplayBoundary };

import { styled, Accordion, AccordionDetails, Box, Typography, IconButton } from '@mui/material';
import { NavLink } from 'react-router-dom';
import ArrowRightRoundedIcon from '@mui/icons-material/ArrowRightRounded';
import { theme } from 'src/assets/theme/theme';
import colors from 'src/assets/theme/colors';
import AddIcon from '@mui/icons-material/Add';

interface IconProps {
  expanded: string;
}

interface StyledNameProps {
  active?: string;
}

export const StyledAccordionSummary = styled(Box)`
  display: flex;
  flex-direction: row;
  margin-top: ${theme.spacing(2.5)};
  color: ${theme.palette.common.white};
`;

export const StyledAccordion = styled(Accordion)`
  box-shadow: none;
`;

export const StyledArrowRightRoundedIcon = styled(ArrowRightRoundedIcon)<IconProps>(({ expanded }) => ({
  color: theme.palette.common.white,
  pointerEvents: 'auto',
  transform: expanded === 'true' ? 'rotate(90deg)' : 'rotate(0deg)',
}));

export const StyledAccordionDetails = styled(AccordionDetails)`
  color: ${theme.palette.common.white};
  margin-top: ${theme.spacing(2.5)};
`;

export const StyledBox = styled(Box)`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;
export const StyledTypography = styled(Typography)`
  padding-top: ${theme.spacing(1)};
  pointer-events: auto;
`;

export const StyledAccordionBox = styled(Box)`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: ${theme.spacing(2.75)};
  &:hover {
    cursor: pointer;
  }
`;

export const StyledArrowIconButton = styled(IconButton)`
  padding: 0;
  margin-top: ${theme.spacing(1.75)};
  margin-right: ${theme.spacing(1)};
  border-radius: ${theme.spacing(1)};
  &:hover {
    border-radius: ${theme.spacing(1)};
  }
`;

export const StyledName = styled(Typography)<StyledNameProps>`
  padding-bottom: ${theme.spacing(2.5)};
  font-weight: ${(props) => (props.active === 'true' ? 'bold' : '')};
  display: block;
  &:hover {
    font-weight: bold;
  }
`;

export const StyledAddIcon = styled(AddIcon)`
  margin-top: ${theme.spacing(3)};
  color: ${colors.white};
  cursor: pointer;
  &:hover {
    border: 1px solid ${colors.white};
    border-radius: ${theme.spacing(25)};
  }
`;

export const StyledLink = styled(NavLink)`
  color: ${colors.white};
  text-decoration: none;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`;

import { IMenu } from 'src/types/menu.types';

export const reorderMenuItem = (
  menus: IMenu[],
  menuId: string,
  sectionId: string,
  itemId: string,
  newDisplayOrder: number,
): IMenu[] => {
  const foundMenu = menus.find((menu) => menu.menuId === menuId);
  const foundSection = foundMenu.sections.find((section) => section.sectionId === sectionId);
  const selectedMenuItem = foundSection.items.find((item) => item.itemId === itemId);
  const foundMoveToMenuItem = foundSection.items.find((item) => item.displayOrder === newDisplayOrder);

  foundMoveToMenuItem.displayOrder = selectedMenuItem.displayOrder;
  selectedMenuItem.displayOrder = newDisplayOrder;

  foundSection.items.sort((a, b) => a.displayOrder - b.displayOrder);
  return [...menus];
};

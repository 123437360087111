import { podBase64, cocoBase64, zizziBase64, askBase64 } from 'src/assets/images/brand-logos';

const getDefaultLogo = (brandName: string) => {
  const name = brandName?.toLowerCase();

  return name.includes('pod')
    ? podBase64
    : name.includes('coco')
    ? cocoBase64
    : name.includes('zizzi')
    ? zizziBase64
    : name.includes('ask')
    ? askBase64
    : undefined;
};

export default getDefaultLogo;

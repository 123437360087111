import { IMenu } from 'src/types/menu.types';

export const updateMenuDetails = (menus: IMenu[], menuId: string, newName?: string, newImage?: string): IMenu[] => {
  return menus.map((menu) => {
    if (menu.menuId === menuId) {
      let newMenu: IMenu = { ...menu };
      if (newName) {
        newMenu = {
          ...newMenu,
          name: newName,
        };
      }
      if (newImage) {
        newMenu = {
          ...newMenu,
          image: newImage,
        };
      }
      return newMenu;
    }
    return menu;
  });
};

import { useState, useEffect } from 'react';
import { getVenues } from 'src/api/getVenues/getMenus';
import { useNotificationOutlet } from 'src/components/Notification';
import { IAggregator } from 'src/types/aggregator.types';
import { IPriceBand } from 'src/types/priceBand.types';
import { IVenue } from 'src/types/venue.types';
import { GeographiesContext } from './geographiesContext';
import { error } from 'src/mocks/data';
import { IGetVenuesResponseBody } from 'src/api/getVenues/types';
import { fetchChannelsVenues } from 'src/api/fetchChannelsVenues/fetchChannelsVenues';

export const GeographiesProvider = ({ children }) => {
  const setMessage = useNotificationOutlet();

  const [venues, setVenues] = useState<IVenue[]>([]);
  const [priceBands, setPriceBands] = useState<IPriceBand[]>([]);
  const [aggregators, setAggregators] = useState<IAggregator[]>([]);

  const [lastFetched, setLastFetched] = useState<string>(new Date().toString());

  const refetch = () => {
    setLastFetched(new Date().toString());
  };

  useEffect(() => {
    getVenues()
      .then((res) => res.json())
      .then((res: IGetVenuesResponseBody) => {
        const venuesList: IVenue[] = res.data
          .filter((venue) => !venue.tmp_is_price_tier)
          .map(
            (venue): IVenue => ({
              venueId: venue.venue_id,
              name: venue.name,
              address: (venue.addresses && venue.addresses[0]?.address_line_1) ?? venue.pickup_address,
              image: venue.image_url,
            }),
          );
        setVenues(venuesList);

        const bands: IPriceBand[] = res.data
          .filter((venue) => venue.tmp_is_price_tier)
          .map(
            (venue): IPriceBand => ({
              priceBandId: venue.venue_id,
              name: venue.name,
            }),
          );
        setPriceBands(bands);
      })
      .catch(() => {
        setMessage(error);
      });
  }, [setMessage, lastFetched]);

  useEffect(() => {
    fetchChannelsVenues()
      .then((res) => res.json())
      .then((res) => {
        const aggregatorsList: IAggregator[] = res.data.map(
          (aggregator): IAggregator => ({
            aggregatorId: aggregator.external_system_id,
            name: aggregator.name,
            description: aggregator.integration_type_name,
            venues: [],
          }),
        );
        setAggregators(aggregatorsList);
      })
      .catch(() => {
        setMessage(error);
      });
  }, [setMessage, lastFetched]);

  return (
    <GeographiesContext.Provider value={{ venues, priceBands, aggregators, refetch }}>
      {children}
    </GeographiesContext.Provider>
  );
};

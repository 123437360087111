import { Controller } from 'react-hook-form';
import { StyledAutocomplete } from './Form.styles';
import { TextField } from '@mui/material';
import type { SyntheticEvent } from 'react';

type OptionsProp = { name: string; id: string };

type FormAutocompleteProps = {
  name: string;
  control: any;
  label: string;
  testId?: string;
  options: OptionsProp[];
  disabled?: boolean;
  defaultValue?: undefined | string;
};

export const FormAutocomplete = ({ name, control, label, testId, options = [], disabled }: FormAutocompleteProps) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return (
          <StyledAutocomplete
            value={options.find((option) => option.id === field.value) ?? null}
            disabled={disabled}
            onBlur={field.onBlur}
            ref={field.ref}
            data-testid={testId}
            id={`${name}-option`}
            getOptionLabel={(option: { name: string }) => option?.name}
            isOptionEqualToValue={(option: { id: string }, value: { id: string }) => option?.id === value?.id}
            options={options}
            renderInput={(params) => <TextField {...params} label={label} name={field.name} />}
            onChange={(_event: SyntheticEvent<Element, Event>, newValue: OptionsProp) =>
              field.onChange(newValue?.id ?? null)
            }
            renderOption={(props, option: OptionsProp) => {
              return (
                <li {...props} key={option?.id}>
                  {option.name}
                </li>
              );
            }}
          />
        );
      }}
    />
  );
};

import { ReactElement } from 'react';
import { CardContainer } from './CardButton.styles';

interface CardButtonProps {
  children: any;
  onClick: any;
}

const CardButton = ({ onClick, children }: CardButtonProps): ReactElement => {
  return (
    <CardContainer onClick={onClick} data-testid="card-button">
      {children}
    </CardContainer>
  );
};

export default CardButton;

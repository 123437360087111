import { MenuItem, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { IMenuItem } from 'src/types/menu.types';
import EllipsisMenu from '../EllipsisMenu';
import Card from '../Card';
import MovePrompt from '../MovePrompt';
import RemovePrompt from '../RemovePrompt';
import { useState } from 'react';
import { useNotificationOutlet } from '../Notification';
import { useMenusContext } from 'src/contexts/menus';
import { deleteMenuItem } from 'src/api/deleteMenuItem/deleteMenuItem';
import { useOktaAuth } from '@okta/okta-react';
import { StyledMenuItem } from '../EllipsisMenu/EllipsisMenu.styles';
import { reorderMenuItem } from 'src/api/reorderMenuItem/reorderMenuItem';

export interface IMenuItemCardProps extends IMenuItem {
  menuId: string;
  sectionId: string;
  displayOrderLeft: number;
  displayOrderRight: number;
}

const MenuItemCard = ({
  menuId,
  name,
  image,
  itemId,
  displayOrderLeft,
  displayOrderRight,
  sectionId,
}: IMenuItemCardProps) => {
  const [isMovePromptVisible, setIsMovePromptVisible] = useState(false);
  const [isRemovePromptVisible, setIsRemovePromptVisible] = useState(false);
  const { deleteAMenuItem, menus, reorderAMenuItem } = useMenusContext();
  const setNotification = useNotificationOutlet();
  const { authState } = useOktaAuth();
  const accessToken = authState?.accessToken?.accessToken;
  const removePromptTitle = `Are you sure you want to delete "${name}"?`;
  const removeItemPromptSubTitle = 'You will remove this menu item from the current menu.';
  const currentMenu = menus.find((menu) => menu.menuId === menuId);
  let isWithinBundle = false;

  const checkIfPartOfBundle = (): void => {
    currentMenu.sections.forEach((section) =>
      section.items.forEach((item) => {
        if (item.itemId === itemId && item.type !== 'BUNDLE') isWithinBundle = true;
      }),
    );

    currentMenu.uncategorised.forEach((item) => {
      if (item.itemId === itemId && item.type !== 'BUNDLE') isWithinBundle = true;
    });
  };

  const handleDelete = (): void => {
    deleteMenuItem(itemId)
      .then(() => {
        deleteAMenuItem(menuId, itemId);
        setNotification({
          severity: 'success',
          message: (
            <>
              You have successfully removed <Typography variant="strongText">&quot;{name}&quot;</Typography> from{' '}
              <Typography variant="strongText">&quot;{currentMenu.name}&quot;</Typography>.
            </>
          ),
        });
      })
      .catch((error) => {
        checkIfPartOfBundle();
        if (isWithinBundle) {
          setNotification({
            severity: 'error',
            description: 'You cannot delete this menu item yet',
            message: error?.message,
          });
        } else {
          setNotification({
            severity: 'error',
            message: 'We were unable to delete, please try again!',
          });
        }
        setIsRemovePromptVisible(false);
      });
  };

  const reorderItem = async (newDisplayOrder: number) => {
    try {
      reorderAMenuItem(menuId, sectionId, itemId, newDisplayOrder);
      await reorderMenuItem({ accessToken, itemId, newDisplayOrder });
    } catch (error) {
      setNotification({
        severity: 'error',
        description: 'Internal Server Error',
        message: 'We have been unable to complete your request',
      });
    }
  };

  const menuItemEllipsisMenu = (
    <EllipsisMenu backgroundType="dark">
      <MenuItem component={Link} to={`/menu/${menuId}/item/${itemId}`}>
        Open
      </MenuItem>
      <MenuItem component={Link} to={`/menu/${menuId}/item/${itemId}/edit`}>
        Edit
      </MenuItem>
      <MenuItem data-testid="open-move-prompt" onClick={() => setIsMovePromptVisible(true)}>
        Move to section
      </MenuItem>
      <MenuItem
        data-testid="move-left"
        disabled={!(displayOrderLeft >= 0)}
        onClick={() => reorderItem(displayOrderLeft)}
      >
        Move left
      </MenuItem>
      <MenuItem
        data-testid="move-right"
        disabled={!(displayOrderRight >= 0)}
        onClick={() => reorderItem(displayOrderRight)}
      >
        Move right
      </MenuItem>
      <StyledMenuItem data-testid="open-remove-prompt" onClick={() => setIsRemovePromptVisible(true)}>
        Delete
      </StyledMenuItem>
    </EllipsisMenu>
  );
  return (
    <>
      <MovePrompt
        title={name}
        menuId={menuId}
        itemId={itemId}
        sectionId={sectionId}
        closePrompt={() => setIsMovePromptVisible(false)}
        open={isMovePromptVisible}
      />
      <RemovePrompt
        title={removePromptTitle}
        subTitle={removeItemPromptSubTitle}
        closePrompt={() => setIsRemovePromptVisible(false)}
        open={isRemovePromptVisible}
        deleteButtonHandler={handleDelete}
      />
      <Card
        text={name}
        id={menuId}
        key={itemId}
        urlImage={image}
        itemId={itemId}
        sectionId={sectionId}
        variant="item"
        ellipsisMenu={menuItemEllipsisMenu}
      />
    </>
  );
};

export default MenuItemCard;

import { ReactElement, MouseEvent, useState, SetStateAction, Dispatch } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { Link } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { StyledButton, StyledMenu, StyledMenuItem, HiddenMenuItem } from './MenuEllipsis.styles';
import RemovePrompt from '../RemovePrompt';
import { useMenusContext } from 'src/contexts/menus';
import { useNotificationOutlet } from 'src/components/Notification';
import Modal from 'src/components/Modal';
import MovePrompt from '../MovePrompt';
import SectionModal from '../SectionModal';
import PublishModal from '../PublishModal';
import { Typography } from '@mui/material';
import { deleteMenuItem } from 'src/api/deleteMenuItem/deleteMenuItem';
import { deleteSection } from 'src/api/deleteSection/deleteSection';
import { deleteMenu } from 'src/api/deleteMenu/deleteMenu';

interface MenuProps {
  menuId: string;
  itemId?: string;
  name?: string;
  variant: 'menu' | 'item' | 'section' | 'modifier';
  sectionId?: string;
  setItemModal?: Dispatch<SetStateAction<boolean>>;
  deleteAction?: () => void;
}

const MenuEllipsis = ({ menuId, itemId, name, variant = 'menu', sectionId, setItemModal }: MenuProps): ReactElement => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [isRenameSectionModalVisible, setIsRenameSectionModalVisible] = useState<boolean>(false);
  const [isRemovePromptVisible, setIsRemovePromptVisible] = useState<boolean>(false);
  const [isMovePromptVisible, setIsMovePromptVisible] = useState<boolean>(false);
  const [isPublishModalVisible, setIsPublishModalVisible] = useState<boolean>(false);

  const open = Boolean(anchorEl);
  const { authState } = useOktaAuth();
  const accessToken = authState?.accessToken?.accessToken;
  const { deleteAMenu, deleteAMenuItem, menus, deleteAMenuSection } = useMenusContext();
  const setNotification = useNotificationOutlet();
  const removePromptTitle = `Are you sure you want to delete "${name}"?`;
  const removePromptSubTitle = 'You will lose all the connections made with this menu.';
  const removeItemPromptSubTitle = 'You will remove this menu item from the current menu.';
  const removeSectionPromptSubTitle = 'All grouped menu items will move to the Uncategorised section.';
  const currentMenu = menus.find((FEMenu) => FEMenu.menuId === menuId);
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePublishModal = () => {
    handleClose();
    setIsPublishModalVisible((prev) => !prev);
  };
  const showModal = (): void => {
    handleClose();
    if (variant === 'item') setItemModal(true);
    setIsModalVisible(true);
  };

  const closeModal = (): void => {
    setIsModalVisible(false);
  };

  const showDeletePrompt = (): void => {
    handleClose();
    setIsRemovePromptVisible(true);
  };

  const closeDeletePrompt = (): void => {
    setIsRemovePromptVisible(false);
  };

  const showMovePrompt = (): void => {
    handleClose();
    setIsMovePromptVisible(true);
  };

  const closeMovePrompt = (): void => {
    setIsMovePromptVisible(false);
  };

  const showRenameSectionModal = (): void => {
    handleClose();
    setIsRenameSectionModalVisible(true);
  };

  const closeRenameSectionModal = (): void => {
    setIsRenameSectionModalVisible(false);
  };

  const onAction = (): void => {
    if (variant === 'menu') {
      setNotification({
        severity: 'success',
        message: (
          <>
            You have successfully deleted <Typography variant="strongText">&quot;{name}&quot;</Typography>.
          </>
        ),
      });
      deleteAMenu(menuId);
    }

    if (variant === 'section') {
      deleteAMenuSection(menuId, sectionId);
    }

    if (variant === 'item') {
      deleteAMenuItem(menuId, itemId);
      setNotification({
        severity: 'success',
        message: (
          <>
            You have successfully removed <Typography variant="strongText">&quot;{name}&quot;</Typography> from{' '}
            <Typography variant="strongText">&quot;{currentMenu.name}&quot;</Typography>.
          </>
        ),
      });
    }

    closeDeletePrompt();
  };

  const onDeletionHandler = () => {
    switch (variant) {
      case 'menu':
        return deleteMenu(menuId);
      case 'item':
        return deleteMenuItem(itemId);
      case 'section':
        return deleteSection(accessToken, menuId, sectionId);
    }
  };

  const isModifier = currentMenu && currentMenu.modifiers.find((modifier) => modifier.itemId === itemId);
  const isWithinBundle =
    !!currentMenu?.sections.find((section) =>
      section.items.find((item) => item.itemId === itemId && item.type !== 'BUNDLE'),
    ) || !!currentMenu?.uncategorised.find((item) => item.itemId === itemId && item.type !== 'BUNDLE');
  const multipleSections = currentMenu?.sections.length > 0;

  const handleDelete = (): void => {
    onDeletionHandler()
      .then(() => {
        onAction();
      })
      .catch((error) => {
        if (isModifier || isWithinBundle) {
          setNotification({
            severity: 'error',
            description: `You cannot delete this ${isModifier ? 'modifier' : 'menu item'} yet`,
            message: error?.message,
          });
        } else {
          setNotification({
            severity: 'error',
            description: '500 Internal Server Error',
            message: 'We have been unable to complete your request. Please try again',
          });
        }
        closeDeletePrompt();
      });
  };

  return (
    <>
      {variant === 'menu' && (
        <>
          <Modal
            title="Edit menu"
            menuName={name}
            closeModal={closeModal}
            open={isModalVisible}
            variant="update"
            id={menuId}
          />
          <PublishModal
            isOpen={isPublishModalVisible}
            closeModal={handlePublishModal}
            menuId={menuId}
            menuName={name}
          />
        </>
      )}
      {variant == 'section' && (
        <SectionModal
          title="Edit Section"
          sectionName={name}
          open={isRenameSectionModalVisible}
          closeModal={closeRenameSectionModal}
          menuId={menuId}
          variant="update"
          sectionId={sectionId}
        />
      )}
      <RemovePrompt
        title={removePromptTitle}
        subTitle={
          variant === 'menu'
            ? removePromptSubTitle
            : variant === 'item'
            ? removeItemPromptSubTitle
            : removeSectionPromptSubTitle
        }
        closePrompt={closeDeletePrompt}
        open={isRemovePromptVisible}
        deleteButtonHandler={handleDelete}
      />
      {variant === 'item' && (
        <MovePrompt
          title={name}
          menuId={menuId}
          itemId={itemId}
          sectionId={sectionId}
          closePrompt={closeMovePrompt}
          open={isMovePromptVisible}
        />
      )}
      <StyledButton
        id="menu-options-button"
        className={`${variant} ${open ? 'active' : ''}`}
        data-testid="menu-options-button"
        aria-controls={open ? 'menu-items-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
      >
        <MoreVertIcon />
      </StyledButton>
      <StyledMenu
        id="menu-items-menu"
        variant="menu"
        MenuListProps={{
          'aria-labelledby': 'menu-options-button',
        }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {variant === 'menu' && (
          <MenuItem component={Link} to={`/menu/${menuId}`}>
            Open
          </MenuItem>
        )}

        {variant === 'menu' && (
          <MenuItem onClick={showModal} data-testid="open-edit-prompt">
            Edit
          </MenuItem>
        )}

        {variant === 'menu' && <MenuItem onClick={handlePublishModal}>Publish</MenuItem>}
        {variant === 'section' && <MenuItem onClick={showRenameSectionModal}>Rename</MenuItem>}
        {variant === 'section' && (
          <HiddenMenuItem disabled hidden>
            Move up
          </HiddenMenuItem>
        )}
        {variant === 'section' && (
          <HiddenMenuItem disabled hidden>
            Move down
          </HiddenMenuItem>
        )}
        {variant === 'item' && (
          <MenuItem component={Link} to={`/menu/${menuId}/item/${itemId}`}>
            Open
          </MenuItem>
        )}
        {variant === 'item' && (
          <MenuItem component={Link} to={`/menu/${menuId}/item/${itemId}/edit`}>
            Edit
          </MenuItem>
        )}

        {variant === 'item' && !isModifier && (
          <MenuItem disabled={!multipleSections} data-testid="open-move-prompt" onClick={showMovePrompt}>
            Move to section
          </MenuItem>
        )}
        <StyledMenuItem data-testid="open-remove-prompt" onClick={showDeletePrompt}>
          Delete
        </StyledMenuItem>
      </StyledMenu>
    </>
  );
};

export default MenuEllipsis;

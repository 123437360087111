import { styled, Typography, Avatar, Menu, Button } from '@mui/material';
import { theme } from 'src/assets/theme/theme';
import ArrowRightRoundedIcon from '@mui/icons-material/ArrowRightRounded';
import colors from 'src/assets/theme/colors';

type ThemeProps = {
  themeprops: 'dark' | 'light';
};

type AvatarProps = {
  avatarsize: 'small' | 'medium' | 'large';
};

const getSize = (size, s, m, l) => {
  return size === 'small' ? `${theme.spacing(s)}` : size === 'medium' ? `${theme.spacing(m)}` : `${theme.spacing(l)}`;
};

export const StyledButton = styled(Button)`
  width: 100%;
  padding-top: ${theme.spacing(4)};
  padding-bottom: ${theme.spacing(4)};
  padding-left: 0;
  padding-right: ${theme.spacing(2)};
  text-transform: capitalize;
  background: transparent;

  &:hover {
    background: transparent;
  }
`;

export const StyledAvatar = styled(Avatar)<AvatarProps>(({ avatarsize }) => ({
  borderRadius: theme.spacing(2),
  cursor: 'pointer',
  marginRight: getSize(avatarsize, 2, 3, 3),
  height: getSize(avatarsize, 8, 12, 16),
  width: getSize(avatarsize, 8, 12, 16),
}));

export const StyledLabel = styled(Typography)<ThemeProps>`
  color: ${(props) => (props.themeprops === 'dark' ? colors.white : colors.darkBlue)};
  max-width: ${theme.spacing(30)};
  text-align: left;
  padding-left: ${theme.spacing(2)};
  text-transform: capitalize;
`;

export const StyledArrowIcon = styled(ArrowRightRoundedIcon)<ThemeProps>`
  color: ${(props) => (props.themeprops === 'dark' ? colors.white : colors.darkBlue)};
  transform: rotate(90deg);
`;

export const StyledMenu = styled(Menu)`
  margin-top: ${theme.spacing(4)};
  margin-left: ${theme.spacing(1.75)};

  & .MuiPaper-root {
    width: ${theme.spacing(75)};
  }

  & .MuiListItemAvatar-root {
    min-width: max-content;
  }
  & .MuiMenuItem-root {
    padding: ${theme.spacing(4)};

    & .MuiListItemIcon-root {
      min-width: max-content;
    }
  }
  & .MuiListItemText-root {
    & .MuiTypography-root {
      white-space: normal;
    }
  }
`;

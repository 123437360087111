import { ReactElement } from 'react';
import { StyledCard, StyledBox, StyledTypography, StyledOptionBox, StyledCardActionArea } from './Card.styles';
import MenuEllipsis from 'src/components/MenuEllipsis';
import { Tooltip, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { getTitle } from 'src/helpers';

interface CardProps {
  text: string;
  id: string;
  itemId?: string;
  variant?: 'item' | 'menu';
  urlImage?: string;
  sectionId?: string;
  ellipsisMenu?: ReactElement;
}

const Card = ({ text, id, itemId, variant = 'menu', urlImage, sectionId, ellipsisMenu }: CardProps): ReactElement => {
  const menuItemCharLimit = 45;

  return (
    <StyledCard data-testid="card" image={urlImage} item={variant}>
      <StyledOptionBox>
        {ellipsisMenu ?? (
          <MenuEllipsis
            menuId={id}
            itemId={variant === 'item' && itemId}
            name={text}
            variant={variant}
            sectionId={sectionId}
          />
        )}
      </StyledOptionBox>
      <StyledCardActionArea
        component={Link}
        to={variant === 'item' ? `/menu/${id}/${variant}/${itemId}` : `/${variant}/${id}`}
        variant={variant}
        data-testid="card-navigation"
      >
        <StyledBox pt={4} pl={4} pr={4} pb={3}>
          <StyledTypography variant="lText">
            <Tooltip arrow placement="bottom" enterNextDelay={700} title={text}>
              <Typography variant="strongText">{getTitle(text, menuItemCharLimit)}</Typography>
            </Tooltip>
          </StyledTypography>
        </StyledBox>
      </StyledCardActionArea>
    </StyledCard>
  );
};

export default Card;

import { theme } from 'src/assets/theme/theme';
import colors from 'src/assets/theme/colors';
import DashedBorderBox from 'src/assets/images/DashedBorderBox';

import { styled, Card, Box, Typography, CardProps, CardActionArea } from '@mui/material';

interface StyledCardProps extends CardProps {
  item?: string;
  image?: string;
}

interface CardActionProps {
  component: any;
  to: string;
  variant: string;
}

const SquareCard = styled(Card)`
  width: ${theme.spacing(50)};
  height: ${theme.spacing(50)};
  margin-bottom: ${theme.spacing(4)};
  margin-right: ${theme.spacing(6)};
  position: relative;
  background-position: center center;
  background-size: cover;
  ${(props) => props.theme.breakpoints.down('md')} {
    width: ${theme.spacing(60)};
  }
  ${(props) => props.theme.breakpoints.down('sm')} {
    width: ${theme.spacing(78)};
  }
`;

export const StyledCard = styled(SquareCard)<StyledCardProps>`
  background-image: ${(props) => (props?.image ? `url(${props.image})` : '')};
  background-color: ${(props) => (props?.item === 'item' ? colors.AZT_Dark : colors.AZT_Light)};
  &::before {
    content: '';
    position: absolute;
    background: ${(props) =>
      props?.image
        ? `linear-gradient(180deg, ${colors.AZT_Dark_Gradient} 11.23%, ${colors.deepBlue22} 100%)`
        : props?.item === 'item'
        ? `linear-gradient(180deg, ${colors.AZT_Dark} 22%, ${colors.deepBlue22} 100%)`
        : `linear-gradient(180deg, ${colors.AZT_Light} 11.23%, ${colors.AZT_Light} 100%)`};
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
  }
`;

export const NewItemCard = styled(SquareCard)`
  border-radius: ${theme.spacing(2)};
  box-shadow: none;
  align-items: center;
  justify-content: center;
  display: flex;
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: background-image 250ms ease-out;
  cursor: pointer;
  background-image: url(${`"${DashedBorderBox({})}"`});
  &:hover {
    background-image: url(${`"${DashedBorderBox({ hover: true })}"`});
  }
  &::before,
  &::after {
    content: '';
    height: ${theme.spacing(5)};
    width: 1.4px;
    background-color: ${colors.AZT_Dark};
    position: absolute;
    left: 50%;
    top: calc(50% - ${theme.spacing(2.5)});
    transform: translate(-50%, -50%);
  }
  &::before {
    transform: translate(-50%, -50%) rotate(90deg);
  }
  p {
    margin-top: ${theme.spacing(10)};
    font-size: 1.125rem;
  }
`;

export const StyledBox = styled(Box)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  position: relative;
  verticial-align: middle;
`;

export const StyledOptionBox = styled(Box)`
  text-align: right;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
`;

export const StyledTypography = styled(Typography)`
  color: ${colors.white};
  position: relative;
`;

export const StyledCardActionArea = styled(CardActionArea)<CardActionProps>`
  width: 100%;
  height: 100%;
  display: block;
`;

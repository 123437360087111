import { IMenu } from 'src/types/menu.types';

export const updateMenuSection = (
  menus: IMenu[],
  menuId: string,
  sectionId: string,
  newName: string,
  newDescription: string,
): IMenu[] => {
  return menus.map((menu) => {
    if (menu.menuId === menuId) {
      return {
        ...menu,
        sections: menu.sections.map((section) => {
          if (section.sectionId === sectionId) {
            return {
              ...section,
              name: newName,
              description: newDescription,
            };
          }
          return section;
        }),
      };
    }
    return menu;
  });
};

import { ReactElement } from 'react';
import {
  HeadingContainer,
  StyledArrowBackIosIcon,
  MobileHeadingContainer,
  MobileHeadingOptions,
  HeaderBox,
  MobileHeading,
  StyledHeadingContainer,
  ButtonContainer,
  BackLink,
  StyledSubtitle,
} from './Header.styles';
import { Tooltip, useMediaQuery, Box } from '@mui/material';
import { theme } from 'src/assets/theme/theme';

interface HeaderProps {
  headingTitle: JSX.Element;
  tooltipText?: string;
  backBtn?: boolean;
  backBtnPath?: string;
  rightSideContents?: JSX.Element;
  subTitle?: string;
}
const Header = ({
  headingTitle,
  tooltipText,
  backBtn,
  backBtnPath,
  rightSideContents,
  subTitle,
}: HeaderProps): ReactElement => {
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return isMobile ? (
    <MobileHeadingContainer>
      {subTitle && <StyledSubtitle variant="sText">{subTitle}</StyledSubtitle>}
      <MobileHeading>{headingTitle}</MobileHeading>
      <MobileHeadingOptions>
        {backBtn && (
          <BackLink to={backBtnPath} data-testid="headerBackBtn">
            <StyledArrowBackIosIcon />
          </BackLink>
        )}
        {rightSideContents && <ButtonContainer>{rightSideContents}</ButtonContainer>}
      </MobileHeadingOptions>
    </MobileHeadingContainer>
  ) : (
    <Box>
      <HeadingContainer data-testid="HeadingContainer">
        <HeaderBox>
          {backBtn && (
            <BackLink to={backBtnPath} data-testid="headerBackBtn">
              <StyledArrowBackIosIcon />
            </BackLink>
          )}
          {tooltipText ? (
            <Tooltip arrow enterNextDelay={700} title={tooltipText ?? ''}>
              <StyledHeadingContainer data-testid={`${tooltipText}-heading`}>{headingTitle}</StyledHeadingContainer>
            </Tooltip>
          ) : (
            <StyledHeadingContainer>{headingTitle}</StyledHeadingContainer>
          )}
        </HeaderBox>
        {rightSideContents && <ButtonContainer>{rightSideContents}</ButtonContainer>}
      </HeadingContainer>
      {subTitle && <StyledSubtitle variant="sText">{subTitle}</StyledSubtitle>}
    </Box>
  );
};

export default Header;

import { API_URL } from '../constants';

export const deleteSection = (authToken: string, menuId: string, sectionId: string): Promise<any> => {
  return fetch(`${API_URL}/menus/${menuId}/sections/${sectionId}`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  }).then((res) => {
    if (res.status === 400) {
      throw new Error();
    }
    if (res.status === 204) return {};
  });
};

import { SvgIcon } from '@mui/material';

const VenuesClipboard = (props) => {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M28.5703 12.8948C28.5703 6.40291 23.198 1.14062 16.5703 1.14062C9.94266 1.14062 4.57031 6.40291 4.57031 12.8948C4.57031 18.3564 8.37379 22.9471 13.5275 24.2667L16.5703 28.5692L19.6131 24.2667C24.7668 22.9471 28.5703 18.3564 28.5703 12.8948V12.8948Z"
          stroke="#4891C7"
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
        <path
          d="M11.7322 6.85938C11.7322 6.85938 11.0494 8.78446 11.7322 9.75368C12.054 10.2106 13.6942 10.7185 13.6942 10.7185V13.6128L12.2985 14.5775V18.4366C12.2985 18.4366 12.6468 19.6315 13.9976 19.4014C15.0273 19.226 15.1303 18.4366 15.1303 18.4366V14.5775L13.9976 13.6128V10.7185C13.9976 10.7185 15.3748 10.2106 15.6967 9.75368C16.3795 8.78446 15.6967 6.85938 15.6967 6.85938"
          stroke="#4891C7"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M13.7139 6.85938V9.14509" stroke="#4891C7" strokeLinecap="round" strokeLinejoin="round" />
        <path
          d="M21.7074 18.034V6.85938C21.7074 6.85938 19.8192 6.85932 18.8762 8.2562C17.9333 9.65308 18.4032 13.8435 18.4032 13.8435H19.3473V18.034C19.3473 18.034 19.3482 19.4308 20.7633 19.4308C21.8609 19.4308 21.7074 18.034 21.7074 18.034Z"
          stroke="#4891C7"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.1636 27.4297C9.01416 27.7295 6.85742 28.3405 6.85742 29.0455C6.85742 30.0467 11.2067 30.8583 16.5717 30.8583C21.9368 30.8583 26.286 30.0467 26.286 29.0455C26.286 28.355 24.2172 27.7547 21.1732 27.4486"
          stroke="#4891C7"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      </svg>
    </SvgIcon>
  );
};

export default VenuesClipboard;

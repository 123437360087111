import { MenuItem, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import EllipsisMenu from '../EllipsisMenu';
import Card from '../Card';
import RemovePrompt from '../RemovePrompt';
import { useState } from 'react';
import { useNotificationOutlet } from '../Notification';
import { deleteMenu } from 'src/api/deleteMenu/deleteMenu';
import { useMenusContext } from 'src/contexts/menus';
import { StyledMenuItem } from '../EllipsisMenu/EllipsisMenu.styles';
import PublishModal from '../PublishModal';
import Modal from '../Modal';
export interface MenuCardProps {
  menuId: string;
  name: string;
  image?: string;
}

const MenuCard = ({ menuId, name, image }: MenuCardProps) => {
  const [isRemovePromptVisible, setIsRemovePromptVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState<boolean>(false);
  const [isPublishModalVisible, setIsPublishModalVisible] = useState<boolean>(false);
  const { deleteAMenu } = useMenusContext();
  const setNotification = useNotificationOutlet();
  const removePromptTitle = `Are you sure you want to delete "${name}"?`;

  const handleDelete = (): void => {
    deleteMenu(menuId)
      .then(() => {
        setNotification({
          severity: 'success',
          message: (
            <>
              You have successfully deleted <Typography variant="strongText">&quot;{name}&quot;</Typography>.
            </>
          ),
        });
        deleteAMenu(menuId);
      })
      .catch(() => {
        setNotification({
          severity: 'error',
          description: '500 Internal Server Error',
          message: 'We have been unable to complete your request. Please try again',
        });
        setIsRemovePromptVisible(false);
      });
  };

  const menuItemEllipsisMenu = (
    <EllipsisMenu backgroundType="dark">
      <MenuItem component={Link} to={`/menu/${menuId}`}>
        Open
      </MenuItem>
      <MenuItem onClick={() => setIsEditModalVisible(true)}>Edit</MenuItem>
      <MenuItem onClick={() => setIsPublishModalVisible(true)}>Publish</MenuItem>
      <StyledMenuItem data-testid="open-remove-prompt" onClick={() => setIsRemovePromptVisible(true)}>
        Delete
      </StyledMenuItem>
    </EllipsisMenu>
  );
  return (
    <>
      <Modal
        title="Edit menu"
        menuName={name}
        closeModal={() => setIsEditModalVisible(false)}
        open={isEditModalVisible}
        variant="update"
        id={menuId}
      />
      <PublishModal
        isOpen={isPublishModalVisible}
        closeModal={() => setIsPublishModalVisible(false)}
        menuId={menuId}
        menuName={name}
      />
      <RemovePrompt
        title={removePromptTitle}
        subTitle={`You will lose all the connections made with this menu.`}
        closePrompt={() => setIsRemovePromptVisible(false)}
        open={isRemovePromptVisible}
        deleteButtonHandler={handleDelete}
      />
      <Card text={name} id={menuId} key={menuId} urlImage={image} variant="menu" ellipsisMenu={menuItemEllipsisMenu} />
    </>
  );
};

export default MenuCard;

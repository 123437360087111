import { Controller } from 'react-hook-form';
import { Checkbox, FormControlLabel } from '@mui/material';

import { ReactElement } from 'react';

type CheckBoxProps = {
  label: string;
  id: string;
};

type FormCheckboxProps = {
  name: string;
  control: any;
  items: CheckBoxProps[];
  getValues: () => any;
  defaultIds?: string[];
};

const CheckBoxGroup = ({ onChange, items, defaultIds, handleCheck }): ReactElement =>
  items.map((item) => (
    <FormControlLabel
      key={item.id}
      control={
        <Checkbox
          data-testid={`${item.id}-checkbox`}
          onChange={() => onChange(handleCheck(item.id))}
          defaultChecked={defaultIds.includes(item.id)}
        />
      }
      label={item.label}
    />
  ));

export const FormCheckbox = ({ name, control, items, getValues, defaultIds = [] }: FormCheckboxProps) => {
  const handleCheck = (checkedId) => {
    const { items: ids } = getValues();
    const newIds = ids?.includes(checkedId) ? ids?.filter((id) => id !== checkedId) : [...(ids ?? []), checkedId];
    return newIds;
  };
  return (
    <Controller
      render={({ field: { onChange } }) => {
        return <CheckBoxGroup onChange={onChange} items={items} defaultIds={defaultIds} handleCheck={handleCheck} />;
      }}
      name={name}
      control={control}
    />
  );
};

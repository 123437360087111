import { styled, Box, Typography } from '@mui/material';
import { theme } from 'src/assets/theme/theme';

const Container = styled(Box)`
  display: flex;
  justify-content: center;
  height: 100vh;
`;

const LoginContent = styled(Box)`
  width: 50vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const StyledBox = styled(Box)`
  ${(props) => props.theme.breakpoints.down('ms')} {
    width: 100vw;
    padding: 0 ${theme.spacing(6)} 0 ${theme.spacing(6)};
  }
`;

const StyledText = styled(Typography)`
  margin-bottom: ${theme.spacing(8)};
  color: ${theme.palette.primary.main};
`;

const ImageContainer = styled(Box)`
  position: relative;
  width: 50vw;
  ${(props) => props.theme.breakpoints.down('dl')} {
    display: none;
  }
`;

const LargeImage = styled('img')`
  object-fit: cover;
  min-width: 100%;
  height: auto;
`;

export { Container, LoginContent, StyledText, StyledBox, ImageContainer, LargeImage };

import { ReactElement } from 'react';
import Prompt, { Container, StyledDialogAction, StyledTitle, StyledSubTitle, StyledText, SmallButton } from '../Prompt';
import { Typography } from '@mui/material';

interface RemovePromptProps {
  title?: string;
  subTitle?: string;
  closePrompt?: any;
  open: boolean;
  deleteButtonHandler?: any;
}

const RemovePrompt = ({ title, subTitle, closePrompt, open, deleteButtonHandler }: RemovePromptProps): ReactElement => {
  return (
    <Prompt testId="close-delete-menu-prompt" closePrompt={closePrompt} open={open}>
      <Container>
        <StyledTitle variant="xlText" variantMapping={{ xlText: 'h2' }}>
          <Typography variant="strongText">{title}</Typography>
        </StyledTitle>
        <StyledSubTitle variant="mText" variantMapping={{ mText: 'p' }}>
          {subTitle}
        </StyledSubTitle>
        <StyledDialogAction>
          <SmallButton variant="outlined" onClick={closePrompt}>
            <StyledText>Back</StyledText>
          </SmallButton>
          <SmallButton data-testid="remove-item" color="primary" variant="contained" onClick={deleteButtonHandler}>
            <StyledText>Delete</StyledText>
          </SmallButton>
        </StyledDialogAction>
      </Container>
    </Prompt>
  );
};

export default RemovePrompt;

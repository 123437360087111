import { theme } from 'src/assets/theme/theme';
import { styled, Box } from '@mui/material';
import colors from 'src/assets/theme/colors';

export const Section = styled(Box)`
  margin: ${theme.spacing(16)} ${theme.spacing(0)} ${theme.spacing(0)} ${theme.spacing(2)};
`;

export const ContentHeader = styled(Box)`
  display: flex;
  align-items: center;
  flex-direction: row;
`;

export const Content = styled(Box)`
  margin-top: ${theme.spacing(6)};
  display: flex;
  jusify-content: flex-start;
  align-items: center;
  flex-direction: row;
`;

export const ItemDetailContainer = styled(Box)`
  margin-top: ${theme.spacing(2)};
  display: flex;
  justify-content: space-between;
  flex-direction: row;

  ${(props) => props.theme.breakpoints.down('md')} {
    flex-direction: column;
  }
`;

export const ItemImage = styled('img')`
  min-height: ${theme.spacing(81)};
  min-width: ${theme.spacing(81)};
  max-height: ${theme.spacing(81)};
  max-width: ${theme.spacing(81)};
  border-radius: ${theme.spacing(2)};
  object-fit: cover;
  object-position: center;

  ${(props) => props.theme.breakpoints.down('md')} {
    margin-top: ${theme.spacing(4)};
  }
`;

export const SectionNumber = styled(Box)`
  height: ${theme.spacing(7)};
  width: ${theme.spacing(7)};
  background-color: ${colors.AZT_Dark};
  color: ${colors.white};
  border-radius: ${theme.spacing(10)};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: ${theme.spacing(4)};
`;

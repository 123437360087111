import { useState } from 'react';
import { MenusContext } from './menusContext';
import { updateMenuDetails as updateMenu } from './updateMenuDetails/updateMenuDetails';
import { IMenu, IMenuAvailability, IMenuItem, ISection } from 'src/types/menu.types';
import { deleteMenuItem } from './deleteMenuItem/deleteMenuItem';
import { createMenuSection } from './createMenuSection/createMenuSection';
import { deleteMenuSection } from './deleteMenuSection/deleteMenuSection';
import { updateMenuSection } from './updateMenuSection/updateMenuSection';
import { moveMenuItem } from './moveMenuItem/moveMenuItem';
import { reorderMenuItem } from './reorderMenuItem/reorderMenuItem';
import { addMenu } from './addmenu/addMenu';
import { moveMenuSection } from './moveMenuSection/moveMenuSection';
import { getMenuById } from './getMenuById/getMenuById';
import { addMenuItem } from './addMenuItem/addMenuItem';
import { updateSchedule } from './updateSchedule/updateSchedule';
import { addMultipleMenuItems } from './addMultipleMenuItems/addMultipleMenuItems';

export const MenusProvider = ({ children }) => {
  // this property is being slowly migrated to for storage of all menus.
  // the shape of the data and the reasons for transition are detailed below
  // https://weareopenr.atlassian.net/wiki/spaces/ASP/pages/137297921/FE+Data+management+session
  const [menus, setMenus] = useState<IMenu[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const updateMenuDetails = (menuId: string, newName?: string, newImage?: string): void => {
    setMenus(updateMenu(menus, menuId, newName, newImage));
  };

  const addNewMenu = (menu: IMenu): void => {
    setMenus(addMenu(menus, menu));
  };

  const addMenus = (newMenus: IMenu[]): void => {
    setMenus(newMenus);
  };

  const deleteAMenuItem = (menuId: string, itemId: string): void => {
    setMenus(deleteMenuItem(menus, menuId, itemId));
  };

  const createAMenuSection = (menuId: string, section: Omit<ISection, 'items'>, itemIds: string[]): void => {
    setMenus(createMenuSection(menus, menuId, section, itemIds));
  };

  const deleteAMenuSection = (menuId: string, sectionId: string): void => {
    setMenus(deleteMenuSection(menus, menuId, sectionId));
  };

  const updateAMenuSection = (menuId: string, sectionId: string, newName: string, newDescription: string) => {
    setMenus(updateMenuSection(menus, menuId, sectionId, newName, newDescription));
  };

  const moveAMenuItem = (
    menuId: string,
    newSectionId: string,
    itemId: string,
    currentSectionId: string,
    newDisplayOrder: number,
  ): void => {
    setMenus(moveMenuItem(menus, menuId, newSectionId, itemId, currentSectionId, newDisplayOrder));
  };

  const reorderAMenuItem = (menuId: string, sectionId: string, itemId: string, newDisplayOrder: number): void => {
    setMenus(reorderMenuItem(menus, menuId, sectionId, itemId, newDisplayOrder));
  };

  const deleteAMenu = (menuId: string) => {
    setMenus(menus.filter((menu) => menu.menuId !== menuId));
  };

  const moveAMenuSection = (menuId: string, sectionId: string, newDisplayOrder: number): void => {
    setMenus(moveMenuSection(menus, menuId, sectionId, newDisplayOrder));
  };

  const getAMenuById = (menuId: string) => getMenuById(menus, menuId);
  const addAMenuItem = (menuId: string, itemId: string, currentItem: IMenuItem): void => {
    setMenus(addMenuItem(menus, menuId, itemId, currentItem));
  };

  const addMenuItems = (
    menuId: string,
    parentItemId: string,
    itemsToAddToMenu: IMenuItem[],
    sectionId: string | null,
  ): void => {
    setMenus(addMultipleMenuItems(menus, menuId, parentItemId, itemsToAddToMenu, sectionId));
  };

  const updateAMenuSchedule = (menuId: string, schedules: IMenuAvailability[]): void => {
    setMenus(updateSchedule(menus, menuId, schedules));
  };
  return (
    <MenusContext.Provider
      value={{
        isLoading,
        setIsLoading,
        updateMenuDetails,
        menus,
        addNewMenu,
        deleteAMenuItem,
        createAMenuSection,
        deleteAMenuSection,
        updateAMenuSection,
        moveAMenuItem,
        reorderAMenuItem,
        addMenus,
        deleteAMenu,
        moveAMenuSection,
        getAMenuById,
        addAMenuItem,
        addMenuItems,
        updateAMenuSchedule,
      }}
    >
      {children}
    </MenusContext.Provider>
  );
};

import { IMenu } from 'src/types/menu.types';

export const moveMenuItem = (
  menus: IMenu[],
  menuId: string,
  newSectionId: string,
  itemId: string,
  currentSectionId: string,
  newDisplayOrder: number,
): IMenu[] => {
  const newMenus = menus.map((menu) => {
    if (menu.menuId === menuId) {
      // if uncategorised item => section
      if (currentSectionId === undefined) {
        const currentItem = menu.uncategorised.find((item) => item.itemId === itemId);
        currentItem.displayOrder = newDisplayOrder;
        return {
          ...menu,
          uncategorised: menu.uncategorised.filter((item) => item.itemId !== itemId),
          sections: menu.sections.map((section) => {
            if (section.sectionId === newSectionId) {
              return {
                ...section,
                items: [...section.items, currentItem].sort((a, b) => a.displayOrder - b.displayOrder),
              };
            }
            return section;
          }),
        };
      } else {
        const currentItem = menu.sections
          .map((section) => section.items.filter((item) => item.itemId === itemId))
          .flat()[0];
        currentItem.displayOrder = newDisplayOrder;
        if (newSectionId === null) {
          //moving from section => uncategorised
          return {
            ...menu,
            uncategorised: [...menu.uncategorised, currentItem],
            sections: menu.sections.map((section) => {
              if (section.sectionId === currentSectionId) {
                return {
                  ...section,
                  items: section.items.filter((item) => item.itemId !== itemId),
                };
              }
              return section;
            }),
          };
        } else {
          //moving from one section => another section
          return {
            ...menu,
            sections: menu.sections.map((section) => {
              if (section.sectionId === newSectionId) {
                return {
                  ...section,
                  items: [...section.items, currentItem],
                };
              } else if (section.sectionId === currentSectionId) {
                return {
                  ...section,
                  items: section.items.filter((item) => item.itemId !== itemId),
                };
              }
              return section;
            }),
          };
        }
      }
    }
    return menu;
  });
  return newMenus;
};

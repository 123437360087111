import { API_URL } from '../constants';
import { fetch } from '../fetchWrapper/fetch';

export const getMenuItem = async (itemId: string): Promise<any> => {
  const url = `${API_URL}/items/${itemId}`;

  const response = await fetch(url, {
    method: 'GET',
  });
  const res = await response.json();
  return res?.data[0] ?? null;
};

import { Box, styled } from '@mui/material';
import { theme } from 'src/assets/theme/theme';
import colors from 'src/assets/theme/colors';
import AddIcon from '@mui/icons-material/Add';

export const StyledAddIcon = styled(AddIcon)`
  margin-left: ${theme.spacing(4)};
  &:hover {
    cursor: pointer;
    border: 1px solid ${colors.AZT_Dark};
    border-radius: ${theme.spacing(25)};
  }
`;

export const StyledEmptyStateContainer = styled(Box)`
  margin-top: ${theme.spacing(12.5)};
`;

export const StyledTitleContainer = styled(Box)`
  display: flex;
  align-items: center;
`;

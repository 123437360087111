import { ReactElement, useState, FC } from 'react';
import { Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { StyledTitle, StyledAddIcon, AddButton, StyledCardContainer } from './Section.styles';
import Modal from '../Modal';
import type { ActiveSectionProp } from 'src/helpers';
import EmptyState from '../EmptyState';
import Header from '../Header';
import { IMenu } from 'src/types/menu.types';
import MenuCard from '../MenuCard';

interface SectionProps {
  type?: string;
  sectionName?: string;
  dividerText?: string;
  emptySectionText: string;
  buttonText: string;
  menus?: IMenu[];
  Icon: FC;
  sectionDivider?: boolean;
  menuId?: string | null;
  section?: ActiveSectionProp;
  modalSwitch?: () => void;
  index?: number;
}

const Section = ({
  type,
  sectionName,
  emptySectionText,
  buttonText,
  Icon,
  menus,
  section,
}: SectionProps): ReactElement => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const history = useHistory();
  const pathname = history?.location?.pathname;
  const createItem = (sectionId: string | undefined) => {
    const param = sectionId ? `?sectionId=${sectionId}` : '';
    history.push(`${pathname}/item/new${param}`);
  };

  const showModal = (): void => {
    setIsModalVisible(true);
  };

  const closeModal = (): void => {
    setIsModalVisible(false);
  };

  const headingTitle = () => {
    return (
      <>
        <StyledTitle variant="xxlText">
          <Typography variant="strongText">{sectionName}</Typography>
        </StyledTitle>
        <AddButton onClick={showModal}>
          <StyledAddIcon data-testid="add-icon-plus" />
        </AddButton>
      </>
    );
  };
  return (
    <>
      {sectionName && <Header headingTitle={headingTitle()} />}

      <Modal title="Create new menu" closeModal={closeModal} open={isModalVisible} variant="create" />
      {sectionName === 'Menus' && menus && menus.length > 0 ? (
        <StyledCardContainer>
          {menus.map((menu) => {
            const { menuId: menuID, name, image } = menu;
            return <MenuCard menuId={menuID} name={name} image={image} key={menuID} />;
          })}
        </StyledCardContainer>
      ) : (
        <EmptyState
          emptySectionText={emptySectionText}
          onClick={type === 'menu' ? showModal : () => createItem(section ? section.section_id : undefined)}
          buttonText={buttonText}
          type={type}
          Icon={Icon}
        />
      )}
    </>
  );
};

export default Section;

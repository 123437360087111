import { ReactElement } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { StyledIconClose, StyledCloseIconContainer, StyledDialog } from './Prompt.styles';

interface RemovePromptProps {
  testId: string;
  closePrompt?: any;
  open: boolean;
  children?: ReactElement;
}

const Prompt = ({ testId, closePrompt, open, children }: RemovePromptProps): ReactElement => {
  return (
    <StyledDialog open={open} maxWidth="md">
      <StyledCloseIconContainer>
        <StyledIconClose data-testid={testId} aria-label="close" onClick={closePrompt}>
          <CloseIcon />
        </StyledIconClose>
      </StyledCloseIconContainer>
      {children}
    </StyledDialog>
  );
};

export default Prompt;

import { AlertColor } from '@mui/material/Alert';

type ErrorParams = {
  statusCode?: number;
  description?: string;
  message?: string;
  severity?: AlertColor;
  open?: boolean;
};

/**
 * Break a string by new row character into a jsx element
 * @param  {string} code error code
 * @return {Object} jsx element
 */

const message2jsx = (message: string) =>
  message.split('\n').map(function (item, idx) {
    return (
      <span key={idx}>
        {item}
        <br />
      </span>
    );
  });

/**
 * Format a server error
 * @param  {Object} error thrown error
 * @return {Object} a digestable error object with a react element in the message
 */

const formatError = (error): ErrorParams => ({
  ...error,
  severity: error.severity ? error.severity : 'success',
  message: message2jsx(error.message),
  open: true,
});

export default formatError;

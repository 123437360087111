export const fetch = (url: string, options: RequestInit) => {
  const brandId = localStorage.getItem('brandId');
  const accessToken = JSON.parse(localStorage.getItem('okta-token-storage')).accessToken.accessToken;
  const requestOptions: RequestInit = {
    ...options,
    headers: {
      ...options.headers,
      'brand-id': brandId,
      Authorization: `Bearer ${accessToken}`,
    },
  };
  return window.fetch(url, requestOptions);
};

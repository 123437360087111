import { formatPriceBandPayload, formatUpdatePriceBandPayload } from '../../utilities';
import { IPriceBandPayload } from 'src/api/types';
import { IFormPriceBand } from '../../types';
import { IItemPriceBand } from 'src/types/menu.types';
import { createPriceBand } from 'src/api/createPriceBand/createPriceBand';
import { updatePriceBand } from 'src/api/updatePriceBand/updatePriceBand';
import filterPriceBands from '../filterPriceBands';

const createPriceBandPromises = (
  itemId: string,
  priceBands: IItemPriceBand[],
  formPriceBands: IFormPriceBand[],
  taxRate: number,
): Promise<any>[] => {
  if (!priceBands.length && !formPriceBands.length) return [];

  const [updatedPriceBands, addedPriceBands] = filterPriceBands(priceBands, formPriceBands);

  const priceBandPromises: Promise<any>[] = [];

  // TODO logic for delete price band here

  if (updatedPriceBands.length) {
    updatedPriceBands.forEach((priceBand: IFormPriceBand) => {
      const priceBandPayload: IPriceBandPayload = formatUpdatePriceBandPayload(priceBand, taxRate);

      if (priceBandPayload.external_systems.length) {
        priceBandPromises.push(updatePriceBand(itemId, priceBandPayload));
      }
    });
  }

  if (addedPriceBands.length) {
    addedPriceBands.forEach((priceBand: IFormPriceBand) => {
      const priceBandPayload: IPriceBandPayload = formatPriceBandPayload(priceBand, taxRate);

      if (priceBandPayload.external_systems.length) {
        priceBandPromises.push(createPriceBand(itemId, priceBandPayload));
      }
    });
  }

  return priceBandPromises;
};

export default createPriceBandPromises;

const roundTo2decimals = (value: number): number => {
  return Math.round((value + Number.EPSILON) * 100) / 100;
};

export const mapRecipesToTable = (recipes) => {
  return recipes.map((recipe) => ({
    id: recipe.recipe_id,
    name: recipe.name,
    allergens: recipe.allergens_information
      .map((allergen) => {
        const childAllergens = allergen.child_allergens
          ? ` (${allergen.child_allergens.map((a) => a.name).join(', ')})`
          : '';
        return `${allergen.name}${childAllergens}`;
      })
      .join(', '),
    calories: recipe.nutritional_information
      .filter((nutrution) => nutrution.name === 'energy')
      .map((nutrution) => {
        const valueLow = roundTo2decimals(nutrution.value_low);
        const valueHigh = roundTo2decimals(nutrution.value_high);
        return valueHigh !== valueLow ? `${valueLow} - ${valueHigh}` : valueHigh;
      })
      .join(' '),
    ingredients: recipe.description,
    category: `${['contains_alcohol', 'is_vegan', 'is_vegetarian']
      .filter((key) => recipe[key])
      .map((key) => key.split('_')[1])
      .join(' ')} `,
  }));
};

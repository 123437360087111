import { API_URL } from '../constants';
import { fetch } from '../fetchWrapper/fetch';

export const createModifierGroup = (itemId: string, requestBody: any): Promise<any> => {
  const url = `${API_URL}/items/${itemId}/modifier-groups`;
  return fetch(url, {
    method: 'POST',
    body: JSON.stringify(requestBody),
    headers: {
      'content-type': 'application/json',
    },
  });
};

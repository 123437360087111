import { styled, Box, Typography, Button } from '@mui/material';
import { theme } from 'src/assets/theme/theme';
import colors from 'src/assets/theme/colors';

interface StyledTitleProps {
  wrap?: boolean;
}

export const StyledContainer = styled(Box)`
  display: flex;
  align-items: center;
  margin-bottom: ${theme.spacing(5)};
  min-height: ${theme.spacing(11)}; ;
`;
export const StyledBorderLeft = styled(Box)`
  border-bottom: 1px solid black;
  width: 100%;

  ${(props) => props.theme.breakpoints.down('ms')} {
    border-bottom: 0;
    width: 0;
  }
`;

export const StyledBorderRight = styled(Box)`
  border-bottom: 1px solid black;
  width: calc(100% - ${theme.spacing(38)});
  margin-right: ${theme.spacing(2.5)};
  &[title='Uncategorised'] {
    width: calc(100% - ${theme.spacing(11)});
  }
  &[title='Modifiers'] {
    width: calc(100% - ${theme.spacing(2.5)});
  }
`;

export const StyledTitle = styled(Typography)<StyledTitleProps>`
  padding: 0 ${theme.spacing(2.5)} 0 ${theme.spacing(2.5)};
  white-space: ${(props) => (props.wrap ? 'unset' : 'nowrap')};
  display: flex;
  position: relative;
`;

export const IconContainer = styled(Button)`
  margin: ${theme.spacing(1)};
  width: ${theme.spacing(10)};
  min-width: ${theme.spacing(10)};
  height: ${theme.spacing(10)};
  padding: ${theme.spacing(0)};
  background-color: transparent;
  border-radius: ${theme.spacing(25)};
  &:hover {
    border: 1px solid ${colors.black};
    border-radius: ${theme.spacing(25)};
    background-color: transparent;
  }
`;

export const StyledInfoTooltipLogoWrapper = styled('button')`
  appearance: none;
  padding: 0;
  margin: ${theme.spacing(0.25)} 0 ${theme.spacing(-0.25)} ${theme.spacing(2)};
  background: none;
  border: none;
  cursor: help;
`;

export const TooltipStyleOverride = {
  tooltip: {
    sx: {
      bgcolor: `${colors.navy}`,
      '& .MuiTooltip-arrow': {
        color: `${colors.navy}`,
      },
    },
  },
};

import { IMenu } from 'src/types/menu.types';

export const deleteMenuSection = (menus: IMenu[], menuId: string, sectionId: string): IMenu[] => {
  const newMenus = menus.map((menu) => {
    if (menu.menuId === menuId) {
      const currentSectionItems = menu.sections.find((section) => section.sectionId === sectionId).items;
      return {
        ...menu,
        uncategorised: [...menu.uncategorised, ...currentSectionItems],
        sections: menu.sections.filter((section) => section.sectionId !== sectionId),
      };
    }
    return menu;
  });
  return newMenus;
};

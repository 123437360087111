import { IGetBrandsResponse } from 'src/api/getBrands/types';
import { Brand } from 'src/types/brand.types';

export const getBrandsService = (brands: IGetBrandsResponse[]): Brand[] => {
  return brands.map(({ brand_id, name, logo_url }) => ({
    id: brand_id,
    name,
    logoUrl: logo_url,
  }));
};

import { theme } from 'src/assets/theme/theme';
import colors from 'src/assets/theme/colors';
import { styled, Box, TextField, FormControl, IconButton, Dialog, Grid, DialogTitle } from '@mui/material';

export const DialogModal = styled(Dialog)`
  max-height: 100vh;
  overflow: hidden;
  max-width: 100vw;
  & .MuiDialog-paper {
    max-width: ${theme.spacing(140)};
  }
`;

export const CloseButton = styled(IconButton)`
  position: absolute;
  right: ${theme.spacing(4)};
  top: ${theme.spacing(4)};
  color: ${colors.darkBlue};
  z-index: 2;
`;

export const Container = styled(Box)`
  display: flex;
  max-height: 100vh;
  padding: 0 0 ${theme.spacing(5)} 0;
  align-self: center;
  ${(props) => props.theme.breakpoints.down('ms')} {
    width: 100%;
  }
`;

export const FormContent = styled(Box)`
  width: 150vw;
  padding: ${theme.spacing(6)};
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const StyledTitle = styled(DialogTitle)`
  max-width: ${theme.spacing(125)};
  width: 100%;
  color: ${theme.palette.primary.main};
`;

export const StyledFormControl = styled(FormControl)`
  max-width: ${theme.spacing(125)};
  width: -webkit-fill-available;
  margin-bottom: ${theme.spacing(5)};
`;

export const TimeField = styled(TextField)`
  width: ${theme.spacing(35)};
  input[type='time']::-webkit-calendar-picker-indicator {
    background: none;
    display: none;
    -webkit-appearance: none;
  }
  padding-right: ${theme.spacing(2)};
  margin-bottom: ${theme.spacing(2)};
`;
export const ContainerGrid = styled(Grid)`
  margin-left: ${theme.spacing(4)};
  margin-top: ${theme.spacing(2)};
`;

import { INewOptionGroup } from 'src/api/createNewMenuItem/types';
import { IOptionGroupPayload } from 'src/api/updateModifierGroup/types';
import { IMenuItem } from 'src/types/menu.types';
import { isPosIdChanged } from '../../utilities';

const formatNewOptionGroupData = (
  updatedOptionGroupData: INewOptionGroup[],
  allItemOptions: IMenuItem[],
): IOptionGroupPayload[] => {
  const newOptionsGroup = updatedOptionGroupData.map((item) => ({
    ...item,
    description: item.description ? item.description : null,
    min_choices: parseInt(item.min_choices),
    max_choices: parseInt(item.max_choices),
    modifiers: item.modifiers.map((option) => {
      const newOption: { parent_item_id?: string; item_id?: string; plu?: string } = option.optionItemId
        ? { item_id: option.optionItemId }
        : { parent_item_id: option.parentItemId };
      if (isPosIdChanged(option, allItemOptions)) newOption.plu = option.posId;
      return newOption;
    }),
  }));
  return newOptionsGroup;
};

export default formatNewOptionGroupData;

import { SyntheticEvent, useState } from 'react';
import type { ReactElement } from 'react';
import { FormControlLabel, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import {
  StyledAutocomplete,
  StyledLabelList,
  StyledRadioContainer,
  StyledStepText,
  StyledSubText,
  StyledTitleStep1,
} from '../PublishModal.styles';
import MainButton from '../../MainButton/MainButton';
import { IAdditionalMenuViewProps } from '../types';
import { IMenu } from 'src/types/menu.types';

const AdditionalMenuView = ({
  onChangeStep,
  selectableMenus,
  selectedMenuId,
  setSelectedMenuId,
}: IAdditionalMenuViewProps): ReactElement => {
  const [radioGroupValue, setRadioGroupValue] = useState<string>(selectedMenuId === '' ? 'single' : 'multiple');
  const oneMenuText: ReactElement = <Typography variant="strongText">No additional menu</Typography>;
  const multipleMenuText: ReactElement = (
    <Typography variant="strongText">Select additional menu to publish</Typography>
  );
  const buttonLabelText: ReactElement = <Typography variant="strongText">Continue</Typography>;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRadioGroupValue((event.target as HTMLInputElement).value);
  };

  const defaultValue = selectableMenus.find((menu) => menu.menuId === selectedMenuId);

  return (
    <>
      <StyledStepText>
        <Typography component={'span'}>Step 1 of 2</Typography>
      </StyledStepText>
      <StyledTitleStep1 variant="xxlText">
        <Typography variant="strongText">Publish additional menu</Typography>
      </StyledTitleStep1>
      <StyledSubText>
        <Typography component={'span'}>To publish more than 1 menu in a day, they have to be at once.</Typography>
      </StyledSubText>
      <StyledRadioContainer>
        <RadioGroup name="add-menus" value={radioGroupValue} onChange={handleChange}>
          <FormControlLabel data-testid="radio-menu" value="single" control={<Radio />} label={oneMenuText} />
          <FormControlLabel data-testid="radio-menus" value="multiple" control={<Radio />} label={multipleMenuText} />
        </RadioGroup>
        {radioGroupValue === 'multiple' && (
          <StyledAutocomplete
            popupIcon={false}
            data-testid="step-1-menu-dropdown"
            defaultValue={defaultValue}
            options={selectableMenus}
            getOptionLabel={(option: IMenu) => option?.name}
            renderInput={(params) => <TextField {...params} label="Menu" />}
            renderOption={(props, option: IMenu) => (
              <StyledLabelList {...props} key={option.menuId}>
                <Typography>{option?.name}</Typography>
              </StyledLabelList>
            )}
            onChange={(_e: SyntheticEvent<Element, Event>, menu: IMenu) => setSelectedMenuId(menu?.menuId)}
          />
        )}
      </StyledRadioContainer>
      <MainButton onClick={() => onChangeStep(2)} type="button" text={buttonLabelText} />
    </>
  );
};

export default AdditionalMenuView;

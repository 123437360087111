import { styled, Box, Button, Typography, SvgIcon } from '@mui/material';
import colors from 'src/assets/theme/colors';
import { theme } from 'src/assets/theme/theme';

export const StyledBox = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100vw;
  max-width: ${theme.spacing(352)};
  ${(props) => props.theme.breakpoints.up('md')} {
    padding-left: ${theme.spacing(72)};
    align-self: center;
  }
`;

export const StyledSectionBox = styled(Box)`
  display: flex;
  height: ${theme.spacing(50)};
  padding: ${theme.spacing(10)};
  max-width: ${theme.spacing(274)};
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23333' stroke-width='2' stroke-dasharray='6%2c 6' stroke-dashoffset='36' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: ${theme.spacing(2)};
  flex-direction: column;
  justify-content: center;
`;

export const StyledContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const StyledButton = styled(Button)`
  border-radius: ${theme.spacing(25)};
  height: ${theme.spacing(8)};
  background-color: ${colors.AZT_Dark};
  color: ${colors.white};
  text-transform: unset !important;
  padding: ${theme.spacing(1)} ${theme.spacing(8)};
  &:hover {
    background-color: ${colors.AZT_Darkset};
  }
`;

export const StyledContent = styled(Typography)`
  margin: ${theme.spacing(4)} ${theme.spacing(0)};
  color: ${colors.black};
`;

export const StyledSvgIcon = styled(SvgIcon)`
  color: ${colors.AZT_Light};
  height: ${theme.spacing(8)};
  width: ${theme.spacing(8)};
`;

export const StyledMenusContainer = styled(Box)`
  width: 100%;
  width: calc(100% + ${theme.spacing(6)});
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 0 -${theme.spacing(3)};
`;

import { styled, Divider, Box, IconButton } from '@mui/material';
import { theme } from 'src/assets/theme/theme';
import colors from 'src/assets/theme/colors';
import AddIcon from '@mui/icons-material/Add';

export const StyledDivider = styled(Divider)`
  opacity: 0.33;
  border: 1px solid ${theme.palette.secondary.main};
`;

export const StyledIconButton = styled(IconButton)`
  color: ${colors.white};
  margin: 0;
  &:hover {
    background-color: ${colors.darkBlue};
  }
`;

export const StyledAccountContainer = styled(Box)`
  margin-left: ${theme.spacing(6)};
  margin-right: ${theme.spacing(1)};
  margin-bottom: ${theme.spacing(6)};
  margin-top: ${theme.spacing(2)};
`;

export const StyledBrandBox = styled(Box)`
  margin-top: ${theme.spacing(5)};
`;

export const StyledCategoryContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  margin-left: ${theme.spacing(4)};
  margin-right: ${theme.spacing(4)};
  margin-bottom: ${theme.spacing(4)};
`;

export const StyledAddIcon = styled(AddIcon)`
  margin-top: ${theme.spacing(10)};
  margin-right: ${theme.spacing(2)};
  align-self: flex-start;
  color: ${colors.white};
  cursor: pointer;
  &:hover {
    border: 1px solid ${colors.white};
    border-radius: ${theme.spacing(25)};
  }
`;

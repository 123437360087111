import { Controller, RegisterOptions } from 'react-hook-form';
import { StyledInput } from './Form.styles';
import { createErrorMessage } from './utilities';

type FormInputProps = {
  name: string;
  control: any;
  label?: string;
  testId?: string;
  rows?: number;
  rules?: RegisterOptions;
  disabled?: boolean;
  size?: 'small' | 'medium';
  textAlign?: 'center';
  placeholder?: string;
  type?: string;
  defaultValue?: string | number;
  showLabel?: boolean;
};

export const FormInputText = ({
  name,
  control,
  label = null,
  testId = null,
  rows = null,
  rules = null,
  disabled = false,
  size = 'medium',
  textAlign,
  placeholder,
  type,
  showLabel = true,
}: FormInputProps) => {
  testId = testId ?? `${name}`;

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { value, onChange }, fieldState: { error } }) => {
        const errorMessage = createErrorMessage(error, rules, label);
        return (
          <StyledInput
            aria-label={label ?? name}
            type={type}
            inputProps={{ 'data-testid': testId, style: { textAlign: textAlign } }}
            size={size}
            InputLabelProps={{ shrink: showLabel }}
            name={name}
            defaultValue={value}
            onChange={onChange}
            placeholder={placeholder}
            error={!!error}
            helperText={error ? errorMessage : null}
            label={label}
            rows={rows ?? 1}
            multiline={!!rows}
            disabled={disabled}
          />
        );
      }}
    />
  );
};

import { IFormAggregator } from '../../types';

const findUpdatedAggregators = (originalAggs: IFormAggregator[], formAggs: IFormAggregator[]): IFormAggregator[] => {
  const updatedAggregators: IFormAggregator[] = [];

  formAggs.forEach((formAgg) => {
    const originalAgg: IFormAggregator = originalAggs.find((agg) => agg.aggregatorId === formAgg.aggregatorId);

    if (originalAgg && originalAgg.price !== formAgg.price) {
      const updatedAggregator = {
        ...originalAgg,
        price: formAgg.price,
      };

      updatedAggregators.push(updatedAggregator);
    }

    if (!originalAgg && formAgg.price !== '') {
      const updatedAggregator = {
        ...formAgg,
        price: formAgg.price,
      };

      updatedAggregators.push(updatedAggregator);
    }
  });

  return updatedAggregators;
};

export default findUpdatedAggregators;

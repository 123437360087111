import { ReactElement } from 'react';
import InfoIcon from 'src/assets/images/InfoTooltip';
import { Tooltip as MuiTooltip } from '@mui/material';
import { StyledInfoIcon, TooltipStyleOverride } from './InfoTooltip.styles';

interface ITooltip {
  title: string;
}

const InfoTooltip = ({ title }: ITooltip): ReactElement => {
  return (
    <>
      <MuiTooltip
        arrow
        placement="top-start"
        title={title}
        componentsProps={TooltipStyleOverride}
        data-testid="info-tooltip"
      >
        <StyledInfoIcon>
          <InfoIcon />
        </StyledInfoIcon>
      </MuiTooltip>
    </>
  );
};

export default InfoTooltip;

import { API_URL } from '../constants';
import { fetch } from '../fetchWrapper/fetch';

export const updateMenuItem = (itemId: string, requestBody: FormData): Promise<any> => {
  const url = `${API_URL}/items/${itemId}`;
  return fetch(url, {
    method: 'PUT',
    body: requestBody,
  });
};

import { APIresponse } from 'src/helpers';
import { API_URL } from '../constants';
import { fetch } from '../fetchWrapper/fetch';

export const deleteModifierGroup = (itemId: string, modifierGroupId: string): Promise<unknown | APIresponse> => {
  const url = `${API_URL}/items/${itemId}/modifier-groups/${modifierGroupId}`;
  return fetch(url, {
    method: 'DELETE',
  });
};

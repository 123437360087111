import { theme } from 'src/assets/theme/theme';
import { styled, Typography, Box, TextField, FormControl } from '@mui/material';

export const StyledInput = styled(TextField)`
  max-width: 500px;
  width: 100%;
  margin-bottom: ${theme.spacing(5)};

  ${(props) => props.theme.breakpoints.down('ms')} {
    width: 100%;
  }
`;

export const StyledImage = styled('img')`
  height: 100%;
  object-fit: cover;
  width: 33vw;
`;

export const Container = styled(Box)`
  display: flex;
  justify-content: center;
  height: 100vh;
  overflow: hidden;
`;

export const FormContent = styled(Box)`
  width: 150vw;
  padding: ${theme.spacing(6)};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const StyledTitle = styled(Typography)`
  max-width: ${theme.spacing(125)};
  width: 100%;
  margin-bottom: ${theme.spacing(8)};
  color: ${theme.palette.primary.main};
`;

export const StyledSubtitle = styled(Typography)`
  max-width: ${theme.spacing(125)};
  width: 100%;
  margin-bottom: ${theme.spacing(4)};
  font-weight: 600;
  color: ${theme.palette.primary.main};
`;

export const ImageContainer = styled(Box)`
  position: relative;
  width: 50vw;

  ${(props) => props.theme.breakpoints.down('dl')} {
    display: none;
  }
`;

export const StyledFormControl = styled(FormControl)`
  max-width: ${theme.spacing(125)};
  width: -webkit-fill-available;
  margin-bottom: ${theme.spacing(2)};
`;

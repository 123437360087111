import { API_URL } from '../constants';

export const importCSV = async (token: string, brandId: string, payload: FormData): Promise<any> => {
  const uri = `${API_URL}/recipes/import`;
  return fetch(uri, {
    method: 'POST',
    body: payload,
    cache: 'reload',
    headers: {
      Authorization: `Bearer ${token}`,
      'brand-id': brandId,
    },
  });
};

import { BrowserRouter as Router, Route, Switch, useHistory } from 'react-router-dom';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { LoginCallback, SecureRoute, Security } from '@okta/okta-react';
import NotFound from './components/NotFound';
import NotificationComponent, { NotificationDisplayBoundary } from './components/Notification';
import { MenusProvider } from './contexts/menus';
import { Welcome } from 'src/pages/Welcome';
import Layout from './components/Layout/Layout';
import { RecipesProvider } from './contexts/recipes';
import { ItemsProvider } from './contexts/items';

declare global {
  interface Window {
    Cypress: any;
  }
}

const CustomLoginCallback = () => {
  const history = useHistory();
  return (
    <LoginCallback
      errorComponent={() => {
        history.push('/login');
        return null;
      }}
    />
  );
};

const App = () => {
  return (
    <NotificationDisplayBoundary>
      <MenusProvider>
        <RecipesProvider>
          <ItemsProvider>
            <NotificationComponent />
            <Switch>
              <Route exact path="/login" component={Welcome} />
              <Route path="/login/callback" component={CustomLoginCallback} />
              <SecureRoute path="/" component={Layout} />
              <Route component={NotFound} />
            </Switch>
          </ItemsProvider>
        </RecipesProvider>
      </MenusProvider>
    </NotificationDisplayBoundary>
  );
};

const AppOkta = () => {
  const history = useHistory();
  const oktaAuth = new OktaAuth({
    issuer: process.env.REACT_APP_OKTA_ISSUER,
    clientId: process.env.REACT_APP_OKTA_CLIENTID,
    redirectUri: window.location.origin + '/login/callback',
    postLogoutRedirectUri: window.location.origin,
    scopes: ['openid', 'profile', 'email'],
    pkce: true,
  });
  /* istanbul ignore next */
  const restoreOriginalUri = async (_oktaAuth: object, originalUri: string) => {
    const returnURL: string = originalUri || '/';
    history.replace(toRelativeUrl(returnURL, window.location.origin));
  };
  const onAuthRequired = () => {
    const previousAuthState = oktaAuth.authStateManager.getPreviousAuthState();
    if (!previousAuthState || !previousAuthState.isAuthenticated) {
      history.replace('/login');
    }
  };

  return window.Cypress ? (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <App />
    </Security>
  ) : (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri} onAuthRequired={onAuthRequired}>
      <App />
    </Security>
  );
};

const RouterApp = () => {
  return (
    <Router>
      <AppOkta />
    </Router>
  );
};

export default RouterApp;

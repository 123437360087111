import { API_URL } from '../constants';
import { fetch } from '../fetchWrapper/fetch';

export const fetchChannelsVenues = async (): Promise<any> => {
  const url = `${API_URL}/channels`;
  return fetch(url, {
    method: 'GET',
    cache: 'reload',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  });
};

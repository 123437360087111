import { Autocomplete, RadioGroup, styled } from '@mui/material';
import { theme } from 'src/assets/theme/theme';

export const StyledRadioGroup = styled(RadioGroup)`
  margin-top: ${theme.spacing(6)};
`;

export const StyledLabelList = styled('li')`
  border: solid 1px black;
  border-top: none;
  position: relative;
  &:first-of-type {
    border-top: solid 1px black;
  }
`;

export const StyledAutocomplete = styled(Autocomplete)`
  margin-top: ${theme.spacing(5)};
`;

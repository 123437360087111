import { ReactElement, MouseEvent, useState } from 'react';
import { Box, Link, ListItemAvatar, ListItemText, ListItemIcon, Grid, MenuItem } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { StyledButton, StyledLabel, StyledArrowIcon, StyledAvatar, StyledMenu } from './DropdownMenu.styles';
import { getDefaultLogo } from './utilities';
import { useGeographiesContext } from 'src/contexts/geographies';

type Option = {
  id: string;
  name: string;
  logoUrl?: string;
};

interface IDropdownMenuProps {
  options: Option[];
  selectedOption: Option;
  onOptionClick: (id: string) => void;
  onAvatarClick?: () => void;
  theme?: 'dark' | 'light';
  avatarSize?: 'small' | 'medium' | 'large';
}

const DropdownMenu = ({
  options,
  selectedOption,
  onOptionClick,
  onAvatarClick = () => {},
  avatarSize = 'medium',
  theme = 'dark',
}: IDropdownMenuProps): ReactElement => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { refetch } = useGeographiesContext();

  const handleDropdownMenuClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDropdownMenuClose = () => {
    setAnchorEl(null);
  };

  const handleOptionClick = (id) => {
    if (id !== selectedOption?.id) {
      onOptionClick(id);
      refetch();
    }

    setAnchorEl(null);
  };

  return (
    <>
      <Box data-testid="dropdown-menu">
        <Grid container direction="row" justifyContent="space-between" alignItems="center" flexWrap="nowrap">
          <Grid item flexGrow={0} flexShrink={1}>
            <Box component={Link} onClick={onAvatarClick} sx={{ cursor: 'pointer' }}>
              <StyledAvatar
                data-testid="dropdown-menu-icon"
                alt="DropdownMenu icon"
                avatarsize={avatarSize}
                src={selectedOption?.logoUrl || getDefaultLogo(selectedOption?.name)}
              />
            </Box>
          </Grid>
          <Grid item flexGrow={1} flexShrink={0}>
            <StyledButton
              id="dropdown-menu-button"
              data-testid="dropdown-menu-button"
              aria-controls={open ? 'dropdown-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              variant="contained"
              disableElevation
              onClick={handleDropdownMenuClick}
            >
              <Grid container direction="row" alignItems="center" justifyContent="space-between">
                <StyledLabel variant="sText" themeprops={theme} data-testid="dropdown-menu-button-label">
                  {selectedOption?.name}
                </StyledLabel>
                <StyledArrowIcon fontSize="large" themeprops={theme} />
              </Grid>
            </StyledButton>
          </Grid>
        </Grid>
      </Box>
      <StyledMenu
        id="dropdown-menu-list"
        data-testid="dropdown-menu-list"
        variant="menu"
        anchorEl={anchorEl}
        open={open}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        MenuListProps={{ 'aria-labelledby': 'dropdown-menu-list' }}
        PaperProps={{ elevation: 0, variant: 'outlined' }}
        onClose={handleDropdownMenuClose}
      >
        {options.map((option, index) => (
          <MenuItem
            data-testid={`dropdown-menu-list-item-${index}`}
            key={option?.id}
            onClick={() => handleOptionClick(option?.id)}
          >
            <Grid container direction="row" justifyContent="space-between" alignItems="center" flexWrap="nowrap">
              <Grid item>
                <Grid container direction="row" alignItems="center" flexWrap="nowrap">
                  <ListItemAvatar>
                    <StyledAvatar
                      data-testid="dropdown-menu-icon"
                      alt="DropdownMenu icon"
                      avatarsize="small"
                      src={option?.logoUrl || getDefaultLogo(option?.name)}
                    />
                  </ListItemAvatar>
                  <ListItemText primary={option.name} primaryTypographyProps={{ variant: 'strongText' }} />
                </Grid>
              </Grid>
              {option?.id === selectedOption?.id && (
                <Grid item>
                  <ListItemIcon data-testid="dropdown-menu-list-item-icon">
                    <CheckIcon />
                  </ListItemIcon>
                </Grid>
              )}
            </Grid>
          </MenuItem>
        ))}
      </StyledMenu>
    </>
  );
};

export default DropdownMenu;

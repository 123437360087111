import { styled, Box, Typography, BoxProps } from '@mui/material';
import colors from 'src/assets/theme/colors';
import { theme } from 'src/assets/theme/theme';

interface SectionLogoProps extends BoxProps {
  src?: string;
  alt?: string;
}

interface StyledContentBoxProps {
  type: string;
}

export const StyledContentBox = styled(Box)<StyledContentBoxProps>`
  width: 100%;
  height: ${theme.spacing(24.5)};
  border: 1px solid ${colors.AZT_Light};
  border-radius: ${theme.spacing(2)};
  padding: ${theme.spacing(3)};
  margin-bottom: ${theme.spacing(4)};
  display: flex;
  align-items: center;
  :hover {
    background-color: ${(props) => (props.type === 'venues' ? colors.AZT_Sky : '')};
  }
`;

export const SectionLogo = styled(Box)<SectionLogoProps>`
  height: 100%;
  width: auto;
  display: inline-block;
  border-radius: ${theme.spacing(2)};
  flex-basis: content;
  flex-shrink: 1;
  margin-right: ${theme.spacing(3)};
`;

export const SectionContent = styled(Box)`
  flex-grow: 1;
`;

export const StyledTitle = styled(Typography)`
  color: ${colors.AZT_Dark};
`;

export const TitleBox = styled(Box)`
  display: flex;
  margin-bottom: ${theme.spacing(4)};
  align-items: center;
  margin-top: ${theme.spacing(10)};
`;

export const StyledContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100vw;
  max-width: ${theme.spacing(280)};
  padding-right: ${theme.spacing(5)};
  margin-left: ${theme.spacing(5)};
  ${(props) => props.theme.breakpoints.up('md')} {
    margin-left: ${theme.spacing(72)};
  }
`;

import { IAggregatorPayload, IPriceBandPayload } from 'src/api/types';
import { IFormPriceBand } from '../../types';

const formatPriceBandPayload = (priceBand: IFormPriceBand, taxRate: number): IPriceBandPayload => {
  return {
    venue_id: priceBand.priceBandId,
    external_systems: priceBand.aggregators
      .filter((aggregator) => aggregator.price !== '')
      .map(
        (aggregator): IAggregatorPayload => ({
          external_system_id: aggregator.aggregatorId,
          base_price_with_tax: parseFloat(aggregator.price),
          tax_rate: taxRate,
        }),
      ),
  };
};

export default formatPriceBandPayload;

import { FC, ReactElement } from 'react';
import { Typography, useMediaQuery } from '@mui/material';
import { theme } from 'src/assets/theme/theme';
import { StyledButton, StyledContainer, StyledContent, StyledSectionBox, StyledSvgIcon } from './EmptyState.style';

interface EmptyStateProps {
  type?: string;
  emptySectionText?: string;
  buttonText?: string;
  Icon?: FC;
  onClick?: () => void;
}

const EmptyState = ({ emptySectionText, onClick, buttonText, type, Icon }: EmptyStateProps): ReactElement => {
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <StyledSectionBox>
      <StyledContainer>
        <StyledSvgIcon>{Icon && <Icon />}</StyledSvgIcon>
        <StyledContent
          variant={isMdUp ? 'lText' : 'mText'}
          align="center"
          variantMapping={{ lText: 'h3', mText: 'h3' }}
        >
          {emptySectionText}
        </StyledContent>
        {buttonText && (
          <StyledButton data-testid={`${type}-cta`} onClick={onClick} aria-label={buttonText}>
            <Typography variant={isMdUp ? 'mText' : 'sText'}>
              <Typography variant="strongText">{buttonText}</Typography>
            </Typography>
          </StyledButton>
        )}
      </StyledContainer>
    </StyledSectionBox>
  );
};

export default EmptyState;

import { IMenuItem } from 'src/types/menu.types';

export const mapItemsToTable = (items: IMenuItem[]) => {
  return items
    .filter((item) => !item.parentItemId)
    .map((item) => {
      return {
        id: item?.itemId,
        image: item?.image,
        name: item?.name,
        description: item?.description,
        price: `£${item?.prices.defaultPrice}`,
        type: item?.type.toLowerCase(),
      };
    });
};

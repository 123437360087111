import { theme } from 'src/assets/theme/theme';
import colors from 'src/assets/theme/colors';
import { styled, Typography, Box, TextField } from '@mui/material';

export const StyledInput = styled(TextField)`
  max-width: 500px;
  width: 100%;
  margin-bottom: ${theme.spacing(5)};

  ${(props) => props.theme.breakpoints.down('ms')} {
    width: 100%;
  }
`;

export const StyledText = styled(Typography)`
  max-width: ${theme.spacing(125)};
  width: 100%;
  margin-bottom: ${theme.spacing(8)};
  color: ${theme.palette.primary.main};
`;

export const StyledHiddenInput = styled('input')`
  opacity: 0;
  pointer-events: none;
  appearance: none;
  width: 0;
  height: 0;
`;
export const StyledImageUploadContainer = styled(Box)`
  max-width: ${theme.spacing(125)};
  width: 100%;
  height: ${theme.spacing(32)};
  margin-bottom: ${theme.spacing(8)};
  margin-top: ${theme.spacing(2)};
`;
export const StyledImageUpload = styled(Box)`
  width: 100%;
  height: 100%;
  border: 1px dashed ${colors.AZT_Dark};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 8px;
  &:hover {
    cursor: pointer;
    border: 1px solid ${colors.AZT_Dark};
  }
`;

export const StyledUploadedImage = styled('img')`
  width: ${theme.spacing(57)};
  height: ${theme.spacing(32)};
  object-fit: cover;
  border-radius: 8px;
  margin-right: ${theme.spacing(4.5)};
`;

export const StyledUploadedImageContainer = styled(Box)`
  display: flex;
`;

export const StyledUploadOptionContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;

export const StyledUploadOption = styled(Box)`
  display: flex;
  & :last-child {
    margin-left: ${theme.spacing(2.5)};
  }
  &:hover {
    cursor: pointer;
  }
`;

export const StyledImageText = styled(Box)`
  max-width: ${theme.spacing(125)};
  width: 100%;
`;

export const StyledForm = styled('form')`
  padding: 0 ${theme.spacing(6)} 0 ${theme.spacing(6)};
`;

import colors from '../colors';

const accordion = {
  MuiAccordion: {
    styleOverrides: {
      root: {
        backgroundColor: `${colors.navy}`,
        borderRadius: 0,
        '::before': {
          backgroundColor: `${colors.navy}`,
        },
      },
    },
  },
};

export default accordion;

import { useState } from 'react';
import { ItemsContext } from './index';
import { IMenuItem } from 'src/types/menu.types';
import { INewItemData } from 'src/types/items.types';
import { updateItemDetails } from './updateItemDetails/updateItemDetails';

export const ItemsProvider = ({ children }) => {
  const [items, setItems] = useState<IMenuItem[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const addItemToItems = (newMenuItem: IMenuItem): void => {
    const newItems = [...items, newMenuItem];
    const sortedItems = newItems.sort((a, b) => a.name.localeCompare(b.name));

    setItems(sortedItems);
  };

  const addItems = (newItems: IMenuItem[]): void => setItems(newItems);

  const updateItem = (itemId: string, newItemData: INewItemData) =>
    setItems(updateItemDetails(items, itemId, newItemData));

  return (
    <ItemsContext.Provider value={{ items, addItems, isLoading, setIsLoading, addItemToItems, updateItem }}>
      {children}
    </ItemsContext.Provider>
  );
};

import { ReactElement } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Container, FormContent, ImageContainer, StyledImage, CloseButton, DialogModal } from './ModalWrapper.styles';

export interface IModalWrapper {
  variant: string;
  closeModal?: any;
  open: boolean;
  children: ReactElement;
  image: string;
  imageAlt: string;
}

export const ModalWrapper = ({ closeModal, open, variant, children, image, imageAlt }: IModalWrapper): ReactElement => {
  return (
    <DialogModal fullScreen open={open}>
      <CloseButton data-testid={`close-${variant}-menu-modal`} aria-label="close" onClick={closeModal}>
        <CloseIcon />
      </CloseButton>

      <Container>
        <ImageContainer>
          <StyledImage loading="lazy" src={image} alt={imageAlt} />
        </ImageContainer>
        <FormContent>{children}</FormContent>
      </Container>
    </DialogModal>
  );
};

import { FormGroup, Typography, FormControlLabel, Checkbox, Grid } from '@mui/material';
import { ReactElement, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { MainButton, useNotificationOutlet } from 'src/components';
import { Container, ContainerGrid, StyledFormControl, StyledTitle, TimeField } from './AvailabilityModal.styles';
import { checkIfStartTimeEarlyThanEndTime } from 'src/helpers/validateTime/validateTime';
import Prompt, { StyledDialogAction } from '../Prompt';
import { useMenusContext } from 'src/contexts/menus';
import { updateMenuSchedule } from 'src/api/updateMenuSchedule/updateMenuSchedule';
import { useOktaAuth } from '@okta/okta-react';

export interface IAvailabilityModalProps {
  isOpen: boolean;
  closePrompt?: () => void;
  menuId: string;
}

export interface IFormatedMenuAvailability {
  dayOfWeek: string;
  startTime: string;
  endTime: string;
  isChecked: boolean;
}
const weekDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

const AvailabilityModal = ({ isOpen, closePrompt, menuId }: IAvailabilityModalProps): ReactElement => {
  const createNotification = useNotificationOutlet();
  const { getAMenuById, updateAMenuSchedule } = useMenusContext();
  const currentMenu = getAMenuById(menuId);
  const { authState } = useOktaAuth();
  const accessToken = authState?.accessToken?.accessToken;

  const initialValues: IFormatedMenuAvailability[] = useMemo(() => {
    const initialData: IFormatedMenuAvailability[] = weekDays.map((day) => {
      const currentAvailability = currentMenu.schedules.find((currentDay) => currentDay.dayOfWeek === day);
      return {
        dayOfWeek: day,
        startTime: currentAvailability?.startTime || '00:00',
        endTime: currentAvailability?.endTime || '23:59',
        isChecked: currentAvailability ? true : false,
      };
    });
    return initialData;
  }, [currentMenu]);

  const {
    watch,
    control,
    handleSubmit,
    getValues,
    formState: { isSubmitting },
  } = useForm<IFormatedMenuAvailability[]>({
    defaultValues: initialValues,
    mode: 'onChange',
    reValidateMode: 'onSubmit',
  });

  const currentValues = getValues();

  const successNotification = {
    severity: 'success',
    message: (
      <>
        You have successfully updated menu availability for{' '}
        <Typography variant="strongText">{currentMenu.name}</Typography>
      </>
    ),
  };
  const errorNotification = {
    severity: 'error',
    message: (
      <>
        Something went wrong whilst updating the menu availability for{' '}
        <Typography variant="strongText">{currentMenu.name}</Typography>
      </>
    ),
  };

  const onSubmit = async (data: IFormatedMenuAvailability[]) => {
    if (isSubmitting) return;
    const result = data.filter((obj) => obj.isChecked === true);
    const payload = {
      schedules: result.map((item) => ({
        day: item.dayOfWeek,
        start_time_hhmm: item.startTime.replace(':', ''),
        end_time_hhmm: item.endTime.replace(':', ''),
      })),
    };
    updateMenuSchedule({
      token: accessToken,
      payload,
      id: menuId,
    })
      .then((response) => {
        if (response.ok) {
          createNotification(successNotification);
          updateAMenuSchedule(
            menuId,
            result.map(({ dayOfWeek, startTime, endTime }) => ({ dayOfWeek, startTime, endTime })),
          );
          closePrompt();
        } else {
          createNotification(errorNotification);
        }
      })
      .catch(() => {
        createNotification(errorNotification);
      });
  };

  return (
    <Prompt open={isOpen} testId={'update-menu-availability'} closePrompt={closePrompt}>
      <Container>
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <StyledTitle variant="xlText">
            <Typography variant="strongText">Update menu availability</Typography>
          </StyledTitle>
          <StyledFormControl>
            <FormGroup>
              {weekDays.map((day, index) => (
                <ContainerGrid container columns={{ xs: 4, md: 12, sm: 8 }} key={day}>
                  <Grid item xs={4}>
                    <FormControlLabel
                      name={`${index}.isChecked`}
                      label={<Typography variant="strongText">{day}</Typography>}
                      data-testid={`${day}label`}
                      defaultValue={initialValues[index].dayOfWeek}
                      control={
                        <Controller
                          name={`${index}.isChecked`}
                          control={control}
                          render={({ field }) => (
                            <Checkbox
                              defaultChecked={field.value ? true : false}
                              onChange={field.onChange}
                              data-testid={`${day}checkbox`}
                            />
                          )}
                        />
                      }
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <Controller
                      name={`${index}.startTime`}
                      control={control}
                      rules={{
                        validate: {
                          isStartTimeEmpty: () =>
                            !currentValues[index].isChecked ||
                            watch(`${index}.startTime`).length > 0 ||
                            'Start Time is empty',
                          isLaterThanEndTime: () =>
                            checkIfStartTimeEarlyThanEndTime(watch(`${index}.startTime`), watch(`${index}.endTime`)) ||
                            'Start Time is Later than End Time',
                        },
                      }}
                      render={({ field: { value, onChange, ref }, fieldState: { error } }) => (
                        <TimeField
                          data-testid={`${day}startTime`}
                          label="Start Time"
                          type={'time'}
                          InputLabelProps={{ shrink: true }}
                          disabled={!currentValues[index].isChecked}
                          error={!!error}
                          defaultValue={value}
                          onChange={onChange}
                          inputRef={ref}
                        />
                      )}
                    />
                    <Controller
                      name={`${index}.endTime`}
                      control={control}
                      rules={{
                        validate: {
                          isEndTimeEmpty: () =>
                            !currentValues[index].isChecked ||
                            watch(`${index}.endTime`).length > 0 ||
                            'End Time is empty',
                        },
                      }}
                      render={({ field: { value, ref, onChange }, fieldState: { error } }) => (
                        <TimeField
                          data-testid={`${day}endTime`}
                          label="End Time"
                          type={'time'}
                          InputLabelProps={{ shrink: true }}
                          disabled={!watch(`${index}.isChecked`)}
                          defaultValue={value}
                          error={!!error}
                          inputRef={ref}
                          onChange={onChange}
                        />
                      )}
                    />
                  </Grid>
                </ContainerGrid>
              ))}
            </FormGroup>
            <StyledDialogAction>
              <MainButton type="submit" text={'Save'} loading={isSubmitting} size="medium" />
            </StyledDialogAction>
          </StyledFormControl>
        </form>
      </Container>
    </Prompt>
  );
};

export default AvailabilityModal;

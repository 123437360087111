import { ReactElement, useState } from 'react';
import SectionModal from 'src/components/SectionModal';
import MenuItemSection from '../MenuSection';
import { IMenu } from 'src/types/menu.types';
import Divider from '../Divider';
import { StyledCardContainer } from '../MenuSection/MenuSection.styles';
import Card, { NewItemCard } from '../Card';
import { Typography } from '@mui/material';
import EmptyState from '../EmptyState';
import Burger from 'src/assets/images/Burger';
import { StyledInfoTooltipLogoWrapper } from './MenuSectionItems.styles';
import InfoTooltip from '../InfoTooltip';
import { StyledTitle } from '../Divider/Divider.styles';
import { IMenuItem } from 'src/types/menu.types';
import CreateNewItemPrompt from './CreateNewItemPrompt/CreateNewItemPrompt';

const MenuItemCardContainer = ({
  menuId,
  items,
  newItemButtonTitle,
  handleNewItemClick,
}: {
  menuId: string;
  items: IMenuItem[];
  newItemButtonTitle: string;
  handleNewItemClick: () => void;
}) => (
  <StyledCardContainer>
    {items.map((menuItem) => {
      const { name, image, itemId } = menuItem;
      return <Card text={name} id={menuId} key={itemId} urlImage={image} itemId={itemId} variant="item" />;
    })}
    <NewItemCard onClick={handleNewItemClick} data-testid={'add-item-to-Modifiers'}>
      <Typography>{newItemButtonTitle}</Typography>
    </NewItemCard>
  </StyledCardContainer>
);

const MenuSectionItems = ({ menu }: { menu: IMenu }): ReactElement => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [isNewItemPromptVisible, setIsNewItemPromptVisible] = useState<boolean>(false);
  const [sectionId, setSectionId] = useState<string>(null);

  const showCreateSectionModal = (): void => {
    setIsModalVisible(true);
  };

  const showCreateNewItemPrompt = (secId?: string): void => {
    if (secId) setSectionId(secId);
    setIsNewItemPromptVisible(true);
  };

  const closeCreateSectionModal = (): void => {
    setIsModalVisible(false);
  };

  const { menuId } = menu;

  return (
    <>
      {menu && (
        <>
          <SectionModal
            title="Create new section"
            closeModal={closeCreateSectionModal}
            open={isModalVisible}
            items={menu.uncategorised}
            variant="create"
            menuId={menuId}
          />
          <CreateNewItemPrompt
            closePrompt={() => {
              setIsNewItemPromptVisible(false);
            }}
            open={isNewItemPromptVisible}
            sectionId={sectionId}
          />
          {(menu.uncategorised.length > 0 || menu.sections.length === 0) && (
            <>
              <Divider onClickAdd={showCreateSectionModal} title="Uncategorised" />
              {menu.uncategorised?.length > 0 ? (
                <MenuItemCardContainer
                  items={menu.uncategorised}
                  menuId={menuId}
                  newItemButtonTitle="New menu item"
                  handleNewItemClick={() => showCreateNewItemPrompt()}
                />
              ) : (
                <EmptyState
                  emptySectionText="Add your first menu item"
                  onClick={() => showCreateNewItemPrompt()}
                  buttonText="New menu item"
                  Icon={Burger}
                />
              )}
            </>
          )}
          {menu.sections.map((section, index) => (
            <MenuItemSection
              key={section.sectionId}
              section={section}
              menuId={menuId}
              onClickNewItem={showCreateNewItemPrompt}
              handleSectionModal={showCreateSectionModal}
              upDisplayOrder={menu.sections[index - 1]?.displayOrder}
              downDisplayOrder={menu.sections[index + 1]?.displayOrder}
            />
          ))}

          {menu.modifiers.length > 0 && (
            <>
              <Divider title="Modifiers">
                <StyledTitle variant="mText" variantMapping={{ mText: 'h2' }}>
                  Modifiers
                  <StyledInfoTooltipLogoWrapper>
                    <InfoTooltip title="Modifiers are saved and editable but won’t be published in the menu as separate menu items." />
                  </StyledInfoTooltipLogoWrapper>
                </StyledTitle>
              </Divider>
              {menu.modifiers?.length > 0 && (
                <MenuItemCardContainer
                  items={menu.modifiers}
                  menuId={menuId}
                  newItemButtonTitle="New modifier"
                  handleNewItemClick={() => showCreateNewItemPrompt()}
                />
              )}
            </>
          )}
        </>
      )}
    </>
  );
};
export default MenuSectionItems;

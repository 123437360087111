import { SvgIcon } from '@mui/material';

const ErrorNotificationIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M6.455 19L2 22.5V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V18C22 18.2652 21.8946 18.5196 21.7071 18.7071C21.5196 18.8946 21.2652 19 21 19H6.455ZM4 18.385L5.763 17H20V5H4V18.385V18.385ZM11 13H13V15H11V13ZM11 7H13V12H11V7Z"
          fill={props.colour}
        />
      </svg>
    </SvgIcon>
  );
};

const SuccessNotificationIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <svg width="27" height="24" viewBox="0 0 27 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.76461 18.5931L5.70204 21L6.41386 16.7827C4.72526 15.3227 3.7832 13.2947 3.7832 11.0526C3.7832 6.60529 7.48992 3 13.3774 3C19.2648 3 22.9716 6.60529 22.9716 11.0526C22.9716 15.5 19.2648 19.1053 13.3774 19.1053C12.0579 19.1053 10.8479 18.9242 9.76461 18.5931V18.5931Z"
          stroke={props.colour}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.1792 9H16.5753"
          stroke={props.colour}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.1792 13H13.3773"
          stroke={props.colour}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};

export { ErrorNotificationIcon, SuccessNotificationIcon };

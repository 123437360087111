import { RadioGroup, styled } from '@mui/material';
import { theme } from 'src/assets/theme/theme';

export const StyledRadioButtonGroupBox = styled(RadioGroup)`
  display: flex;
  flex-wrap: wrap;
  gap: ${theme.spacing(10)};
  margin-top: ${theme.spacing(4)};
  flex-direction: row;
`;

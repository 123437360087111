import { styled } from '@mui/material';
import colors from 'src/assets/theme/colors';

export const StyledInfoIcon = styled('div')`
  width: min-content;
`;

export const TooltipStyleOverride = {
  tooltip: {
    sx: {
      bgcolor: `${colors.navy}`,
      '& .MuiTooltip-arrow': {
        color: `${colors.navy}`,
      },
    },
  },
};

export const StorybookContainer = styled('span')`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25vw;
`;

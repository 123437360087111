import { styled, Box, Collapse } from '@mui/material';

export const NotificationWrapper = styled(Box)`
  ${(props) => props.theme.breakpoints.down('md')} {
    .MuiAlert-icon {
      display: none;
    }
  }
`;

export const StyledCollapse = styled(Collapse)`
  width: 100%;
`;

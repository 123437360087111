import { API_URL } from '../constants';
import { fetch } from '../fetchWrapper/fetch';

export const deleteMenu = (menuId: string): Promise<any> => {
  return fetch(`${API_URL}/menus/${menuId}`, {
    method: 'DELETE',
  }).then((res) => {
    if (res.status === 400) {
      throw new Error();
    }
    if (res.status === 204) return {};
  });
};

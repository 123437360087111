import colors from './colors';

const palette = {
  common: {
    white: colors.white,
  },
  primary: {
    main: colors.navy,
  },
  secondary: {
    main: colors.lightBlue,
  },
  error: {
    main: colors.violetRed,
  },
  warning: {
    main: colors.navy,
  },
  success: {
    main: colors.navy,
  },
  info: {
    main: colors.navy,
  },
};
export default palette;

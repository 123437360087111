import { FC, ReactElement } from 'react';
import { StyledTitle, TitleBox, StyledContentBox, SectionLogo, SectionContent } from './DefaultSection.styles';
import { Typography } from '@mui/material';
import type { DefaultSectionProp } from 'src/helpers';
import EmptyState from '../EmptyState';

import JustEatLogo from 'src/assets/images/justeat-logo.svg';
import UberEatsLogo from 'src/assets/images/ubereats-logo.png';
import deliverooLogo from 'src/assets/images/deliveroo-logo.png';
import venuesPlaceholderLogo from 'src/assets/images/venuesPlaceholderLogo.png';

interface DefaultSectionProps {
  sectionName?: string;
  content: DefaultSectionProp[];
  emptySectionText?: string;
  Icon?: FC;
  type?: string;
}

const DefaultSection = ({ sectionName, content, emptySectionText, Icon, type }: DefaultSectionProps): ReactElement => {
  return (
    <>
      {sectionName && (
        <TitleBox>
          <StyledTitle variant="xxlText" variantMapping={{ xxlText: 'h2' }}>
            <Typography variant="strongText">{sectionName}</Typography>
          </StyledTitle>
        </TitleBox>
      )}
      {content && content.length ? (
        content.map((item) => (
          <StyledContentBox key={item.id} type={type}>
            {item.name === 'Just Eat' && <SectionLogo component="img" src={JustEatLogo} alt={item.name} />}
            {item.name === 'Uber Eats' && <SectionLogo component="img" src={UberEatsLogo} alt={item.name} />}
            {item.name === 'Deliveroo' && <SectionLogo component="img" src={deliverooLogo} alt={item.name} />}
            {type === 'venues' && (
              <SectionLogo component="img" src={item.image ? item.image : venuesPlaceholderLogo} alt={item.name} />
            )}
            <SectionContent>
              <Typography variant="lText">
                <Typography variant="strongText">{item.name}</Typography>
              </Typography>
              <Typography variant="sText">{item.description}</Typography>
            </SectionContent>
          </StyledContentBox>
        ))
      ) : (
        <EmptyState emptySectionText={emptySectionText} type={type} Icon={Icon} />
      )}
    </>
  );
};

export default DefaultSection;

import { theme } from 'src/assets/theme/theme';
import { styled, TextField, Autocomplete } from '@mui/material';

export const StyledInput = styled(TextField)`
  width: 100%;

  margin-bottom: ${(props) => (props.type === 'modifier' ? theme.spacing(0) : theme.spacing(5))};

  ${(props) => props.theme.breakpoints.down('ms')} {
    width: 100%;
  }

  & .MuiFormHelperText-root {
    ${(props) =>
      props.error &&
      props.type === 'modifier' && {
        position: 'absolute',
        bottom: theme.spacing(-5),
        width: 'max-content',
      }}
`;

export const StyledAutocomplete = styled(Autocomplete)`
  width: 100%;
  margin-bottom: ${theme.spacing(5)};
`;

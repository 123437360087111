import { IMenuItem } from 'src/types/menu.types';
import { INewItemData } from 'src/types/items.types';

export const updateItemDetails = (items: IMenuItem[], itemId: string, newItemData: INewItemData): IMenuItem[] => {
  const { name, description, defaultPrice, image } = newItemData;

  return items.map((item) => {
    if (item.itemId === itemId) {
      const newItem: IMenuItem = { ...item };

      if (name) {
        newItem.name = name;
      }
      if (description) {
        newItem.description = description;
      }
      if (defaultPrice) {
        newItem.prices.defaultPrice = defaultPrice;
      }
      if (image) {
        newItem.image = image;
      }
      return newItem;
    }
    return item;
  });
};

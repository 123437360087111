import type { IMenuResponse, IItem } from 'src/types';

const recipe = {
  recipe_id: 'uuid',
  name: 'Vegetable Gyoza',
  description: 'Vegetable Gyoza',
  is_vegan: false,
  is_vegetarian: false,
  contains_alcohol: false,
  nutritional_information: [],
  allergens_information: [],
  create_date: new Date('2022-03-30 12:35:06.690682'),
  update_date: new Date('2022-03-30 12:35:06.690682'),
  create_user_id: 'uid',
  update_user_id: 'uid',
};

const asset = {
  name: 'menu_image.jpeg',
  asset_id: '677d0d60-3a11-4df8-8307-752d2af5f704',
  create_date: new Date('2022-04-20T14:32:02.759Z'),
  update_date: new Date('2022-04-20T14:32:02.759Z'),
  asset_variants: [
    {
      url: 'https://azzurri-product-images-dev.s3.eu-west-1.amazonaws.com/677d0d60-3a11-4df8-8307-752d2af5f704',
      asset_id: '677d0d60-3a11-4df8-8307-752d2af5f704',
    },
  ],
  create_user_id: '00uiaa1va4oHBCbJQ696',
  update_user_id: '00uiaa1va4oHBCbJQ696',
};

export const burger: IItem = {
  item_id: 'burger',
  name: 'Burger',
  description: 'very yummy',
  currency: 'GBP',
  price: 1.5,
  assets: [asset],
  plu: '1234',
  recipes: [recipe],
  tax_rate: 20,
  type: 'STANDARD',
  is_standalone: true,
  create_date: new Date('2022-04-20T14:32:02.759Z'),
  update_date: new Date('2022-04-20T14:32:02.759Z'),
  create_user_id: '00uia83yqD9COeLn6696',
  update_user_id: '00uia83yqD9COeLn6696',
};

export const hotdog: IItem = {
  item_id: 'hotdog',
  name: 'Hot Dog',
  description: 'very yummy',
  currency: 'GBP',
  price: 1.5,
  assets: [asset],
  plu: '1234',
  recipes: [recipe],
  tax_rate: 20,
  type: 'STANDARD',
  is_standalone: true,
  create_date: new Date('2022-04-20T14:40:02.891Z'),
  update_date: new Date('2022-04-20T14:40:02.891Z'),
  create_user_id: '00uia83yqD9COeLn6696',
  update_user_id: '00uia83yqD9COeLn6696',
};

export const ketchup: IItem = {
  ...hotdog,
  ...{ item_id: 'ketchup', name: 'Ketchup', type: 'MODIFIER', is_standalone: false },
};

export const steak: IItem = {
  ...burger,
  ...{ item_id: 'steak', name: 'Steak' },
};

export const margarita: IItem = {
  ...hotdog,
  ...{ item_id: 'margarita', name: 'Margherita' },
};

export const menuDetail: IMenuResponse = {
  type: 'menus',
  data: [
    {
      menu_id: 'c14d4885-342f-4c3b-bc3f-fde244d90ed3',
      name: "(Do not delete plz) Shuman's Summer Menu",
      description: 'blah blah',
      seo_description: 'No seo description',
      create_date: new Date('2022-04-20T14:40:02.891Z'),
      update_date: new Date('2022-04-20T14:40:02.891Z'),
      create_user_id: 'uid1234',
      update_user_id: '00uiaa1va4oHBCbJQ696',
      assets: [asset],
      sections: [
        {
          name: 'Grill section',
          items: ['burger', 'steak'],
          section_id: '3514c6f2-df34-49c5-a506-bcdeede2e81d',
          create_date: new Date('2022-04-20T14:40:02.891Z'),
          update_date: new Date('2022-04-20T14:40:02.891Z'),
          create_user_id: 'uid1234',
          update_user_id: '00uiaa1va4oHBCbJQ696',
        },
        {
          name: 'Pizza section',
          items: ['margarita'],
          section_id: '3514c6f2-df34-49c5-a506-bcdeede2e81c',
          create_date: new Date('2022-04-20T14:40:02.891Z'),
          update_date: new Date('2022-04-20T14:40:02.891Z'),
          create_user_id: 'uid1234',
          update_user_id: '00uiaa1va4oHBCbJQ696',
        },
      ],
      items: [burger, hotdog, ketchup, margarita, steak],
    },
  ],
};

export const emptyMenuDetail: IMenuResponse = JSON.parse(JSON.stringify(menuDetail));
emptyMenuDetail.data[0].sections = [];
emptyMenuDetail.data[0].items = [];

import { TPublicationStatus } from 'src/types/menu.types';
import { formatDate } from '../formatDate/formatDate';
import { formatTime } from '../formatTime/formatTime';

const formatStatus = (status: string): string => {
  return (status.charAt(0).toUpperCase() + status.toLowerCase().slice(1)).split('_').join(' ');
};

export const getPublicationStatus = (status: TPublicationStatus, publicationDate: Date | null): string => {
  if (status === 'UNPUBLISHED' || publicationDate === null) {
    return formatStatus(status);
  }
  return `Last published: ${formatDate(publicationDate)} (${formatStatus(status)})`;
};

export const getDetailedPublicationStatus = (status: TPublicationStatus, publicationDate: Date) => {
  return `Last published: ${formatDate(publicationDate)}, ${formatTime(publicationDate)} (${formatStatus(status)})`;
};

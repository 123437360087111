import { ReactElement, MouseEvent, useState } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { EllipsisMenuButtonProps, StyledButton, StyledMenu } from './EllipsisMenu.styles';

interface EllipsisMenuProps extends ReactElement, EllipsisMenuButtonProps {}

const EllipsisMenu = ({ children, backgroundType }): EllipsisMenuProps => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  return (
    <>
      <StyledButton
        id="menu-options-button"
        className={`section ${open ? 'active' : ''}`}
        data-testid={'menu-options-button'}
        aria-controls={open ? 'menu-items-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleOpen}
        background-type={backgroundType}
      >
        <MoreVertIcon />
      </StyledButton>
      <StyledMenu
        id="menu-items-menu"
        variant="menu"
        MenuListProps={{
          'aria-labelledby': 'menu-options-button',
        }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onBlur={handleClose}
      >
        {children}
      </StyledMenu>
    </>
  );
};

export default EllipsisMenu;

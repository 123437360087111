import { ReactElement, SyntheticEvent, useState } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { FormInputText } from '../FormElements';
import {
  FormContainer,
  LeftContentBox,
  RightContentBox,
  StyledMaxMinBox,
  TextContainer,
  StyledTooltipContainer,
  CheckboxLabel,
  CheckboxLabelList,
  AutocompleteRenderInput,
  StyledChip,
  StyledPopper,
  ChipsCancelIcon,
} from './MenuItemForm.styles';
import Divider from '../Divider';
import { StyledMenuItem } from '../EllipsisMenu/EllipsisMenu.styles';
import {
  Autocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  Box,
  MenuItem,
  Typography,
} from '@mui/material';
import InfoTooltip from '../InfoTooltip';
import EllipsisMenu from '../EllipsisMenu';
import { Checkbox } from '../Checkbox/Checkbox';
import { IMenuItem } from 'src/types/menu.types';
import POSIdPrompt from '../POSIdPrompt/POSIdPrompt';
import { IFormOption, IFormOptionGroup, IFormValues } from './types';
interface IOptionGroupsProps {
  formFields: UseFormReturn<IFormValues>;
  variant?: 'create' | 'update';
  createNewOptionGroup?: () => void;
  index?: number;
  itemOptions: IMenuItem[];
  menuItemType: string;
  fieldLength: number;
  onDeleteOptionsGroup: () => void;
  optionGroups: IFormOptionGroup[];
}

const OptionGroups = ({
  formFields,
  variant,
  createNewOptionGroup,
  index,
  menuItemType,
  fieldLength,
  itemOptions,
  onDeleteOptionsGroup,
  optionGroups,
}: IOptionGroupsProps): ReactElement => {
  const { getValues, control, trigger, setValue, watch } = formFields;
  const watchOptions = watch(`modifier_groups.${index}`);

  const ellipsisMenu = (
    <EllipsisMenu backgroundType="light">
      <StyledMenuItem onClick={onDeleteOptionsGroup} data-testid={'delete-section-button'}>
        Delete
      </StyledMenuItem>
    </EllipsisMenu>
  );

  const [isPOSIdPromptVisible, setIsPOSIdPromptVisible] = useState<boolean>(false);
  const [optionName, setOptionName] = useState<string>('');

  const removeOption = (itemToRemove: IFormOption) => {
    setValue(
      `modifier_groups.${index}.modifiers`,
      getValues(`modifier_groups.${index}.modifiers`).filter(
        (option) =>
          option.optionItemId !== itemToRemove.optionItemId || option.parentItemId !== itemToRemove.parentItemId,
      ),
    );
  };

  const deleteIcon = (currentOption: IFormOption) => {
    if (menuItemType === 'STANDARD') {
      return (
        <Box pl={3} pr={3} pt={1}>
          <ChipsCancelIcon
            data-testid="CancelOutlinedIcon"
            onClick={() => {
              removeOption(currentOption);
            }}
          />
        </Box>
      );
    } else {
      return (
        <EllipsisMenu backgroundType="light">
          <MenuItem
            onClick={() => {
              setIsPOSIdPromptVisible(true);
              setOptionName(currentOption.name);
            }}
          >
            Change POS ID
          </MenuItem>
          <StyledMenuItem
            onClick={() => {
              removeOption(currentOption);
            }}
          >
            Remove
          </StyledMenuItem>
        </EllipsisMenu>
      );
    }
  };

  return (
    <>
      <POSIdPrompt
        closePrompt={() => {
          setIsPOSIdPromptVisible(false);
        }}
        open={isPOSIdPromptVisible}
        optionName={optionName}
        setValue={setValue}
        index={index}
        optionGroups={optionGroups}
      />
      <Box>
        <Divider
          title="Modifiers"
          onClickAdd={() => {
            createNewOptionGroup();
          }}
          ellipsisMenu={
            ((menuItemType === 'BUNDLE' && (index !== 0 || fieldLength > 1)) || menuItemType === 'STANDARD') &&
            ellipsisMenu
          }
        >
          <FormInputText
            name={`modifier_groups.${index}.name`}
            size="small"
            control={control}
            rules={{ validate: (v) => !!v || 'Please enter a section name' }}
            textAlign="center"
            placeholder={`${menuItemType === 'STANDARD' ? 'Add' : 'Enter'} section name`}
            testId={'title-modifier'}
            type="modifier"
            showLabel={false}
          />
        </Divider>
        <FormContainer>
          <LeftContentBox>
            {menuItemType === 'STANDARD' && (
              <FormInputText
                name={`modifier_groups.${index}.description`}
                control={control}
                label="Description"
                rules={{ required: true }}
                testId={`${variant}-modifiers-description`}
                rows={2}
              />
            )}
            <Controller
              name={`modifier_groups.${index}.modifiers`}
              control={control}
              render={({ field: { value: selectedOptions } }) => {
                const options: IMenuItem[] = [...itemOptions];
                // reorder selected items to appear at top of dropdown
                selectedOptions?.forEach((modifier, i) => {
                  const itemToMoveIndex = options.findIndex((el) => el.itemId === modifier.parentItemId);
                  // move to top
                  options.splice(i, 0, options[itemToMoveIndex]);
                  options.splice(itemToMoveIndex + 1, 1);
                });

                return (
                  <Autocomplete
                    isOptionEqualToValue={(option, value) =>
                      option.itemId === value.parentItemId ||
                      option.itemId === value.itemId ||
                      option.itemId === value.optionItemId
                    }
                    multiple
                    onChange={(
                      _event: SyntheticEvent<Element, Event>,
                      currentValue: IFormOption[],
                      reason: AutocompleteChangeReason,
                      item: AutocompleteChangeDetails<IMenuItem>,
                    ) => {
                      if (reason === 'selectOption') {
                        // on new item selection, add new option item to array of selected items
                        const newValue: IFormOption[] = currentValue.map((value: IFormOption) =>
                          value === item.option
                            ? {
                                itemId: undefined,
                                parentItemId: item.option.itemId,
                                name: item.option.name,
                              }
                            : value,
                        );
                        setValue(`modifier_groups.${index}.modifiers`, newValue);
                      }
                      if (reason === 'clear') {
                        setValue(`modifier_groups.${index}.modifiers`, []);
                      }
                      if (reason === 'removeOption') {
                        setValue(`modifier_groups.${index}.modifiers`, currentValue);
                      }

                      trigger(`modifier_groups.${index}.max_choices`);
                    }}
                    value={selectedOptions || []}
                    data-testid={`${variant}-${menuItemType === 'STANDARD' ? 'modifiers' : 'menu-items'}`}
                    PopperComponent={({ style, ...props }) => <StyledPopper {...props} style={{ ...style }} />}
                    options={options}
                    disableCloseOnSelect
                    getOptionLabel={(option: IMenuItem) => option.name}
                    renderOption={(props, option: IMenuItem, { selected }) => (
                      <CheckboxLabelList {...props} key={option.itemId ? option.itemId : option.parentItemId}>
                        <Checkbox
                          label={
                            <CheckboxLabel id="labelBox">
                              <Typography>{option.name}</Typography>
                              {option.prices.defaultPrice > 0 && (
                                <Typography>
                                  {'£'}
                                  {option.prices.defaultPrice}
                                </Typography>
                              )}
                            </CheckboxLabel>
                          }
                          onChange={() => {}}
                          isChecked={selected}
                        />
                      </CheckboxLabelList>
                    )}
                    renderTags={(value: IFormOption[]) =>
                      value.map((option: IFormOption) => (
                        <StyledChip
                          label={<Typography variant="mText">{option.name}</Typography>}
                          key={`chip-${option.optionItemId ? option.optionItemId : option.parentItemId}`}
                          onDelete={() => {
                            trigger(`modifier_groups.${index}.max_choices`);
                          }}
                          deleteIcon={deleteIcon(option)}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <AutocompleteRenderInput
                        {...params}
                        label={menuItemType === 'STANDARD' ? 'Modifiers' : 'Menu items'}
                      />
                    )}
                  />
                );
              }}
            />
          </LeftContentBox>
          <RightContentBox>
            <TextContainer>
              <Typography variant="mText">Range of choices</Typography>
              <StyledTooltipContainer>
                <InfoTooltip title="e.g. Min 1 & Max 3 means at least one choice is required, plus ability to choose up to 3" />
              </StyledTooltipContainer>
            </TextContainer>

            <StyledMaxMinBox>
              <FormInputText
                name={`modifier_groups.${index}.min_choices`}
                control={control}
                rules={{
                  pattern: /^[%.0-9]+$/i,
                  required: true,
                  validate: {
                    isInRange: (v) => (+v >= 0 && +v <= 99) || 'Min should be between 0 and 99',
                    isUnderMaxValue: (v) => +v <= +watchOptions?.max_choices || "Min can't be greater than max",
                  },
                  onChange: (v) => !!v.target.value && trigger(`modifier_groups.${index}.max_choices`),
                }}
                label="Min"
                testId={`modifier_groups.${index}.min_choices`}
              />
              <FormInputText
                name={`modifier_groups.${index}.max_choices`}
                control={control}
                rules={{
                  pattern: /^[%.0-9]+$/i,
                  required: true,
                  validate: {
                    isInRange: (v) => (+v >= 0 && +v <= 99) || 'Max should be between 0 and 99',
                    isAboveMinAllowed: (v) => +v >= 1 || "Max can't be less than 1",
                    isAboveMin: (v) => +v >= +watchOptions?.min_choices || "Max can't be less than min",
                    isAboveItemsNumber: (v) =>
                      +v <= watchOptions.modifiers.length || "Max can't be greater than options selected",
                  },
                  onChange: (v) => !!v.target.value && trigger(`modifier_groups.${index}.min_choices`),
                }}
                label="Max"
                testId={`modifier_groups.${index}.max_choices`}
              />
            </StyledMaxMinBox>
          </RightContentBox>
        </FormContainer>
      </Box>
    </>
  );
};

export default OptionGroups;

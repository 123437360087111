import { createContext, useContext } from 'react';
import { IMenu, ISection, IMenuItem, IMenuAvailability } from 'src/types/menu.types';

export interface IMenusCtx {
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
  updateMenuDetails: (menuId: string, newName?: string, newImage?: string) => void;
  menus: IMenu[];
  addNewMenu: (menu: IMenu) => void;
  deleteAMenuItem: (menuId: string, itemId: string) => void;
  createAMenuSection: (menuId: string, section: Omit<ISection, 'items'>, itemIds: string[]) => void;
  deleteAMenuSection: (menuId: string, sectionId: string) => void;
  updateAMenuSection: (menuId: string, sectionId: string, newName: string, newDescription: string) => void;
  moveAMenuItem: (
    menuId: string,
    newSectionId: string,
    itemId: string,
    currentSectionId: string,
    newDisplayOrder: number,
  ) => void;
  reorderAMenuItem: (menuId: string, sectionId: string, itemId: string, newDisplayOrder: number) => void;
  addMenus: (newMenus: IMenu[]) => void;
  deleteAMenu: (menuId: string) => void;
  moveAMenuSection: (menuId: string, sectionId: string, newDisplayOrder: number) => void;
  getAMenuById: (menuId: string) => IMenu;
  addAMenuItem: (menuId: string, itemId: string, currentItem: IMenuItem) => void;
  addMenuItems: (menuId: string, parentItemId: string, itemsToAddToMenu: IMenuItem[], sectionId: string | null) => void;
  updateAMenuSchedule: (menuId: string, schedules: IMenuAvailability[]) => void;
}

export const MenusContext = createContext<IMenusCtx>({} as IMenusCtx);

export const useMenusContext = (): IMenusCtx => useContext(MenusContext);

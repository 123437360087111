import { styled, List, Typography, ListItem, ListItemText, Box } from '@mui/material';
import { theme } from 'src/assets/theme/theme';

export const StyledText = styled(Typography)`
  text-transform: none;
`;

export const StyledList = styled(List)`
  border-collapse: initial;
  margin-top: ${theme.spacing(2)};
  max-width: 100%;
  & .MuiListItem-root {
    padding-left: ${theme.spacing(0)};
  }
`;

export const StyledListItem = styled(ListItem)`
  align-items: flex-start;
  ${(props) => props.theme.breakpoints.down('md')} {
    flex-direction: column;
  }
`;
export const StyledListItemText = styled(ListItemText)`
  border-collapse: initial;
  max-width: 20%;
  ${(props) => props.theme.breakpoints.down('md')} {
    max-width: 100%;
  }
`;

export const StyledListItemDetails = styled(ListItemText)`
  border-collapse: initial;
`;

export const StyledDivider = styled(Box)`
  display: flex;
  height: 1px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='1' ry='1' stroke='%23367BB0FF' stroke-width='2' stroke-dasharray='4%2c 4' stroke-dashoffset='11' stroke-linecap='butt'/%3e%3c/svg%3e");
  border-radius: 1px;
  flex-direction: column;
`;

import { ReactElement, useState } from 'react';
import { MenuItem, Typography } from '@mui/material';
import { StyledCardContainer } from './MenuSection.styles';
import Divider from '../Divider';
import EmptyState from '../EmptyState';
import { NewItemCard } from 'src/components/Card';
import Burger from 'src/assets/images/Burger';
import EllipsisMenu from '../EllipsisMenu';
import { StyledMenuItem } from '../EllipsisMenu/EllipsisMenu.styles';
import { moveSection } from 'src/api/moveSection/moveSection';
import { useOktaAuth } from '@okta/okta-react';
import { useNotificationOutlet } from '../Notification';
import { useMenusContext } from 'src/contexts/menus';
import { ISection } from 'src/types/menu.types';
import { deleteSection } from 'src/api/deleteSection/deleteSection';
import SectionModal from '../SectionModal';
import RemovePrompt from '../RemovePrompt';
import MenuItemCard from '../MenuItemCard';

export interface IMenuSectionProps {
  menuId: string;
  section: ISection;
  handleSectionModal: () => void;
  onClickNewItem: (sectionId?: string) => void;
  upDisplayOrder?: number;
  downDisplayOrder?: number;
}

const MenuSection = ({
  menuId,
  handleSectionModal,
  onClickNewItem,
  section,
  upDisplayOrder,
  downDisplayOrder,
}: IMenuSectionProps): ReactElement => {
  const { sectionId, name, description, items } = section;
  const { moveAMenuSection, deleteAMenuSection } = useMenusContext();
  const { authState } = useOktaAuth();
  const setMessage = useNotificationOutlet();
  const accessToken = authState?.accessToken?.accessToken;
  const [isRenameSectionModalVisible, setIsRenameSectionModalVisible] = useState<boolean>(false);
  const [isRemovePromptVisible, setIsRemovePromptVisible] = useState<boolean>(false);
  const removePromptTitle = `Are you sure you want to delete "${name}"?`;
  const removeSectionPromptSubTitle = 'All grouped menu items will move to the Uncategorised section.';

  const handleDelete = (): void => {
    deleteSection(accessToken, menuId, sectionId)
      .then(() => {
        deleteAMenuSection(menuId, sectionId);
      })
      .catch(() => {
        setMessage({
          severity: 'error',
          message: 'We were unable to delete, please try again!',
        });
      });
  };

  const reorderSection = async (newDisplayOrder: number) => {
    try {
      moveAMenuSection(menuId, sectionId, newDisplayOrder);
      await moveSection({ accessToken, menuId, sectionId, newDisplayOrder });
    } catch (error) {
      setMessage({
        severity: 'error',
        description: 'Internal Server Error',
        message: 'We have been unable to complete your request',
      });
    }
  };

  const ellipsisMenu = (
    <EllipsisMenu backgroundType="light">
      <MenuItem onClick={() => setIsRenameSectionModalVisible(true)} data-testid={`rename-section-${name}`}>
        Edit
      </MenuItem>
      <MenuItem
        disabled={!(upDisplayOrder >= 0)}
        onClick={() => reorderSection(upDisplayOrder)}
        data-testid={`move-up-section-${name}`}
      >
        Move up
      </MenuItem>
      <MenuItem
        disabled={!(downDisplayOrder >= 0)}
        onClick={() => reorderSection(downDisplayOrder)}
        data-testid={`move-down-section-${name}`}
      >
        Move down
      </MenuItem>
      <StyledMenuItem onClick={() => setIsRemovePromptVisible(true)} data-testid={`delete-section-${name}`}>
        Delete
      </StyledMenuItem>
    </EllipsisMenu>
  );

  return (
    <>
      <RemovePrompt
        title={removePromptTitle}
        subTitle={removeSectionPromptSubTitle}
        closePrompt={() => setIsRemovePromptVisible(false)}
        open={isRemovePromptVisible}
        deleteButtonHandler={handleDelete}
      />
      <SectionModal
        title="Edit Section"
        sectionName={name}
        sectionDescription={description}
        open={isRenameSectionModalVisible}
        closeModal={() => setIsRenameSectionModalVisible(false)}
        menuId={menuId}
        variant="update"
        sectionId={sectionId}
      />
      <Divider onClickAdd={handleSectionModal} title={name} ellipsisMenu={ellipsisMenu} />

      {items?.length > 0 ? (
        <StyledCardContainer>
          {items.map((item, index) => (
            <MenuItemCard
              {...item}
              menuId={menuId}
              itemId={item.itemId}
              sectionId={sectionId}
              displayOrderLeft={items[index - 1]?.displayOrder}
              displayOrderRight={items[index + 1]?.displayOrder}
              key={item.itemId}
            />
          ))}
          <NewItemCard onClick={() => onClickNewItem(sectionId)} data-testid={`add-item-to-${name}`}>
            <Typography>New menu item</Typography>
          </NewItemCard>
        </StyledCardContainer>
      ) : (
        <EmptyState
          emptySectionText="Add your first menu item"
          onClick={() => onClickNewItem(sectionId)}
          buttonText="New menu item"
          Icon={Burger}
        />
      )}
    </>
  );
};

export default MenuSection;

import { RegisterOptions, FieldError } from 'react-hook-form';

const createErrorMessage = (error?: FieldError, rules?: RegisterOptions, label?: string) => {
  if (error?.message) {
    return error?.message;
  } else {
    return `Please enter a ${rules?.pattern ? 'valid ' : ''}${label && label.toLocaleLowerCase()}`;
  }
};

export default createErrorMessage;

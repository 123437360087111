import { styled, Box, Typography } from '@mui/material';
import colors from 'src/assets/theme/colors';
import { theme } from 'src/assets/theme/theme';
import AddIcon from '@mui/icons-material/Add';

export const StyledBox = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100vw;
  max-width: ${theme.spacing(352)};
  ${(props) => props.theme.breakpoints.up('md')} {
    padding-left: ${theme.spacing(72)};
    align-self: center;
  }
`;

export const StyledTitle = styled(Typography)`
  color: ${colors.AZT_Dark};
`;

export const StyledContent = styled(Typography)`
  margin: ${theme.spacing(4)} ${theme.spacing(0)};
  color: ${colors.black};
`;

export const StyledAddIcon = styled(AddIcon)`
  margin-top: ${theme.spacing(1)};
  height: ${theme.spacing(9)};
  width: ${theme.spacing(9)};
  color: ${colors.AZT_Dark};
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
`;

export const AddButton = styled('button')`
  appearance: none;
  background: transparent;
  border: 1px transparent solid;
  border-radius: ${theme.spacing(25)};
  height: ${theme.spacing(9)};
  width: ${theme.spacing(9)};
  padding: 0;
  position: relative;
  margin-left: 0.8rem;
  &:hover {
    border: 1px solid ${colors.AZT_Dark};
  }
`;

export const StyledMenusContainer = styled(Box)`
  width: 100%;
  width: calc(100% + ${theme.spacing(6)});
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 0 -${theme.spacing(3)};
`;

export const StyledCardContainer = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

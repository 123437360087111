import { ReactElement, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Drawer, useMediaQuery, useTheme, Grid } from '@mui/material';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import Category from '../Category/Category';
import Modal from '../Modal';
import DropdownMenu from '../DropdownMenu';
import RecipesModal from '../RecipesModal';
import {
  StyledDivider,
  StyledIconButton,
  StyledAccountContainer,
  StyledCategoryContainer,
  StyledBrandBox,
} from './SideBar.styles';
import { IMenu } from '../../types/menu.types';
import { Brand } from 'src/types/brand.types';

interface SideBarData {
  open: boolean;
  brands: Brand[];
  selectedBrandId: string;
  handleClick: () => void;
  menus: IMenu[];
  onBrandSwitch: (id: string) => void;
}

const SideBar = ({ open, brands, selectedBrandId, handleClick, onBrandSwitch, menus }: SideBarData): ReactElement => {
  const history = useHistory();
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

  const [isMenuModalVisible, setIsMenuModalVisible] = useState<boolean>(false);
  const [isRecipesModalVisible, setIsRecipesModalVisible] = useState<boolean>(false);

  const selectedOption = brands.find((i) => i.id === selectedBrandId);

  const showMenuModal = (): void => {
    setIsMenuModalVisible(true);
  };

  const showRecipesModal = (): void => {
    setIsRecipesModalVisible(true);
  };

  const closeModal = (): void => {
    setIsMenuModalVisible(false);
    setIsRecipesModalVisible(false);
  };

  const handleAvatarClick = (): void => {
    history.push('/');

    if (!isMdUp) {
      handleClick();
    }
  };

  const onAddNewMenuItemClick = (): void => {
    history.push('/items/item/new');
  };

  return (
    <Box data-testid="sidebar-container">
      <Modal title="Create new menu" closeModal={closeModal} open={isMenuModalVisible} variant="create" />
      <RecipesModal title="Import recipes" closeModal={closeModal} open={isRecipesModalVisible} />
      <Drawer
        variant={isMdUp ? 'permanent' : 'temporary'}
        anchor="left"
        open={open}
        onClose={handleClick}
        sx={{
          width: theme.spacing(66),
          flexShrink: 0,
          ['& .MuiDrawer-paper']: { width: theme.spacing(66), boxSizing: 'border-box', overflow: 'visible' },
        }}
      >
        <Box>
          <StyledAccountContainer>
            {!isMdUp && (
              <Grid container direction="column" alignItems="flex-end">
                <Grid item>
                  <StyledIconButton onClick={handleClick} data-testid="close-button" aria-label="close sidebar button">
                    <CloseSharpIcon fontSize="large" />
                  </StyledIconButton>
                </Grid>
              </Grid>
            )}
            <StyledBrandBox>
              {!!selectedOption && (
                <DropdownMenu
                  options={brands}
                  selectedOption={selectedOption}
                  avatarSize="large"
                  onOptionClick={onBrandSwitch}
                  onAvatarClick={handleAvatarClick}
                />
              )}
            </StyledBrandBox>
          </StyledAccountContainer>

          <StyledDivider />
          <StyledCategoryContainer>
            <Category text="Menus" path="/menu" content={menus} addNewItem={showMenuModal} />
            <Category text="Menu items" path="/items" addNewItem={onAddNewMenuItemClick} />
            <Category text="Recipes" path="/recipes" addNewItem={showRecipesModal} />
          </StyledCategoryContainer>

          <StyledDivider />
        </Box>
      </Drawer>
    </Box>
  );
};

export default SideBar;

import { ReactElement, useEffect, useState, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { Typography } from '@mui/material';
import { createFormData } from 'src/helpers';
import { useRecipesContext } from 'src/contexts/recipes';
import { useItemsContext } from 'src/contexts/items';
import { useGeographiesContext } from 'src/contexts/geographies';
import { ICreateNewMenuItemResponse } from 'src/api/createNewMenuItem/types';
import { useNotificationOutlet } from '../Notification';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { createNewMenuItem, updateMenuItem } from 'src/api';
import {
  createUpdateOptionGroupRequest,
  formatPayload,
  getItemOptions,
  createPriceBandPromises,
  createFormArregators,
} from './utilities';
import { useMenusContext } from 'src/contexts/menus';
import { IMenuItem } from 'src/types/menu.types';
import { formatMenuItems } from 'src/services/getMenuService/getMenuService';
import MenuItemType from '../MenuItemType/MenuItemType';
import MenuItemDetails from '../MenuItemDetails/MenuItemDetails';
import MenuItemOptionGroups from '../MenuItemOptionGroups/MenuItemOptionGroups';
import { MenuItemPrice } from '../MenuItemPrice/MenuItemPrice';
import { IMenuItemsResponse } from 'src/api/types';
import { IFormOption, IFormOptionGroup, IFormValues, MenuItemFormProps, IFormPriceBand } from './types';
interface IParams {
  menuId: string;
}

const MenuItemForm = ({ currentItem, variant, hiddenButton, setHasErrors }: MenuItemFormProps): ReactElement => {
  const [newImage, setNewImage] = useState<File>();
  const { recipes } = useRecipesContext();
  const { getAMenuById } = useMenusContext();
  const { items, addItemToItems, updateItem } = useItemsContext();
  const { aggregators } = useGeographiesContext();
  const history = useHistory();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const { menuId } = useParams<IParams>();

  const setMessage = useNotificationOutlet();
  const currentMenu = getAMenuById(menuId);
  const [optionGroupsToDelete, setOptionGroupsToDelete] = useState<string[]>([]);

  useEffect(() => {
    document.title = currentItem?.name || 'Openr';
  }, [currentItem]);

  const initialValues: IFormValues = useMemo((): IFormValues => {
    return {
      currency: 'GBP',
      name: currentItem?.name,
      description: currentItem?.description ?? null,
      defaultPrice: currentItem?.prices.defaultPrice.toString(),
      tax_rate: currentItem?.taxRate,
      plu: currentItem?.posId,
      recipe_id: currentItem?.recipeId,
      files: newImage ? newImage : null,
      type: currentItem?.type,
      modifier_groups: currentItem?.optionGroup?.map(
        (optionGroup): IFormOptionGroup => ({
          name: optionGroup.name,
          modifiers: optionGroup.items.map(
            (mod): IFormOption => ({
              optionItemId: mod.optionItemId,
              name: mod.name,
              posId: mod.posId,
              parentItemId: mod.parentItemId,
            }),
          ),
          description: optionGroup.description,
          max_choices: parseInt(optionGroup.max),
          min_choices: parseInt(optionGroup.min),
          pk_modifier_group_id: optionGroup.optionGroupId,
        }),
      ),
      price_bands: currentItem?.prices.priceBands.map(
        (priceBand): IFormPriceBand => ({
          priceBandId: priceBand.priceBandId,
          name: priceBand.name,
          aggregators: createFormArregators(priceBand.aggregators, aggregators),
        }),
      ),
    };
  }, [currentItem, recipes, variant]);

  const formFields = useForm<IFormValues>({
    defaultValues: initialValues,
    mode: 'onChange',
  });

  const { watch, handleSubmit, control, formState } = formFields;

  const menuItemType = watch('type');

  useEffect(() => {
    setHasErrors(Object.keys(formState.errors).length > 0);
  }, [formState]);

  const itemOptions: IMenuItem[] = useMemo(
    () => getItemOptions(currentMenu, menuItemType, items),
    [currentMenu, menuItemType, items],
  );

  const showSuccessNotification = (itemName: string) => {
    setMessage({
      message: (
        <>
          You have successfully edited details for <Typography variant="strongText">&quot;{itemName}&quot;</Typography>.
        </>
      ),
    });
  };

  const showErrorNotification = (error: any) => {
    const setErrorMessage = () => {
      if (`${error}`.includes('Syntax')) {
        return 'Please refresh the browser';
      } else if (currentItem.type === 'BUNDLE') {
        return 'Server error. Please try again or contact your technical support team.';
      } else return `Something went wrong whilst ${'updating'} menu item`;
    };
    setMessage({
      severity: 'error',
      message: setErrorMessage(),
    });
  };

  const onSubmit = async (data) => {
    const payload = formatPayload(data, variant, newImage, itemOptions);
    const requestBody = createFormData(payload);

    try {
      if (variant === 'create') {
        const res = await createNewMenuItem(
          requestBody,
          menuId,
          data.type !== 'MODIFIER' && queryParams.get('sectionId'),
        );

        const response: ICreateNewMenuItemResponse = await res.json();

        if (!menuId) addItemToItems(formatMenuItems(response.data)[0]);
      }

      if (variant === 'update') {
        const res = await updateMenuItem(currentItem.itemId, requestBody);
        const json = await res.json();

        if (!menuId) {
          const { name, description, price = undefined, assets, prices }: IMenuItemsResponse = json.data[0] || {};

          updateItem(currentItem.itemId, {
            name,
            description,
            defaultPrice: prices?.default_base_price_with_tax || price, // support for deprecated key "price"
            image: assets[0]?.asset_variants[0]?.url,
          });
        }

        const allPriceBandPromises = createPriceBandPromises(
          currentItem.itemId,
          currentItem.prices.priceBands,
          data.price_bands,
          data.tax_rate,
        );

        const allOptionGroupPromises = createUpdateOptionGroupRequest(
          optionGroupsToDelete,
          itemOptions,
          currentItem,
          data.modifier_groups,
        );

        await Promise.all([...allPriceBandPromises, ...allOptionGroupPromises]);

        showSuccessNotification(payload.name);
      }

      const redirectPath = menuId ? `/menu/${menuId}` : '/items';

      history.push(redirectPath);
    } catch (e) {
      showErrorNotification(e);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} data-testid="menu-item-form" id="menu-item-form">
        <MenuItemType variant={variant} itemType={menuItemType} control={control} />
        {menuItemType && (
          <>
            <MenuItemDetails
              itemType={menuItemType}
              variant={variant}
              currentItem={currentItem}
              formFields={formFields}
              newImage={newImage}
              setNewImage={setNewImage}
            />
            <MenuItemPrice formFields={formFields} />
            {(menuItemType === 'STANDARD' || menuItemType === 'BUNDLE') && (
              <MenuItemOptionGroups
                currentItem={currentItem}
                itemType={menuItemType}
                itemVariant={variant}
                optionGroupsToDelete={optionGroupsToDelete}
                setOptionGroupsToDelete={setOptionGroupsToDelete}
                formFields={formFields}
                itemOptions={itemOptions}
              />
            )}
            {hiddenButton}
          </>
        )}
      </form>
    </>
  );
};

export default MenuItemForm;

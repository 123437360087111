import { ChangeEvent, ReactElement, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useOktaAuth } from '@okta/okta-react';
import { Typography } from '@mui/material';
import {
  StyledText,
  StyledHiddenInput,
  StyledImageUploadContainer,
  StyledImageUpload,
  StyledUploadedImage,
  StyledUploadOption,
  StyledUploadedImageContainer,
  StyledUploadOptionContainer,
  StyledImageText,
  StyledForm,
} from './Modal.styles';
import ModalImg from 'src/assets/images/create-menu-modal.png';
import { MenuProp } from 'src/helpers';
import { useMenusContext } from 'src/contexts/menus';
import { ModalWrapper, useNotificationOutlet, FormInputText, MainButton } from 'src/components';
import type { IModalWrapper } from 'src/components/ModalWrapper';
import UploadImage from 'src/assets/images/UploadImage';
import { createMenu } from '../../api/createMenu/createMenu';
import { updateMenu } from '../../api/updateMenu/updateMenu';
import { IMenuRequest } from 'src/api/types';
import { IMenu } from 'src/types/menu.types';

export interface IModal extends Omit<IModalWrapper, 'children' | 'image' | 'imageAlt'> {
  title?: string;
  id?: string;
  menuName?: string;
}

const Modal = ({ menuName, title, closeModal, open, variant, id }: IModal): ReactElement => {
  const { authState } = useOktaAuth();
  const { menus, updateMenuDetails, addNewMenu } = useMenusContext();
  const setMessage = useNotificationOutlet();
  const accessToken = authState?.accessToken?.accessToken;
  const selectedMenu = menus.find((menu) => menu.menuId === id);
  const [uploadedImage, setUploadedImage] = useState<File | undefined>();
  const [initialImage] = useState<string | undefined>(selectedMenu ? selectedMenu.image : undefined);

  const handleImageUpload = (e: ChangeEvent<HTMLInputElement>) => {
    setUploadedImage(e.target.files[0]);
  };

  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting },
    register,
  } = useForm({
    defaultValues: {
      name: menuName,
      files: uploadedImage,
    },
  });

  useEffect(() => {
    if (!open) {
      reset({ name: menuName });
    }
  }, [menuName, open, reset]);

  const onSubmit = async (data: { name: string; files: File }) => {
    if (isSubmitting) return;
    const payload: IMenuRequest = { name: data.name.replace(/'/g, "''") };
    if (uploadedImage) {
      payload.files = uploadedImage;
    }
    try {
      const res = variant === 'update' ? await updateMenu(accessToken, id, payload) : await createMenu(payload);
      const json = await res.json();
      const menu: MenuProp = json.data[0];
      if (variant === 'update') {
        updateMenuDetails(id, menu.name, menu.assets[0]?.asset_variants[0]?.url);
        setMessage({
          message: (
            <>
              You have successfully updated menu name to <strong>&ldquo;{menu.name}&rdquo;</strong>
            </>
          ),
        });
      } else {
        const newMenu: IMenu = {
          name: menu.name,
          menuId: menu.menu_id,
          image: menu.assets[0]?.asset_variants[0]?.url || null,
          sections: [],
          uncategorised: [],
          modifiers: [],
          schedules: [],
          publication: {
            publicationStatus: 'UNPUBLISHED',
            aggregators: [],
            publicationDate: null,
          },
          created: new Date(),
          linkedMenuIds: [],
        };
        addNewMenu(newMenu);
        setMessage({
          message: (
            <>
              You have successfully created <strong>&ldquo;{menu.name}&rdquo;</strong>
            </>
          ),
        });
      }
      closeModal();
    } catch (e) {
      setMessage({
        severity: 'error',
        message: `Something went wrong whilst ${variant === 'create' ? 'creating' : 'updating'} the menu`,
      });
    }
  };

  return (
    <ModalWrapper closeModal={closeModal} open={open} variant={variant} image={ModalImg} imageAlt={'Picture of menus'}>
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <StyledText variant="xxlText">
          <Typography variant="strongText">{title}</Typography>
        </StyledText>
        <FormInputText
          name="name"
          control={control}
          rules={{ required: true }}
          label="Menu name"
          testId={`${variant}-menu-name`}
        />
        <StyledHiddenInput
          id="menu-image-upload"
          type="file"
          {...register('files')}
          accept="image/png, image/jpeg"
          onChange={(e: ChangeEvent<HTMLInputElement>) => handleImageUpload(e)}
          data-testid="menu-image-upload"
        />

        <StyledImageText>
          <Typography>Image</Typography>
        </StyledImageText>
        <StyledImageUploadContainer>
          {uploadedImage || initialImage ? (
            <StyledUploadedImageContainer>
              <StyledUploadedImage
                src={uploadedImage ? window.URL.createObjectURL(uploadedImage) : initialImage}
                alt="Uploaded cover image"
              />
              <StyledUploadOptionContainer>
                <label htmlFor="menu-image-upload">
                  <StyledUploadOption>
                    <UploadImage />
                    <Typography>Replace image</Typography>
                  </StyledUploadOption>
                </label>
              </StyledUploadOptionContainer>
            </StyledUploadedImageContainer>
          ) : (
            <label htmlFor="menu-image-upload">
              <StyledImageUpload>
                <UploadImage />
                <Typography>Upload image</Typography>
              </StyledImageUpload>
            </label>
          )}
        </StyledImageUploadContainer>
        <MainButton type="submit" loading={isSubmitting} text={variant === 'create' ? 'Create' : 'Save'} />
      </StyledForm>
    </ModalWrapper>
  );
};

export default Modal;

import { ISelectedDestination } from '../../types';
import { getChannel } from '../getChannel/getChannel';

export const isChannelSelected = (channel: string, selectedDestinations: ISelectedDestination[]): boolean => {
  const channels = getChannel(channel, selectedDestinations);

  if (channels.length > 0 && channels[0].selected) {
    return true;
  }
  return false;
};

import { theme } from 'src/assets/theme/theme';
import colors from 'src/assets/theme/colors';
import MenuItem from '@mui/material/MenuItem';
import { styled, Button, Box } from '@mui/material';
import Menu from '@mui/material/Menu';

export const StyledButton = styled(Button)`
  margin: 0 ${theme.spacing(1)};
  bottom: ${theme.spacing(0.5)};
  width: ${theme.spacing(10)};
  min-width: ${theme.spacing(10)};
  height: ${theme.spacing(10)};
  padding: ${theme.spacing(0)};
  background-color: transparent;
  border-radius: ${theme.spacing(25)};
  cursor: pointer;
  &.MuiButton-root {
    border: 1px solid transparent;
    svg path {
      fill: ${colors.AZT_Dark};
    }
    &:hover,
    &.active {
      border-color: ${colors.AZT_Dark};
    }
  }

  &:hover {
    border: 1px solid ${colors.white};
    border-radius: ${theme.spacing(25)};
    background-color: transparent;
  }
`;

export const StyledMenu = styled(Menu)`
  width: ${theme.spacing(37)};
  left: ${theme.spacing(10)};
  top: ${theme.spacing(2)};
`;

export const StyledMenuItem = styled(MenuItem)`
  color: ${colors.violetRed};
`;

export const MenuContainer = styled(Box)`
  align-self: self-start;
`;

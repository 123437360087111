import { createContext, useContext } from 'react';
import { IAggregator } from 'src/types/aggregator.types';
import { IPriceBand } from 'src/types/priceBand.types';
import { IVenue } from 'src/types/venue.types';

export interface IGeographiesCtx {
  venues: IVenue[];
  priceBands: IPriceBand[];
  aggregators: IAggregator[];
  refetch: () => void;
}

export const GeographiesContext = createContext<IGeographiesCtx>({} as IGeographiesCtx);

export const useGeographiesContext = (): IGeographiesCtx => useContext(GeographiesContext);

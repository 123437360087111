import { styled, Box } from '@mui/material';
import { theme } from 'src/assets/theme/theme';

export const StyledBox = styled(Box)`
  width: 100%;
  padding: ${theme.spacing(6)};
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
  overflow-x: hidden;

  ${(props) => props.theme.breakpoints.up('md')} {
    padding: ${theme.spacing(10)};
  }
`;

export const StyledMaxWidth = styled(Box)`
  max-width: ${theme.spacing(280)};
  width: 100%;
`;

export const LayoutBox = styled(Box)`
  display: flex;
  flex-direction: row;
`;

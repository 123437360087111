import type { PublishProp } from './types';

export const publishMenu = async ({ token, payload }: PublishProp): Promise<any> => {
  const api = process.env.REACT_APP_API_ENDPOINT;
  const uri = `${api}/publish`;

  const response = await fetch(uri, {
    method: 'POST',
    body: JSON.stringify(payload),
    cache: 'reload',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
  return response ? response.status : null;
};

import { FormEvent, useState, useEffect, ChangeEvent } from 'react';
import type { ReactElement } from 'react';
import { FormHelperText, Input, Typography } from '@mui/material';

import {
  StyledUploadBox,
  StyledText,
  StyledImageContainer,
  StyledButtonText,
  StyledUploadedText,
} from './RecipesModal.styles';
import ModalImg from 'src/assets/images/create-menu-item-modal.png';
import MainButton from '../MainButton/MainButton';
import UploadImage from 'src/assets/images/UploadImage';
import { useNotificationOutlet } from 'src/components/Notification';
import { importCSV } from 'src/api/importCSV/importCSV';
import { error as mockError } from 'src/mocks/data';
import { useOktaAuth } from '@okta/okta-react';
import { useRecipesContext } from 'src/contexts/recipes';
import type { IRecipesProp } from 'src/helpers';
import { ModalWrapper } from 'src/components';
import { getRecipes } from 'src/api/getRecipes/getRecipes';

export interface RecipesModalProps {
  title: string;
  closeModal: () => void;
  open: boolean;
  variant?: string;
}

const RecipesModal = ({ title, closeModal, open, variant }: RecipesModalProps): ReactElement => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [file, setFile] = useState<File | null>(null);
  const setMessage = useNotificationOutlet();
  const { setRecipes } = useRecipesContext();
  const { authState } = useOktaAuth();
  const accessToken = authState?.accessToken?.accessToken;
  const storedBrandId = window.localStorage.getItem('brandId');

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!file) {
      setError(true);
      return;
    }
    const formData: FormData = new FormData();
    formData.append('recipesFile', file);
    formData.append('source', '6d1dcbe2-cb6b-44fe-bc31-3a3d961663d0');
    setIsLoading(true);

    importCSV(accessToken, storedBrandId, formData)
      .then((res) => res.json())
      .then((res) => {
        if (res.Error) {
          setMessage({
            severity: 'error',
            message: <>{res.Error}</>,
          });
        } else {
          setMessage({
            message: (
              <>
                You have succesfully imported{' '}
                <Typography variant="strongText">
                  {`${res['Import results'] && res['Import results'].length} recipes`}.
                </Typography>
              </>
            ),
          });
          getRecipes(accessToken, storedBrandId)
            .then((recipeRes) => recipeRes.json())
            .then((response: { data: IRecipesProp[] }) => {
              setRecipes(response.data);
            })
            .catch(() => {});
        }

        closeModal();
        setIsLoading(false);
      })
      .catch(() => {
        setMessage(mockError);
      });
  };

  const handleCapture = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setFile(target.files[0]);
    setError(false);
  };

  useEffect(() => {
    if (!open) {
      setIsLoading(false);
      setError(false);
      setFile(null);
    }
  }, [open]);

  return (
    <ModalWrapper
      closeModal={closeModal}
      open={open}
      variant={variant}
      image={ModalImg}
      imageAlt={'Picture of ingredients'}
    >
      <form onSubmit={handleSubmit}>
        <StyledText variant="xxlText">
          <Typography variant="strongText">{title}</Typography>
        </StyledText>
        <Input
          inputProps={{
            accept: '.csv',
            'data-testid': 'upload-recipes',
          }}
          sx={{ display: 'none' }}
          id="upload-csv-file"
          type="file"
          onChange={handleCapture}
        />
        <StyledImageContainer>
          <label htmlFor="upload-csv-file">
            <StyledUploadBox error={error}>
              <UploadImage />
              <StyledButtonText>Select CSV for upload</StyledButtonText>
              <StyledUploadedText>{file && file?.name}</StyledUploadedText>
            </StyledUploadBox>
          </label>
          {error && <FormHelperText className="Mui-error">This field is mandatory</FormHelperText>}
        </StyledImageContainer>
        <MainButton
          data-testid="import-csv-button"
          type="submit"
          loading={isLoading ? 'Importing...' : isLoading}
          text={'Import'}
        />
      </form>
    </ModalWrapper>
  );
};

export default RecipesModal;

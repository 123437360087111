import { Box, Typography } from '@mui/material';
import colors from 'src/assets/theme/colors';
import HeadingTitle from '../HeadingTitle';
import { StyledLink } from './FormatItemInformation.styles';

type FormatItemInformationParams = {
  heading: string;
  information: string | number;
  link?: string;
};

export const FormatItemInformation = ({ heading, information, link }: FormatItemInformationParams) => {
  return (
    <Box mt={6}>
      <HeadingTitle variantType="mText" text={heading} color={colors.AZT_Dark} />
      {link && information ? (
        <StyledLink to={link} data-testid={`link-${information}`}>
          {information}
        </StyledLink>
      ) : (
        <Typography variant="mText" data-testid={`subtext-${information}`}>
          {information !== undefined && information !== null && information !== '' ? information : `No ${heading}`}
        </Typography>
      )}
    </Box>
  );
};

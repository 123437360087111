import { IMenu, ISection, IMenuItem } from 'src/types/menu.types';

const getMenuItemsInMenu = (menu: IMenu): IMenuItem[] => {
  const { modifiers = [], uncategorised = [], sections = [] } = menu;
  const menuItems = uncategorised.filter((item: IMenuItem) => item.type !== 'BUNDLE');
  const secItems = sections
    .map((section: ISection) => section.items.filter((item: IMenuItem) => item.type !== 'BUNDLE'))
    .flat();
  return [...modifiers, ...menuItems, ...secItems];
};

export default getMenuItemsInMenu;

import { IMenu, IMenuAvailability } from 'src/types/menu.types';

export const updateSchedule = (menus: IMenu[], menuId: string, schedules: IMenuAvailability[]): IMenu[] => {
  const newMenus = menus.map((menu) => {
    if (menu.menuId === menuId) {
      return {
        ...menu,
        schedules: schedules,
      };
    }
    return menu;
  });
  return newMenus;
};

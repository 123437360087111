import { API_URL } from '../constants';
import { ICreateNewSectionRequestBody } from './types';
import { fetch } from '../fetchWrapper/fetch';

export const createNewMenuSection = (menuId: string, requestBody: ICreateNewSectionRequestBody): Promise<any> => {
  return fetch(`${API_URL}/menus/${menuId}/sections`, {
    method: 'POST',
    body: JSON.stringify(requestBody),
    headers: {
      'content-type': 'application/json',
    },
  });
};

import { ReactElement, ChangeEvent } from 'react';
import { Typography, Box } from '@mui/material';
import {
  FormContainer,
  LeftContentBox,
  RightContentBox,
  HiddenFileUpload,
  UploadedImage,
  LabelBox,
  NewItemCard,
  CustomErrorMessage,
} from './MenuItemDetails.styles';
import NumericalSubtitle from '../NumericalSubtitle';
import { FormInputText, FormAutocomplete } from 'src/components/FormElements';
import { UseFormReturn } from 'react-hook-form';
import { getRecipesOptions } from 'src/helpers';
import { useRecipesContext } from 'src/contexts/recipes';
import UploadImage from 'src/assets/images/UploadImage';
import AutorenewOutlinedIcon from '@mui/icons-material/AutorenewOutlined';
import { IMenuItem } from 'src/types/menu.types';
import { IFormValues } from '../menuItemForm/types';

export interface IMenuItemDetailsProps {
  itemType?: string;
  variant: 'create' | 'update';
  currentItem?: IMenuItem;
  newImage: File;
  setNewImage: (value: File) => void;
  formFields: UseFormReturn<IFormValues>;
}

const MenuItemDetails = ({
  itemType,
  variant,
  currentItem,
  newImage,
  setNewImage,
  formFields,
}: IMenuItemDetailsProps): ReactElement => {
  const {
    register,
    setValue,
    formState: { errors },
    control,
  } = formFields;

  const { recipes } = useRecipesContext();

  const handleImageUpload = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files[0];
    if (files) {
      setNewImage(files);
      setValue('files', files);
    }
  };

  const displayItemImage = (): string => {
    if (newImage) {
      return window.URL.createObjectURL(newImage);
    } else if (currentItem.image && !newImage) {
      return currentItem.image;
    }
  };

  return (
    <>
      <NumericalSubtitle number="2" subtitle="Details" />
      <FormContainer>
        <LeftContentBox>
          {itemType !== 'BUNDLE' && (
            <FormAutocomplete
              name="recipe_id"
              label="Recipe"
              control={control}
              testId="autocomplete-recipe"
              options={getRecipesOptions(recipes)}
            />
          )}

          <FormInputText
            name="name"
            control={control}
            rules={{ required: true }}
            label="Name"
            testId={`${variant}-menu-item-name`}
          />
          <FormInputText
            name="description"
            control={control}
            label="Description"
            testId={`${variant}-menu-item-description`}
            rows={2}
          />

          <FormInputText name="plu" control={control} label="POS ID" testId={`${variant}-menu-item-pos-id`} />

          <FormInputText
            name="tax_rate"
            control={control}
            rules={{ pattern: /^[%.0-9]+$/i, max: 100, required: true }}
            label="Tax rate (%)"
            testId={`${variant}-menu-item-tax`}
          />
        </LeftContentBox>
        <Box display="flex">
          <HiddenFileUpload
            id="upload-button-file"
            type="file"
            required={false}
            {...register('files', {
              validate: {
                fileSize: () => !newImage || newImage?.size < 10000000 || 'The image needs to be under 10MB',
              },
            })}
            onChange={(e: ChangeEvent<HTMLInputElement>) => handleImageUpload(e)}
            accept="image/png, image/jpeg"
            data-testid="menu-item-image-upload"
          />

          <RightContentBox data-testid="image-upload-container">
            {newImage || currentItem?.image ? (
              <>
                <UploadedImage
                  haserror={(!!errors.files).toString()}
                  src={displayItemImage()}
                  alt="uploaded item menu image"
                />
                <label htmlFor="upload-button-file">
                  <LabelBox>
                    <AutorenewOutlinedIcon />
                    <Typography>Replace</Typography>
                  </LabelBox>
                </label>
              </>
            ) : (
              <label htmlFor="upload-button-file">
                <NewItemCard data-testid="upload-image">
                  <UploadImage />
                  <Typography>Add Image</Typography>
                </NewItemCard>
              </label>
            )}

            {errors.files && <CustomErrorMessage className="Mui-error">{errors.files.message}</CustomErrorMessage>}
          </RightContentBox>
        </Box>
      </FormContainer>
    </>
  );
};

export default MenuItemDetails;

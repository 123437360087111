import photo from 'src/static/images/image28.png';
import { Container, StyledText, StyledBox, LoginContent, ImageContainer, LargeImage } from './Welcome.styles';
import { useOktaAuth } from '@okta/okta-react';
import MainButton from '../../components/MainButton';
import { Typography } from '@mui/material';
import { useEffect } from 'react';

export const Welcome = () => {
  const { oktaAuth } = useOktaAuth();

  useEffect(() => {
    document.title = 'Welcome';
  }, []);

  const login = async () => {
    await oktaAuth.signInWithRedirect();
  };

  return (
    <>
      <Container>
        <LoginContent>
          <StyledBox>
            <StyledText variant="xxlText">
              <Typography variant="strongText">Welcome back</Typography>
            </StyledText>
            <MainButton text="Login" onClick={login} />
          </StyledBox>
        </LoginContent>
        <ImageContainer>
          <LargeImage src={photo} alt="People in a restaurant" />
        </ImageContainer>
      </Container>
    </>
  );
};

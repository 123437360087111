import { ReactElement } from 'react';
import { List, Typography } from '@mui/material';
import {
  OptionGroupWrapper,
  OptionGroupDescription,
  OptionGroupCriteria,
  OptionTitleContainer,
  OptionTitleText,
  OptionSubSectionTitle,
  OptionListItem,
  OptionListItemLink,
} from './OptionGroup.styles';

import { IOptionGroup, TMenuItemType } from 'src/types/menu.types';

const SelectionCriteria = (min?: number, max?: number): string => {
  if (min && max) return `Min of ${min} and Max of ${max}`;
  else if (min) return `Min of ${min}`;
  else if (max) return `Max of ${max}`;
  else return 'None';
};

export const OptionGroup = ({
  optionGroup,
  menuId,
  menuItemType,
}: {
  optionGroup: IOptionGroup;
  menuId: string;
  menuItemType: TMenuItemType;
}): ReactElement => {
  return (
    <OptionGroupWrapper key={optionGroup.optionGroupId}>
      <OptionTitleContainer>
        <OptionTitleText wrap={optionGroup.name.length >= 90 ? 'true' : 'false'}>{optionGroup.name}</OptionTitleText>
      </OptionTitleContainer>
      <OptionGroupDescription>
        {menuItemType === 'STANDARD' && (
          <>
            <OptionSubSectionTitle variant="strongText">Description</OptionSubSectionTitle>
            <Typography variant="mText">{optionGroup.description}</Typography>
          </>
        )}
        <OptionSubSectionTitle variant="strongText">
          {menuItemType === 'STANDARD' ? 'Modifiers' : 'Menu items'}
        </OptionSubSectionTitle>
        <List>
          {optionGroup.items.map((option) => {
            return (
              <OptionListItem key={option.optionItemId} variantMapping={{ mText: 'li' }} variant="mText">
                <OptionListItemLink
                  title={option.name}
                  to={`${menuId ? `/menu/${menuId}/item/${option.optionItemId}` : `/items/${option.parentItemId}`}`}
                  data-testid="OptionListItemLinks"
                >
                  {option.name}
                </OptionListItemLink>
                {menuItemType === 'BUNDLE' && option.posId && ` (${option.posId})`}
              </OptionListItem>
            );
          })}
        </List>
      </OptionGroupDescription>
      <OptionGroupCriteria>
        <OptionSubSectionTitle variant="strongText">Range of choices</OptionSubSectionTitle>
        {SelectionCriteria(parseInt(optionGroup.min), parseInt(optionGroup.max))}
      </OptionGroupCriteria>
    </OptionGroupWrapper>
  );
};

import { useContext } from 'react';
import { IconButton, Typography, Snackbar, Slide } from '@mui/material/';
import Alert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import { ErrorNotificationIcon, SuccessNotificationIcon } from 'src/assets/images/AlertIcons';
import colors from 'src/assets/theme/colors';
import { NotificationContext, useNotificationOutlet } from './';
import { NotificationWrapper, StyledCollapse } from './Notification.styles';

const { white, paleBlue } = colors;

const Notification = () => {
  const { notification } = useContext(NotificationContext);
  const setMessage = useNotificationOutlet();

  const { severity, description, message, statusCode, open = true } = { ...notification };

  const handleClose = () => {
    setMessage({ ...notification, open: false });
  };

  const IconMapping = {
    error: <ErrorNotificationIcon colour={white} />,
    success: <SuccessNotificationIcon colour={paleBlue} />,
  };

  const slideTransition = (props) => {
    return <Slide {...props} direction="down" />;
  };

  return (
    notification && (
      <NotificationWrapper>
        <Snackbar
          open={open}
          autoHideDuration={10000}
          onClose={handleClose}
          anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
          TransitionComponent={slideTransition}
          ClickAwayListenerProps={{ onClickAway: handleClose }}
        >
          <StyledCollapse in={open}>
            <Alert
              role="presentation"
              data-testid="Alert"
              variant="filled"
              iconMapping={IconMapping}
              severity={severity}
              color={severity}
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={handleClose}
                  data-testid="IconButton"
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {(description || statusCode) && (
                <Typography variant="mText" data-testid="AlertTitle" variantMapping={{ mText: 'h5' }}>
                  <strong>
                    {statusCode && `${statusCode} `}
                    {description && description}
                  </strong>
                </Typography>
              )}
              {message && (
                <Typography data-testid="AlertMessage" variant="mText" variantMapping={{ mText: 'p' }}>
                  {message}
                </Typography>
              )}
            </Alert>
          </StyledCollapse>
        </Snackbar>
      </NotificationWrapper>
    )
  );
};

export default Notification;

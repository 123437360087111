import { API_URL } from '../constants';
import { fetch } from '../fetchWrapper/fetch';

export const createNewMenuItem = (requestBody: FormData, menuId?: string, sectionId?: string): Promise<any> => {
  let url = `${API_URL}/items`;

  if (menuId) {
    if (sectionId) {
      url = `${API_URL}/menus/${menuId}/sections/${sectionId}/items`;
    } else {
      url = `${API_URL}/menus/${menuId}/items`;
    }
  }

  return fetch(url, {
    method: 'POST',
    body: requestBody,
  });
};

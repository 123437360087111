import { API_URL } from '../constants';

export const moveMenuItemToSection = (
  token: string,
  menuId: string,
  itemId: string,
  moveToSectionId: string,
  newDisplayOrder: number,
): Promise<any> =>
  fetch(`${API_URL}/menus/${menuId}/items/${itemId}`, {
    method: 'PUT',
    body: JSON.stringify({ section_id: moveToSectionId, display_order: newDisplayOrder }),
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

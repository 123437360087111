import { IMenu } from 'src/types/menu.types';

export const moveMenuSection = (
  menus: IMenu[],
  menuId: string,
  sectionId: string,
  newDisplayOrder: number,
): IMenu[] => {
  const foundMenu = menus.find((menu) => menu.menuId === menuId);
  const selectedSection = foundMenu.sections.find((section) => section.sectionId === sectionId);
  const foundMoveToSection = foundMenu.sections.find((section) => section.displayOrder === newDisplayOrder);
  foundMoveToSection.displayOrder = selectedSection.displayOrder;
  selectedSection.displayOrder = newDisplayOrder;
  foundMenu.sections.sort((a, b) => a.displayOrder - b.displayOrder);
  return [...menus];
};

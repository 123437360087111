import { ReactElement, useEffect } from 'react';
import { Typography } from '@mui/material';
import Prompt, {
  Container,
  StyledDialogAction,
  StyledTitle,
  StyledText,
  FullLengthButton,
  StyledFormControl,
} from '../Prompt';
import { FormInputText } from 'src/components';
import { useForm } from 'react-hook-form';
import { IFormOptionGroup } from '../menuItemForm/types';

export interface IPOSPromptProps {
  closePrompt?: () => void;
  open: boolean;
  optionName: string;
  setValue?: any;
  index: number;
  optionGroups?: IFormOptionGroup[];
}

const POSIdPrompt = ({
  closePrompt,
  open,
  optionName,
  setValue,
  index,
  optionGroups,
}: IPOSPromptProps): ReactElement => {
  const currentOption = optionName
    ? optionGroups[index].modifiers.find((option) => option.name === optionName)
    : undefined;

  const { control, getValues, reset } = useForm({
    defaultValues: {
      posId: '',
    },
  });

  useEffect(() => {
    reset({ posId: currentOption ? currentOption.posId : '' });
  }, [currentOption, open]);

  const saveButtonHandler = () => {
    const value = getValues();
    setValue(
      `modifier_groups.${index}.modifiers`,
      optionGroups[index].modifiers.map((option) => {
        if (option.name === optionName) {
          return { ...option, posId: value.posId };
        } else {
          return option;
        }
      }),
    );
    closePrompt();
  };

  return (
    <Prompt testId="pos-id-prompt" closePrompt={closePrompt} open={open}>
      <Container>
        <StyledTitle variant="xlText">
          <Typography variant="strongText">{optionName} POS ID</Typography>
        </StyledTitle>
        <StyledFormControl fullWidth onSubmit={saveButtonHandler}>
          <FormInputText name="posId" control={control} label="POS ID" rules={{ required: true }} />
        </StyledFormControl>
        <StyledDialogAction>
          <FullLengthButton
            data-testid="save-pos-id-button"
            color="primary"
            variant="contained"
            onClick={saveButtonHandler}
          >
            <StyledText>Save</StyledText>
          </FullLengthButton>
        </StyledDialogAction>
      </Container>
    </Prompt>
  );
};

export default POSIdPrompt;

import { IMenu } from 'src/types/menu.types';

export const addMenu = (existingMenus: IMenu[], newMenu: IMenu): IMenu[] => {
  const menuExists = existingMenus.find((menu) => menu.menuId === newMenu.menuId);
  if (menuExists) {
    return existingMenus.map((existingMenu) => {
      if (existingMenu.menuId === newMenu.menuId) {
        return {
          ...existingMenu,
          ...newMenu,
        };
      }
      return existingMenu;
    });
  } else {
    return [newMenu, ...existingMenus];
  }
};

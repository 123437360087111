import MuiAccordion from './MuiAccordion';
import MuiAccordionSummary from './MuiAccordionSummary';
import MuiDrawer from './MuiDrawer';
import MuiPopover from './MuiPopover';
import MuiAlert from './MuiAlert';
import MuiAlertTitle from './MuiAlertTitle';
import MuiLoadingButton from './MuiLoadingButton';
import TextField from './TextField';
import MuiSnackbar from './MuiSnackbar';

const MuiOverrides = {
  components: {
    ...MuiAccordion,
    ...MuiAccordionSummary,
    ...MuiDrawer,
    ...MuiPopover,
    ...MuiAlert,
    ...MuiAlertTitle,
    ...MuiLoadingButton,
    ...TextField,
    ...MuiSnackbar,
  },
};
export default MuiOverrides;

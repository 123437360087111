import MontserratRegularWoff from '../fonts/montserrat-v23-latin-regular.woff';
import MontserratRegularWoff2 from '../fonts/montserrat-v23-latin-regular.woff2';
import MontserratMediumWoff from '../fonts/montserrat-v23-latin-500.woff';
import MontserratMediumWoff2 from '../fonts/montserrat-v23-latin-500.woff2';
import MontserratSemiBoldWoff from '../fonts/montserrat-v23-latin-600.woff';
import MontserratSemiBoldWoff2 from '../fonts/montserrat-v23-latin-600.woff2';

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    xsText: true;
    sText: true;
    mText: true;
    lText: true;
    xlText: true;
    xxlText: true;
    strongText: true;
    buttonText: true;
  }
}

const pxToRem = (px: number): string => `${px / 16}rem`;

export const MuiTypography = {
  MuiTypography: {
    defaultProps: {
      variantMapping: {
        mText: 'span',
        lText: 'h3',
        xlText: 'h2',
        xxlText: 'h1',
        strongText: 'strong',
      },
    },
  },
};

export const MuiCssBaseline = {
  MuiCssBaseline: {
    styleOverrides: `
        @font-face {
          font-family: 'Montserrat';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('Montserrat'), local('Montserrat-Regular'), url(${MontserratRegularWoff2}) format('woff2'),
               local('Montserrat'), local('Montserrat-Regular'), url(${MontserratRegularWoff}) format('woff');
        }
        @font-face {
            font-family: 'Montserrat';
            font-style: normal;
            font-display: swap;
            font-weight: 500;
            src: local('Montserrat'), local('Montserrat-Medium'), url(${MontserratMediumWoff2}) format('woff2'),
                 local('Montserrat'), local('Montserrat-Medium'), url(${MontserratMediumWoff}) format('woff');
        }
        @font-face {
          font-family: 'Montserrat';
          font-style: normal;
          font-display: swap;
          font-weight: 600;
          src: local('Montserrat'), local('Montserrat-SemiBold'), url(${MontserratSemiBoldWoff2}) format('woff2'),
               local('Montserrat'), local('Montserrat-SemiBold'), url(${MontserratSemiBoldWoff}) format('woff');
        }`,
  },
};

const typography = {
  fontFamily: 'Montserrat, sans-serif',
  xsText: {
    fontSize: pxToRem(12),
    fontWeight: 400,
    lineHeight: 1.25,
    fontFamily: 'Montserrat, sans-serif',
  },
  sText: {
    fontSize: pxToRem(14),
    fontWeight: 400,
    lineHeight: 1.215,
    fontFamily: 'Montserrat, sans-serif',
  },
  mText: {
    fontSize: pxToRem(16),
    lineHeight: 1.25,
    fontWeight: 400,
    fontFamily: 'Montserrat, sans-serif',
  },
  lText: {
    fontSize: pxToRem(18),
    lineHeight: 1.22,
    fontWeight: 400,
    fontFamily: 'Montserrat, sans-serif',
  },
  xlText: {
    fontSize: pxToRem(24),
    lineHeight: 1.2,
    fontWeight: 400,
    fontFamily: 'Montserrat, sans-serif',
  },
  xxlText: {
    fontSize: pxToRem(36),
    lineHeight: 1.22,
    fontWeight: 400,
    fontFamily: 'Montserrat, sans-serif',
  },
  strongText: {
    fontWeight: 600,
  },
  buttonText: {
    fontSize: pxToRem(14),
    fontWeight: 600,
    lineHeight: 1.215,
    fontFamily: 'Montserrat, sans-serif',
  },
};

export default typography;

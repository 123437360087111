import { IMenuItem, TMenuItemType, IMenu } from 'src/types/menu.types';
import { getMenuItemsInMenu } from '../';

const getItemOptions = (menu: IMenu | undefined, type: TMenuItemType, items?: IMenuItem[]): IMenuItem[] => {
  if (menu) {
    return type === 'STANDARD' ? menu?.modifiers : getMenuItemsInMenu(menu);
  } else {
    return type === 'STANDARD'
      ? items.filter((i: IMenuItem) => i.type === 'MODIFIER')
      : items.filter((i: IMenuItem) => i.type !== 'BUNDLE');
  }
};

export default getItemOptions;

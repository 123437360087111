import { SvgIcon } from '@mui/material';

export const EditIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.378 6.90387L5.27838 14.0035H2.00163L1.99951 10.7246L9.09914 3.625L12.378 6.90387Z"
          stroke="white"
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.10107 3.62103L10.3237 2.39844C10.8549 1.86719 11.7162 1.86719 12.2475 2.39844L13.6025 3.75349C14.1338 4.28473 14.1338 5.14606 13.6025 5.6773L12.3799 6.89989V6.89989"
          stroke="white"
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};

export default EditIcon;

import { SvgIcon } from '@mui/material';

const ChannelsClipboard = (props) => {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M3.18083 6.66667H24.5142V2.66667H3.18083V6.66667ZM16.5142 21.3333V9.33333H11.1808V21.3333H16.5142ZM19.1808 21.3333H24.5142V9.33333H19.1808V21.3333ZM8.51416 21.3333V9.33333H3.18083V21.3333H8.51416ZM1.84749 0H25.8475C26.2011 0 26.5403 0.140476 26.7903 0.390524C27.0404 0.640573 27.1808 0.979711 27.1808 1.33333V22.6667C27.1808 23.0203 27.0404 23.3594 26.7903 23.6095C26.5403 23.8595 26.2011 24 25.8475 24H1.84749C1.49387 24 1.15473 23.8595 0.904684 23.6095C0.654636 23.3594 0.51416 23.0203 0.51416 22.6667V1.33333C0.51416 0.979711 0.654636 0.640573 0.904684 0.390524C1.15473 0.140476 1.49387 0 1.84749 0V0Z"
          fill="#4891C7"
        />
      </svg>
    </SvgIcon>
  );
};

export default ChannelsClipboard;

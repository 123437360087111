import { IListProps } from 'src/components/List/List';
import { IAggregator } from 'src/types/aggregator.types';
import { IItemPrice } from 'src/types/menu.types';

export const mapPriceBandToList = (itemPrice: IItemPrice, aggregators: IAggregator[]): IListProps[] => {
  return itemPrice.priceBands.map((priceBand) => {
    return {
      listItemPrimaryText: 'Price Band',
      listItemSecondaryText: priceBand.name,
      listItemId: priceBand.priceBandId,
      listItemDetails: aggregators.map((aggregator) => {
        const price = priceBand.aggregators.find((itemPrice) => itemPrice.aggregatorId === aggregator.aggregatorId);
        return {
          primaryText: `${aggregator.name} (£)`,
          id: aggregator.aggregatorId,
          name: aggregator.name,
          value: (price ? price.price : itemPrice.defaultPrice).toString(),
        };
      }),
    };
  });
};

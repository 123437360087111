import { useState, useEffect } from 'react';
import type { ReactElement, ChangeEvent, BaseSyntheticEvent } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import {
  LeftAlignedContainer,
  PublishDestinationContainer,
  PublishViewContainer,
  StyledPublishStatus,
  StyledStepText,
  StyledSubText,
  StyledText,
  VenueContainer,
} from '../PublishModal.styles';
import MainButton from '../../MainButton/MainButton';
import { fetchChannelsVenues } from 'src/api';
import { useNotificationOutlet } from 'src/components/Notification';
import { Checkbox } from '../../Checkbox/Checkbox';
import { isChannelSelected } from '../selection/isChannelSelected/isChannelSelected';
import { selectAllVenues } from '../selection/selectAllVenues/selectAllVenues';
import { isVenueSelected } from '../selection/isVenueSelected/isVenueSelected';
import { selectedVenue } from '../selection/selectedVenue/selectedVenue';
import { publishMenu } from '../../../api/publishMenu/publishMenu';
import { useOktaAuth } from '@okta/okta-react';
import { IPublishDestination, IPublishViewProps, ISelectedDestination } from '../types';
import { getDetailedPublicationStatus } from 'src/helpers/getPublicationStatus/getPublicationStatus';
import { mergeChannelsAndStatuses } from '../mergeChannelsAndStatuses/mergeChannelsAndStatuses';
import { getMenuService } from 'src/services/getMenuService/getMenuService';
import { IChannelsVenues } from 'src/api/fetchChannelsVenues/types';
import { getMenu } from 'src/api/getMenu/getMenu';
import { IMenuResponse } from 'src/api/getMenu/types';

const PublishView = ({
  closeModal,
  isOpen,
  menuId,
  menuName,
  selectedMenu,
  publicationData,
}: IPublishViewProps): ReactElement => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [channels, setChannels] = useState([]);
  const { authState } = useOktaAuth();
  const accessToken = authState?.accessToken?.accessToken;
  const [selectedDestinations, setSelectedDestinations] = useState<ISelectedDestination[]>(selectAllVenues(channels));
  const createNotification = useNotificationOutlet();
  const buttonLabelText = <Typography variant="strongText">Publish</Typography>;
  const menusToPublish = selectedMenu ? [menuId, selectedMenu] : [menuId];

  const errorNotificationChannelVenues = {
    severity: 'error',
    message: 'Something went wrong fetching Channels and Venues',
  };

  const errorNotification = {
    severity: 'error',
    message: (
      <>
        Something went wrong whilst publishing <Typography variant="strongText">{menuName}</Typography>
      </>
    ),
  };

  const successNotification = {
    severity: 'success',
    message: (
      <>
        You have successfully published <Typography variant="strongText">{menuName}</Typography> to{' '}
        <Typography variant="strongText">{selectedDestinations.map((dest) => dest.channel).join(', ')}</Typography>
      </>
    ),
  };

  const selectChannel = (event: BaseSyntheticEvent, destination: IPublishDestination): void => {
    const checked: boolean = event.target.checked;
    if (checked) {
      const newSelection = selectAllVenues([destination])[0];
      setSelectedDestinations([...selectedDestinations, newSelection]);
    } else {
      setSelectedDestinations(selectedDestinations.filter((dest) => dest.channel !== destination.name));
    }
  };

  const selectVenue = (event: BaseSyntheticEvent, destination: IPublishDestination, venue_id: string): void => {
    const checked: boolean = event.target.checked;
    const existingChannel = selectedDestinations.find((dest) => dest.channel === destination.name);
    const currentSelectedDestinations = selectedDestinations.filter(
      (dest) => dest.channel_id !== destination.channel_id,
    );

    if (existingChannel && checked) {
      existingChannel.selectedVenues.push(venue_id);
      setSelectedDestinations([
        ...currentSelectedDestinations,
        selectedVenue([destination], existingChannel.selectedVenues)[0],
      ]);
    } else if (!existingChannel && checked) {
      setSelectedDestinations([...currentSelectedDestinations, selectedVenue([destination], [venue_id])[0]]);
    } else {
      if (existingChannel.selectedVenues.length === 1) {
        setSelectedDestinations(currentSelectedDestinations);
      } else {
        setSelectedDestinations([
          ...currentSelectedDestinations,
          selectedVenue(
            [destination],
            existingChannel.selectedVenues.filter((venue) => venue !== venue_id),
          )[0],
        ]);
      }
    }
  };

  useEffect(() => {
    (async () => {
      if (isOpen) {
        setIsLoading(true);
        let pubData = publicationData;
        try {
          if (!publicationData) {
            const response = await getMenu(accessToken, menuId);
            const menuData: IMenuResponse = await response.json();
            const menu = getMenuService(menuData.data[0]);
            pubData = menu.publication;
          }
          const channelsResponse: IChannelsVenues[] = await fetchChannelsVenues()
            .then((res) => res.json())
            .then((res) => res?.data);
          const channelsList: IPublishDestination[] = mergeChannelsAndStatuses(pubData, channelsResponse);
          setChannels(channelsList);
          setSelectedDestinations(selectAllVenues(channelsList.filter((channel) => channel.venues.length > 0)));
        } catch (e) {
          createNotification(errorNotificationChannelVenues);
        } finally {
          setIsLoading(false);
        }
      }
    })();
  }, [accessToken, isOpen]);

  const isChannelDisabled = (channel) => {
    if (channel.venues.length === 0) {
      return true;
    }
    return false;
  };

  const handlePublish = () => {
    if (!isSubmitting) {
      setIsSubmitting(true);
      const payload = {
        menus: menusToPublish,
        channels: selectedDestinations.map((selectedDestination) => {
          return { channel_id: selectedDestination.channel_id, venues: selectedDestination.selectedVenues };
        }),
      };

      publishMenu({
        token: accessToken,
        payload,
      })
        .then((response) => {
          if (response === 202) {
            createNotification(successNotification);
            closeModal();
            setSelectedDestinations(selectAllVenues(channels.filter((channel) => channel.venues.length > 0)));
          } else {
            createNotification(errorNotification);
          }
        })
        .catch(() => {
          createNotification(errorNotification);
        })
        .finally(() => setIsSubmitting(false));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    handlePublish();
  };

  return (
    <PublishViewContainer>
      {isLoading ? (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      ) : (
        <>
          <StyledStepText>
            <Typography component={'span'}>Step 2 of 2</Typography>
          </StyledStepText>
          <form onSubmit={handleSubmit}>
            <StyledText variant="xxlText">
              <Typography variant="strongText">Publish destination</Typography>
            </StyledText>
            <LeftAlignedContainer>
              <StyledSubText variant="mText" variantMapping={{ mText: 'h2' }}>
                <Typography variant="strongText">Send menu to:</Typography>
              </StyledSubText>

              {channels.map((publishDestination) => (
                <PublishDestinationContainer key={publishDestination.name}>
                  <Checkbox
                    label={publishDestination.name}
                    isChecked={
                      isChannelDisabled(publishDestination)
                        ? false
                        : isChannelSelected(publishDestination.name, selectedDestinations)
                    }
                    onChange={(e: ChangeEvent) => {
                      selectChannel(e, publishDestination);
                    }}
                    disabled={isChannelDisabled(publishDestination)}
                  />
                  <StyledPublishStatus variant="sText">
                    {publishDestination.publicationDate &&
                      getDetailedPublicationStatus(
                        publishDestination.publicationStatus,
                        publishDestination.publicationDate,
                      )}
                  </StyledPublishStatus>
                  <VenueContainer>
                    {publishDestination.venues.map((venue) => (
                      <Box key={venue.venue_id} display="flex" flexDirection="column">
                        <Checkbox
                          key={venue.venue_id}
                          label={venue.name}
                          isChecked={isVenueSelected(publishDestination.name, selectedDestinations, venue.venue_id)}
                          onChange={(e: ChangeEvent) => {
                            selectVenue(e, publishDestination, venue.venue_id);
                          }}
                        />
                        <StyledPublishStatus variant="sText">
                          {venue.publicationDate &&
                            getDetailedPublicationStatus(venue.publicationStatus, venue.publicationDate)}
                        </StyledPublishStatus>
                      </Box>
                    ))}
                  </VenueContainer>
                </PublishDestinationContainer>
              ))}
            </LeftAlignedContainer>
            <MainButton loading={isSubmitting} type="submit" text={buttonLabelText} />
          </form>
        </>
      )}
    </PublishViewContainer>
  );
};

export default PublishView;

import { API_URL } from '../constants';
import { fetch } from '../fetchWrapper/fetch';

export const updateMenuSection = (
  menuId: string,
  sectionId: string,
  body: { name: string; description: string },
): Promise<any> => {
  return fetch(`${API_URL}/menus/${menuId}/sections/${sectionId}`, {
    method: 'PUT',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

import colors from 'src/assets/theme/colors';

interface IDashedBox {
  color?: string;
  hover?: boolean;
}

export const DashedBorderBox = ({ color = colors.AZT_Dark, hover = false }: IDashedBox): string => {
  return `data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='${encodeURIComponent(
    color,
  )}' stroke-width='2' stroke-dasharray='${
    hover ? '0' : '6%2c 6'
  }' stroke-dashoffset='36' stroke-linecap='square'/%3e%3c/svg%3e`;
};

export default DashedBorderBox;

import { deleteModifierGroup } from 'src/api/deleteModifierGroup/deleteModifierGroup';
import { createModifierGroup } from 'src/api/createModifierGroup/createModifierGroup';
import { updateModifierGroup } from 'src/api/updateModifierGroup/updateModifierGroup';
import {
  arrayOfObjectsEqual,
  findUpdatedOptionGroups,
  formatCurrentOptionGroup,
  formatNewOptionGroupData,
  isPosIdChanged,
} from '..';
import { IOptionGroupPayload } from 'src/api/updateModifierGroup/types';
import { IMenuItem } from 'src/types/menu.types';
import { INewOptionGroup } from 'src/api/createNewMenuItem/types';

const createUpdateOptionGroupRequest = (
  optionGroupsToDelete: string[],
  allItemOptions: IMenuItem[],
  currentItem: IMenuItem,
  updatedOptionGroup: INewOptionGroup[],
): Promise<any>[] => {
  const updatePromises: Promise<any>[] = [];

  if (optionGroupsToDelete.length > 0) {
    updatePromises.push(
      ...optionGroupsToDelete.map((optionsGroupId: string) => deleteModifierGroup(currentItem.itemId, optionsGroupId)),
    );
  }

  if (currentItem.optionGroup?.length > 0 || updatedOptionGroup.length > 0) {
    const currentOptionGroups = formatCurrentOptionGroup(currentItem.optionGroup, allItemOptions);
    const newOptionsGroup = formatNewOptionGroupData(updatedOptionGroup, allItemOptions);
    const isOptionsDataChanged = !!currentOptionGroups && !arrayOfObjectsEqual(currentOptionGroups, newOptionsGroup);

    if (isOptionsDataChanged) {
      const updatedOptionsGroups = findUpdatedOptionGroups(currentOptionGroups, newOptionsGroup);

      if (updatedOptionsGroups.length !== 0) {
        updatePromises.push(
          ...updatedOptionsGroups.map((updatedGroup: { id: string; updatedData: any }) =>
            updateModifierGroup(currentItem.itemId, updatedGroup.id, updatedGroup.updatedData),
          ),
        );
      }

      updatePromises.push(
        ...newOptionsGroup.map((optionGroup: IOptionGroupPayload) => {
          if (!optionGroup.pk_modifier_group_id) {
            const newFormat = {
              ...optionGroup,
              max_choices: optionGroup.max_choices,
              min_choices: optionGroup.min_choices,
              modifiers: optionGroup.modifiers.map((option) => {
                const newOption: { parent_item_id?: string; plu?: string } = {
                  parent_item_id: option.parent_item_id,
                };
                const optionFormat = {
                  parentItemId: option.parent_item_id,
                  posId: option.plu,
                };
                if (isPosIdChanged(optionFormat, allItemOptions)) newOption.plu = option.plu;
                return newOption;
              }),
            };
            return createModifierGroup(currentItem.itemId, newFormat).then((res) => res.json());
          }
        }),
      );
    }
  }

  return updatePromises;
};

export default createUpdateOptionGroupRequest;

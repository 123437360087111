import { IMenu, ISection } from 'src/types/menu.types';

export const createMenuSection = (
  menus: IMenu[],
  menuId: string,
  section: Omit<ISection, 'items'>,
  itemIds: string[],
): IMenu[] => {
  const newMenus = menus.map((menu) => {
    if (menu.menuId === menuId) {
      const newSection: ISection = {
        ...section,
        items: menu.uncategorised.filter((item) => itemIds.includes(item.itemId)),
      };
      return {
        ...menu,
        sections: [...menu.sections, newSection],
        uncategorised: menu.uncategorised.filter((item) => !itemIds.includes(item.itemId)),
      };
    }
    return menu;
  });
  return newMenus;
};

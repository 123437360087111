import { arrayOfObjectsEqual } from 'src/components/menuItemForm/utilities';

const findUpdatedDataInObject = (originalObj, newObj) => {
  const updatedData = {};

  Object.keys(originalObj).forEach((key) => {
    const originalValue = originalObj[key];
    const newValue = newObj[key];

    const isEqualValue = originalValue === newValue;
    const isEqualArray = Array.isArray(originalValue) && arrayOfObjectsEqual(originalValue, newValue);

    if (isEqualValue || isEqualArray) return;

    // add to updatedData object only the data that is updated
    updatedData[key] = newValue;
  });

  return updatedData;
};

export default findUpdatedDataInObject;

import { findUpdatedDataInObject, objectsEqual } from 'src/helpers';
import { IOptionGroupPayload } from 'src/api/updateModifierGroup/types';

const findUpdatedOptionGroups = (originalArr: IOptionGroupPayload[], newArr: IOptionGroupPayload[]) => {
  const updatedOptionGroups = [];

  newArr.forEach((currentOptionGroup) => {
    // find option group object in original array
    const originalOptionGroup = originalArr.find(
      (optionGroup) => optionGroup.pk_modifier_group_id === currentOptionGroup.pk_modifier_group_id,
    );

    // return if option group was not present in the original array (as that is “create” and not “update” logic)
    if (!originalOptionGroup) return;

    // compare option group objects from original and new arrays
    const isOptionGroupUpdated = !objectsEqual(currentOptionGroup, originalOptionGroup);

    // return if option group does not have any changes
    if (!isOptionGroupUpdated) return;

    // find data that has been updated in an option group
    const updatedData = findUpdatedDataInObject(originalOptionGroup, currentOptionGroup);

    // create an object with id and updatedData for update api call
    const optionGroupWithUpdatedData = {
      id: originalOptionGroup.pk_modifier_group_id,
      updatedData,
    };

    updatedOptionGroups.push(optionGroupWithUpdatedData);
  });

  return updatedOptionGroups;
};

export default findUpdatedOptionGroups;

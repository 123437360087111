import { theme } from 'src/assets/theme/theme';
import colors from 'src/assets/theme/colors';
import { styled, Box, IconButton, Avatar, Menu, MenuItem, Typography } from '@mui/material';

interface AvatarProps {
  open: boolean;
}

export const StyledBox = styled(Box)`
  display: flex;
  top: 0;
  flex-direction: row-reverse;
  justify-content: space-between;
  z-index: 100;
  width: 100%;

  ${(props) => props.theme.breakpoints.down('md')} {
    flex-direction: row;
    padding-left: 4px;
  }
`;

export const StyledIconButton = styled(IconButton)`
  padding: 0;
`;

export const StyledAvatar = styled(Avatar)<AvatarProps>(({ open }) => ({
  backgroundColor: open ? `${colors.navy}` : `${colors.lightBlue}`,
  width: `${theme.spacing(12.5)}`,
  height: `${theme.spacing(12.5)}`,
  fontSize: `${theme.spacing(4.5)}`,
  '&:hover': {
    backgroundColor: `${colors.navy}`,
  },
}));

export const StyledMenu = styled(Menu)`
  margin-top: ${theme.spacing(1.5)};
`;

export const StyledMenuItem = styled(MenuItem)({
  padding: `${theme.spacing(5.25)} ${theme.spacing(12.5)} ${theme.spacing(5.25)} ${theme.spacing(5)}`,
  '&:nth-of-type(1)': {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  '&.Mui-disabled': {
    opacity: 1,
  },
});

export const StyledAvatarWithinModal = styled(Avatar)`
  background-color: ${colors.lightBlue};
  width: ${theme.spacing(12.5)};
  height: ${theme.spacing(12.5)};
  margin-right: ${theme.spacing(2.25)};
`;

export const StyledLogout = styled(Typography)`
  color: ${colors.violetRed};
`;

export const StyledMenuIconButton = styled(IconButton)`
  color: ${colors.navy};
  ${(props) => props.theme.breakpoints.up('md')} {
    display: none;
  }
`;

import { useMemo, useState } from 'react';
import type { ReactElement } from 'react';
import ModalImg from '../../assets/images/chef-plating.png';
import { ModalWrapper } from 'src/components';
import PublishView from './PublishView';
import AdditionalMenuView from './AdditionalMenuView';
import { StyledContainer } from './PublishModal.styles';
import { useMenusContext } from 'src/contexts/menus';
import { IModalProps } from './types';

const PublishModal = ({ closeModal, isOpen, menuId, publicationData }: IModalProps): ReactElement => {
  const [step, setStep] = useState<number>(1);
  const { menus, getAMenuById } = useMenusContext();
  const currentMenu = getAMenuById(menuId);
  const [linkedMenuId, setLinkedMenuId] = useState<string>(currentMenu.linkedMenuIds[0] ?? '');

  const filteredMenusOptions = useMemo(() => {
    return menus.filter((menu) => menu.menuId !== menuId);
  }, [menus]);

  const onCloseModal = () => {
    closeModal();
    setStep(1);
  };

  return (
    <ModalWrapper
      closeModal={onCloseModal}
      open={isOpen}
      image={ModalImg}
      imageAlt={'Picture of a chef plating up'}
      variant={'publish'}
    >
      <>
        <StyledContainer>
          {step === 1 ? (
            <AdditionalMenuView
              onChangeStep={setStep}
              selectableMenus={filteredMenusOptions}
              selectedMenuId={linkedMenuId}
              setSelectedMenuId={setLinkedMenuId}
            />
          ) : (
            <PublishView
              closeModal={onCloseModal}
              isOpen={isOpen}
              menuId={menuId}
              menuName={currentMenu.name}
              selectedMenu={linkedMenuId}
              publicationData={publicationData}
            />
          )}
        </StyledContainer>
      </>
    </ModalWrapper>
  );
};

export default PublishModal;

import { ICreateNewMenuItemBody, INewOptionGroup } from 'src/api/createNewMenuItem/types';
import { IPriceBandPayload } from 'src/api/types';
import { IMenuItem } from 'src/types/menu.types';
import { formatPriceBandPayload, isPosIdChanged } from '../../utilities';
import { IFormPriceBand } from '../../types';
import { IUpdateMenuItemBody } from 'src/api/updateMenuItem/types';

const formatPayload = (
  data: any,
  variant: string,
  newImage: File,
  allItemOptions: IMenuItem[],
): ICreateNewMenuItemBody | IUpdateMenuItemBody => {
  const payload: ICreateNewMenuItemBody = {
    name: data.name,
    description: data.description ? data.description : null,
    prices: JSON.stringify({
      default_base_price_with_tax: parseFloat(data.defaultPrice),
      tax_rate: parseFloat(data.tax_rate),
      price_bands: data.price_bands.map(
        (priceBand: IFormPriceBand): IPriceBandPayload => formatPriceBandPayload(priceBand, parseFloat(data.tax_rate)),
      ),
    }),
    plu: data.plu,
    recipe_id: data.recipe_id,
    currency: data.currency,
    type: data.type,
    modifier_groups: JSON.stringify(
      data.modifier_groups &&
        data.modifier_groups.map((modifier_group: INewOptionGroup) => ({
          pk_modifier_group_id: modifier_group.pk_modifier_group_id,
          name: modifier_group.name,
          description: modifier_group.description,
          modifiers: modifier_group.modifiers.map((option) => {
            const newOption: { parent_item_id?: string; item_id?: string; plu?: string } = option.optionItemId
              ? { item_id: option.optionItemId }
              : { parent_item_id: option.parentItemId };
            if (isPosIdChanged(option, allItemOptions)) newOption.plu = option.posId;
            return newOption;
          }),
          min_choices: modifier_group.min_choices,
          max_choices: modifier_group.max_choices,
        })),
    ),
  };

  if (data.files) payload.files = data.files.length > 0 ? data.files : newImage;

  if (data.type === 'BUNDLE') {
    delete payload.recipe_id;
  }

  if (data.type === 'MODIFIER' || variant === 'update') {
    delete payload.modifier_groups;
  }

  if (variant === 'update') {
    const updatePayload: IUpdateMenuItemBody = {
      ...payload,
      prices: JSON.stringify({
        default_base_price_with_tax: parseFloat(data.defaultPrice),
        tax_rate: parseFloat(data.tax_rate),
      }),
    };

    return updatePayload;
  }

  return payload;
};

export default formatPayload;

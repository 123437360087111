import { API_URL } from '../constants';

export const getMenu = async (token: string, menuId: string): Promise<any> => {
  const brandId = localStorage.getItem('brandId');
  const url = `${API_URL}/menus/${menuId}`;
  return fetch(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'brand-id': brandId,
    },
  });
};

import { useState, useEffect, ReactElement, useRef } from 'react';
import { Header, MainButton } from 'src/components';
import { StyledTypography } from './MenuItemEdit.style';
import { CircularProgress, Typography } from '@mui/material';
import { SpinnerContainer } from '../Dashboard/Dashboard.styles';
import { useOktaAuth } from '@okta/okta-react';
import { getMenuItem } from 'src/api/getMenuItem/getMenuItem';
import { useNotificationOutlet } from 'src/components/Notification';
import { error } from 'src/mocks/data';
import MenuItemForm from 'src/components/menuItemForm';
import { useMenusContext } from 'src/contexts/menus';
import { IMenuItem } from 'src/types/menu.types';
import { IMenuResponse } from 'src/api/getMenu/types';
import { formatMenuItems, getMenuService } from 'src/services/getMenuService/getMenuService';
import { getMenu } from 'src/api/getMenu/getMenu';
import { EditChildItemPrompt } from 'src/components/EditChildItemPrompt/EditChildItemPrompt';

const MenuItemEdit = ({ match }: { match: Record<string, any> }): ReactElement => {
  const { authState } = useOktaAuth();
  const [currentItem, setCurrentItem] = useState<IMenuItem>();
  const { getAMenuById, isLoading, addNewMenu, addAMenuItem } = useMenusContext();
  const [isMenuLoading, setIsMenuLoading] = useState<boolean>(true);
  const accessToken = authState?.accessToken?.accessToken;
  const menuId: string = match?.params?.menuId;
  const itemId: string = match?.params?.itemId;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const setMessage = useNotificationOutlet();
  const currentMenu = getAMenuById(menuId);
  const [hasErrors, setHasErrors] = useState<boolean>(false);
  const [isChildItemPromptOpen, setIsChildItemPromptOpen] = useState<boolean>(false);
  const refSubmitButtom = useRef<HTMLButtonElement>(null);

  const submitForm = () => {
    refSubmitButtom?.current?.click();
  };

  const setSubmittingState = () => {
    setIsSubmitting(true);
    setTimeout(() => {
      setIsSubmitting(false);
    }, 2500);
  };

  const triggerSubmit = () => {
    if (isSubmitting) return;
    if (currentItem.parentItemId && !hasErrors) {
      setIsChildItemPromptOpen(true);
    } else {
      submitForm();
      setSubmittingState();
    }
  };

  const HiddenButton = <button hidden={true} ref={refSubmitButtom} type={'submit'} disabled={isSubmitting} />;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const viewItem = await getMenuItem(itemId);
        const menuItem = formatMenuItems([viewItem])[0];
        if (!isLoading && currentMenu) {
          if (
            currentMenu.uncategorised.length === 0 &&
            currentMenu.modifiers.length === 0 &&
            currentMenu.sections.length === 0 &&
            isMenuLoading
          ) {
            const response = await getMenu(accessToken, menuId);
            const menuData: IMenuResponse = await response.json();
            addNewMenu(getMenuService(menuData.data[0]));
            setIsMenuLoading(false);
          } else {
            addAMenuItem(menuId, itemId, menuItem);
          }
        }

        if (!isLoading && !isMenuLoading) {
          addAMenuItem(menuId, itemId, menuItem);
        }

        setCurrentItem(menuItem);
      } catch (_e) {
        setMessage(error);
      }
    };
    fetchData();
  }, [accessToken, itemId, setCurrentItem, isLoading, isMenuLoading]);

  const HeadingTitle = (): JSX.Element => {
    return (
      <StyledTypography variant="xxlText">
        <Typography variant="strongText">{currentItem.name}</Typography>
      </StyledTypography>
    );
  };
  const HeaderButton = (): ReactElement => {
    return (
      <div>
        <MainButton
          type="button"
          text="Save"
          loading={!hasErrors && isSubmitting}
          size="small"
          textFormat="buttonText"
          onClick={() => triggerSubmit()}
        />
      </div>
    );
  };

  return (
    <>
      {isLoading || (isMenuLoading && currentItem === undefined) ? (
        <SpinnerContainer>
          <CircularProgress color="secondary" data-testid="loading-spinner" />
        </SpinnerContainer>
      ) : (
        <>
          <EditChildItemPrompt
            menuName={currentItem.name}
            isOpen={isChildItemPromptOpen}
            closePrompt={() => setIsChildItemPromptOpen(false)}
            saveButtonHandler={() => {
              submitForm();
              setSubmittingState();
            }}
          />
          <Header
            headingTitle={<HeadingTitle />}
            backBtn
            backBtnPath={menuId ? `/menu/${menuId}` : '/items'}
            rightSideContents={<HeaderButton />}
          />
          <MenuItemForm
            currentItem={currentItem}
            variant="update"
            hiddenButton={HiddenButton}
            setHasErrors={setHasErrors}
          />
        </>
      )}
    </>
  );
};

export default MenuItemEdit;

import { CircularProgress, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { ReactElement, useRef, useState } from 'react';
import { Header } from 'src/components';
import { MainButton } from 'src/components';
import { ButtonContainer } from './MenuItemsCreate.style';
import { SpinnerContainer } from '../Dashboard/Dashboard.styles';
import MenuItemForm from 'src/components/menuItemForm';
import { useRecipesContext } from 'src/contexts/recipes';

interface IParams {
  menuId: string;
}

export const MenuItemCreate = (): ReactElement => {
  const { menuId } = useParams<IParams>();
  const { isLoading } = useRecipesContext();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasErrors, setHasErrors] = useState<boolean>(false);

  const backButtonUrl: string = menuId ? `/menu/${menuId}` : '/items';

  const refSubmitButton = useRef<HTMLButtonElement>(null);

  const triggerSubmit = () => {
    if (isSubmitting) return;
    refSubmitButton?.current?.click();
    setIsSubmitting(true);
    setTimeout(() => {
      setIsSubmitting(false);
    }, 2500);
  };

  const HiddenButton = <button hidden={true} ref={refSubmitButton} type={'submit'} disabled={isSubmitting} />;

  const HeaderButton = (): ReactElement => {
    return (
      <ButtonContainer>
        <MainButton
          type="submit"
          text="Create"
          size="small"
          loading={!hasErrors && isSubmitting}
          textFormat="buttonText"
          onClick={() => triggerSubmit()}
        />
      </ButtonContainer>
    );
  };

  const HeadingTitle = (): ReactElement => {
    return <Typography variant="xxlText">New menu item</Typography>;
  };

  return (
    <>
      {isLoading ? (
        <SpinnerContainer>
          <CircularProgress color="secondary" data-testid="loading-spinner" />
        </SpinnerContainer>
      ) : (
        <>
          <Header
            backBtn
            backBtnPath={backButtonUrl}
            headingTitle={<HeadingTitle />}
            rightSideContents={<HeaderButton />}
          />
          <MenuItemForm variant="create" hiddenButton={HiddenButton} setHasErrors={setHasErrors} />
        </>
      )}
    </>
  );
};

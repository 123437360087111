import { theme } from 'src/assets/theme/theme';
import colors from 'src/assets/theme/colors';
import { styled, Box, TextField, Popper, Chip } from '@mui/material';
import InfoSharpIcon from '@mui/icons-material/InfoSharp';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

const imageUploadSize = theme.spacing(81);

export const FormContainer = styled(Box)`
  display: flex;
  width: 100%;
  justify-content: space-between;

  ${(props) => props.theme.breakpoints.down('md')} {
    flex-direction: column;
  }
`;

export const LeftContentBox = styled(Box)`
  padding-right: ${theme.spacing(5)};
  width: ${theme.spacing(182.75)};
  margin-bottom: ${theme.spacing(5)};

  ${(props) => props.theme.breakpoints.down('md')} {
    width: 100%;
  }
`;

export const RightContentBox = styled(Box)`
  width: ${imageUploadSize};
  max-width: ${theme.spacing(125)};

  margin-bottom: ${theme.spacing(5)};

  ${(props) => props.theme.breakpoints.up('md')} {
    width: ${imageUploadSize};
  }

  ${(props) => props.theme.breakpoints.down('sm')} {
    width: 100%;
  }
`;

export const TextContainer = styled(Box)`
  display: flex;
  align-items: center;
  width: ${theme.spacing(81)};
`;

export const StyledMaxMinBox = styled(Box)`
  & > .MuiFormControl-root > .MuiOutlinedInput-root {
    width: ${theme.spacing(25)};
  }
  width: ${theme.spacing(54)};
  display: flex;
  justify-content: space-between;
  margin-top: ${theme.spacing(5)};
`;

export const StyledInfoIcon = styled(InfoSharpIcon)`
  margin-left: ${theme.spacing(4)};
  color: ${colors.AZT_Dark};
`;

export const StyledTooltipContainer = styled('span')`
  margin-left: ${theme.spacing(2)};
`;

export const CheckboxLabel = styled(Box)`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-self: center;
`;

export const CheckboxLabelList = styled('li')`
  border: solid 1px black;
  border-top: none;
  position: relative;
  &:first-of-type {
    border-top: solid 1px black;
  }
  .MuiFormControlLabel-root {
    width: 100%;
    margin-right: 0;

    .MuiFormControlLabel-label {
      width: 100%;
    }
  }
`;

export const AutocompleteRenderInput = styled(TextField)`
  & .MuiOutlinedInput-root {
    min-height: ${theme.spacing(30)};
  }
`;

export const StyledPopper = styled(Popper)`
  padding: 0;
  height: 0;
  & .MuiAutocomplete-listbox {
    padding: 0px 0px;
  }
`;

export const StyledChip = styled(Chip)`
  background-color: ${colors.lightGrey};
  border: none;
  height: ${theme.spacing(14)};
  border-radius: ${theme.spacing(7.5)};
  margin-right: ${theme.spacing(4)};
  margin-bottom: ${theme.spacing(2)};
  .MuiChip-label {
    padding-right: 0;
    padding-left: ${theme.spacing(5)};
  }
`;

export const ChipsCancelIcon = styled(CancelOutlinedIcon)`
  fill: ${colors.black};
  stroke: ${colors.white};
  strokewidth: 1;
`;

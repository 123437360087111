import { ReactElement, useEffect } from 'react';
import { useFieldArray, UseFormReturn } from 'react-hook-form';
import { StyledText } from './MenuItemOptionGroups.styles';
import { Typography } from '@mui/material';
import NumericalSubtitle from '../NumericalSubtitle';
import OptionGroups from '../menuItemForm/OptionGroups';
import CardButton from '../CardButton';
import { IMenuItem } from 'src/types/menu.types';
import { IFormValues } from '../menuItemForm/types';
export interface IMenuItemOptionGroupsProps {
  currentItem?: IMenuItem;
  itemType: string;
  itemVariant: 'create' | 'update';
  optionGroupsToDelete: string[];
  setOptionGroupsToDelete: (value: string[]) => void;
  formFields: UseFormReturn<IFormValues>;
  itemOptions: IMenuItem[];
}

const MenuItemOptionGroups = ({
  currentItem,
  itemType,
  itemVariant,
  optionGroupsToDelete,
  setOptionGroupsToDelete,
  formFields,
  itemOptions,
}: IMenuItemOptionGroupsProps): ReactElement => {
  const { getValues, control } = formFields;
  const currentItemValues = getValues();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'modifier_groups',
  });

  const createNewOptionGroup = (): void => {
    append({ modifiers: [], description: '', name: '', min_choices: 1, max_choices: 1 });
  };

  useEffect(() => {
    if (itemVariant === 'create') {
      if (itemType === 'BUNDLE') {
        remove();
        createNewOptionGroup();
      } else if (fields.length !== 0) {
        remove();
      }
    }
  }, [itemType]);

  const deleteOptionGroupSection = (index: number) => {
    remove(index);

    if (itemVariant === 'update' && currentItem?.optionGroup[index]) {
      setOptionGroupsToDelete([...optionGroupsToDelete, currentItem.optionGroup[index].optionGroupId]);
    }
  };

  return (
    <>
      <NumericalSubtitle number="4" subtitle={`${itemType === 'STANDARD' ? 'Modifier options' : 'Bundle options'}`} />
      {fields.length > 0 ? (
        fields.map((field, index) => (
          <OptionGroups
            formFields={formFields}
            variant={itemVariant}
            key={field.id}
            index={index}
            createNewOptionGroup={createNewOptionGroup}
            menuItemType={itemType}
            fieldLength={fields.length}
            optionGroups={currentItemValues.modifier_groups}
            onDeleteOptionsGroup={() => deleteOptionGroupSection(index)}
            itemOptions={itemOptions}
          />
        ))
      ) : (
        <CardButton data-testid="add-modifier-section" onClick={createNewOptionGroup}>
          <StyledText>
            <Typography variant="strongText">+ Add Section</Typography>
          </StyledText>
        </CardButton>
      )}
    </>
  );
};

export default MenuItemOptionGroups;

export const recipesCsv = [
  {
    'Recipe ID': '034b33b6-5495-46af-81a7-13fdf0ff3e46',
    'Item Name': 'Vegetable Gyoza',
    Celery: false,
    'Cereals Containing Gluten': true,
    'Cereals Containing Gluten Species': 'Contains: Wheat',
    Crustaceans: false,
    Egg: false,
    Fish: false,
    Lupin: false,
    Milk: false,
    Molluscs: false,
    Mustard: false,
    Peanuts: false,
    Sesame: true,
    Soya: true,
    Sulphites: false,
    'Tree Nut': false,
    'Tree Nut Species': '',
    Garlic: true,
    Onions: false,
    Kcal: 156,
    Ingredients: 'Vegetable Gyozas',
    Vegetarian: true,
    Vegan: true,
  },
  {
    'Recipe ID': '08a9e092-6f64-43c3-b10b-082c48d30fca',
    'Item Name': 'Teriyaki - Panko Prawns (Small)',
    Celery: false,
    'Cereals Containing Gluten': true,
    'Cereals Containing Gluten Species': 'Contains: Wheat',
    Crustaceans: true,
    Egg: false,
    Fish: false,
    Lupin: false,
    Milk: false,
    Molluscs: false,
    Mustard: false,
    Peanuts: false,
    Sesame: false,
    Soya: true,
    Sulphites: false,
    'Tree Nut': false,
    'Tree Nut Species': '',
    Garlic: true,
    Onions: false,
    Kcal: 588,
    Ingredients:
      'Teriyaki Sauce POD, Panko Prawns, CHILLIES RED DUTCH, CORIANDER BUNCH X 100GM SP, SPRING ONIONS X 1 BUN SP, Thai Jasmine Rice - POD',
    Vegetarian: false,
    Vegan: false,
  },
  {
    'Recipe ID': '08b395c4-6dea-4947-b8bb-37f05e552374',
    'Item Name': 'Teriyaki - Prawn - Noodle - Large',
    Celery: false,
    'Cereals Containing Gluten': true,
    'Cereals Containing Gluten Species': 'Contains: Wheat',
    Crustaceans: true,
    Egg: false,
    Fish: false,
    Lupin: false,
    Milk: false,
    Molluscs: false,
    Mustard: false,
    Peanuts: false,
    Sesame: false,
    Soya: true,
    Sulphites: false,
    'Tree Nut': false,
    'Tree Nut Species': '',
    Garlic: true,
    Onions: false,
    Kcal: 987,
    Ingredients:
      "Teriyaki Sauce POD, Panko Prawns, 8 '' Thick Non Egg Noodles (with Turmeric & Paprika), CHILLIES RED DUTCH, CORIANDER BUNCH X 100GM SP, SPRING ONIONS X 1 BUN SP",
    Vegetarian: false,
    Vegan: false,
  },
  {
    'Recipe ID': '1940dd85-ae4d-45d8-a08e-b4370309eadd',
    'Item Name': 'Sweet Chilli - Panko Prawn (Small)',
    Celery: false,
    'Cereals Containing Gluten': true,
    'Cereals Containing Gluten Species': 'Contains: Wheat',
    Crustaceans: true,
    Egg: false,
    Fish: false,
    Lupin: false,
    Milk: false,
    Molluscs: false,
    Mustard: false,
    Peanuts: false,
    Sesame: false,
    Soya: true,
    Sulphites: true,
    'Tree Nut': false,
    'Tree Nut Species': '',
    Garlic: true,
    Onions: false,
    Kcal: 699,
    Ingredients:
      'Panko Prawns, Sweet Chilli Sauce POD, CHILLIES RED DUTCH, CORIANDER BUNCH X 100GM SP, SPRING ONIONS X 1 BUN SP, Thai Jasmine Rice - POD',
    Vegetarian: false,
    Vegan: false,
  },
  {
    'Recipe ID': '1d8996af-4af5-4803-80e1-9ad2a6301ac7',
    'Item Name': 'Red Thai Chicken Curry - Rice (Large)',
    Celery: false,
    'Cereals Containing Gluten': true,
    'Cereals Containing Gluten Species': 'Contains: Wheat',
    Crustaceans: false,
    Egg: false,
    Fish: false,
    Lupin: false,
    Milk: false,
    Molluscs: false,
    Mustard: false,
    Peanuts: false,
    Sesame: false,
    Soya: true,
    Sulphites: false,
    'Tree Nut': false,
    'Tree Nut Species': '',
    Garlic: true,
    Onions: false,
    Kcal: 765,
    Ingredients:
      'Red Thai Curry POD, CHILLIES RED DUTCH, CORIANDER BUNCH X 100GM SP, SPRING ONIONS X 1 BUN SP, Thai Jasmine Rice - POD',
    Vegetarian: false,
    Vegan: false,
  },
  {
    'Recipe ID': '1edccb94-c867-4200-bac2-899ce9c797d8',
    'Item Name': 'Katsu - Panko Prawn (Large)',
    Celery: false,
    'Cereals Containing Gluten': true,
    'Cereals Containing Gluten Species': 'Contains: Wheat',
    Crustaceans: true,
    Egg: false,
    Fish: false,
    Lupin: false,
    Milk: false,
    Molluscs: false,
    Mustard: false,
    Peanuts: false,
    Sesame: false,
    Soya: true,
    Sulphites: false,
    'Tree Nut': false,
    'Tree Nut Species': '',
    Garlic: true,
    Onions: false,
    Kcal: 949,
    Ingredients:
      'Katsu Curry POD, Panko Prawns, CHILLIES RED DUTCH, CORIANDER BUNCH X 100GM SP, SPRING ONIONS X 1 BUN SP, Thai Jasmine Rice - POD',
    Vegetarian: false,
    Vegan: false,
  },
  {
    'Recipe ID': '25b9ef3b-9770-4c56-95ac-27e3807a85c9',
    'Item Name': 'Edamame Beans - Pod',
    Celery: false,
    'Cereals Containing Gluten': false,
    'Cereals Containing Gluten Species': '',
    Crustaceans: false,
    Egg: false,
    Fish: false,
    Lupin: false,
    Milk: false,
    Molluscs: false,
    Mustard: false,
    Peanuts: false,
    Sesame: false,
    Soya: true,
    Sulphites: false,
    'Tree Nut': false,
    'Tree Nut Species': '',
    Garlic: false,
    Onions: false,
    Kcal: 123,
    Ingredients: 'Edamame Beans - POD, Crushed Chillies, TABLE SALT',
    Vegetarian: true,
    Vegan: true,
  },
  {
    'Recipe ID': '2621d28f-4b3b-436b-8c9f-9345f19b3fe0',
    'Item Name': 'Sweet Chilli Dippers',
    Celery: false,
    'Cereals Containing Gluten': true,
    'Cereals Containing Gluten Species': 'Contains: Barley, Wheat',
    Crustaceans: false,
    Egg: false,
    Fish: false,
    Lupin: false,
    Milk: true,
    Molluscs: false,
    Mustard: false,
    Peanuts: false,
    Sesame: false,
    Soya: true,
    Sulphites: true,
    'Tree Nut': false,
    'Tree Nut Species': '',
    Garlic: true,
    Onions: false,
    Kcal: 392,
    Ingredients: 'COCONUT CHICKEN MINI FILLETS, Sweet Chilli Sauce POD, CHILLIES RED DUTCH, SPRING ONIONS X 1 BUN SP',
    Vegetarian: false,
    Vegan: false,
  },
  {
    'Recipe ID': '2826e1bb-2e2e-4c23-a269-456e65f83d68',
    'Item Name': 'Teriyaki - Shredded Beef (Large)',
    Celery: false,
    'Cereals Containing Gluten': true,
    'Cereals Containing Gluten Species': 'Contains: Wheat',
    Crustaceans: false,
    Egg: true,
    Fish: false,
    Lupin: false,
    Milk: false,
    Molluscs: false,
    Mustard: false,
    Peanuts: false,
    Sesame: false,
    Soya: true,
    Sulphites: false,
    'Tree Nut': false,
    'Tree Nut Species': '',
    Garlic: true,
    Onions: false,
    Kcal: 1005,
    Ingredients:
      'Teriyaki Sauce POD, Shredded Beef Strips, CHILLIES RED DUTCH, CORIANDER BUNCH X 100GM SP, SPRING ONIONS X 1 BUN SP, Thai Jasmine Rice - POD',
    Vegetarian: false,
    Vegan: false,
  },
  {
    'Recipe ID': '28c610b0-8fc8-4392-8030-1e48433d5796',
    'Item Name': 'Sweet Chilli - Panko Prawn (Large)',
    Celery: false,
    'Cereals Containing Gluten': true,
    'Cereals Containing Gluten Species': 'Contains: Wheat',
    Crustaceans: true,
    Egg: false,
    Fish: false,
    Lupin: false,
    Milk: false,
    Molluscs: false,
    Mustard: false,
    Peanuts: false,
    Sesame: false,
    Soya: true,
    Sulphites: true,
    'Tree Nut': false,
    'Tree Nut Species': '',
    Garlic: true,
    Onions: false,
    Kcal: 1202,
    Ingredients:
      'Panko Prawns, Sweet Chilli Sauce POD, CHILLIES RED DUTCH, CORIANDER BUNCH X 100GM SP, SPRING ONIONS X 1 BUN SP, Thai Jasmine Rice - POD',
    Vegetarian: false,
    Vegan: false,
  },
  {
    'Recipe ID': '2a7df3bd-9610-43ab-8aa2-ab550fbfb2d7',
    'Item Name': 'Panko Prawn Dippers - Sweet Chilli',
    Celery: false,
    'Cereals Containing Gluten': true,
    'Cereals Containing Gluten Species': 'Contains: Wheat',
    Crustaceans: true,
    Egg: false,
    Fish: false,
    Lupin: false,
    Milk: false,
    Molluscs: false,
    Mustard: false,
    Peanuts: false,
    Sesame: false,
    Soya: true,
    Sulphites: true,
    'Tree Nut': false,
    'Tree Nut Species': '',
    Garlic: true,
    Onions: false,
    Kcal: 253,
    Ingredients: 'Panko Prawns, Sweet Chilli Sauce POD, CHILLIES RED DUTCH, SPRING ONIONS X 1 BUN SP',
    Vegetarian: false,
    Vegan: false,
  },
  {
    'Recipe ID': '328221f8-0cfe-4fe8-a126-90a10cc4d593',
    'Item Name': 'Beef Massaman Small',
    Celery: false,
    'Cereals Containing Gluten': false,
    'Cereals Containing Gluten Species': '',
    Crustaceans: true,
    Egg: false,
    Fish: true,
    Lupin: false,
    Milk: false,
    Molluscs: false,
    Mustard: false,
    Peanuts: false,
    Sesame: false,
    Soya: false,
    Sulphites: true,
    'Tree Nut': false,
    'Tree Nut Species': '',
    Garlic: true,
    Onions: false,
    Kcal: 361,
    Ingredients:
      'Beef Massaman POD, CHILLIES RED DUTCH, CORIANDER BUNCH X 100GM SP, SPRING ONIONS X 1 BUN SP, Thai Jasmine Rice - POD',
    Vegetarian: false,
    Vegan: false,
  },
  {
    'Recipe ID': '3a0547f2-bfc0-45ef-b313-0c9059747964',
    'Item Name': 'Green Thai Chicken Curry - Rice (Large)',
    Celery: false,
    'Cereals Containing Gluten': true,
    'Cereals Containing Gluten Species': 'Contains: Wheat',
    Crustaceans: false,
    Egg: false,
    Fish: false,
    Lupin: false,
    Milk: false,
    Molluscs: false,
    Mustard: false,
    Peanuts: false,
    Sesame: false,
    Soya: true,
    Sulphites: true,
    'Tree Nut': false,
    'Tree Nut Species': '',
    Garlic: true,
    Onions: false,
    Kcal: 681,
    Ingredients:
      'Green Thai Curry POD, CHILLIES RED DUTCH, CORIANDER BUNCH X 100GM SP, SPRING ONIONS X 1 BUN SP, Thai Jasmine Rice - POD',
    Vegetarian: false,
    Vegan: false,
  },
  {
    'Recipe ID': '411a2a74-829b-4299-9235-6c51e9fbbb81',
    'Item Name': 'Katsu - Chicken (Large)',
    Celery: false,
    'Cereals Containing Gluten': true,
    'Cereals Containing Gluten Species': 'Contains: Wheat',
    Crustaceans: false,
    Egg: false,
    Fish: false,
    Lupin: false,
    Milk: false,
    Molluscs: false,
    Mustard: false,
    Peanuts: false,
    Sesame: false,
    Soya: true,
    Sulphites: false,
    'Tree Nut': false,
    'Tree Nut Species': '',
    Garlic: true,
    Onions: false,
    Kcal: 1120,
    Ingredients:
      'Katsu Curry POD, CHICKEN MILANESE CONTINGENCY 220G, CHILLIES RED DUTCH, CORIANDER BUNCH X 100GM SP, SPRING ONIONS X 1 BUN SP, Thai Jasmine Rice - POD',
    Vegetarian: false,
    Vegan: false,
  },
  {
    'Recipe ID': '41a97be3-ea49-476b-acc3-ef53c017eded',
    'Item Name': 'Sweet Chilli - Chicken (Large)',
    Celery: false,
    'Cereals Containing Gluten': true,
    'Cereals Containing Gluten Species': 'Contains: Wheat',
    Crustaceans: false,
    Egg: false,
    Fish: false,
    Lupin: false,
    Milk: false,
    Molluscs: false,
    Mustard: false,
    Peanuts: false,
    Sesame: false,
    Soya: true,
    Sulphites: true,
    'Tree Nut': false,
    'Tree Nut Species': '',
    Garlic: true,
    Onions: false,
    Kcal: 1373,
    Ingredients:
      'CHICKEN MILANESE CONTINGENCY 220G, Sweet Chilli Sauce POD, CHILLIES RED DUTCH, CORIANDER BUNCH X 100GM SP, SPRING ONIONS X 1 BUN SP, Thai Jasmine Rice - POD',
    Vegetarian: false,
    Vegan: false,
  },
  {
    'Recipe ID': '45344f12-04bd-4e7e-8ac2-2b5cc07442b9',
    'Item Name': 'Teriyaki Chicken - Noodles - Small',
    Celery: false,
    'Cereals Containing Gluten': true,
    'Cereals Containing Gluten Species': 'Contains: Wheat',
    Crustaceans: false,
    Egg: false,
    Fish: false,
    Lupin: false,
    Milk: false,
    Molluscs: false,
    Mustard: false,
    Peanuts: false,
    Sesame: false,
    Soya: true,
    Sulphites: false,
    'Tree Nut': false,
    'Tree Nut Species': '',
    Garlic: true,
    Onions: false,
    Kcal: 608,
    Ingredients:
      "Teriyaki Sauce POD, 8 '' Thick Non Egg Noodles (with Turmeric & Paprika), CHICKEN MILANESE CONTINGENCY 220G, CHILLIES RED DUTCH, CORIANDER BUNCH X 100GM SP, SPRING ONIONS X 1 BUN SP",
    Vegetarian: false,
    Vegan: false,
  },
  {
    'Recipe ID': '4697342e-71e9-451a-bbee-5b19c03a09d4',
    'Item Name': 'Teriyaki - Breaded Chicken (Small)',
    Celery: false,
    'Cereals Containing Gluten': true,
    'Cereals Containing Gluten Species': 'Contains: Wheat',
    Crustaceans: false,
    Egg: false,
    Fish: false,
    Lupin: false,
    Milk: false,
    Molluscs: false,
    Mustard: false,
    Peanuts: false,
    Sesame: false,
    Soya: true,
    Sulphites: false,
    'Tree Nut': false,
    'Tree Nut Species': '',
    Garlic: true,
    Onions: false,
    Kcal: 618,
    Ingredients:
      'Teriyaki Sauce POD, CHICKEN MILANESE CONTINGENCY 220G, CHILLIES RED DUTCH, CORIANDER BUNCH X 100GM SP, SPRING ONIONS X 1 BUN SP, Thai Jasmine Rice - POD',
    Vegetarian: false,
    Vegan: false,
  },
  {
    'Recipe ID': '4bc8352e-9f87-4ff8-9cd5-2ed06002a627',
    'Item Name': 'Red Thai Chicken Curry - Rice (Small)',
    Celery: false,
    'Cereals Containing Gluten': true,
    'Cereals Containing Gluten Species': 'Contains: Wheat',
    Crustaceans: false,
    Egg: false,
    Fish: false,
    Lupin: false,
    Milk: false,
    Molluscs: false,
    Mustard: false,
    Peanuts: false,
    Sesame: false,
    Soya: true,
    Sulphites: false,
    'Tree Nut': false,
    'Tree Nut Species': '',
    Garlic: true,
    Onions: false,
    Kcal: 421,
    Ingredients:
      'Red Thai Curry POD, CHILLIES RED DUTCH, CORIANDER BUNCH X 100GM SP, SPRING ONIONS X 1 BUN SP, Thai Jasmine Rice - POD',
    Vegetarian: false,
    Vegan: false,
  },
  {
    'Recipe ID': '4f276d29-4853-4d57-807e-817d2b50fffc',
    'Item Name': 'Small Sweet Chilli Seitan - Noodles',
    Celery: false,
    'Cereals Containing Gluten': true,
    'Cereals Containing Gluten Species': 'Contains: Wheat',
    Crustaceans: false,
    Egg: false,
    Fish: false,
    Lupin: false,
    Milk: false,
    Molluscs: false,
    Mustard: false,
    Peanuts: false,
    Sesame: false,
    Soya: true,
    Sulphites: true,
    'Tree Nut': false,
    'Tree Nut Species': '',
    Garlic: true,
    Onions: false,
    Kcal: 503,
    Ingredients:
      "8 '' Thick Non Egg Noodles (with Turmeric & Paprika), Funky Chyck'n Seitan Breaded Bites, Sweet Chilli Sauce POD, CHILLIES RED DUTCH, CORIANDER BUNCH X 100GM SP, SPRING ONIONS X 1 BUN SP",
    Vegetarian: true,
    Vegan: true,
  },
  {
    'Recipe ID': '56ea38fd-9bcf-4bfd-9ea4-31756f50de62',
    'Item Name': 'Sweet Chilli - Shredded Beef (Small)',
    Celery: false,
    'Cereals Containing Gluten': false,
    'Cereals Containing Gluten Species': '',
    Crustaceans: false,
    Egg: true,
    Fish: false,
    Lupin: false,
    Milk: false,
    Molluscs: false,
    Mustard: false,
    Peanuts: false,
    Sesame: false,
    Soya: true,
    Sulphites: true,
    'Tree Nut': false,
    'Tree Nut Species': '',
    Garlic: true,
    Onions: false,
    Kcal: 661,
    Ingredients:
      'Sweet Chilli Sauce POD, Shredded Beef Strips, CHILLIES RED DUTCH, CORIANDER BUNCH X 100GM SP, SPRING ONIONS X 1 BUN SP, Thai Jasmine Rice - POD',
    Vegetarian: false,
    Vegan: false,
  },
  {
    'Recipe ID': '58ea3298-f76f-4b3b-bbec-b5e41e56e8eb',
    'Item Name': 'Large Teriyaki Seitan - Noodles ',
    Celery: false,
    'Cereals Containing Gluten': true,
    'Cereals Containing Gluten Species': 'Contains: Wheat',
    Crustaceans: false,
    Egg: false,
    Fish: false,
    Lupin: false,
    Milk: false,
    Molluscs: false,
    Mustard: false,
    Peanuts: false,
    Sesame: false,
    Soya: true,
    Sulphites: false,
    'Tree Nut': false,
    'Tree Nut Species': '',
    Garlic: true,
    Onions: false,
    Kcal: 690,
    Ingredients:
      "Teriyaki Sauce POD, 8 '' Thick Non Egg Noodles (with Turmeric & Paprika), Funky Chyck'n Seitan Breaded Bites, CHILLIES RED DUTCH, CORIANDER BUNCH X 100GM SP, SPRING ONIONS X 1 BUN SP",
    Vegetarian: true,
    Vegan: true,
  },
  {
    'Recipe ID': '6169ea65-59e7-4953-8a4f-ad10a1c5c8e9',
    'Item Name': 'Katsu - Chicken (Small)',
    Celery: false,
    'Cereals Containing Gluten': true,
    'Cereals Containing Gluten Species': 'Contains: Wheat',
    Crustaceans: false,
    Egg: false,
    Fish: false,
    Lupin: false,
    Milk: false,
    Molluscs: false,
    Mustard: false,
    Peanuts: false,
    Sesame: false,
    Soya: true,
    Sulphites: false,
    'Tree Nut': false,
    'Tree Nut Species': '',
    Garlic: true,
    Onions: false,
    Kcal: 581,
    Ingredients:
      'Katsu Curry POD, CHICKEN MILANESE CONTINGENCY 220G, CHILLIES RED DUTCH, CORIANDER BUNCH X 100GM SP, SPRING ONIONS X 1 BUN SP, Thai Jasmine Rice - POD',
    Vegetarian: false,
    Vegan: false,
  },
  {
    'Recipe ID': '61ad9878-4af8-47c3-9171-da9e14b410e7',
    'Item Name': 'Panko Prawn Dippers - Katsu',
    Celery: false,
    'Cereals Containing Gluten': true,
    'Cereals Containing Gluten Species': 'Contains: Wheat',
    Crustaceans: true,
    Egg: false,
    Fish: false,
    Lupin: false,
    Milk: false,
    Molluscs: false,
    Mustard: false,
    Peanuts: false,
    Sesame: false,
    Soya: true,
    Sulphites: false,
    'Tree Nut': false,
    'Tree Nut Species': '',
    Garlic: true,
    Onions: false,
    Kcal: 223,
    Ingredients: 'Katsu Curry POD, Panko Prawns, CHILLIES RED DUTCH, SPRING ONIONS X 1 BUN SP',
    Vegetarian: false,
    Vegan: false,
  },
  {
    'Recipe ID': '6620141c-5eb3-4070-a99c-945fc77b2b5b',
    'Item Name': 'Large Sweet Chilli Seitan - Rice ',
    Celery: false,
    'Cereals Containing Gluten': true,
    'Cereals Containing Gluten Species': 'Contains: Wheat',
    Crustaceans: false,
    Egg: false,
    Fish: false,
    Lupin: false,
    Milk: false,
    Molluscs: false,
    Mustard: false,
    Peanuts: false,
    Sesame: false,
    Soya: true,
    Sulphites: true,
    'Tree Nut': false,
    'Tree Nut Species': '',
    Garlic: true,
    Onions: false,
    Kcal: 905,
    Ingredients:
      "Funky Chyck'n Seitan Breaded Bites, Sweet Chilli Sauce POD, CHILLIES RED DUTCH, CORIANDER BUNCH X 100GM SP, SPRING ONIONS X 1 BUN SP, Thai Jasmine Rice - POD",
    Vegetarian: true,
    Vegan: true,
  },
  {
    'Recipe ID': '7469061d-fd0a-457e-b61f-0de7bff42a6c',
    'Item Name': 'Large Teriyaki Seitan - Rice',
    Celery: false,
    'Cereals Containing Gluten': true,
    'Cereals Containing Gluten Species': 'Contains: Wheat',
    Crustaceans: false,
    Egg: false,
    Fish: false,
    Lupin: false,
    Milk: false,
    Molluscs: false,
    Mustard: false,
    Peanuts: false,
    Sesame: false,
    Soya: true,
    Sulphites: false,
    'Tree Nut': false,
    'Tree Nut Species': '',
    Garlic: true,
    Onions: false,
    Kcal: 709,
    Ingredients:
      "Teriyaki Sauce POD, Funky Chyck'n Seitan Breaded Bites, CHILLIES RED DUTCH, CORIANDER BUNCH X 100GM SP, SPRING ONIONS X 1 BUN SP, Thai Jasmine Rice - POD",
    Vegetarian: true,
    Vegan: true,
  },
  {
    'Recipe ID': '74d3c127-5186-478d-b6be-d80c02b6b0b2',
    'Item Name': 'Large Sweet Chilli Seitan - Noodles',
    Celery: false,
    'Cereals Containing Gluten': true,
    'Cereals Containing Gluten Species': 'Contains: Wheat',
    Crustaceans: false,
    Egg: false,
    Fish: false,
    Lupin: false,
    Milk: false,
    Molluscs: false,
    Mustard: false,
    Peanuts: false,
    Sesame: false,
    Soya: true,
    Sulphites: true,
    'Tree Nut': false,
    'Tree Nut Species': '',
    Garlic: true,
    Onions: false,
    Kcal: 886,
    Ingredients:
      "8 '' Thick Non Egg Noodles (with Turmeric & Paprika), Funky Chyck'n Seitan Breaded Bites, Sweet Chilli Sauce POD, CHILLIES RED DUTCH, CORIANDER BUNCH X 100GM SP, SPRING ONIONS X 1 BUN SP",
    Vegetarian: true,
    Vegan: true,
  },
  {
    'Recipe ID': '76d07141-8d2c-4daf-8f88-9182a6457833',
    'Item Name': 'Large Katsu Seitan - Rice',
    Celery: false,
    'Cereals Containing Gluten': true,
    'Cereals Containing Gluten Species': 'Contains: Wheat',
    Crustaceans: false,
    Egg: false,
    Fish: false,
    Lupin: false,
    Milk: false,
    Molluscs: false,
    Mustard: false,
    Peanuts: false,
    Sesame: false,
    Soya: true,
    Sulphites: false,
    'Tree Nut': false,
    'Tree Nut Species': '',
    Garlic: true,
    Onions: false,
    Kcal: 652,
    Ingredients:
      "Katsu Curry POD, Funky Chyck'n Seitan Breaded Bites, CHILLIES RED DUTCH, CORIANDER BUNCH X 100GM SP, SPRING ONIONS X 1 BUN SP, Thai Jasmine Rice - POD",
    Vegetarian: true,
    Vegan: true,
  },
  {
    'Recipe ID': '77f17a1e-a42b-44de-93e5-dff21522e9ff',
    'Item Name': 'Green Thai Chicken Curry - Rice (Small)',
    Celery: false,
    'Cereals Containing Gluten': true,
    'Cereals Containing Gluten Species': 'Contains: Wheat',
    Crustaceans: false,
    Egg: false,
    Fish: false,
    Lupin: false,
    Milk: false,
    Molluscs: false,
    Mustard: false,
    Peanuts: false,
    Sesame: false,
    Soya: true,
    Sulphites: true,
    'Tree Nut': false,
    'Tree Nut Species': '',
    Garlic: true,
    Onions: false,
    Kcal: 418,
    Ingredients:
      'Green Thai Curry POD, CHILLIES RED DUTCH, CORIANDER BUNCH X 100GM SP, SPRING ONIONS X 1 BUN SP, Thai Jasmine Rice - POD',
    Vegetarian: false,
    Vegan: false,
  },
  {
    'Recipe ID': '7973e8db-41fc-4454-af78-822fb59c3acd',
    'Item Name': 'Katsu Dippers',
    Celery: false,
    'Cereals Containing Gluten': true,
    'Cereals Containing Gluten Species': 'Contains: Barley, Wheat',
    Crustaceans: false,
    Egg: false,
    Fish: false,
    Lupin: false,
    Milk: true,
    Molluscs: false,
    Mustard: false,
    Peanuts: false,
    Sesame: false,
    Soya: true,
    Sulphites: false,
    'Tree Nut': false,
    'Tree Nut Species': '',
    Garlic: true,
    Onions: false,
    Kcal: 362,
    Ingredients: 'COCONUT CHICKEN MINI FILLETS, Katsu Curry POD, CHILLIES RED DUTCH, SPRING ONIONS X 1 BUN SP',
    Vegetarian: false,
    Vegan: false,
  },
  {
    'Recipe ID': '798433c5-4e1f-455e-9f8a-7f965383de9e',
    'Item Name': 'Sweet Chilli - Shredded Beef (Large)',
    Celery: false,
    'Cereals Containing Gluten': false,
    'Cereals Containing Gluten Species': '',
    Crustaceans: false,
    Egg: true,
    Fish: false,
    Lupin: false,
    Milk: false,
    Molluscs: false,
    Mustard: false,
    Peanuts: false,
    Sesame: false,
    Soya: true,
    Sulphites: true,
    'Tree Nut': false,
    'Tree Nut Species': '',
    Garlic: true,
    Onions: false,
    Kcal: 1201,
    Ingredients:
      'Sweet Chilli Sauce POD, Shredded Beef Strips, CHILLIES RED DUTCH, CORIANDER BUNCH X 100GM SP, SPRING ONIONS X 1 BUN SP, Thai Jasmine Rice - POD',
    Vegetarian: false,
    Vegan: false,
  },
  {
    'Recipe ID': '7aad09d7-d5ac-48e0-bcf1-60a6d11d6adb',
    'Item Name': 'Sweet Chilli Beef - Noodles (Large)',
    Celery: false,
    'Cereals Containing Gluten': true,
    'Cereals Containing Gluten Species': 'Contains: Wheat',
    Crustaceans: false,
    Egg: true,
    Fish: false,
    Lupin: false,
    Milk: false,
    Molluscs: false,
    Mustard: false,
    Peanuts: false,
    Sesame: false,
    Soya: true,
    Sulphites: true,
    'Tree Nut': false,
    'Tree Nut Species': '',
    Garlic: true,
    Onions: false,
    Kcal: 1181,
    Ingredients:
      "8 '' Thick Non Egg Noodles (with Turmeric & Paprika), Sweet Chilli Sauce POD, Shredded Beef Strips, CHILLIES RED DUTCH, CORIANDER BUNCH X 100GM SP, SPRING ONIONS X 1 BUN SP",
    Vegetarian: false,
    Vegan: false,
  },
  {
    'Recipe ID': '90c1080c-03f0-450f-9dcf-976c449c2b2d',
    'Item Name': 'Teriyaki Chicken - Noodles - Large',
    Celery: false,
    'Cereals Containing Gluten': true,
    'Cereals Containing Gluten Species': 'Contains: Wheat',
    Crustaceans: false,
    Egg: false,
    Fish: false,
    Lupin: false,
    Milk: false,
    Molluscs: false,
    Mustard: false,
    Peanuts: false,
    Sesame: false,
    Soya: true,
    Sulphites: false,
    'Tree Nut': false,
    'Tree Nut Species': '',
    Garlic: true,
    Onions: false,
    Kcal: 1158,
    Ingredients:
      "Teriyaki Sauce POD, 8 '' Thick Non Egg Noodles (with Turmeric & Paprika), CHICKEN MILANESE CONTINGENCY 220G, CHILLIES RED DUTCH, CORIANDER BUNCH X 100GM SP, SPRING ONIONS X 1 BUN SP",
    Vegetarian: false,
    Vegan: false,
  },
  {
    'Recipe ID': '90dcdd3c-c07d-4ca8-9960-0a1210fb9b69',
    'Item Name': 'Katsu - Panko Prawns (Small)',
    Celery: false,
    'Cereals Containing Gluten': true,
    'Cereals Containing Gluten Species': 'Contains: Wheat',
    Crustaceans: true,
    Egg: false,
    Fish: false,
    Lupin: false,
    Milk: false,
    Molluscs: false,
    Mustard: false,
    Peanuts: false,
    Sesame: false,
    Soya: true,
    Sulphites: false,
    'Tree Nut': false,
    'Tree Nut Species': '',
    Garlic: true,
    Onions: false,
    Kcal: 532,
    Ingredients:
      'Katsu Curry POD, Panko Prawns, CHILLIES RED DUTCH, CORIANDER BUNCH X 100GM SP, SPRING ONIONS X 1 BUN SP, Thai Jasmine Rice - POD',
    Vegetarian: false,
    Vegan: false,
  },
  {
    'Recipe ID': '9a7f839c-db2c-4088-a292-7f367ab1dc29',
    'Item Name': 'Beef Massaman Large',
    Celery: false,
    'Cereals Containing Gluten': false,
    'Cereals Containing Gluten Species': '',
    Crustaceans: true,
    Egg: false,
    Fish: true,
    Lupin: false,
    Milk: false,
    Molluscs: false,
    Mustard: false,
    Peanuts: false,
    Sesame: false,
    Soya: false,
    Sulphites: true,
    'Tree Nut': false,
    'Tree Nut Species': '',
    Garlic: true,
    Onions: false,
    Kcal: 777,
    Ingredients:
      'Beef Massaman POD, CHILLIES RED DUTCH, CORIANDER BUNCH X 100GM SP, SPRING ONIONS X 1 BUN SP, Thai Jasmine Rice - POD',
    Vegetarian: false,
    Vegan: false,
  },
  {
    'Recipe ID': 'a5f13cf2-076c-4791-93a4-a11faa1b3d77',
    'Item Name': 'Sweet Chilli Prawn - Noodles (Large)',
    Celery: false,
    'Cereals Containing Gluten': true,
    'Cereals Containing Gluten Species': 'Contains: Wheat',
    Crustaceans: true,
    Egg: false,
    Fish: false,
    Lupin: false,
    Milk: false,
    Molluscs: false,
    Mustard: false,
    Peanuts: false,
    Sesame: false,
    Soya: true,
    Sulphites: true,
    'Tree Nut': false,
    'Tree Nut Species': '',
    Garlic: true,
    Onions: false,
    Kcal: 1183,
    Ingredients:
      "Panko Prawns, 8 '' Thick Non Egg Noodles (with Turmeric & Paprika), Sweet Chilli Sauce POD, CHILLIES RED DUTCH, CORIANDER BUNCH X 100GM SP, SPRING ONIONS X 1 BUN SP",
    Vegetarian: false,
    Vegan: false,
  },
  {
    'Recipe ID': 'a748cae9-917d-4c8e-bbac-e0c198b21275',
    'Item Name': 'Chocolate Brownie ',
    Celery: false,
    'Cereals Containing Gluten': false,
    'Cereals Containing Gluten Species': '',
    Crustaceans: false,
    Egg: true,
    Fish: false,
    Lupin: false,
    Milk: false,
    Molluscs: false,
    Mustard: false,
    Peanuts: false,
    Sesame: false,
    Soya: false,
    Sulphites: false,
    'Tree Nut': false,
    'Tree Nut Species': '',
    Garlic: false,
    Onions: false,
    Kcal: 311,
    Ingredients: 'Chocolate Brownie',
    Vegetarian: true,
    Vegan: false,
  },
  {
    'Recipe ID': 'b27db934-da44-4296-80de-7088446ad0bf',
    'Item Name': 'Teriyaki - Shredded Beef (Small)',
    Celery: false,
    'Cereals Containing Gluten': true,
    'Cereals Containing Gluten Species': 'Contains: Wheat',
    Crustaceans: false,
    Egg: true,
    Fish: false,
    Lupin: false,
    Milk: false,
    Molluscs: false,
    Mustard: false,
    Peanuts: false,
    Sesame: false,
    Soya: true,
    Sulphites: false,
    'Tree Nut': false,
    'Tree Nut Species': '',
    Garlic: true,
    Onions: false,
    Kcal: 532,
    Ingredients:
      'Teriyaki Sauce POD, Shredded Beef Strips, CHILLIES RED DUTCH, CORIANDER BUNCH X 100GM SP, SPRING ONIONS X 1 BUN SP, Thai Jasmine Rice - POD',
    Vegetarian: false,
    Vegan: false,
  },
  {
    'Recipe ID': 'b29abf72-0dd4-4116-aa66-1b6786e6799b',
    'Item Name': 'Small Sweet Chilli Seitan',
    Celery: false,
    'Cereals Containing Gluten': true,
    'Cereals Containing Gluten Species': 'Contains: Wheat',
    Crustaceans: false,
    Egg: false,
    Fish: false,
    Lupin: false,
    Milk: false,
    Molluscs: false,
    Mustard: false,
    Peanuts: false,
    Sesame: false,
    Soya: true,
    Sulphites: true,
    'Tree Nut': false,
    'Tree Nut Species': '',
    Garlic: true,
    Onions: false,
    Kcal: 513,
    Ingredients:
      "Funky Chyck'n Seitan Breaded Bites, Sweet Chilli Sauce POD, CHILLIES RED DUTCH, CORIANDER BUNCH X 100GM SP, SPRING ONIONS X 1 BUN SP, Thai Jasmine Rice - POD",
    Vegetarian: true,
    Vegan: true,
  },
  {
    'Recipe ID': 'b9e1505a-7f8f-457c-bff0-58cdc323535a',
    'Item Name': 'Sweet Chili Chicken - Noodles (Large)',
    Celery: false,
    'Cereals Containing Gluten': true,
    'Cereals Containing Gluten Species': 'Contains: Wheat',
    Crustaceans: false,
    Egg: false,
    Fish: false,
    Lupin: false,
    Milk: false,
    Molluscs: false,
    Mustard: false,
    Peanuts: false,
    Sesame: false,
    Soya: true,
    Sulphites: true,
    'Tree Nut': false,
    'Tree Nut Species': '',
    Garlic: true,
    Onions: false,
    Kcal: 1353,
    Ingredients:
      "8 '' Thick Non Egg Noodles (with Turmeric & Paprika), CHICKEN MILANESE CONTINGENCY 220G, Sweet Chilli Sauce POD, CORIANDER BUNCH X 100GM SP, SPRING ONIONS X 1 BUN SP",
    Vegetarian: false,
    Vegan: false,
  },
  {
    'Recipe ID': 'bae9df1f-fc31-4dd3-b291-f573e7a3685e',
    'Item Name': 'Teriyaki Beef - Noodles - Large',
    Celery: false,
    'Cereals Containing Gluten': true,
    'Cereals Containing Gluten Species': 'Contains: Wheat',
    Crustaceans: false,
    Egg: true,
    Fish: false,
    Lupin: false,
    Milk: false,
    Molluscs: false,
    Mustard: false,
    Peanuts: false,
    Sesame: false,
    Soya: true,
    Sulphites: false,
    'Tree Nut': false,
    'Tree Nut Species': '',
    Garlic: true,
    Onions: false,
    Kcal: 985,
    Ingredients:
      "Teriyaki Sauce POD, 8 '' Thick Non Egg Noodles (with Turmeric & Paprika), Shredded Beef Strips, CHILLIES RED DUTCH, CORIANDER BUNCH X 100GM SP, SPRING ONIONS X 1 BUN SP",
    Vegetarian: false,
    Vegan: false,
  },
  {
    'Recipe ID': 'bdbcb579-8007-4e45-b7fa-9e76655e3f96',
    'Item Name': 'Small Teriyaki Seitan - Rice ',
    Celery: false,
    'Cereals Containing Gluten': true,
    'Cereals Containing Gluten Species': 'Contains: Wheat',
    Crustaceans: false,
    Egg: false,
    Fish: false,
    Lupin: false,
    Milk: false,
    Molluscs: false,
    Mustard: false,
    Peanuts: false,
    Sesame: false,
    Soya: true,
    Sulphites: false,
    'Tree Nut': false,
    'Tree Nut Species': '',
    Garlic: true,
    Onions: false,
    Kcal: 384,
    Ingredients:
      "Teriyaki Sauce POD, Funky Chyck'n Seitan Breaded Bites, CHILLIES RED DUTCH, CORIANDER BUNCH X 100GM SP, SPRING ONIONS X 1 BUN SP, Thai Jasmine Rice - POD",
    Vegetarian: true,
    Vegan: true,
  },
  {
    'Recipe ID': 'c05246ca-5531-4c63-8faa-8d9fe3226f15',
    'Item Name': 'Katsu Sauce Crispy Beef - Rice (Small)',
    Celery: false,
    'Cereals Containing Gluten': true,
    'Cereals Containing Gluten Species': 'Contains: Wheat',
    Crustaceans: false,
    Egg: true,
    Fish: false,
    Lupin: false,
    Milk: false,
    Molluscs: false,
    Mustard: false,
    Peanuts: false,
    Sesame: false,
    Soya: true,
    Sulphites: false,
    'Tree Nut': false,
    'Tree Nut Species': '',
    Garlic: true,
    Onions: false,
    Kcal: 494,
    Ingredients:
      'Katsu Curry POD, Shredded Beef Strips, CHILLIES RED DUTCH, CORIANDER BUNCH X 100GM SP, SPRING ONIONS X 1 BUN SP, Thai Jasmine Rice - POD',
    Vegetarian: false,
    Vegan: false,
  },
  {
    'Recipe ID': 'c15fa0eb-64fd-437f-ae03-93206e9f292f',
    'Item Name': 'Teriyaki - Breaded Chicken (Large)',
    Celery: false,
    'Cereals Containing Gluten': true,
    'Cereals Containing Gluten Species': 'Contains: Wheat',
    Crustaceans: false,
    Egg: false,
    Fish: false,
    Lupin: false,
    Milk: false,
    Molluscs: false,
    Mustard: false,
    Peanuts: false,
    Sesame: false,
    Soya: true,
    Sulphites: false,
    'Tree Nut': false,
    'Tree Nut Species': '',
    Garlic: true,
    Onions: false,
    Kcal: 1177,
    Ingredients:
      'Teriyaki Sauce POD, CHICKEN MILANESE CONTINGENCY 220G, CHILLIES RED DUTCH, CORIANDER BUNCH X 100GM SP, SPRING ONIONS X 1 BUN SP, Thai Jasmine Rice - POD',
    Vegetarian: false,
    Vegan: false,
  },
  {
    'Recipe ID': 'c28b3806-a6c8-419b-a9cf-313917f1f60e',
    'Item Name': 'Teriyaki - Prawn - Noodles (Small)',
    Celery: false,
    'Cereals Containing Gluten': true,
    'Cereals Containing Gluten Species': 'Contains: Wheat',
    Crustaceans: true,
    Egg: false,
    Fish: false,
    Lupin: false,
    Milk: false,
    Molluscs: false,
    Mustard: false,
    Peanuts: false,
    Sesame: false,
    Soya: true,
    Sulphites: false,
    'Tree Nut': false,
    'Tree Nut Species': '',
    Garlic: true,
    Onions: false,
    Kcal: 560,
    Ingredients:
      "Teriyaki Sauce POD, Panko Prawns, 8 '' Thick Non Egg Noodles (with Turmeric & Paprika), CHILLIES RED DUTCH, CORIANDER BUNCH X 100GM SP, SPRING ONIONS X 1 BUN SP",
    Vegetarian: false,
    Vegan: false,
  },
  {
    'Recipe ID': 'c9d29b08-f191-4cc2-9fc5-8a302e549763',
    'Item Name': 'Sweet Chilli Chicken - Noodles (Small)',
    Celery: false,
    'Cereals Containing Gluten': true,
    'Cereals Containing Gluten Species': 'Contains: Wheat',
    Crustaceans: false,
    Egg: false,
    Fish: false,
    Lupin: false,
    Milk: false,
    Molluscs: false,
    Mustard: false,
    Peanuts: false,
    Sesame: false,
    Soya: true,
    Sulphites: true,
    'Tree Nut': false,
    'Tree Nut Species': '',
    Garlic: true,
    Onions: false,
    Kcal: 737,
    Ingredients:
      "8 '' Thick Non Egg Noodles (with Turmeric & Paprika), CHICKEN MILANESE CONTINGENCY 220G, Sweet Chilli Sauce POD, CHILLIES RED DUTCH, CORIANDER BUNCH X 100GM SP, SPRING ONIONS X 1 BUN SP",
    Vegetarian: false,
    Vegan: false,
  },
  {
    'Recipe ID': 'cb5bfe98-9bbf-46fc-b519-b8f667e5d10b',
    'Item Name': 'Sweet Chilli - Chicken (Small)',
    Celery: false,
    'Cereals Containing Gluten': true,
    'Cereals Containing Gluten Species': 'Contains: Wheat',
    Crustaceans: false,
    Egg: false,
    Fish: false,
    Lupin: false,
    Milk: false,
    Molluscs: false,
    Mustard: false,
    Peanuts: false,
    Sesame: false,
    Soya: true,
    Sulphites: true,
    'Tree Nut': false,
    'Tree Nut Species': '',
    Garlic: true,
    Onions: false,
    Kcal: 746,
    Ingredients:
      'CHICKEN MILANESE CONTINGENCY 220G, Sweet Chilli Sauce POD, CHILLIES RED DUTCH, SPRING ONIONS X 1 BUN SP, Thai Jasmine Rice - POD',
    Vegetarian: false,
    Vegan: false,
  },
  {
    'Recipe ID': 'd1b1af4a-9ed1-43c1-8b77-70ca0436967c',
    'Item Name': 'Sweet Chilli Prawn - Noodles (Small)',
    Celery: false,
    'Cereals Containing Gluten': true,
    'Cereals Containing Gluten Species': 'Contains: Wheat',
    Crustaceans: true,
    Egg: false,
    Fish: false,
    Lupin: false,
    Milk: false,
    Molluscs: false,
    Mustard: false,
    Peanuts: false,
    Sesame: false,
    Soya: true,
    Sulphites: true,
    'Tree Nut': false,
    'Tree Nut Species': '',
    Garlic: true,
    Onions: false,
    Kcal: 689,
    Ingredients:
      "Panko Prawns, 8 '' Thick Non Egg Noodles (with Turmeric & Paprika), Sweet Chilli Sauce POD, CHILLIES RED DUTCH, CORIANDER BUNCH X 100GM SP, SPRING ONIONS X 1 BUN SP",
    Vegetarian: false,
    Vegan: false,
  },
  {
    'Recipe ID': 'dbeec0d4-5883-4ded-8fa6-86266b15d07b',
    'Item Name': 'Sweet Chilli Beef - Noodles (Small)',
    Celery: false,
    'Cereals Containing Gluten': true,
    'Cereals Containing Gluten Species': 'Contains: Wheat',
    Crustaceans: false,
    Egg: true,
    Fish: false,
    Lupin: false,
    Milk: false,
    Molluscs: false,
    Mustard: false,
    Peanuts: false,
    Sesame: false,
    Soya: true,
    Sulphites: true,
    'Tree Nut': false,
    'Tree Nut Species': '',
    Garlic: true,
    Onions: false,
    Kcal: 651,
    Ingredients:
      "8 '' Thick Non Egg Noodles (with Turmeric & Paprika), Sweet Chilli Sauce POD, Shredded Beef Strips, CHILLIES RED DUTCH, CORIANDER BUNCH X 100GM SP, SPRING ONIONS X 1 BUN SP",
    Vegetarian: false,
    Vegan: false,
  },
  {
    'Recipe ID': 'dd80e376-1e68-4c58-9207-c4473df83878',
    'Item Name': 'Squid & Sweet Chilli',
    Celery: false,
    'Cereals Containing Gluten': false,
    'Cereals Containing Gluten Species': '',
    Crustaceans: false,
    Egg: false,
    Fish: false,
    Lupin: false,
    Milk: false,
    Molluscs: true,
    Mustard: false,
    Peanuts: false,
    Sesame: false,
    Soya: true,
    Sulphites: true,
    'Tree Nut': false,
    'Tree Nut Species': '',
    Garlic: true,
    Onions: false,
    Kcal: 239,
    Ingredients:
      'Sweet Chilli Sauce POD, Pineapple Cut Squid - Gigas, CHILLIES RED DUTCH, CRACKED BLK PEPPER, LIMES, SPRING ONIONS X 1 BUN SP, TABLE SALT, White Rice Flour',
    Vegetarian: false,
    Vegan: false,
  },
  {
    'Recipe ID': 'de334c14-b2b8-41c5-a2e2-a052ae20747a',
    'Item Name': 'Katsu Seitan Rice (Small)',
    Celery: false,
    'Cereals Containing Gluten': true,
    'Cereals Containing Gluten Species': 'Contains: Wheat',
    Crustaceans: false,
    Egg: false,
    Fish: false,
    Lupin: false,
    Milk: false,
    Molluscs: false,
    Mustard: false,
    Peanuts: false,
    Sesame: false,
    Soya: true,
    Sulphites: false,
    'Tree Nut': false,
    'Tree Nut Species': '',
    Garlic: true,
    Onions: false,
    Kcal: 346,
    Ingredients:
      "Katsu Curry POD, Funky Chyck'n Seitan Breaded Bites, CHILLIES RED DUTCH, CORIANDER BUNCH X 100GM SP, SPRING ONIONS X 1 BUN SP, Thai Jasmine Rice - POD",
    Vegetarian: true,
    Vegan: true,
  },
  {
    'Recipe ID': 'df001a23-4d86-4d1f-8bd0-0a1d113bc008',
    'Item Name': 'Teriyaki Beef - Noodles - Small',
    Celery: false,
    'Cereals Containing Gluten': true,
    'Cereals Containing Gluten Species': 'Contains: Wheat',
    Crustaceans: false,
    Egg: true,
    Fish: false,
    Lupin: false,
    Milk: false,
    Molluscs: false,
    Mustard: false,
    Peanuts: false,
    Sesame: false,
    Soya: true,
    Sulphites: false,
    'Tree Nut': false,
    'Tree Nut Species': '',
    Garlic: true,
    Onions: false,
    Kcal: 522,
    Ingredients:
      "Teriyaki Sauce POD, 8 '' Thick Non Egg Noodles (with Turmeric & Paprika), Shredded Beef Strips, CHILLIES RED DUTCH, CORIANDER BUNCH X 100GM SP, SPRING ONIONS X 1 BUN SP",
    Vegetarian: false,
    Vegan: false,
  },
  {
    'Recipe ID': 'e9fef768-e059-47c4-9907-c79aba4d129f',
    'Item Name': 'Katsu - Crispy Beef - Rice (Large)',
    Celery: false,
    'Cereals Containing Gluten': true,
    'Cereals Containing Gluten Species': 'Contains: Wheat',
    Crustaceans: false,
    Egg: true,
    Fish: false,
    Lupin: false,
    Milk: false,
    Molluscs: false,
    Mustard: false,
    Peanuts: false,
    Sesame: false,
    Soya: true,
    Sulphites: false,
    'Tree Nut': false,
    'Tree Nut Species': '',
    Garlic: true,
    Onions: false,
    Kcal: 947,
    Ingredients:
      'Katsu Curry POD, Shredded Beef Strips, CHILLIES RED DUTCH, CORIANDER BUNCH X 100GM SP, SPRING ONIONS X 1 BUN SP, Thai Jasmine Rice - POD',
    Vegetarian: false,
    Vegan: false,
  },
  {
    'Recipe ID': 'ec60364e-5887-4b64-99dc-3c2aa7b03425',
    'Item Name': 'Teriyaki - Panko Prawns (Large)',
    Celery: false,
    'Cereals Containing Gluten': true,
    'Cereals Containing Gluten Species': 'Contains: Wheat',
    Crustaceans: true,
    Egg: false,
    Fish: false,
    Lupin: false,
    Milk: false,
    Molluscs: false,
    Mustard: false,
    Peanuts: false,
    Sesame: false,
    Soya: true,
    Sulphites: false,
    'Tree Nut': false,
    'Tree Nut Species': '',
    Garlic: true,
    Onions: false,
    Kcal: 1006,
    Ingredients:
      'Teriyaki Sauce POD, Panko Prawns, CHILLIES RED DUTCH, CORIANDER BUNCH X 100GM SP, SPRING ONIONS X 1 BUN SP, Thai Jasmine Rice - POD',
    Vegetarian: false,
    Vegan: false,
  },
  {
    'Recipe ID': 'fe139b89-fd9d-470f-b7c4-565c96c88b01',
    'Item Name': 'Small Teriyaki Seitan - Noodles',
    Celery: false,
    'Cereals Containing Gluten': true,
    'Cereals Containing Gluten Species': 'Contains: Wheat',
    Crustaceans: false,
    Egg: false,
    Fish: false,
    Lupin: false,
    Milk: false,
    Molluscs: false,
    Mustard: false,
    Peanuts: false,
    Sesame: false,
    Soya: true,
    Sulphites: false,
    'Tree Nut': false,
    'Tree Nut Species': '',
    Garlic: true,
    Onions: false,
    Kcal: 374,
    Ingredients:
      "Teriyaki Sauce POD, 8 '' Thick Non Egg Noodles (with Turmeric & Paprika), Funky Chyck'n Seitan Breaded Bites, CHILLIES RED DUTCH, CORIANDER BUNCH X 100GM SP, SPRING ONIONS X 1 BUN SP",
    Vegetarian: true,
    Vegan: true,
  },
];

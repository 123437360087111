import { IMenuDataResponse } from 'src/api/getMenu/types';
import { IMenuItemsResponse } from 'src/api/types';
import { IMenu, IMenuItem, TPublicationStatus } from '../../types/menu.types';

export const formatMenuItems = (menuItems: IMenuItemsResponse[]): IMenuItem[] => {
  return menuItems.map((menuItem) => ({
    itemId: menuItem.item_id,
    name: menuItem.name,
    description: menuItem.description,
    currency: menuItem.currency,
    prices: {
      defaultPrice: menuItem.prices?.default_base_price_with_tax || menuItem?.price, // support for deprecated FE key "price"
      priceBands: menuItem.prices?.price_bands?.map((band) => ({
        priceBandId: band.venue_id,
        name: band.name,
        aggregators: band.external_systems?.map((aggregator) => ({
          aggregatorId: aggregator.fk_external_system_id,
          aggregatorName: aggregator.name,
          price: aggregator.base_price_with_tax,
        })),
      })),
    },
    taxRate: menuItem.tax_rate,
    posId: menuItem.plu,
    type: menuItem.type,
    image: menuItem.assets[0]?.asset_variants[0].url,
    displayOrder: menuItem.display_order,
    recipeId: menuItem.recipes ? menuItem.recipes[0] : null,
    parentItemId: menuItem.fk_parent_item_id,
    optionGroup: menuItem.modifier_groups?.map((option) => ({
      name: option.name,
      optionGroupId: option.pk_modifier_group_id,
      description: option.description,
      min: option.min_choices,
      max: option.max_choices,
      items: option.modifiers.map((item) => ({
        optionItemId: item.item.item_id,
        name: item.item.name,
        price: item.item.price,
        posId: item.item.plu,
        parentItemId: item.item.fk_parent_item_id,
      })),
    })),
  }));
};

export const getMenuService = (data: IMenuDataResponse): IMenu => {
  const image = data.assets[0]?.asset_variants[0].url;

  const modifiers = data.items.filter((item) => item.type === 'MODIFIER');

  const sections = data.sections.map((section) => ({
    name: section.name,
    sectionId: section.section_id,
    description: section.description,
    displayOrder: section.display_order,
    items: formatMenuItems(
      section.items
        .map((item) => data.items.filter((singleItem) => item === singleItem.item_id && singleItem.type !== 'MODIFIER'))
        .flat(),
    ),
  }));

  const uncategorised = data.items.filter(
    (item) =>
      item.type !== 'MODIFIER' &&
      sections.every((section) => section.items.every((sectionItem) => sectionItem.itemId !== item.item_id)),
  );
  const menuPublicationDate = data.publication.publication_date ? new Date(data.publication.publication_date) : null;

  const dayOfWeekAsString = (dayIndex: number) => {
    return ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][dayIndex] || '';
  };

  const convertTimeFormat = (startEndTime: string) => {
    return startEndTime.slice(0, 2) + ':' + startEndTime.slice(2);
  };

  const menu: IMenu = {
    name: data.name,
    menuId: data.menu_id,
    image: image,
    modifiers: formatMenuItems(modifiers),
    sections: sections,
    uncategorised: formatMenuItems(uncategorised),
    schedules: data.schedules
      .sort((a, b) => {
        return a.day_of_week - b.day_of_week;
      })
      .map((item) => ({
        dayOfWeek: dayOfWeekAsString(item.day_of_week),
        startTime: convertTimeFormat(item.start_time_hhmm),
        endTime: convertTimeFormat(item.end_time_hhmm),
      })),
    publication: {
      publicationStatus: data.publication.status as TPublicationStatus,
      publicationDate: menuPublicationDate,
      aggregators: data.publication.channels.map((aggregator) => ({
        aggregatorId: aggregator.external_system_id,
        publicationStatus: aggregator.status as TPublicationStatus,
        publicationDate: new Date(aggregator.publication_date),
        venues: aggregator.venues.map((venue) => ({
          venueId: venue.venue_id,
          publicationDate: new Date(venue.publication_date),
          publicationStatus: venue.status as TPublicationStatus,
        })),
      })),
    },
    created: new Date(data.create_date),
    linkedMenuIds: data.linked_menus.map((linkedMenu) => linkedMenu.menu_id),
  };

  return menu;
};

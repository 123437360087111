import { IMenu, IMenuItem } from 'src/types/menu.types';

export const addMenuItem = (menus: IMenu[], menuId: string, itemId: string, currentItem: IMenuItem): IMenu[] => {
  const currentMenu = menus.find((menu) => menu.menuId === menuId);
  if (currentMenu.uncategorised.find((item) => item.itemId === itemId)) {
    currentMenu.uncategorised = currentMenu.uncategorised.map((item) => {
      if (item.itemId === itemId) {
        return {
          ...item,
          ...currentItem,
        };
      }
      return item;
    });
  } else if (currentMenu.modifiers.find((item) => item.itemId === itemId)) {
    currentMenu.modifiers = currentMenu.modifiers.map((item) => {
      if (item.itemId === itemId) {
        return {
          ...item,
          ...currentItem,
        };
      }
      return item;
    });
  } else {
    currentMenu.sections = currentMenu.sections.map((section) => ({
      ...section,
      items: section.items.map((item) => {
        if (item.itemId === itemId) {
          return {
            ...item,
            ...currentItem,
          };
        }
        return item;
      }),
    }));
  }
  return menus;
};

import { theme } from 'src/assets/theme/theme';
import colors from 'src/assets/theme/colors';
import { styled, Card } from '@mui/material';

export const CardContainer = styled(Card)`
  border: 1px dashed ${colors.AZT_Light};
  border-radius: ${theme.spacing(2)};
  width: 100%;
  height: ${theme.spacing(17)};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: none;
`;

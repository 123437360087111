import { ChangeEvent, ReactElement } from 'react';
import { Checkbox as MUICheckbox } from '@mui/material';
import { StyledCheckbox } from './Checkbox.styles';

interface ICheckboxProps {
  isChecked: boolean;
  label: string | ReactElement;
  onChange: (e: ChangeEvent) => void;
  disabled?: boolean;
}
export const Checkbox = ({ isChecked, label, onChange, disabled }: ICheckboxProps): ReactElement => {
  return (
    <StyledCheckbox
      label={label}
      control={<MUICheckbox checked={isChecked} onChange={onChange} disabled={disabled} />}
    />
  );
};

import { IGetMenusResponseMenu } from 'src/api/getMenus/types';
import { IMenu } from 'src/types/menu.types';

export const getMenusService = (menus: IGetMenusResponseMenu[]): IMenu[] => {
  return menus.map((menu) => ({
    menuId: menu.menu_id,
    name: menu.name,
    description: menu.description,
    image: menu.assets[0]?.asset_variants[0]?.url,
    uncategorised: [],
    modifiers: [],
    sections: [],
    schedules: [],
    publication: {
      publicationStatus: 'UNPUBLISHED',
      aggregators: [],
      publicationDate: new Date(),
    },
    created: new Date(menu.create_date),
    linkedMenuIds: [],
  }));
};

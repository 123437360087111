import { theme } from 'src/assets/theme/theme';
import { styled, Box } from '@mui/material';
import colors from 'src/assets/theme/colors';

export const StyledBox = styled(Box)`
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-top: ${theme.spacing(16)};
  margin-bottom: ${theme.spacing(4)};
`;

export const SectionNumber = styled(Box)`
  height: ${theme.spacing(7)};
  width: ${theme.spacing(7)};
  background-color: ${colors.AZT_Dark};
  color: ${colors.white};
  border-radius: ${theme.spacing(10)};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: ${theme.spacing(4)};
`;

/* eslint-disable @typescript-eslint/naming-convention */
import { ReactElement, useEffect } from 'react';
import { FormGroup } from '@mui/material';
import { StyledTitle, StyledFormControl, StyledSubtitle } from './SectionModal.styles';
import ModalImg from '../../assets/images/section-modal.png';
import MainButton from '../MainButton/MainButton';
import { useNotificationOutlet } from 'src/components/Notification';
import { useForm } from 'react-hook-form';
import { FormInputText, FormCheckbox } from 'src/components/FormElements';
import { ModalWrapper } from 'src/components';
import { IMenuItem, ISection } from '../../types/menu.types';
import { useMenusContext } from 'src/contexts/menus';
import { createNewMenuSection } from 'src/api/createNewMenuSection/createNewMenuSection';
import { updateMenuSection } from 'src/api/updateMenuSection/updateMenuSection';

export interface ModalProps {
  title?: string;
  closeModal?: any;
  open: boolean;
  variant: string;
  id?: string;
  setTitle?: any;
  sectionName?: string;
  sectionDescription?: string;
  items?: IMenuItem[];
  menuId: string;
  sectionId?: string;
}

const SectionModal = ({
  sectionName,
  sectionDescription,
  title,
  closeModal,
  open,
  variant,
  menuId,
  sectionId,
  items: uncategorisedItems,
}: ModalProps): ReactElement => {
  const { createAMenuSection, updateAMenuSection: renameAMenuSection } = useMenusContext();
  const setMessage = useNotificationOutlet();

  const {
    control,
    handleSubmit,
    reset,
    getValues,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      name: sectionName,
      description: sectionDescription,
      items: [],
    },
  });

  useEffect(() => {
    if (!open) {
      reset({ name: sectionName, description: sectionDescription });
    }
  }, [open, reset, sectionName, sectionDescription]);

  const onSubmit = async (data) => {
    if (isSubmitting) return;
    const formattedSectionName = data.name.replace(/'/g, "''");
    const requestBody = {
      name: formattedSectionName,
      description: data.description,
    };
    const makeRequest = () => {
      if (variant === 'update') {
        return updateMenuSection(menuId, sectionId, requestBody);
      }
      return createNewMenuSection(menuId, {
        name: formattedSectionName,
        description: data.description,
        items: data.items,
      });
    };

    await makeRequest()
      .then((res) => res.json())
      .then((jsonRes) => {
        if (variant === 'create') {
          const { section_id, name, display_order, items, description } = jsonRes.data[0];

          const newSection: Omit<ISection, 'items'> = {
            sectionId: section_id,
            name,
            description,
            displayOrder: display_order,
          };

          createAMenuSection(menuId, newSection, items);
        } else if (variant === 'update') {
          renameAMenuSection(menuId, jsonRes.data[0].section_id, jsonRes.data[0].name, jsonRes.data[0].description);
        }

        closeModal();
      })
      .catch(() => {
        setMessage({
          severity: 'error',
          message: `Something went wrong whilst ${variant === 'create' ? 'creating' : 'updating'} the menu section`,
        });
      });
  };

  return (
    <ModalWrapper
      closeModal={closeModal}
      open={open}
      image={ModalImg}
      imageAlt={'Picture of four beautifully displayed breakfast plates'}
      variant={variant}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <StyledTitle variant="xxlText">{title}</StyledTitle>
        <FormInputText
          name="name"
          control={control}
          rules={{ required: true }}
          label="Section name"
          testId={`${variant}-section-name`}
        />
        <FormInputText
          name="description"
          control={control}
          label="Section description"
          rows={2}
          testId={`${variant}-section-description`}
        />
        {variant === 'create' && uncategorisedItems?.length > 0 && (
          <>
            <StyledSubtitle>Select menu items to include</StyledSubtitle>
            <StyledFormControl>
              <FormGroup>
                <FormCheckbox
                  control={control}
                  name={'items'}
                  items={uncategorisedItems.map(({ name, itemId }) => ({ label: name, id: itemId }))}
                  getValues={getValues}
                />
              </FormGroup>
            </StyledFormControl>
          </>
        )}
        <MainButton type="submit" text={variant === 'create' ? 'Create' : 'Save'} loading={isSubmitting} />
      </form>
    </ModalWrapper>
  );
};

export default SectionModal;

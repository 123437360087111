import { ReactElement, useState } from 'react';
import { InputLabel, MenuItem, Select, Box, Typography } from '@mui/material';
import Prompt, {
  Container,
  StyledDialogAction,
  StyledTitle,
  StyledText,
  FullLengthButton,
  StyledFormControl,
  StyledMenuItem,
} from '../Prompt';
import { Tooltip } from '@mui/material';
import { useOktaAuth } from '@okta/okta-react';
import { moveMenuItemToSection } from 'src/api/moveMenuItemToSection/moveMenuItemToSection';
import { useMenusContext } from 'src/contexts/menus';
import { useNotificationOutlet } from '../Notification';

interface MovePromptProps {
  title?: string;
  menuId: string;
  itemId: string;
  sectionId: string;
  closePrompt?: any;
  open: boolean;
}

const MovePrompt = ({ title, menuId, itemId, sectionId, closePrompt, open }: MovePromptProps): ReactElement => {
  const { menus, moveAMenuItem } = useMenusContext();
  const [value, setValue] = useState<string>('');
  const { authState } = useOktaAuth();
  const setNotification = useNotificationOutlet();
  const accessToken = authState?.accessToken?.accessToken;
  const sectionCharLimit = 115;
  const currentMenu = menus.find((FEMenu) => FEMenu.menuId === menuId);

  const handleChange = (e: { target: { value: string } }) => {
    setValue(e.target.value);
  };

  const moveButtonHandler = () => {
    const newSectionId = value === 'Uncategorised' ? null : value;
    let newDisplayOrder = 0;
    if (newSectionId) {
      const selectedSection = currentMenu.sections.find((section) => section.sectionId === newSectionId);
      newDisplayOrder =
        selectedSection.items.length > 0 ? Math.max(...selectedSection.items.map((item) => item.displayOrder)) + 1 : 0;
    } else {
      newDisplayOrder =
        currentMenu.uncategorised.length > 0
          ? Math.max(...currentMenu.uncategorised.map((item) => item.displayOrder)) + 1
          : 0;
    }
    moveMenuItemToSection(accessToken, menuId, itemId, newSectionId, newDisplayOrder)
      .then((response) => {
        if (!response.ok) {
          setNotification({
            severity: 'error',
            description: 'Internal Server Error',
            message: 'We have been unable to complete your request',
          });
        } else {
          moveAMenuItem(menuId, newSectionId, itemId, sectionId, newDisplayOrder);
        }
      })
      .catch(() => {
        setNotification({
          severity: 'error',
          description: 'Internal Server Error',
          message: 'We have been unable to complete your request',
        });
      });
  };

  return (
    <Prompt testId="close-move-menu-item-prompt" closePrompt={closePrompt} open={open}>
      <Container>
        <StyledTitle variant="xlText">
          <Typography variant="strongText">Move &quot;{title}&quot;</Typography>
        </StyledTitle>
        <StyledFormControl fullWidth onSubmit={moveButtonHandler}>
          <InputLabel id="move-section-dropdown">Move to section</InputLabel>
          <Select
            labelId="move-section-dropdown-label"
            data-testid="move-section-dropdown-list"
            id="move-section-dropdown"
            label="Move to section"
            value={value}
            onChange={handleChange}
          >
            {sectionId && <MenuItem value="Uncategorised">Uncategorised</MenuItem>}
            {currentMenu.sections
              .filter((section) => section.sectionId !== sectionId)
              .map(({ sectionId: id, name }) => (
                <StyledMenuItem key={`move-prompt-option-${id ?? 'uncategorised'}-${itemId}`} value={id}>
                  <Tooltip arrow title={name} enterNextDelay={700}>
                    <Box>{name.length > sectionCharLimit ? `${name.substring(0, sectionCharLimit)}...` : name}</Box>
                  </Tooltip>
                </StyledMenuItem>
              ))}
          </Select>
        </StyledFormControl>
        <StyledDialogAction>
          <FullLengthButton
            data-testid="save-move-item-section-button"
            color="primary"
            variant="contained"
            onClick={moveButtonHandler}
          >
            <StyledText>Save</StyledText>
          </FullLengthButton>
        </StyledDialogAction>
      </Container>
    </Prompt>
  );
};

export default MovePrompt;

import colors from '../colors';

const { error, white } = colors;

const textfield = {
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        '&.Mui-error': {
          color: error,
        },
      },
    },
  },
  MuiFormLabel: {
    styleOverrides: {
      root: {
        '&.Mui-error': {
          color: error,
        },
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        '&.Mui-error': {
          color: error,
          '.MuiOutlinedInput-notchedOutline': {
            borderColor: error,
            '&:after': {
              content: '"!"',
              color: white,
              backgroundColor: error,
              position: 'absolute' as const,
              right: '1em',
              top: '50%',
              transform: 'translateY(-60%)',
              display: 'flex',
              width: '20px',
              height: '20px',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '50%',
              fontSize: '80%',
            },
          },
        },
      },
    },
  },
};

export default textfield;

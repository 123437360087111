import { IMenu, IMenuItem } from 'src/types/menu.types';

export const addMultipleMenuItems = (
  menus: IMenu[],
  menuId: string,
  parentItemId: string,
  itemsToAddToMenu: IMenuItem[],
  sectionId: string | null,
): IMenu[] => {
  return menus.map((menu) => {
    if (menu.menuId === menuId) {
      const currentSection = sectionId && menu.sections.find((section) => section.sectionId === sectionId);
      itemsToAddToMenu.map((item) => {
        if (item.type === 'MODIFIER') {
          menu.modifiers = [...menu.modifiers, item];
        } else if (currentSection && parentItemId === item.parentItemId) {
          currentSection.items = [...currentSection.items, item];
        } else {
          menu.uncategorised = [...menu.uncategorised, item];
        }
      });
    }
    return menu;
  });
};

import colors from 'src/assets/theme/colors';
import { theme } from 'src/assets/theme/theme';
import { styled, Button, Box, Dialog, Typography, DialogActions, FormControl, MenuItem } from '@mui/material';

export const StyledCloseIconContainer = styled(Box)`
  display: flex;
  justify-content: flex-end;
`;

export const StyledIconClose = styled(Button)`
  color: ${colors.darkBlue};
  padding: ${theme.spacing(4)};
  &:hover {
    background: none;
  }
`;

export const StyledDialog = styled(Dialog)({
  '& > .MuiDialog-container > .MuiPaper-root': {
    borderRadius: `${theme.spacing(3.25)}`,
    width: `${theme.spacing(145)}`,
  },
});

export const StyledDialogAction = styled(DialogActions)`
  display: flex;
  justify-content: space-around;
  align-items: center;
  ${(props) => props.theme.breakpoints.down('ms')} {
    flex-direction: column;
  }
`;

const StyledButton = styled(Button)`
  max-height: ${theme.spacing(13.75)};
  border-radius: ${theme.spacing(7.5)};
  padding: ${theme.spacing(2)} ${theme.spacing(2)};
  margin: ${theme.spacing(8)} 0 ${theme.spacing(5)} 0;

  ${(props) => props.theme.breakpoints.down('ms')} {
    min-width: 100%;
  }
`;

export const SmallButton = styled(StyledButton)`
  min-width: ${theme.spacing(50)};
`;

export const FullLengthButton = styled(StyledButton)`
  min-width: 100%;
`;

export const StyledText = styled(Typography)`
  text-transform: none;
`;

export const Container = styled(Box)`
  width: 80%;
  padding: 0 0 ${theme.spacing(5)} 0;
  align-self: center;
  ${(props) => props.theme.breakpoints.down('ms')} {
    width: 100%;
  }
`;

export const StyledTitle = styled(Typography)`
  padding: 0;
`;

export const StyledSubTitle = styled(Typography)`
  width: 100%;
  margin-top: ${theme.spacing(4)};
  font-weight: 500;
  color: ${theme.palette.primary.main};
`;

export const StyledFormControl = styled(FormControl)`
  margin-top: ${theme.spacing(6)};
`;

export const StyledMenuItem = styled(MenuItem)`
  white-space: normal;
`;

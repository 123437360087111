import { ReactElement } from 'react';
import InfoTooltip from '../InfoTooltip';
import {
  StyledImgWrapper,
  StyledInfoTooltipLogoWrapper,
  StyledInput,
  StyledInputWrapper,
  StyledLabel,
  StyledRadioButtonCard,
  StyledText,
} from './RadioButton.styles';

interface IRadioButtonProps {
  name: string;
  value: string;
  label: string;
  toolTipText: string;
  logo: ReactElement;
  isSelected?: boolean;
}

const RadioButton = ({ name, value, label, toolTipText, logo, isSelected }: IRadioButtonProps) => {
  return (
    <StyledLabel>
      <StyledRadioButtonCard is_selected={isSelected.toString()}>
        <StyledInfoTooltipLogoWrapper>
          <InfoTooltip title={toolTipText} />
        </StyledInfoTooltipLogoWrapper>
        <StyledImgWrapper>{logo}</StyledImgWrapper>
        <StyledInputWrapper>
          <StyledText is_selected={isSelected.toString()}>{label}</StyledText>
          <StyledInput name={name} value={value} id={`${value}-option`} checked={isSelected} aria-label={value} />
        </StyledInputWrapper>
      </StyledRadioButtonCard>
    </StyledLabel>
  );
};

export default RadioButton;

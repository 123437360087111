import { SvgIcon } from '@mui/material';

const MenuClipboard = (props) => {
  return (
    <SvgIcon {...props}>
      <svg aria-hidden="true" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.3332 4.66667V7.33333C21.3332 7.70152 21.0347 8 20.6665 8H11.3332C10.965 8 10.6665 7.70152 10.6665 7.33333V4.66667C10.6665 4.29848 10.965 4 11.3332 4H20.6665C21.0347 4 21.3332 4.29848 21.3332 4.66667Z"
          stroke="#4891C7"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.3333 5.33301H25.2892C26.762 5.33301 27.9559 6.52692 27.9559 7.99967V25.333C27.9559 26.8058 26.762 27.9997 25.2892 27.9997H6.66667C5.19391 27.9997 4 26.8058 4 25.333V7.99967C4 6.52692 5.19391 5.33301 6.66667 5.33301H10.6667V5.33301"
          stroke="#4891C7"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M12 14.6667H20" stroke="#4891C7" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M12 19.9997H16" stroke="#4891C7" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </SvgIcon>
  );
};

export default MenuClipboard;

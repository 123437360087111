import type { IMoveSectionProp } from './types';

export const moveSection = async ({
  accessToken,
  menuId,
  sectionId,
  newDisplayOrder,
}: IMoveSectionProp): Promise<Response> => {
  const api = process.env.REACT_APP_API_ENDPOINT;
  const uri = `${api}/menus/${menuId}/sections/${sectionId}/reorder`;
  return fetch(uri, {
    method: 'PATCH',
    body: JSON.stringify({ display_order: newDisplayOrder }),
    cache: 'reload',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

import { styled, Typography, Radio } from '@mui/material';
import { theme } from 'src/assets/theme/theme';
import colors from 'src/assets/theme/colors';

interface RadioButtonSelectedProps {
  is_selected: string;
}

export const StyledRadioButtonCard = styled('span')<RadioButtonSelectedProps>`
  box-sizing: border-box;
  width: ${theme.spacing(59)};
  height: ${theme.spacing(54.25)};
  border: 2px solid ${(props) => (props.is_selected === 'true' ? colors.lightBlue : colors.lightestGrey)};
  border-radius: ${theme.spacing(1)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const StyledImgWrapper = styled('span')`
  position: absolute;
  top: ${theme.spacing(6)};
`;

export const StyledInputWrapper = styled('span')`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  bottom: ${theme.spacing(7.25)};
`;

export const StyledLabel = styled('label')`
  cursor: pointer;
`;

export const StyledText = styled(Typography)<RadioButtonSelectedProps>`
  text-transform: none;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.75rem;
  text-align: center;
  color: ${(props) => (props.is_selected === 'true' ? colors.lightBlue : colors.black)};
  position: relative;
  bottom: ${theme.spacing(4.25)};
  text-transform: capitalize;
`;

export const StyledInput = styled(Radio)`
  margin: 0;
  width: ${theme.spacing(5)};
  height: ${theme.spacing(5)};
  appearance: none;
  border: ${theme.spacing(0.5)} solid ${colors.black};
  border-radius: 50%;
  background-clip: content-box;
  padding: 3px;

  &:checked {
    background-color: ${colors.lightBlue};
    border: 1px solid ${colors.lightBlue};
  }
`;

export const StyledInfoTooltipLogoWrapper = styled('span')`
  position: absolute;
  top: ${theme.spacing(4)};
  right: ${theme.spacing(4)};
`;

export const TooltipStyleOverride = {
  tooltip: {
    sx: {
      bgcolor: `${colors.navy}`,
      '& .MuiTooltip-arrow': {
        color: `${colors.navy}`,
      },
    },
  },
};

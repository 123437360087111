import { objectsEqual } from 'src/helpers';
import { IItemPriceBand } from 'src/types/menu.types';
import findUpdatedAggregators from '../findUpdatedAggregators';
import { IFormPriceBand } from '../../types';

export const formatPriceBands = (priceBands: IItemPriceBand[]): IFormPriceBand[] =>
  priceBands.map((priceBand) => ({
    ...priceBand,
    aggregators: priceBand.aggregators.map((agg) => ({
      ...agg,
      price: agg.price.toString(),
    })),
  }));

const filterPriceBands = (
  priceBands: IItemPriceBand[] = [],
  formPriceBands: IFormPriceBand[] = [],
): IFormPriceBand[][] => {
  const formattedPriceBands: IFormPriceBand[] = formatPriceBands(priceBands);

  const updatedPriceBands: IFormPriceBand[] = [];
  const addedPriceBands: IFormPriceBand[] = [];

  formPriceBands.forEach((formPriceBand: IFormPriceBand) => {
    const originalPriceBand: IFormPriceBand = formattedPriceBands.find(
      (priceBand) => priceBand.priceBandId === formPriceBand.priceBandId,
    );

    if (originalPriceBand) {
      const isPriceBandUpdated = !objectsEqual(formPriceBand, originalPriceBand);

      if (!isPriceBandUpdated) return;

      const updatedAggregators = findUpdatedAggregators(originalPriceBand.aggregators, formPriceBand.aggregators);

      const updatedPriceBand = {
        ...originalPriceBand,
        aggregators: updatedAggregators,
      };

      updatedPriceBands.push(updatedPriceBand);
    } else {
      addedPriceBands.push(formPriceBand);
    }
  });

  return [updatedPriceBands, addedPriceBands];
};

export default filterPriceBands;

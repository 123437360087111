import { createContext, useContext } from 'react';
import { IMenuItem } from 'src/types/menu.types';
import { INewItemData } from 'src/types/items.types';

export interface IItemsContext {
  items: IMenuItem[];
  addItems: (items: IMenuItem[]) => void;
  setIsLoading: (isLoading: boolean) => void;
  isLoading: boolean;
  addItemToItems: (newMenuItem: IMenuItem) => void;
  updateItem: (itemId: string, NewItemData: INewItemData) => void;
}

export const ItemsContext = createContext<IItemsContext>({} as IItemsContext);

export const useItemsContext = (): IItemsContext => useContext(ItemsContext);

import { styled, Box, FormHelperText, Card } from '@mui/material';
import { theme } from 'src/assets/theme/theme';
import DashedBorderBox from 'src/assets/images/DashedBorderBox';

interface HasError {
  haserror: string;
}

const imageUploadSize = theme.spacing(81);

const SquareCard = styled(Card)`
  width: ${theme.spacing(81)};
  height: ${theme.spacing(81)};
  position: relative;
  background-position: center center;
  background-size: cover;
  ${(props) => props.theme.breakpoints.down('md')} {
    width: ${imageUploadSize};
  }
  ${(props) => props.theme.breakpoints.down('sm')} {
    width: 100%;
  }
`;

export const FormContainer = styled(Box)`
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-direction: row;

  ${(props) => props.theme.breakpoints.down('dl')} {
    flex-direction: column;
  }
`;

export const LeftContentBox = styled(Box)`
  ${(props) => props.theme.breakpoints.down('md')} {
    margin-bottom: ${theme.spacing(5)};
    width: 100%;
  }
`;

export const RightContentBox = styled(Box)`
  width: ${imageUploadSize};
  max-width: ${theme.spacing(125)};
  margin-left: ${theme.spacing(5)};
  ${(props) => props.theme.breakpoints.down('dl')} {
    width: 100%;
    margin-left: 0;
  }
`;

export const HiddenFileUpload = styled('input')`
  opacity: 0;
  pointer-events: none;
  appearance: none;
  height: ${theme.spacing(0)};
  width: ${theme.spacing(0)};
  overflow: hidden;
`;

export const UploadedImage = styled('img')<HasError>`
  height: ${imageUploadSize};
  width: ${imageUploadSize};
  object-fit: cover;
  border-radius: ${theme.spacing(2)};
`;

export const LabelBox = styled(Box)`
  cursor: pointer;
  display: flex;
  flex-direction: row;
`;

export const NewItemCard = styled(SquareCard)`
  border-radius: ${theme.spacing(2)};
  box-shadow: none;
  align-items: center;
  justify-content: center;
  display: flex;
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: background-image 250ms ease-out;
  cursor: pointer;
  flex-direction: column;
  background-image: url(${`"${DashedBorderBox({})}"`});
  &:hover {
    background-image: url(${`"${DashedBorderBox({ hover: true })}"`});
  }
  &::before {
    transform: translate(-50%, -50%) rotate(90deg);
  }
  p {
    margin-top: ${theme.spacing(5)};
    font-size: 1.125rem;
  }
`;

export const CustomErrorMessage = styled(FormHelperText)`
  margin: ${theme.spacing(2.5)} ${theme.spacing(3.5)} 0;
`;

import { IMenuRequest } from '../types';
import { createFormData } from 'src/helpers';
import { API_URL } from '../constants';
import { fetch } from '../fetchWrapper/fetch';

export const createMenu = (payload: IMenuRequest): Promise<any> => {
  const requestBody = createFormData(payload);
  return fetch(`${API_URL}/menus`, {
    method: 'POST',
    body: requestBody,
  });
};

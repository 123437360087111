import { IPublishDestination, ISelectedDestination } from '../../types';

export const selectAllVenues = (publishDestinations: IPublishDestination[]): ISelectedDestination[] => {
  const destinations: ISelectedDestination[] = [];
  publishDestinations.forEach((destination: IPublishDestination) => {
    const selectedDestination: ISelectedDestination = {
      channel: destination.name,
      channel_id: destination.channel_id,
      selected: true,
      selectedVenues: destination.venues.map((venue) => venue.venue_id),
    };
    destinations.push(selectedDestination);
  });
  return destinations;
};

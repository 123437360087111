import colors from '../colors';

const drawer = {
  MuiDrawer: {
    styleOverrides: {
      root: {
        '.MuiBackdrop-root': {
          opacity: '0 !important',
        },
      },
      paper: {
        width: '264px',
        backgroundColor: `${colors.navy}`,
        borderBottom: 'none',
      },
    },
  },
};

export default drawer;

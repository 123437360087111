import colors from 'src/assets/theme/colors';
import { theme } from 'src/assets/theme/theme';
import { styled, Typography, Box } from '@mui/material';

export const StyledImageContainer = styled(Box)`
  max-width: ${theme.spacing(125)};
  width: 100%;
  height: ${theme.spacing(37.5)};
  margin-bottom: ${theme.spacing(14)};
`;
interface IStyledUploadBoxProps {
  error?: boolean;
}
export const StyledUploadBox = styled(Box)<IStyledUploadBoxProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: ${theme.spacing(125)};
  width: 100%;
  height: ${theme.spacing(37.5)};
  margin-bottom: ${theme.spacing(5)};
  cursor: pointer;
  border-radius: ${theme.spacing(2)};
  transition: backgroundImage 250ms ease-out;
  background-image: ${(props) =>
    props.error
      ? "url(\"data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23CC1A76FF' stroke-width='2' stroke-dasharray='6%2c 6' stroke-dashoffset='36' stroke-linecap='square'/%3e%3c/svg%3e\")"
      : "url(\"data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23333' stroke-width='2' stroke-dasharray='6%2c 6' stroke-dashoffset='36' stroke-linecap='square'/%3e%3c/svg%3e\")"};
  &:hover {
    ${(props) =>
      props.error
        ? ''
        : "background-image: url(\"data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23333' stroke-width='2'  stroke-linecap='square'/%3e%3c/svg%3e\")"};
  }
`;

export const StyledButtonText = styled(Typography)`
  margin-top: ${theme.spacing(2)};
`;

export const StyledImage = styled('img')`
  height: 100%;
  object-fit: cover;
  width: 33vw;
`;

export const Container = styled(Box)`
  display: flex;
  justify-content: center;
  height: 100vh;
  overflow: hidden;
`;

export const FormContent = styled(Box)`
  width: 150vw;
  padding: ${theme.spacing(6)};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const StyledText = styled(Typography)`
  max-width: ${theme.spacing(125)};
  width: 100%;
  margin-bottom: ${theme.spacing(8)};
  color: ${theme.palette.primary.main};
`;

export const StyledUploadedText = styled(Typography)`
  margin-top: ${theme.spacing(2)};
  color: ${colors.AZT_Light};
`;

export const ImageContainer = styled(Box)`
  position: relative;
  width: 50vw;

  ${(props) => props.theme.breakpoints.down('dl')} {
    display: none;
  }
`;

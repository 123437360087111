import { SvgIcon } from '@mui/material';

export const EmptyArrowClipboard = (props) => {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 30 30" width="24" height="24" x="40%" y="35%" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M6.51813 4.58798C6.80711 4.78665 6.80711 5.21335 6.51813 5.41202L1.28326 9.01099C0.951513 9.23907 0.5 9.00156 0.5 8.59897L0.499999 1.40103C0.499999 0.998441 0.951513 0.760932 1.28326 0.989009L6.51813 4.58798Z"
          stroke="white"
        />
      </svg>
    </SvgIcon>
  );
};

export default EmptyArrowClipboard;

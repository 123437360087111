import { Typography } from '@mui/material';
import { ReactElement } from 'react';
import { StyledTypography } from './Heading.styles';
import { Variant } from '@mui/material/styles/createTypography';

interface IHeadingProps {
  variantType: string;
  text: string;
  color?: string;
}

export const HeadingTitle = ({ variantType, text, color }: IHeadingProps): ReactElement => {
  return (
    <StyledTypography variant={variantType as Variant}>
      <Typography color={color} variant="strongText">
        {text}
      </Typography>
    </StyledTypography>
  );
};

import { SvgIcon } from '@mui/material';

const UploadImage = (props) => {
  return (
    <SvgIcon {...props}>
      <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.49993 12.4991L12.4991 7.5L17.6104 12.4991"
          stroke="#16243A"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.499 7.50195L12.499 21.5002"
          stroke="#16243A"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M21.5 3.5H3.5" stroke="#16243A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </SvgIcon>
  );
};

export default UploadImage;

import { Box, Skeleton, Typography, useMediaQuery } from '@mui/material';
import { ReactElement, useEffect } from 'react';
import { Table } from '../../components/Table/Table';
import { StyledAddIcon as AddIcon, StyledEmptyStateContainer, StyledTitleContainer } from './Items.styles';
import { theme } from 'src/assets/theme/theme';
import Header from 'src/components/Header';
import { useLocation, useHistory } from 'react-router-dom';
import { useItemsContext } from 'src/contexts/items';
import { mapItemsToTable } from './mapItemsToTable/mapItemsToTable';
import EmptyState from 'src/components/EmptyState';
import Burger from 'src/assets/images/Burger';

export const Items = (): ReactElement => {
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { hash } = useLocation();
  const history = useHistory();
  const { items, isLoading } = useItemsContext();

  useEffect(() => {
    document.title = 'Items';
  }, []);

  const onAddNewMenuItemClick = (): void => {
    history.push('/items/item/new');
  };

  const headingTitle = () => {
    return (
      <StyledTitleContainer>
        <Typography variant="xxlText">
          <Typography variant="strongText">Menu items</Typography>
        </Typography>
        <AddIcon fontSize="large" onClick={onAddNewMenuItemClick} data-testid="add-items-icon" />
      </StyledTitleContainer>
    );
  };

  return (
    <>
      {isLoading ? (
        <Box data-testid="skeleton-container" width="100%">
          <Box display="flex" alignItems="center">
            <Skeleton animation="wave" variant="text" height={50} width={145} />
            <Box ml={4}>
              <Skeleton animation="wave" variant="circular" height={35} width={35} />
            </Box>
          </Box>
          {!isMobile && (
            <Box display="flex" alignItems="center" mt={12} mb={8} justifyContent="space-between">
              {[...Array(5)].map((_n, i) => (
                <Box key={`title-${i}`}>
                  <Skeleton animation="wave" variant="text" height={32} width={145} />
                </Box>
              ))}
            </Box>
          )}
          {[...Array(10)].map((_n, i) => (
            <Box key={`row-${i}`} mt={2.5}>
              <Skeleton animation="wave" variant="rectangular" height={isMobile ? 400 : 100} />
            </Box>
          ))}
        </Box>
      ) : (
        <Box>
          <Header headingTitle={headingTitle()} />
          {items.length > 0 ? (
            <Table type="items" data={mapItemsToTable(items)} selectedRowId={hash.substring(1)} />
          ) : (
            <StyledEmptyStateContainer>
              <EmptyState
                emptySectionText="Add your first menu item"
                buttonText="New menu item"
                Icon={Burger}
                onClick={onAddNewMenuItemClick}
              />
            </StyledEmptyStateContainer>
          )}
        </Box>
      )}
    </>
  );
};

import { ReactElement, ReactNode } from 'react';
import { StyledContainer, StyledBorderLeft, StyledBorderRight, IconContainer, StyledTitle } from './Divider.styles';
import AddIcon from '@mui/icons-material/Add';

interface IDividerProps {
  title?: string;
  children?: ReactNode;
  onClickAdd?: () => void;
  menuId?: string;
  ellipsisMenu?: ReactElement;
}

const Divider = ({ title, children, onClickAdd, ellipsisMenu }: IDividerProps): ReactElement => {
  return (
    <StyledContainer data-testid={`${title}-section-divider`}>
      <StyledBorderLeft />
      {children ?? (
        <StyledTitle wrap={title.length >= 40 ? true : undefined} variant="mText" variantMapping={{ mText: 'h2' }}>
          {title}
        </StyledTitle>
      )}
      <StyledBorderRight title={title} />
      {onClickAdd && (
        <IconContainer>
          <AddIcon data-testid="add-icon-divider" onClick={onClickAdd} />
        </IconContainer>
      )}

      {ellipsisMenu ?? null}
    </StyledContainer>
  );
};

export default Divider;

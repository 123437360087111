import { Typography } from '@mui/material';
import colors from 'src/assets/theme/colors';
import HeadingTitle from '../HeadingTitle';
import { StyledBox, SectionNumber } from './NumericalSubtitle.styles';

interface INumericalSubtitleProps {
  number: string;
  subtitle: string;
}
const NumericalSubtitle = ({ number, subtitle }: INumericalSubtitleProps) => {
  return (
    <StyledBox>
      <SectionNumber>
        <Typography variant="strongText">{number}</Typography>
      </SectionNumber>
      <HeadingTitle variantType="xlText" text={subtitle} color={colors.AZT_Dark} />
    </StyledBox>
  );
};

export default NumericalSubtitle;

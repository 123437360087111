import { IMenuRequest } from '../types';
import { createFormData } from 'src/helpers';
import { API_URL } from '../constants';

export const updateMenu = (authToken: string, menuId: string, payload: IMenuRequest): Promise<any> => {
  const requestBody = createFormData(payload);
  return fetch(`${API_URL}/menus/${menuId}`, {
    method: 'PUT',
    body: requestBody,
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  });
};

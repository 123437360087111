import { ReactElement, useState } from 'react';
import { saveAs } from 'file-saver';
import { Checkbox, FormControlLabel, FormGroup, Typography } from '@mui/material';
import { StyledTitle, StyledFormControl, StyledSubtitle } from './ExportModal.styles';
import ModalImg from '../../assets/images/chef-plating.png';
import MainButton from '../MainButton/MainButton';
import { exportCSV } from 'src/api/exportCSV/exportCSV';
import { useOktaAuth } from '@okta/okta-react';
import { useNotificationOutlet } from 'src/components/Notification';
import { error } from 'src/mocks/data';
import { ModalWrapper } from 'src/components';

export interface ModalProps {
  title?: string;
  closeModal?: any;
  open: boolean;
  id: string;
  name: string;
}

const ExportModal = ({ title, closeModal, open, id, name }: ModalProps): ReactElement => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { authState } = useOktaAuth();
  const setMessage = useNotificationOutlet();
  const accessToken = authState?.accessToken?.accessToken;

  const handleExport = () => {
    const payload = {
      destinations: ['COMTREX'],
    };

    exportCSV({
      token: accessToken,
      payload,
      menuId: id,
    })
      .then((response) => {
        saveAs(response, `${name}.csv`);
        setMessage({
          message: <>You have successfully exported menu {name}.</>,
        });
        setIsLoading(false);
        closeModal();
      })
      .catch(() => {
        setMessage(error);
        setIsLoading(false);
      });
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (isLoading) return;
    setIsLoading(true);
    handleExport();
  };

  return (
    <ModalWrapper
      closeModal={closeModal}
      open={open}
      image={ModalImg}
      imageAlt={'Picture of a chef plating up'}
      variant={'export'}
    >
      <form onSubmit={() => {}}>
        <StyledTitle variant="xxlText">
          <Typography variant="strongText">{title}</Typography>
        </StyledTitle>
        <StyledSubtitle variant="mText" variantMapping={{ mText: 'h2' }}>
          <Typography variant="strongText">Select format</Typography>
        </StyledSubtitle>
        <StyledFormControl>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  data-testid="comtrex-checkbox"
                  checked
                  onChange={() => {}}
                  value="comtrex"
                  name="comtrex"
                  inputProps={{ 'aria-label': 'comtrex checkbox' }}
                />
              }
              label="Comtrex"
            />
          </FormGroup>
        </StyledFormControl>
        <MainButton
          type="submit"
          text={isLoading ? 'Preparing file for download...' : 'Download'}
          loading={isLoading}
          onClick={handleSubmit}
        />
      </form>
    </ModalWrapper>
  );
};

export default ExportModal;

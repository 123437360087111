export const InfoTooltip = () => {
  return (
    <div data-testid="InfoTooltip">
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="8" cy="8" r="8" fill="#010711" />
        <rect x="7" y="6.5" width="2" height="7" rx="1" fill="white" />
        <rect x="7" y="3.5" width="2" height="2" rx="1" fill="white" />
      </svg>
    </div>
  );
};

export default InfoTooltip;

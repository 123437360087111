import { API_URL } from '../constants';
import { fetch } from '../fetchWrapper/fetch';

export const deleteMenuItem = (itemId: string): Promise<any> => {
  return fetch(`${API_URL}/items/${itemId}`, {
    method: 'DELETE',
  }).then((res) => {
    if (res.status === 400) {
      return res.json().then((json) => {
        throw new Error(json.message);
      });
    }
    if (res.status === 204) return {};
  });
};

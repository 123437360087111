import { API_URL } from '../constants';
import { fetch } from '../fetchWrapper/fetch';
import { IPriceBandPayload } from '../types';

export const createPriceBand = (itemId: string, requestBody: IPriceBandPayload): Promise<any> => {
  const url = `${API_URL}/items/${itemId}/prices`;
  return fetch(url, {
    method: 'POST',
    body: JSON.stringify(requestBody),
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

import { ReactElement } from 'react';
import { FormControl, InputLabel, Select, MenuItem, Grid, useMediaQuery } from '@mui/material';
import { theme } from 'src/assets/theme/theme';
import { Control, Controller, UseFormSetValue, UseFormRegister } from 'react-hook-form';
import { PriceBandRow, PriceBandBox, StyledFormHelperText } from './PriceBand.styles';
import { FormInputText } from 'src/components/FormElements';
import { IPriceBand } from 'src/types/priceBand.types';
import { IFormValues } from '../menuItemForm/types';
import { IFormPriceBand, IFormAggregator } from 'src/components/menuItemForm/types';

interface IPriceBandProps {
  control?: Control<IFormValues>;
  aggregators: IFormAggregator[];
  priceBands: IPriceBand[];
  index: number;
  setValue: UseFormSetValue<IFormValues>;
  selectedPriceBands: IFormPriceBand[];
  defaultPrice: string;
  register?: UseFormRegister<IFormValues>;
}

export const PriceBand = ({
  control,
  aggregators,
  priceBands,
  setValue,
  selectedPriceBands,
  index,
  defaultPrice,
}: IPriceBandProps): ReactElement => {
  const getAvailablePriceBands = () => {
    const currentPriceBands = selectedPriceBands.map((selectedPriceBand) => selectedPriceBand.priceBandId);
    currentPriceBands.splice(index, 1);
    return priceBands.filter((priceBand) => !currentPriceBands.includes(priceBand.priceBandId));
  };

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <PriceBandRow>
      <PriceBandBox>
        <Controller
          name={`price_bands.${index}.priceBandId`}
          control={control}
          rules={{ required: true }}
          render={({ field: { value }, fieldState: { error } }) => {
            const availablePriceBands = getAvailablePriceBands();
            return (
              <FormControl fullWidth>
                <InputLabel id="price-band-dropdown">Price band</InputLabel>
                <Select
                  data-testid="price-band-dropdown"
                  id="price-band-dropdown"
                  label="Price band"
                  value={value ? value : ''}
                  onChange={(e) => {
                    setValue(`price_bands.${index}.priceBandId`, e.target.value);
                  }}
                  error={!!error && !value}
                >
                  {!!availablePriceBands &&
                    availablePriceBands.map((option) => (
                      <MenuItem key={option.priceBandId} value={option.priceBandId}>
                        {option.name}
                      </MenuItem>
                    ))}
                </Select>
                {!!error && !value && <StyledFormHelperText>Select a price band</StyledFormHelperText>}
              </FormControl>
            );
          }}
        />
      </PriceBandBox>
      <Grid container pt={isMobile && 4}>
        {aggregators.map((aggregator, aggregatorIndex) => (
          <Grid item key={aggregator.aggregatorId} xs={isMobile ? 12 : 12 / aggregators.length} pl={!isMobile && 3}>
            <FormInputText
              name={`price_bands.${index}.aggregators.${aggregatorIndex}.price`}
              control={control}
              label={`${aggregator.aggregatorName} (£)`}
              rules={{ pattern: /^[.0-9]+$/i }}
              testId={`price-band-${aggregator.aggregatorId}`}
              placeholder={defaultPrice}
            />
          </Grid>
        ))}
      </Grid>
    </PriceBandRow>
  );
};

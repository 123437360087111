import { theme } from 'src/assets/theme/theme';
import { styled, Typography, Box, TextField, Autocomplete } from '@mui/material';
import colors from 'src/assets/theme/colors';

export const StyledInput = styled(TextField)`
  max-width: ${theme.spacing(75)};
  width: 100%;
  margin-bottom: ${theme.spacing(5)};

  ${(props) => props.theme.breakpoints.down('ms')} {
    width: 100%;
  }
`;

export const StyledImage = styled('img')`
  height: 100%;
  object-fit: cover;
  width: 33vw;
`;

export const Container = styled(Box)`
  display: flex;
  justify-content: center;
  height: 100vh;
  overflow: hidden;
`;

export const PublishViewContainer = styled(Box)`
  max-width: ${theme.spacing(125)};
`;

export const FormContent = styled(Box)`
  padding: ${theme.spacing(6)};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const StyledStepText = styled(Typography)`
  max-width: ${theme.spacing(125)};
  width: 100%;
  color: ${colors.AZT_Light};
`;

export const StyledRadioContainer = styled(Box)`
  width: ${theme.spacing(125)};
  margin-top: ${theme.spacing(8)};
  margin-bottom: ${theme.spacing(8)};
`;

export const StyledContainer = styled(Box)`
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: column;
`;

export const StyledAutocomplete = styled(Autocomplete)`
  margin-top: ${theme.spacing(2)};
`;

export const StyledLabelList = styled('li')`
  border: solid 1px black;
  border-top: none;
  position: relative;
  &:first-of-type {
    border-top: solid 1px black;
  }
`;

export const StyledText = styled(Typography)`
  max-width: ${theme.spacing(130)};
  width: 100%;
  margin-bottom: ${theme.spacing(8)};
  color: ${theme.palette.primary.main};
`;

export const StyledTitleStep1 = styled(Typography)`
  max-width: ${theme.spacing(125)};
  width: 100%;
  color: ${theme.palette.primary.main};
  margin-bottom: ${theme.spacing(2)};
`;

export const StyledSubText = styled(Typography)`
  color: ${theme.palette.primary.main};
`;

export const LeftAlignedContainer = styled(Box)`
  margin: ${theme.spacing(2)} 0 ${theme.spacing(8)} ${theme.spacing(8)};
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;

export const PublishDestinationContainer = styled(Box)`
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  & .MuiFormControlLabel-label {
    font-weight: 600;
  }
`;

export const VenueContainer = styled(Box)`
  margin-left: ${theme.spacing(2.5)};
  padding-left: ${theme.spacing(5)};
  border-left: 1px dashed ${colors.AZT_Dark_Opaque};
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;

export const ImageContainer = styled(Box)`
  position: relative;
  width: 50vw;

  ${(props) => props.theme.breakpoints.down('dl')} {
    display: none;
  }
`;

export const StyledPublishStatus = styled(Typography)`
  position: relative;
  top: -10px;
  margin-left: ${theme.spacing(8)};
`;

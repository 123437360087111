import { theme } from 'src/assets/theme/theme';
import colors from 'src/assets/theme/colors';
import { styled, Box, Typography } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Link } from 'react-router-dom';

export const StyledTypography = styled(Typography)`
  margin-left: ${theme.spacing(3)};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const StyledArrowBackIosIcon = styled(ArrowBackIosIcon)`
  padding-left: ${theme.spacing(2)};
  height: ${theme.spacing(8)};
  width: ${theme.spacing(8)};
  color: ${colors.AZT_Dark};
  cursor: pointer;
`;

export const HeadingContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: ${theme.spacing(10)};
`;

export const MobileHeadingContainer = styled(HeadingContainer)`
  flex-direction: column;
`;

export const MobileHeadingOptions = styled(Box)`
  display: flex;
  margin-top: ${theme.spacing(1.5)};
  margin-bottom: ${theme.spacing(5)};
  justify-content: space-between;
  width: 100%;
`;

export const HeaderBox = styled(Box)`
  display: flex;
  flex-direction: row;
`;

export const BackLink = styled(Link)`
  box-sizing: border-box;
  align-self: self-start;
  padding: ${theme.spacing(2)};
  display: flex;
  border: 1px solid transparent;
  border-radius: ${theme.spacing(25)};
  height: ${theme.spacing(12)};
  width: ${theme.spacing(12)};
  align-items: center;
  justify-content: center;
  transition: border 250ms ease-in-out;
  &:hover {
    border-color: ${colors.AZT_Dark};
  }
`;

export const MobileHeading = styled(Box)`
  align-self: self-start;
`;

export const StyledHeadingContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ButtonContainer = styled(Box)`
  margin-left: auto;
  align-self: center;
  display: flex;
  flex-direction: row;
`;

export const StyledSubtitle = styled(Typography)`
  margin-left: ${theme.spacing(15)};
  ${(props) => props.theme.breakpoints.down('md')} {
    margin-left: ${theme.spacing(4)};
  }
`;

import { ReactElement, Suspense } from 'react';

import { StyledRadioButtonGroupBox } from './RadioButtonGroup.styles';
import RadioButton from '../RadioButton';
import { Controller, Control } from 'react-hook-form';

interface RadioButtonOptionProps {
  option: string;
  LogoComponent: React.FC<{ isSelected: boolean }>;
  toolTipText: string;
  label: string;
}

const RadioButtonGroup = ({
  radioButtonGroupName,
  radioOptions,
  control,
}: {
  radioButtonGroupName: string;
  control: Control<any>;
  radioOptions?: RadioButtonOptionProps[];
}): ReactElement => {
  return (
    <Controller
      rules={{ required: true }}
      control={control}
      name={radioButtonGroupName}
      render={({ field }) => {
        return (
          <StyledRadioButtonGroupBox {...field} role="radiogroup">
            {radioOptions.map(({ option, LogoComponent, toolTipText, label }, index) => {
              return (
                <RadioButton
                  key={index}
                  isSelected={field.value === option}
                  name={radioButtonGroupName}
                  value={option}
                  label={label}
                  toolTipText={toolTipText}
                  logo={
                    <Suspense fallback={<></>}>
                      <LogoComponent isSelected={field.value === option} />
                    </Suspense>
                  }
                />
              );
            })}
          </StyledRadioButtonGroupBox>
        );
      }}
    />
  );
};

export default RadioButtonGroup;

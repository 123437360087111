import { theme } from 'src/assets/theme/theme';
import colors from 'src/assets/theme/colors';
import MenuItem from '@mui/material/MenuItem';
import { styled, Button } from '@mui/material';
import Menu from '@mui/material/Menu';

export interface EllipsisMenuButtonProps {
  'background-type'?: 'light' | 'dark';
}

export const StyledButton = styled(Button)<EllipsisMenuButtonProps>`
  margin: ${theme.spacing(1)};
  width: ${theme.spacing(10)};
  min-width: ${theme.spacing(10)};
  height: ${theme.spacing(10)};
  padding: ${theme.spacing(0)};
  background-color: transparent;
  border-radius: ${theme.spacing(25)};
  border: 1px solid transparent;
  svg path {
    fill: ${(props) => (props['background-type'] === 'light' ? colors.AZT_Dark : colors.white)};
  }
  &:hover {
    border: 1px solid ${(props) => (props['background-type'] === 'light' ? colors.AZT_Dark : colors.white)};
    border-radius: ${theme.spacing(25)};
    background-color: transparent;
  }
`;

export const StyledMenu = styled(Menu)`
  width: ${theme.spacing(47)};
  left: ${theme.spacing(10)};
  top: ${theme.spacing(2)};
`;

export const StyledMenuItem = styled(MenuItem)`
  color: ${colors.violetRed};
`;

export const HiddenMenuItem = styled(MenuItem)`
  display: ${(props) => (props.hidden ? 'none' : 'flex')};
`;

import { IPublishDestination, ISelectedDestination } from '../../types';

export const selectedVenue = (
  publishDestinations: IPublishDestination[],
  selectedVenues: string[],
): ISelectedDestination[] => {
  return publishDestinations.map((destination: IPublishDestination) => {
    return {
      channel: destination.name,
      channel_id: destination.channel_id,
      selected: true,
      selectedVenues: selectedVenues,
    };
  });
};

import { ReactElement, MouseEvent, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { MenuItem, Typography } from '@mui/material';
import { useNotificationOutlet } from 'src/components/Notification';
import RemovePrompt from '../RemovePrompt';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { StyledButton, StyledMenu, StyledMenuItem, MenuContainer } from './ItemOptions.styles';
import ExportModal from 'src/components/ExportModal';
import { useMenusContext } from 'src/contexts/menus';
import Modal from '../Modal/Modal';
import { deleteMenu } from 'src/api/deleteMenu/deleteMenu';
import AvailabilityModal from '../AvailabilityModal';
export interface ItemMenuProps {
  id: string;
  name: string;
}

const ItemOptions = ({ id, name }: ItemMenuProps): ReactElement => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [isMenuModalOpen, setIsMenuModalOpen] = useState<boolean>(false);
  const [isRemovePromptVisible, setIsRemovePromptVisible] = useState<boolean>(false);
  const [isAvailabilityModalOpen, setIsAvailabilityModalOpen] = useState<boolean>(false);
  const removePromptTitle = `Are you sure you want to delete "${name}"?`;
  const removePromptSubTitle = 'You will lose all the connections made with this menu.';
  const { deleteAMenu } = useMenusContext();
  const open = Boolean(anchorEl);
  const setNotification = useNotificationOutlet();
  const history = useHistory();

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const showDeletePrompt = (): void => {
    handleClose();
    setIsRemovePromptVisible(true);
  };

  const closeDeletePrompt = (): void => {
    setIsRemovePromptVisible(false);
  };

  const showModal = (): void => {
    handleClose();
    setIsModalVisible(true);
  };

  const handleMenuModal = (): void => {
    setIsMenuModalOpen((prev) => !prev);
    handleClose();
  };

  const closeModal = (): void => {
    setIsModalVisible(false);
  };

  const handleAvailabilityModal = (): void => {
    setIsAvailabilityModalOpen((prev) => !prev);
    handleClose();
  };

  const handleDelete = (): void => {
    deleteMenu(id)
      .then(() => {
        setNotification({
          severity: 'success',
          message: (
            <>
              You have successfully deleted <Typography variant="strongText">&quot;{name}&quot;</Typography>.
            </>
          ),
        });
        history.push('/');
        deleteAMenu(id);
      })
      .catch((err) => console.log(err));
  };

  return (
    <MenuContainer>
      <Modal
        title="Edit menu"
        closeModal={handleMenuModal}
        open={isMenuModalOpen}
        variant="update"
        id={id}
        menuName={name}
      />
      <ExportModal title="Export CSV" closeModal={closeModal} open={isModalVisible} id={id} name={name} />
      <RemovePrompt
        title={removePromptTitle}
        subTitle={removePromptSubTitle}
        closePrompt={closeDeletePrompt}
        open={isRemovePromptVisible}
        deleteButtonHandler={handleDelete}
      />
      <AvailabilityModal isOpen={isAvailabilityModalOpen} menuId={id} closePrompt={handleAvailabilityModal} />
      <StyledButton
        id="item-options-button"
        data-testid="item-menu-options-button"
        aria-controls={open ? 'menu-items-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
      >
        <MoreVertIcon />
      </StyledButton>
      <StyledMenu
        id="menu-items-menu"
        variant="menu"
        MenuListProps={{
          'aria-labelledby': 'menu-options-button',
        }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem data-testid="open-rename-prompt" onClick={handleMenuModal}>
          Edit
        </MenuItem>
        <MenuItem data-testid="open-export-prompt" onClick={showModal}>
          Export
        </MenuItem>
        <MenuItem data-testid="open-availability-prompt" onClick={handleAvailabilityModal}>
          Availability
        </MenuItem>
        <StyledMenuItem data-testid="open-remove-prompt" onClick={showDeletePrompt}>
          Delete
        </StyledMenuItem>
      </StyledMenu>
    </MenuContainer>
  );
};

export default ItemOptions;

import { ReactElement, useEffect } from 'react';
import Section from 'src/components/Section';
import DefaultSection from 'src/components/DefaultSection';
import { Wrapper, SpinnerContainer } from 'src/pages/Dashboard/Dashboard.styles';
import MenuClipboard from 'src/assets/images/MenuClipboard';
import ChannelsClipboard from 'src/assets/images/ChannelsClipboard';
import VenuesClipboard from 'src/assets/images/VenuesClipboard';
import { useMenusContext } from 'src/contexts/menus';
import { CircularProgress } from '@mui/material';
import { useGeographiesContext } from 'src/contexts/geographies';

export const Dashboard = (): ReactElement => {
  const { venues, aggregators } = useGeographiesContext();
  const { menus, isLoading } = useMenusContext();

  useEffect(() => {
    document.title = 'Dashboard';
  }, []);

  return (
    <Wrapper data-testid="Wrapper">
      {isLoading ? (
        <SpinnerContainer>
          <CircularProgress color="secondary" />
        </SpinnerContainer>
      ) : (
        <>
          <Section
            type="menu"
            sectionName="Menus"
            emptySectionText="Add your first menu"
            buttonText="Create menu"
            Icon={MenuClipboard}
            menus={menus}
          />
          <DefaultSection
            sectionName="Venues"
            content={venues.slice(0, 5).map((venue) => ({
              id: venue.venueId,
              name: venue.name,
              description: venue.address,
              image: venue.image,
            }))}
            type="venues"
            emptySectionText="No venues available yet"
            Icon={VenuesClipboard}
          />
          <DefaultSection
            sectionName="Channels"
            content={aggregators.map((aggregator) => ({
              id: aggregator.aggregatorId,
              name: aggregator.name,
              description: aggregator.description,
            }))}
            emptySectionText="No channels available yet"
            type="channels"
            Icon={ChannelsClipboard}
          />
        </>
      )}
    </Wrapper>
  );
};

import { API_URL } from '../constants';
import { fetch } from '../fetchWrapper/fetch';

export const updateModifierGroup = (itemId: string, modifierGroupId: string, requestBody: any): Promise<any> => {
  const url = `${API_URL}/items/${itemId}/modifier-groups/${modifierGroupId}`;

  return fetch(url, {
    method: 'PATCH',
    body: JSON.stringify(requestBody),
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

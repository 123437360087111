import { IOptionGroupPayload } from 'src/api/updateModifierGroup/types';
import { IMenuItem, IOptionGroup } from 'src/types/menu.types';
import { isPosIdChanged } from '../../utilities';

const formatCurrentOptionGroup = (
  currentOptionGroupData: IOptionGroup[],
  allItemOptions: IMenuItem[],
): IOptionGroupPayload[] => {
  const currentOptionGroups = currentOptionGroupData.map((optionGroup) => ({
    name: optionGroup.name,
    modifiers: optionGroup.items.map((option) => {
      const newOption: { item_id?: string; plu?: string } = { item_id: option.optionItemId };
      if (isPosIdChanged(option, allItemOptions)) newOption.plu = option.posId;
      return newOption;
    }),
    description: optionGroup.description,
    max_choices: parseInt(optionGroup.max),
    min_choices: parseInt(optionGroup.min),
    pk_modifier_group_id: optionGroup.optionGroupId,
  }));

  return currentOptionGroups;
};
export default formatCurrentOptionGroup;

import { IItemAggregator } from 'src/types/menu.types';
import { IAggregator } from 'src/types/aggregator.types';
import { IFormAggregator } from '../../types';

const createFormArregators = (updatedItemAggs: IItemAggregator[], allAggs: IAggregator[]): IFormAggregator[] =>
  allAggs.map((aggregator: IAggregator) => {
    const updatedItemAgg: IItemAggregator | undefined = updatedItemAggs.find(
      (itemAgg: IItemAggregator) => aggregator.aggregatorId === itemAgg.aggregatorId,
    );

    const formAggregator: IFormAggregator = {
      aggregatorId: aggregator.aggregatorId,
      aggregatorName: aggregator.name,
      price: '',
    };

    if (updatedItemAgg) {
      return {
        ...formAggregator,
        price: updatedItemAgg.price.toString(),
      };
    }

    return formAggregator;
  });

export default createFormArregators;

import { IChannelsVenues } from 'src/api/fetchChannelsVenues/types';
import { IPublication } from 'src/types/menu.types';
import { IPublishDestination } from '../types';

export const mergeChannelsAndStatuses = (
  publicationData: IPublication,
  res: IChannelsVenues[],
): IPublishDestination[] => {
  return res.map((channel) => {
    const channelStatus = publicationData.aggregators.find(
      (aggregator) => aggregator.aggregatorId === channel.external_system_id,
    );
    return {
      channel_id: channel.external_system_id,
      name: channel.name,
      description: channel.integration_type_name,
      ...(channelStatus && {
        publicationStatus: channelStatus.publicationStatus,
        publicationDate: channelStatus.publicationDate,
      }),
      venues: channel.venues.map((venue) => {
        const venueStatus = channelStatus?.venues?.find((v) => venue.venue_id === v.venueId);
        return {
          name: venue.name,
          venue_id: venue.venue_id,
          ...(venueStatus && {
            publicationDate: venueStatus.publicationDate,
            publicationStatus: venueStatus.publicationStatus,
          }),
        };
      }),
    };
  });
};

import { styled } from '@mui/material';
import { theme } from 'src/assets/theme/theme';

export const StyledInfoTooltipLogoWrapper = styled('div')`
  appearance: none;
  padding: 0;
  margin: ${theme.spacing(0.25)} 0 ${theme.spacing(-0.25)} ${theme.spacing(2)};
  background: none;
  border: none;
  cursor: help;
`;

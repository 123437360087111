import { ReactElement, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  StyledTable,
  StyledTableHeader,
  StyledTableData,
  StyledTableRow,
  StyledTypography,
  StyledMobileRow,
  StyledMobileData,
  StyledMobileTable,
  StyledImage,
  ImageContainer,
} from './Table.styles';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import Typography from '@mui/material/Typography';
import { theme } from 'src/assets/theme/theme';
import { useMediaQuery } from '@mui/material';
import emptyImage from 'src/assets/images/itemsPlaceholder.png';

export const Table = ({
  type,
  data,
  selectedRowId,
}: {
  data: Record<string, string>[];
  selectedRowId?: string;
  type?: string;
}): ReactElement => {
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const tableHeaders: string[] = Object.keys(data[0]).filter((header) => header !== 'id');
  const history = useHistory();
  useEffect(() => {
    if (selectedRowId) document.getElementById(selectedRowId).scrollIntoView();
  }, [selectedRowId]);

  const onRowClick = (id: string) => {
    if (type === 'items') history.push(`/items/${id}`);
  };

  return (
    <>
      {isMobile ? (
        <StyledMobileTable>
          {data.map((row: Record<string, string>) => (
            <StyledMobileRow
              onClick={() => onRowClick(row.id)}
              key={`row-${row.id}`}
              id={row.id}
              selectedrow={`${selectedRowId && selectedRowId === row.id}`}
            >
              {tableHeaders.map((header: string) => (
                <StyledMobileData key={`data-${row[header]}_${header}`}>
                  <StyledTypography variant="mText" variantMapping={{ mText: 'h2' }}>
                    <Typography variant="strongText">{header}</Typography>
                  </StyledTypography>
                  {header === 'image' ? (
                    <ImageContainer>
                      <StyledImage loading="lazy" src={row[header] ? row[header] : emptyImage} alt={`${row?.name}`} />
                    </ImageContainer>
                  ) : (
                    <StyledTypography>{row[header]}</StyledTypography>
                  )}
                </StyledMobileData>
              ))}
            </StyledMobileRow>
          ))}
        </StyledMobileTable>
      ) : (
        <StyledTable>
          <TableHead>
            <StyledTableRow>
              {tableHeaders.map((header: string) => (
                <StyledTableHeader key={header}>
                  <StyledTypography variant="mText" variantMapping={{ mText: 'h2' }}>
                    <Typography variant="strongText">{header}</Typography>
                  </StyledTypography>
                </StyledTableHeader>
              ))}
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {data.map((row: Record<string, string>) => (
              <StyledTableRow
                onClick={() => onRowClick(row.id)}
                key={`row-${row.id}`}
                id={row.id}
                type={type}
                selectedrow={`${selectedRowId && selectedRowId === row.id}`}
              >
                {tableHeaders.map((header: string) => (
                  <StyledTableData type={type} key={`${row[header]}-${header}`}>
                    {header === 'image' ? (
                      <ImageContainer>
                        <StyledImage loading="lazy" src={row[header] ? row[header] : emptyImage} alt={`${row?.name}`} />
                      </ImageContainer>
                    ) : (
                      <StyledTypography>{row[header]}</StyledTypography>
                    )}
                  </StyledTableData>
                ))}
              </StyledTableRow>
            ))}
          </TableBody>
        </StyledTable>
      )}
    </>
  );
};

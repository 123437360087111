import { theme } from 'src/assets/theme/theme';
import colors from 'src/assets/theme/colors';
import { Box, styled, FormHelperText } from '@mui/material';

export const PriceBandRow = styled(Box)`
  display: flex;
  width: 100%;
  ${(props) => props.theme.breakpoints.down('md')} {
    flex-wrap: wrap;
    padding-top: ${theme.spacing(5)};
    border-top: 1px dashed ${colors.AZT_Dark_Opaque};
  }
`;

export const PriceBandBox = styled(Box)`
  width: 70%;
  ${(props) => props.theme.breakpoints.down('dl')} {
    width: 100%;
  }
`;

export const StyledFormHelperText = styled(FormHelperText)`
  color: ${colors.darkRed};
`;

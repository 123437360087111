import { IAggregatorPayload, IPriceBandPayload } from 'src/api/types';
import { IFormPriceBand } from '../../types';

const formatUpdatePriceBandPayload = (priceBand: IFormPriceBand, taxRate: number): IPriceBandPayload => ({
  venue_id: priceBand.priceBandId,
  external_systems: priceBand.aggregators.map(
    (aggregator): IAggregatorPayload => ({
      external_system_id: aggregator.aggregatorId,
      base_price_with_tax: aggregator.price === '' ? null : parseFloat(aggregator.price),
      tax_rate: taxRate,
    }),
  ),
});

export default formatUpdatePriceBandPayload;

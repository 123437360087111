import { formatError } from 'src/helpers';
import { recipesCsv } from './recipes';
import type { IRecipesApiProps, IRecipesProp } from 'src/contexts/recipes';
import { IAsset, MenuItemsProp } from 'src/helpers/menus/menus.types';
import { ISection } from 'src/types/menu.types';
import { IMenuItemsResponse } from 'src/api/types';
import { IListProps } from 'src/components/List/List';

export { margarita, menuDetail } from './menudetail';

export const recipe: IRecipesProp = {
  recipe_id: 'uuid',
  name: 'Vegetable Gyoza',
  description: 'Vegetable Gyoza',
  is_vegan: false,
  is_vegetarian: false,
  contains_alcohol: false,
  nutritional_information: [],
  allergens_information: [],
  create_date: new Date('2022-03-30 12:35:06.690682'),
  update_date: new Date('2022-03-30 12:35:06.690682'),
  create_user_id: 'uid',
  update_user_id: 'uid',
};

export const asset: IAsset = {
  asset_id: 'g2gh32iherf897fdjh354',
  name: 'burger image',
  asset_variants: [
    {
      url: 'https://azzurri-product-images-dev.s3.eu-west-1.amazonaws.com/d9551a60-0a6a-4eb5-9c80-ee0851767cff',
      asset_id: 'g2gh32iherf897fdjh354',
    },
  ],
};

export const burger: MenuItemsProp = {
  item_id: 'burger',
  name: 'Burger',
  description: 'very yummy',
  currency: 'GBP',
  price: 1.5,
  assets: [asset],
  plu: '1234',
  recipes: [recipe],
  tax_rate: 20,
  type: 'STANDARD',
  create_date: new Date('2022-04-20T14:32:02.759Z'),
  update_date: new Date('2022-04-20T14:32:02.759Z'),
  create_user_id: '00uia83yqD9COeLn6696',
  update_user_id: '00uia83yqD9COeLn6696',
  modifier_groups: [],
};

export const burgerWithSauce: MenuItemsProp = {
  item_id: 'veganburger',
  name: 'Vegan Burger',
  description: 'very yummy plant based goodness',
  currency: 'GBP',
  price: 11.5,
  assets: [asset],
  plu: '1234',
  recipes: [recipe],
  tax_rate: 20,
  type: 'STANDARD',
  create_date: new Date('2022-04-20T14:32:02.759Z'),
  update_date: new Date('2022-04-20T14:32:02.759Z'),
  create_user_id: '00uia83yqD9COeLn6696',
  update_user_id: '00uia83yqD9COeLn6696',
  modifier_groups: [
    {
      name: 'sauce',
      modifiers: [
        {
          item: {
            name: 'Ketchup',
            item_id: 'e61845aa-7a92-41ad-a7b0-fecb37456345',
          },
          fk_item_id: 'e61845aa-7a92-41ad-a7b0-fecb37456345',
          pk_modifier_id: '8f3c16a3-97b2-4fec-9fe7-ebbf334fc9f2',
        },
      ],
      description: 'string',
      max_choices: 1,
      min_choices: 0,
      pk_modifier_group_id: '0daf7589-d1bb-47ad-b4f2-e97cb3f3e532',
    },
    {
      name: 'extras',
      modifiers: [
        {
          item: {
            name: 'Pickles',
            item_id: 'e61845aa-7a92-41ad-a7b0-fecb37456385',
          },
          fk_item_id: 'e61845aa-7a92-41ad-a7b0-fecb37456385',
          pk_modifier_id: '8f3c16a3-97b2-4fec-9fe7-ebbf334fc9f8',
        },
      ],
      description: 'string',
      max_choices: 1,
      min_choices: 0,
      pk_modifier_group_id: '0daf7589-d1bb-47ad-b4f2-e97cb3f3e538',
    },
  ],
};

export const hotdog: MenuItemsProp = {
  item_id: 'hotdog',
  name: 'Hot Dog',
  description: 'very yummy',
  currency: 'GBP',
  price: 1.5,
  assets: [asset],
  plu: '1234',
  recipes: [recipe],
  tax_rate: 20,
  type: 'STANDARD',
  create_date: new Date('2022-04-20T14:40:02.891Z'),
  update_date: new Date('2022-04-20T14:40:02.891Z'),
  create_user_id: '00uia83yqD9COeLn6696',
  update_user_id: '00uia83yqD9COeLn6696',
};

export const ketchup: MenuItemsProp = { ...hotdog, ...{ item_id: 'ketchup', name: 'Ketchup', type: 'MODIFIER' } };

export const pizza: MenuItemsProp = { ...hotdog, ...{ item_id: 'pizza', name: 'Pizza' } };

const camdenVenue = {
  id: 'camdenId',
  name: 'Camden',
  description: '176 Royal College Street',
};

const hampsteadVenue = {
  id: 'hampsteadId',
  name: 'Hampstead',
  description: '83-84 Hampstead High St',
};

const podVenue = {
  id: '79442972-9aaf',
  name: 'POD test',
  description: 'Pod service',
  image: 'image test',
};

const deliverooService = {
  id: 'deliveroo',
  name: 'Deliveroo',
  description: 'Delivery Service',
};

const justEatService = {
  id: 'justeat',
  name: 'Just Eat',
  description: 'Just Eat Service',
};

const uberEatsService = {
  id: 'ubereats',
  name: 'Uber Eats',
  description: 'Uber Eats Service',
};

export const menuItems = [burger, hotdog, ketchup, pizza];

export const venueList = [camdenVenue, hampsteadVenue, podVenue];

export const channelList = [deliverooService, justEatService, uberEatsService];

export const menu = {
  menu_id: 'summer-menu',
  name: 'Summer Menu',
  create_date: new Date('2022-01-30 12:35:06.690682'),
  update_date: new Date('2022-01-30 12:35:06.690682'),
  items: menuItems,
  assets: [asset],
  sections: [
    {
      section_id: 'new-section',
      name: 'new section',
      items: [ketchup],
      create_date: new Date('2022-03-30 12:35:06.690682'),
      update_date: new Date('2022-03-30 12:35:06.690682'),
      create_user_id: 'abcde',
      update_user_id: 'abcde',
    },
  ],
};

export const newSectionResponse = {
  type: 'sections',
  data: [
    {
      section_id: 'new-section',
      name: 'new section',
      items: [hotdog.item_id],
      create_date: new Date('2022-03-30 12:35:06.690682'),
      update_date: new Date('2022-03-30 12:35:06.690682'),
      create_user_id: 'abcde',
      update_user_id: 'abcde',
    },
  ],
};

export const channelsResponse = {
  type: 'channels',
  data: [
    {
      channel_id: 'uuid string',
      name: 'string',
      create_date: new Date('2022-03-30 12:35:06.690682'),
      update_date: new Date('2022-03-30 12:35:06.690682'),
      create_user_id: 'abcde',
      update_user_id: 'abcde',
      venues: [
        { venue_id: 'uuid venue string', name: 'venue string 1' },
        { venue_id: 'uuid venue string 2', name: 'venue string 2' },
      ],
    },
    {
      channel_id: 'uuid string 2',
      name: 'string 2',
      create_date: new Date('2022-03-30 12:35:06.690682'),
      update_date: new Date('2022-03-30 12:35:06.690682'),
      create_user_id: 'abcde',
      update_user_id: 'abcde',
      venues: [],
    },
  ],
};

export const venuesResponse = {
  type: 'venues',
  data: [
    {
      venue_id: 'uuid string',
      name: 'string',
      pickup_address: 'string',
      creation_date: '2022-03-30 12:35:06.690682',
      modification_date: '2022-03-30 12:35:06.690682',
      price_band: 'string',
      fk_parent_venue_id: 'uuid string',
      image_url: 'string',
      tmp_is_price_tier: false,
      addresses: [],
    },
  ],
};

export const recipesResponse: IRecipesApiProps = {
  type: 'recipes',
  data: [
    {
      recipe_id: 'uuid-string',
      name: 'test 1',
      description: 'string',
      is_vegan: false,
      is_vegetarian: false,
      contains_alcohol: false,
      nutritional_information: [
        {
          nutrition_id: 'uuid nutrition string',
          name: 'string',
          value_high: 20,
          value_low: 20,
        },
      ],
      allergens_information: [
        {
          allergen_id: 'uuid allergen string',
          name: 'string',
          child_allergens: null,
        },
      ],
      create_date: new Date('2022-03-30 12:35:06.690682'),
      update_date: new Date('2022-03-30 12:35:06.690682'),
      create_user_id: 'abcde',
      update_user_id: 'abcde',
    },
  ],
};

export const menuWithSections = {
  type: 'menus',
  data: [
    {
      menu_id: 'summer-menu',
      name: 'Summer Menu',
      description: 'string',
      seo_description: 'string',
      create_date: new Date('2022-03-30 12:35:06.690682'),
      update_date: new Date('2022-03-30 12:35:06.690682'),
      create_user_id: 'abcde',
      update_user_id: 'abcde',
      assets: [],
      sections: [
        {
          section_id: 'uuid-string',
          name: 'string',
          create_date: new Date('2022-03-30 12:35:06.690682'),
          update_date: new Date('2022-03-30 12:35:06.690682'),
          create_user_id: 'abcde',
          update_user_id: 'abcde',
          items: [burger],
        },
        {
          section_id: 'hotmains-id',
          name: 'Hot Mains',
          create_date: new Date('2022-03-30 12:35:06.690682'),
          update_date: new Date('2022-03-30 12:35:06.690682'),
          create_user_id: 'abcde',
          update_user_id: 'abcde',
          items: [],
        },
      ],
      items: [hotdog],
    },
  ],
};

export const menuObject = {
  type: 'menu',
  data: [
    Object.assign({}, menu, { menu_id: 'summer-menu' }),
    Object.assign({}, menu, { menu_id: 'uuid-string-2', create_date: '2019-03-26 12:35:06.690682' }),
    Object.assign({}, menu, { menu_id: 'uuid-string-3', create_date: '2022-02-26 12:35:06.690682' }),
  ],
};

export const menuObjectWith11Menus = {
  type: 'menu',
  data: [
    Object.assign({}, menu, { menu_id: 'testmenu1' }),
    Object.assign({}, menu, { menu_id: 'testmenu2' }),
    Object.assign({}, menu, { menu_id: 'testmenu3' }),
    Object.assign({}, menu, { menu_id: 'testmenu4' }),
    Object.assign({}, menu, { menu_id: 'testmenu5' }),
    Object.assign({}, menu, { menu_id: 'testmenu6' }),
    Object.assign({}, menu, { menu_id: 'testmenu7' }),
    Object.assign({}, menu, { menu_id: 'testmenu8' }),
    Object.assign({}, menu, { menu_id: 'testmenu9' }),
    Object.assign({}, menu, { menu_id: 'testmenu10' }),
    Object.assign({}, menu, { menu_id: 'testmenu11' }),
  ],
};

export const menuItem = {
  type: 'items',
  data: menuItems,
};

export const oneItem = {
  type: 'items',
  data: [burger],
};

export const oneModifier = {
  type: 'items',
  data: [ketchup],
};

export const mockData = [
  {
    name: 'Menus',
    content: [menuObject.data[0]],
  },
];

export const mockData11Menus = [
  {
    name: 'Menus',
    content: [menuObjectWith11Menus.data],
  },
];

export const mockRecipeData = [
  {
    name: 'Recipes',
    content: [],
  },
];

export const mockLogIn = {
  authState: {
    accessToken: {
      accessToken: 'accessToken',
    },
    idToken: {
      claims: {
        name: 'Joe Bloggs',
      },
    },
    isAuthenticated: true,
  },
  authService: {},
};
export const loggedOut = {
  authState: {},
  authService: {},
};

const errorFromServer = {
  severity: 'error',
  description: 'Internal Server Error',
  message: 'We have been unable to complete your request.\nPlease try again.',
};

const successFromServer = {
  severity: 'success',
  message: 'Success',
};

export const recipeData: Record<string, string> = {
  name: 'Teriyaki - Panko Prawns (Small)',
  allergens: 'Gluten - Wheat, Crustaceans, Soya, Tree Nuts, Garlic',
  calories: '588',
  ingredients:
    'Teriyaki Sauce POD, Panko Prawns, CHILLIES RED DUTCH, CORIANDER BUNCH X 100GM SP, SPRING ONIONS X 1 BUN SP, Thai Jasmine Rice - POD',
  category: 'Vegetarian, Vegan',
};

export const itemsData: Record<string, string> = {
  id: 'teriyaki-id',
  image: '',
  name: 'teriyaki',
  description: 'Gluten - Wheat, Crustaceans, Soya, Tree Nuts, Garlic',
  price: '£11',
  type: 'standard',
};

export const error = formatError(errorFromServer);
export const success = formatError(successFromServer);

export const recipesApiResponse: IRecipesApiProps = {
  type: 'recipes',
  data: recipesCsv.map((food, index) => {
    const newrecipe: IRecipesProp = { ...recipe };
    newrecipe.name = food['Item Name'];
    newrecipe.recipe_id = food['Recipe ID'];
    newrecipe.is_vegan = food.Vegan;
    newrecipe.is_vegetarian = food.Vegetarian;
    if (food.Kcal)
      newrecipe.nutritional_information = [
        {
          nutrition_id: 'uuid-kcal',
          name: 'energy',
          value_low: index === 0 ? food.Kcal - 100 : food.Kcal,
          value_high: food.Kcal,
        },
      ];

    newrecipe.allergens_information = Object.entries(food)
      .filter(
        ([key, value]) =>
          !['Recipe ID', 'Item Name', 'Kcal', 'Ingredients', 'Vegetarian', 'Vegan'].includes(key) && value,
      )
      .map(([key], idx) => ({
        allergen_id: key,
        name: key,
        child_allergens:
          index === 0 && idx == 0
            ? [
                {
                  allergen_id: key,
                  name: 'child allergen',
                },
              ]
            : null,
      }));
    newrecipe.description = food.Ingredients.split(',')
      .map((ingredient) => ingredient.trim().toString())
      .join(', ');
    return newrecipe;
  }),
};

export const SummerMenu = {
  menu_id: 'summer-menu',
  name: 'Summer Menu',
  description: 'No description',
  seo_description: 'No seo description',
  create_date: '2022-04-27T13:39:49.485',
  update_date: '2022-04-28T12:04:39.582',
  create_user_id: '00uia83yqD9COeLn6696',
  update_user_id: '00uia83yqD9COeLn6696',
  sections: [
    {
      name: 'Hot Mains',
      section_id: 'hot-main',
      create_date: '2022-04-28T12:37:37.713',
      update_date: '2022-04-28T12:37:37.713',
      create_user_id: '00uia83yqD9COeLn6696',
      update_user_id: '00uia83yqD9COeLn6696',
      items: [
        {
          name: 'Bibimbap',
          price: 9,
          item_id: 'bibimbap',
          currency: 'GBP',
          create_date: '2022-04-28T07:01:09.289',
          description: 'Korean bibimbap looks gorgeous on the plate.',
          update_date: '2022-04-28T07:01:09.289',
          create_user_id: '00uia83yqD9COeLn6696',
          update_user_id: '00uia83yqD9COeLn6696',
        },
        {
          name: 'Bulgogi',
          price: 7,
          item_id: 'bulgogi',
          currency: 'GBP',
          create_date: '2022-04-28T07:02:01.364',
          description: 'Thinly sliced meat that has a smoky-sweet flavor',
          update_date: '2022-04-28T07:02:01.364',
          create_user_id: '00uia83yqD9COeLn6696',
          update_user_id: '00uia83yqD9COeLn6696',
        },
      ],
    },
    {
      name: 'Sides',
      section_id: 'sides',
      create_date: '2022-04-28T12:34:57.217',
      update_date: '2022-04-28T12:34:57.217',
      create_user_id: '00uia83yqD9COeLn6696',
      update_user_id: '00uia83yqD9COeLn6696',
      items: [
        {
          name: 'Mandoo',
          price: 3.5,
          item_id: 'mandoo',
          currency: 'GBP',
          create_date: '2022-04-28T07:02:45.121',
          description: 'Dumplings',
          update_date: '2022-04-28T07:02:45.121',
          create_user_id: '00uia83yqD9COeLn6696',
          update_user_id: '00uia83yqD9COeLn6696',
        },
        {
          name: 'Kim Chi',
          price: 2.5,
          item_id: 'kim-chi',
          currency: 'GBP',
          create_date: '2022-04-28T12:34:40.102',
          description: 'Fragrant and Tasty',
          update_date: '2022-04-28T12:34:40.102',
          create_user_id: '00uia83yqD9COeLn6696',
          update_user_id: '00uia83yqD9COeLn6696',
        },
      ],
    },
  ],
  items: [],
};

export const OfflineMenus = {
  type: 'menu',
  data: [SummerMenu],
};

export const publishDestinations = [
  {
    name: 'Just Eat',
    channel_id: '12345',
    venues: [
      {
        name: 'Camden',
        venue_id: '12345',
        phone_number: '020 123 456 78',
        mobile_number: '070 123 456  789',
        fax: '123-456-789',
        email: 'venue ',
        pickup_address: '123 Venue street',
      },
      {
        name: 'Marble arch venue',
        venue_id: '78910',
        phone_number: '020 123 456 78',
        mobile_number: '070 123 456  789',
        fax: '123-456-789',
        email: 'venue ',
        pickup_address: '123 Venue street',
      },
    ],
  },
  {
    name: 'Deliveroo',
    channel_id: '67890',
    venues: [
      {
        name: 'Tottenham court road',
        venue_id: '12345',
        phone_number: '020 123 456 78',
        mobile_number: '070 123 456  789',
        fax: '123-456-789',
        email: 'venue ',
        pickup_address: '123 Venue street',
      },
      {
        name: 'The strand',
        venue_id: '78910',
        phone_number: '020 123 456 78',
        mobile_number: '070 123 456  789',
        fax: '123-456-789',
        email: 'venue ',
        pickup_address: '123 Venue street',
      },
    ],
  },
];

export const menuSectionReorderResponse = {
  data: [
    { section_id: 'B', display_order: 1 },
    { section_id: 'sectionId', display_order: 2 },
    { section_id: 'c', display_order: 3 },
  ],
};

export const menuSectionNoItems: ISection = {
  sectionId: 'sectionId',
  description: 'description',
  displayOrder: 0,
  name: 'sectionName',
  items: [],
};

export const brandsResponse = {
  type: 'brands',
  data: [
    {
      brand_id: '123asd',
      name: 'Ask Italian',
    },
    {
      brand_id: '456fgh',
      name: 'Coco di Mama',
    },
    {
      brand_id: '789jkl',
      name: 'Pod',
    },
    {
      brand_id: '234sdf',
      name: 'Zizzi',
    },
  ],
};

export const venuesResponseAPI = {
  type: 'venues',
  data: [
    {
      venue_id: 'uuid string',
      name: 'string',
      phone_number: 'string',
      mobile_number: 'string',
      fax: 'string',
      email: 'string',
      pickup_address: 'string',
      create_date: '2022-03-30 12:35:06.690682',
      update_date: '2022-03-30 12:35:06.690682',
      create_user_id: 'abcde',
      update_user_id: 'abcde',
      price_band: 'price band name',
      assets: [],
      addresses: [],
    },
  ],
};

export const localStorageMock = (function () {
  let store = {};

  return {
    getItem(key) {
      return store[key];
    },

    setItem(key, value) {
      store[key] = value;
    },

    clear() {
      store = {};
    },

    removeItem(key) {
      delete store[key];
    },

    getAll() {
      console.log(store);
    },
  };
})();

export const mockBrandId = '12c41f3c-1a97-4499-a9ff-f498cbfde2a3';
export const itemAPIResponse = {
  type: 'items',
  data: [
    {
      plu: '12213',
      name: 'Greek Salad',
      type: 'STANDARD',
      price: 23,
      assets: [
        {
          name: '__opt__aboutcom__coeus__resources__content_migration__simply_recipes__uploads__2011__08__dads-greek-salad-horiz-a-1600-bc71b85bc907414a9bb3179a923b58fc.jpeg',
          asset_id: '63654c4e-dade-4a79-90e9-ca2d9e093a50',
          create_date: '2022-06-10T16:12:32.121',
          update_date: '2022-06-10T16:12:32.121',
          asset_variants: [
            {
              url: 'https://azzurri-product-images-dev.s3.eu-west-1.amazonaws.com/63654c4e-dade-4a79-90e9-ca2d9e093a50',
              asset_id: '63654c4e-dade-4a79-90e9-ca2d9e093a50',
            },
          ],
          create_user_id: '00uiaajdy8OYTlMyl696',
          update_user_id: '00uiaajdy8OYTlMyl696',
        },
      ],
      item_id: '3e310693-59ef-4874-b1f5-5432ddda307a',
      currency: 'GBP',
      tax_rate: 20,
      create_date: '2022-06-10T16:12:32.121',
      description: 'Healthy salad',
      update_date: '2022-07-06T11:28:06.459',
      is_standalone: true,
      create_user_id: '00uiaajdy8OYTlMyl696',
      update_user_id: '00uiaajdy8OYTlMyl696',
      display_order: 0,
    },
    {
      plu: '435345',
      name: "Steve's Gyoza",
      type: 'STANDARD',
      price: 43,
      assets: [],
      fk_parent_item_id: 'parent id',
      item_id: 'eaef476f-cc15-432a-bf6f-d59e0daa217f',
      currency: 'GBP',
      tax_rate: 20,
      create_date: '2022-07-06T13:10:54.508',
      description: '45435',
      update_date: '2022-07-06T13:10:54.508',
      is_standalone: null,
      create_user_id: '00uiaajdy8OYTlMyl696',
      update_user_id: '00uiaajdy8OYTlMyl696',
    },
    {
      plu: '12',
      name: 'garlic bread',
      type: 'STANDARD',
      price: 12,
      assets: [],
      item_id: '6f39c5f7-fdff-46ba-a689-c984fd03b08d',
      currency: 'GBP',
      tax_rate: 12,
      create_date: '2022-07-22T15:35:29.618',
      description: '12',
      update_date: '2022-07-22T15:35:29.618',
      is_standalone: true,
      create_user_id: '00uiaajdy8OYTlMyl696',
      update_user_id: '00uiaajdy8OYTlMyl696',
      display_order: 0,
    },
    {
      plu: '12',
      name: 'create uncategorised',
      type: 'MODIFIER',
      price: 12,
      assets: [],
      item_id: 'efcd5e28-5571-416c-bbae-2967bc7d4698',
      currency: 'GBP',
      tax_rate: 12,
      fk_parent_item_id: 'parent id',
      create_date: '2022-07-22T16:03:53.569',
      description: '12',
      update_date: '2022-07-22T16:03:53.569',
      is_standalone: false,
      create_user_id: '00uiaajdy8OYTlMyl696',
      update_user_id: '00uiaajdy8OYTlMyl696',
    },
    {
      plu: '12',
      name: 'apple pie',
      type: 'STANDARD',
      price: 12,
      assets: [],
      item_id: 'a0b3c334-efa7-4d50-a1c0-3db8b13e0503',
      currency: 'GBP',
      tax_rate: 12,
      create_date: '2022-07-25T14:09:08.256',
      description: 'ewr',
      update_date: '2022-07-25T14:09:08.256',
      is_standalone: true,
      create_user_id: '00uiaajdy8OYTlMyl696',
      update_user_id: '00uiaajdy8OYTlMyl696',
    },
  ],
};

export const priceBandListData: IListProps[] = [
  {
    listItemSecondaryText: 'Premium',
    listItemPrimaryText: 'Price Band',
    listItemId: '123',
    listItemDetails: [
      {
        primaryText: 'Deliveroo (£)',
        name: 'Deliveroo',
        value: '9.3',
        id: '49988591-da0c-4719-86b8-54bf89485668',
      },
      {
        primaryText: 'Just Eat (£)',
        name: 'Just Eat',
        value: '9.5',
        id: 'ef7536c6-05c1-4672-8709-c9b7d33a67c1',
      },
      {
        primaryText: 'Uber Eats (£)',
        name: 'Uber Eats',
        value: '9.4',
        id: '5408ffb1-e533-43ff-8106-138058cc4b53',
      },
    ],
  },
  {
    listItemPrimaryText: 'Price Band',
    listItemSecondaryText: 'Mid',
    listItemId: '124',
    listItemDetails: [
      {
        primaryText: 'Deliveroo (£)',
        name: 'Deliveroo',
        value: '9.3',
        id: '49988591-da0c-4719-86b8-54bf89485668',
      },
      {
        primaryText: 'Just Eat (£)',
        name: 'Just Eat',
        value: '9.5',
        id: 'ef7536c6-05c1-4672-8709-c9b7d33a67c1',
      },
      {
        primaryText: 'Uber Eats (£)',
        name: 'Uber Eats',
        value: '9.4',
        id: '5408ffb1-e533-43ff-8106-138058cc4b53',
      },
    ],
  },
  {
    listItemPrimaryText: 'Price Band',
    listItemSecondaryText: 'Lower',
    listItemId: '125',
    listItemDetails: [
      {
        primaryText: 'Deliveroo (£)',
        name: 'Deliveroo',
        value: '1',
        id: '49988591-da0c-4719-86b8-54bf89485668',
      },
      {
        primaryText: 'Just Eat (£)',
        name: 'Just Eat',
        value: '9.5',
        id: 'ef7536c6-05c1-4672-8709-c9b7d33a67c1',
      },
      {
        primaryText: 'Uber Eats (£)',
        name: 'Uber Eats',
        value: '9.4',
        id: '5408ffb1-e533-43ff-8106-138058cc4b53',
      },
    ],
  },
];

export const burgers: IMenuItemsResponse = {
  item_id: 'burger',
  name: 'Burger',
  description: 'very yummy',
  currency: 'GBP',
  price: 1.5,
  assets: [asset],
  plu: '1234',
  recipes: [],
  tax_rate: 20,
  type: 'STANDARD',
  create_user_id: '00uia83yqD9COeLn6696',
  update_user_id: '00uia83yqD9COeLn6696',
  modifier_groups: [],
  prices: {
    default_base_price_with_tax: 1.5,
    tax_rate: 20,
    price_bands: [],
  },
  display_order: 1,
  create_date: '2022-03-30 12:35:06.690682',
  update_date: '2022-03-30 12:35:06.690682',
};

export const ketchups: IMenuItemsResponse = {
  ...burgers,
  ...{ item_id: 'ketchup', name: 'Ketchup', type: 'MODIFIER' },
};
export const item1 = {
  type: 'items',
  data: [burgers],
};

export const modifier1 = {
  type: 'items',
  data: [ketchups],
};
